import { MeetupMapBlock } from '@/src/@types/statamic'
import { getAddresses } from '@/src/BlinkAdminApiClient'
import Content from '@/src/components/elements/Content'
import Marker, {
  getIconBasedOnLocationType,
} from '@/src/components/elements/Marker'
import MeetupMap from '@/src/components/elements/MeetupMap'
import Title from '@/src/components/elements/Title'
import CourseMarker from '@/src/components/icons/CourseMarker'
import ExamMarker from '@/src/components/icons/ExamMarker'
import LessonMarker from '@/src/components/icons/LessonMarker'
import WabMarker from '@/src/components/icons/WabMarker'
import Container from '@/src/components/layout/Container'

type MeetupMapBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export async function getStaticProps({
  title,
  text,
  addresses,
  center_latitude,
  center_longitude,
}: MeetupMapBlock) {
  const locations = await getAddresses()
  return {
    title,
    text,
    locations,
    addresses,
    center_latitude,
    center_longitude,
  }
}

export default function Component({
  title,
  text,
  locations,
  addresses,
  center_latitude,
  center_longitude,
}: MeetupMapBlockProps) {
  const mapMarkers: [number, number][] | undefined =
    !center_latitude || !center_longitude
      ? locations
          .filter((location) =>
            addresses.some(
              (address) => address.value === location.id.toString()
            )
          )
          .map((location) => [location.longitude, location.latitude])
      : undefined

  const mapCenter =
    center_latitude && center_longitude
      ? { lat: center_latitude, lng: center_longitude }
      : undefined

  return (
    <Container>
      <Title style={2}>{title}</Title>
      {text && <Content content={text} className="mt-8 md:mt-10" />}
      <div className="mt-10 md:mt-8 md:flex">
        {locations.some(
          (location) =>
            location.type === 'pickup' ||
            location.type === 'pgs-pickup' ||
            location.type === 'parking-lot'
        ) && (
          <div className="mt-2.5 flex items-center first:ml-0 first:mt-0 md:ml-10 md:mt-0">
            <LessonMarker className="h-[38px] w-[30px] text-blue-900 md:h-[51px] md:w-[41px]" />
            <p className="ml-[15px] text-sm md:text-base">
              Treffpunkt Fahrlektionen
            </p>
          </div>
        )}
        {locations.some((location) => location.type === 'course') && (
          <div className="mt-2.5 flex items-center first:ml-0 first:mt-0 md:ml-10 md:mt-0">
            <CourseMarker className="h-[38px] w-[30px] text-blue-900 md:h-[51px] md:w-[41px]" />
            <p className="ml-[15px] text-sm md:text-base">Kurslokal</p>
          </div>
        )}
        {locations.some((location) => location.type === 'exam-office') && (
          <div className="mt-2.5 flex items-center first:ml-0 first:mt-0 md:ml-10 md:mt-0">
            <ExamMarker className="h-[38px] w-[30px] text-blue-900 md:h-[51px] md:w-[41px]" />
            <p className="ml-[15px] text-sm md:text-base">Prüfungszentrum</p>
          </div>
        )}
        {locations.some((location) => location.type === 'wab') && (
          <div className="mt-2.5 flex items-center first:ml-0 first:mt-0 md:ml-10 md:mt-0">
            <WabMarker className="h-[38px] w-[30px] text-blue-900 md:h-[51px] md:w-[41px]" />
            <p className="ml-[15px] text-sm md:text-base">WAB Standort</p>
          </div>
        )}
      </div>
      {locations.length !== 0 && (
        <div className="mt-5 md:mt-8">
          <MeetupMap markers={mapMarkers} center={mapCenter}>
            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{
                  lat: location.latitude,
                  lng: location.longitude,
                }}
                icon={getIconBasedOnLocationType(location.type)}
                size="huge"
              >
                {`
              <div>
              <h4 class="font-bold text-base md:text-lg">${location.name}</h4>
              <a class="text-purple-600 hover:text-purple-800 underline text-sm md:text-base" href="https://www.google.com/maps/place/${
                location.place
              }+${location.street.replace(
                ' ',
                '+'
              )}" target="_blank" rel="noopener noreferrer">
                ${location.street}</br>${location.place}
              </a>
              </div>
              `}
              </Marker>
            ))}
          </MeetupMap>
        </div>
      )}
    </Container>
  )
}
