import classNames from 'classnames'
import Container from '../../components/layout/Container'
import { StoryBlock } from '@/src/@types/statamic'
import Bard from '@/src/components/bard/Bard'
import Picture, {
  illustrationBackgrounds,
} from '@/src/components/elements/Picture'
import styles from '/src/css/Masks.module.css'
import Title from '@/src/components/elements/Title'

export function getTitle({ story_parts }: StoryBlock) {
  return story_parts[0].title
}

export default function Component({ story_parts }: StoryBlock) {
  return (
    <Container>
      <div className="grid grid-cols-1 gap-y-28 md:py-20">
        {story_parts.map((story_part, index) => (
          <div key={index} className="grid grid-cols-1 justify-center md:flex">
            <div
              className={classNames([
                'flex items-center md:mx-[25px] lg:mx-[50px]',
                {
                  'justify-start': index % 2 === 0,
                  'justify-end': index % 2 !== 0,
                },
              ])}
            >
              <div className="relative size-52 md:size-72">
                <svg
                  viewBox="0 0 102 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames(
                    'absolute bottom-[-20%] -z-10 w-[40%] md:w-[50%]',
                    {
                      'left-auto right-0': index % 2 !== 0,
                      'left-0': index % 2 === 0,
                    }
                  )}
                >
                  <path
                    opacity="0.5"
                    d="M89.7472 18.9626C66.621 -4.75209 41.2718 -4.80699 18.5084 10.9573C-3.78328 27.0911 -5.08067 58.7429 10.0163 82.0199C25.1133 105.297 58.7277 105.174 79.2501 92.3652C99.7726 79.5567 112.874 42.6773 89.7472 18.9626Z"
                    fill="url(#paint0_linear_3406_42)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3406_42"
                      x1="-6.97"
                      y1="4.545"
                      x2="109.921"
                      y2="92.2015"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FBFBFF" />
                      <stop offset="1" stopColor="#DAE0FF" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg
                  width="236"
                  height="246"
                  viewBox="0 0 236 246"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={classNames(
                    'absolute top-[-40%] -z-10 w-[70%] md:w-[80%]',
                    {
                      'left-auto right-[-20%]': index % 2 !== 0,
                      'left-[-20%] ': index % 2 === 0,
                    }
                  )}
                >
                  <path
                    d="M65.3154 237.662C65.3154 237.662 2.03979 219.36 0.0472419 125.137C-1.9453 30.9136 59.6403 15.2546 81.0443 5.27223C102.448 -4.71013 166.834 -1.67484 209.154 40.2291C251.475 82.1331 250.038 223.75 158.976 240.769C93.5597 252.995 65.3154 237.662 65.3154 237.662Z"
                    fill="url(#paint0_linear_3447_8062)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3447_8062"
                      x1="10.62"
                      y1="262.242"
                      x2="222.725"
                      y2="-12.911"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FBFBFF" />
                      <stop offset="1" stopColor="#DAE0FF" />
                    </linearGradient>
                  </defs>
                </svg>

                <div
                  className={`absolute inset-0 mx-auto size-52 md:size-72 ${
                    story_part.image.illustration_background
                      ? illustrationBackgrounds[
                          story_part.image.illustration_background.value
                        ]
                      : 'bg-gradient-600'
                  } ${styles['mask-11']} ${styles['mask']}`}
                >
                  <div className="absolute inset-0">
                    <Picture
                      priority
                      {...story_part.image}
                      fill
                      className="object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames('mt-2 md:mt-0 md:w-1/2', {
                'md:-order-1 md:ml-[50px] lg:ml-[100px]': index % 2 !== 0,
                'md:mr-[50px] lg:mr-[100px]': index % 2 === 0,
              })}
            >
              {story_part.subtitle && (
                <p className="mb-5 font-bold md:text-xl">
                  {story_part.subtitle}
                </p>
              )}
              <Title style={2}>{story_part.title}</Title>
              <Bard className="mt-6" content={story_part.text} fullWidth />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
