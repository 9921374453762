import Link from 'next/link'
import IconButton from '../form/IconButton'
import PhoneIcon from '../icons/PhoneIcon'

export default function PhoneButton({
  number = '0315391065',
}: {
  number?: string
}) {
  return (
    <Link href={`tel:${number}`} passHref legacyBehavior>
      <IconButton as="a" size="small" target="_blank" rel="noopener noreferrer">
        <PhoneIcon className="size-3.5" />
        <span className="sr-only">Kontakt Telefon</span>
      </IconButton>
    </Link>
  )
}
