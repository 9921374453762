import classNames from 'classnames'
import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  useId,
  useState,
} from 'react'

const FileInput = (
  {
    style,
    ...props
  }: ComponentPropsWithoutRef<'input'> & { style?: 'purple' | 'white' },
  ref: ForwardedRef<HTMLInputElement>
) => {
  const id = `file-input-${useId()}`

  const [fileName, setFileName] = useState<string>()

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.files?.[0]?.name ?? '')
    props.onChange?.(event)
  }

  return (
    <div className="flex items-baseline">
      <label
        htmlFor={id}
        className={classNames([
          'cursor-pointer rounded-full border-2 px-5 pb-[11px] pt-3 text-sm font-bold !leading-[21px]  md:text-base',
          {
            'border-purple-700 text-purple-700 hover:bg-purple-700 hover:text-white':
              !style || style === 'purple',
            'border-white text-white': style === 'white',
          },
        ])}
      >
        Bild hochladen
      </label>
      {fileName && <p className="ml-2 text-sm font-bold">{fileName}</p>}
      <input
        ref={ref}
        id={id}
        type="file"
        className="sr-only"
        {...props}
        onChange={(event) => {
          changeHandler(event)
          if (props.onChange) props.onChange(event)
        }}
      />
    </div>
  )
}
export default forwardRef(FileInput)
