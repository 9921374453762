import Icon from '../icons/Icon'

export default function WhatsappIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.7382 10.4331C12.6855 10.3449 12.5457 10.2933 12.3339 10.1869C12.1232 10.0815 11.0889 9.57191 10.8954 9.50202C10.7029 9.43214 10.5632 9.39666 10.4223 9.60739C10.2826 9.81811 9.87832 10.2922 9.75575 10.4331C9.63319 10.5729 9.50955 10.5911 9.2999 10.4858C9.08917 10.3793 8.41076 10.1579 7.60656 9.44074C6.98084 8.88275 6.55831 8.19359 6.43574 7.98179C6.31318 7.77214 6.42284 7.65817 6.52821 7.55281C6.62282 7.4582 6.73786 7.3066 6.84429 7.18404C6.95073 7.06255 6.98621 6.97439 7.05717 6.83354C7.12705 6.69378 7.09265 6.57014 7.03997 6.46477C6.98514 6.35941 6.56476 5.32298 6.38951 4.90153C6.21857 4.49191 6.0444 4.54674 5.91538 4.54029L5.5122 4.53276C5.37244 4.53276 5.14343 4.58544 4.95099 4.79617C4.75854 5.00797 4.21452 5.51651 4.21452 6.55293C4.21452 7.58936 4.96926 8.59031 5.07355 8.73008C5.17891 8.86985 6.55723 10.9965 8.66879 11.9082C9.17088 12.1253 9.5633 12.2544 9.86864 12.3511C10.3729 12.5113 10.832 12.4887 11.1943 12.435C11.5985 12.3748 12.4393 11.9254 12.6156 11.434C12.7919 10.9427 12.7919 10.5213 12.7382 10.4331ZM4.67253 14.3036C5.85947 15.0089 6.99266 15.4303 8.4914 15.4314C12.35 15.4314 15.4937 12.2909 15.4959 8.43012C15.497 4.56179 12.3683 1.42455 8.49677 1.42348C4.6349 1.42348 1.49336 4.56394 1.49228 8.42474C1.49121 10.0009 1.95352 11.1803 2.72869 12.4156L2.02232 14.9981L4.67253 14.3036ZM0 17L1.19447 12.635C0.456931 11.3566 0.0698836 9.90842 0.0698836 8.42367C0.073109 3.77909 3.8522 0 8.49462 0C10.7481 0.00107513 12.8629 0.878384 14.4541 2.47066C16.0442 4.06293 16.9194 6.17986 16.9194 8.43012C16.9172 13.0747 13.1381 16.8538 8.4957 16.8538C7.0862 16.8527 5.69713 16.5001 4.46718 15.8281L0 17Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
