import { GetStaticPropsContext } from 'next'
import Link from 'next/link'
import {
  ImageType,
  MagazineHeroBlock,
  PartnersHeroBlock,
} from '../../@types/statamic'
import Picture from '../../components/elements/Picture'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import PartnersQuery from '../../graphql/PartnersQuery.graphql'
import { request } from '../../StatamicGraphQLClient'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import Content from '@/src/components/elements/Content'

export function getTitle({ title }: PartnersHeroBlock) {
  return title
}

export function getDescription({ text }: MagazineHeroBlock) {
  if (text) {
    const pTag = text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export async function getStaticProps(
  props: PartnersHeroBlock,
  page: GetStaticPropsContext<PageParams, PreviewData>
) {
  const {
    entries: { data: entries },
  } = await request<{
    entries: {
      data: {
        uri: string
        title: string
        image: ImageType
        partner_icon: ImageType
        preview_text: string
      }[]
    }
  }>(
    PartnersQuery,
    {
      site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
    },
    page.previewData?.statamicToken
  )

  return {
    ...props,
    entries,
  }
}

export default function Component({
  title,
  text,
  entries,
}: Awaited<ReturnType<typeof getStaticProps>>) {
  return (
    <Container>
      <Title style="bigH1">{title}</Title>
      <div className="mt-4 md:mt-8">
        <Content content={text} />
      </div>
      <div className="md::mt-16 mt-10 grid gap-x-[28px] gap-y-10 overflow-auto md:grid-cols-2 md:gap-y-[50px] lg:grid-cols-3">
        {entries.map((entry, index) => (
          <article key={index}>
            <Link
              href={entry.uri}
              className="group block rounded-[15px] bg-gradient-100 transition-all hover:bg-gradient-600 hover:text-white"
            >
              <div className="aspect-h-9 aspect-w-16 relative w-auto">
                {entry.image && (
                  <Picture
                    {...entry.image}
                    fill
                    sizes="(min-width: 1024px) 33vw, (min-width: 768px) 50vw, 100vw"
                    className="rounded-t-[15px] object-cover"
                  />
                )}
              </div>
              <div className="relative">
                <div className="absolute right-5 top-[-35px] flex items-center justify-center rounded-md bg-white px-7 py-4">
                  <div className="relative h-[30px] w-[67px]">
                    <Picture
                      {...entry.partner_icon}
                      sizes="67px"
                      fill
                      className="object-cover"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 p-5">
                <h3 className="text-2xl font-extrabold">{entry.title}</h3>
                <p className="mt-2.5">{entry.preview_text}</p>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </Container>
  )
}
