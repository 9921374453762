import { GetStaticPropsContext } from 'next'
import { SWRConfig } from 'swr'
import BaseLayout from '../elements/BaseLayout'
import Catcher from '../elements/Catcher'
import MetaTags from '../MetaTags'
import { GenericVkuPage, GenericPage } from '@/src/@types/admin'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import { BaseDataType } from '@/src/@types/statamic'
import { PageProps } from '@/src/@types/util'
import Content from '@/src/blocks/Content/Component'
import ShortLinks from '@/src/blocks/ShortLinks/Component'
import BaseData from '@/src/graphql/BaseData.graphql'
import { loadBlockProps, renderBlock } from '@/src/lib/PageBuilder'
import { ensureTrailingSlash } from '@/src/lib/str'
import { request } from '@/src/StatamicGraphQLClient'

const BASE_URL = process.env.BASE_URL

type Props = PageProps<typeof getStaticProps>

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams, PreviewData>,
  values: GenericVkuPage
) {
  const { navs, settings, globalCatcher } = await request<BaseDataType>(
    BaseData,
    { site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE },
    context.previewData?.statamicToken
  )

  const uri = context.params?.uri ? context.params?.uri?.join('/') : ''

  let blocks
  if (values.bookable.length > 0) {
    blocks = await loadBlockProps(
      [
        {
          type: 'courses',
          location: [],
          address: values.bookable.map((page) => {
            return {
              value: page.addressId.toString(),
            }
          }),
          course_type: [{ value: 'vku' }],
          google_rating: null,
          sidebar_title: null,
        },
      ],
      context
    )
  }

  return {
    props: {
      navs,
      settings,
      globalCatcher,
      courseBlock: blocks ? blocks[0] : null,
      fullUrl: uri ? BASE_URL + '/' + ensureTrailingSlash(uri) : BASE_URL,
    },
  }
}

export default function VkuPage({
  navs,
  settings,
  globalCatcher,
  courseBlock,
  fullUrl,
  name,
  mentioned,
  linked,
}: Props & GenericVkuPage) {
  return (
    <>
      <MetaTags
        seoTitle={`VKU ${name} | Jetzt online buchen!`}
        seoSummary={`VKU ${name} bei BLINK: Modernes Kurskonzept ✓ Motivierte Fahrlehrer:innen  ✓ Zentrale Location ✓ Jetzt online anmelden und loslegen!`}
        title={`VKU ${name}`}
        description={`VKU ${name} | Jetzt online buchen!`}
        fullUrl={fullUrl}
      />
      <BaseLayout navs={navs} settings={settings}>
        <div className="space-y-20 pb-16 pt-24 lg:space-y-24 xl:pt-36">
          <Catcher {...globalCatcher} />
          <Content
            type="content"
            content={[
              {
                type: 'text',
                text: `<h1>[VKU in ${name}] für deinen Führerschein</h1><p>In ${name} bieten wir aktuell zwar keine VKU an, vielleicht findest du aber einen passenden Kurs an ${
                  mentioned.length === 1
                    ? 'unserem nahegelegenen Standort'
                    : 'unseren nahegelegenen Standorte'
                } in ${createMentionedLinks(
                  mentioned
                )} von ${name} entfernt.</p>`,
              },
            ]}
            pageInfo={{
              settings,
              socialImage: null,
              fullUrl,
              blueprint: 'courses',
            }}
            sidebar={[]}
          />
          {courseBlock && (
            <SWRConfig value={{ fallback: courseBlock.fallback ?? {} }}>
              {renderBlock(courseBlock, 1, [courseBlock], {
                settings,
                socialImage: null,
                fullUrl,
                blueprint: 'courses',
              })}
            </SWRConfig>
          )}
          {linked.length > 0 && (
            <ShortLinks
              type="short_links"
              optional_title="Kein passender VKU gefunden?"
              heading={`Weitere Standorte in der [Nähe von ${name}]`}
              links={linked.map((page) => {
                return {
                  link_text: `VKU ${page.name} (${page.distance} km)`,
                  link_link: page.url,
                }
              })}
            />
          )}
        </div>
      </BaseLayout>
    </>
  )
}

function createMentionedLinks(mentioned: GenericPage[]) {
  if (mentioned.length === 1) {
    const mentionedLink = mentioned
      .map((page) => `<strong><a href="${page.url}">${page.name}</a></strong>`)
      .join('')
    return `${mentionedLink}, nur ${mentioned[0].distance} Kilometer`
  }
  const mentionedLinks = mentioned
    .map((page, index) => {
      if (index === 0) {
        return `<strong><a href="${page.url}">${page.name}</a></strong> (${page.distance} km)`
      } else if (index === mentioned.length - 1) {
        return ` und <strong><a href="${page.url}">${page.name}</a></strong> (${page.distance} km)`
      } else {
        return `, <strong><a href="${page.url}">${page.name}</a></strong> (${page.distance} km)`
      }
    })
    .join('')

  return `${mentionedLinks}, nur wenige Kilometer`
}
