import { ShareNetwork } from '@phosphor-icons/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import GradientText from '../elements/GradientText'
import Picture from '../elements/Picture'
import { ClickTooltip } from '../elements/Tooltip'
import Button from '../form/Button'
import Quiz from '@/img/quiz.png'
import { BardQuiz as BardQuizType, ImageType } from '@/src/@types/statamic'
import share from '@/src/lib/share'

enum State {
  Initial,
  Running,
  End,
}

enum QuestionState {
  Open,
  Correct,
  Wrong,
}

export default function BardQuiz({
  title,
  date,
  questions,
  ...answerScreens
}: BardQuizType) {
  const router = useRouter()
  const [swiper, setSwiper] = useState<SwiperType | null>(null)

  const [quizState, setQuizState] = useState(State.Initial)
  const [answers, setAnswers] = useState<
    { state: QuestionState; answered?: string; correct?: string }[]
  >(
    questions.map((q) => {
      return {
        state: QuestionState.Open,
        answered: undefined,
        correct: q.answers.find((a) => a.correct)?.answer,
      }
    })
  )
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const correctPercentage = useMemo(
    () =>
      answers.reduce((x, a) => {
        if (a.state === QuestionState.Correct) {
          return x + 1
        }
        return x
      }, 0) / answers.length,
    [answers]
  )

  useEffect(() => {
    if (currentQuestionIndex > questions.length - 1) {
      setQuizState(State.End)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex])

  const replaceText = (text?: string) => {
    if (!text) {
      return ''
    }

    return text
      .replaceAll(
        '{richtig}',
        answers
          .reduce((x, a) => {
            if (a.state === QuestionState.Correct) {
              return x + 1
            }
            return x
          }, 0)
          .toString()
      )
      .replaceAll(
        '{falsch}',
        answers
          .reduce((x, a) => {
            if (a.state === QuestionState.Wrong) {
              return x + 1
            }
            return x
          }, 0)
          .toString()
      )
      .replaceAll('{total}', answers.length.toString())
  }

  return (
    <div className="relative flex flex-col overflow-hidden rounded-2xl bg-gradient-150 px-12 py-10 lg:block">
      {quizState === State.Initial && (
        <>
          <p className="mr-[40px] text-xl font-extrabold lg:mr-[236px] lg:text-7xl">
            {title}
          </p>
          <p className="mr-[40px] mt-1 text-sm lg:mr-[236px] lg:text-lg">
            <span className="mr-2 font-bold text-purple-700">Quiz</span>
            {date && dayjs(date).format('DD.MM.YYYY')}
          </p>
          <Button className="mt-6" onClick={() => setQuizState(State.Running)}>
            Quiz starten
          </Button>
          <svg
            width="400"
            height="400"
            viewBox="0 0 400 400"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -top-16 right-[-120px] hidden lg:block"
          >
            <path
              d="M289.296 387.204C289.296 387.204 396.543 357.322 399.92 203.489C403.297 49.6548 298.915 24.0892 262.637 7.79144C226.359 -8.50628 117.231 -3.5507 45.501 64.8639C-26.2286 133.278 -23.7939 364.489 130.55 392.276C241.424 412.237 289.296 387.204 289.296 387.204Z"
              fill="url(#paint0_linear_7576_63690)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7576_63690"
                x1="-6.98921"
                y1="-8.38855"
                x2="144.69"
                y2="488.773"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BFC9FF" />
                <stop offset="1" stopColor="#E0E5FF" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="94"
            height="90"
            viewBox="0 0 94 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-0 lg:hidden"
          >
            <path
              d="M142.422 84.0817C142.422 84.0817 195.22 70.2616 196.882 -0.886543C198.545 -72.0347 147.158 -83.8588 129.298 -91.3965C111.438 -98.9342 57.7144 -96.6422 22.4019 -65.0005C-12.9106 -33.3587 -11.712 73.5762 64.2714 86.4277C118.855 95.6598 142.422 84.0817 142.422 84.0817Z"
              fill="url(#paint0_linear_7373_63766)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_7373_63766"
                x1="-3.43902"
                y1="-98.8797"
                x2="63.1318"
                y2="133.379"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BFC9FF" />
                <stop offset="1" stopColor="#E0E5FF" />
              </linearGradient>
            </defs>
          </svg>
          <div className="absolute inset-y-0 right-12 hidden items-center justify-center lg:flex">
            <Image src={Quiz} alt="Quiz" height={160} width={160} unoptimized />
          </div>
          <div className="absolute right-2 top-2 lg:hidden">
            <Image src={Quiz} alt="Quiz" height={55} width={55} unoptimized />
          </div>
        </>
      )}
      {quizState === State.Running && (
        <>
          <div className="inline-flex items-center">
            Frage {currentQuestionIndex + 1}{' '}
            <div className="ml-4 flex space-x-2.5">
              {answers.map((answer, index) => (
                <div key={index} className="relative">
                  <div
                    className={classNames([
                      'h-2.5 w-2.5 rounded-full lg:h-3 lg:w-3',
                      {
                        'bg-blue-150': answer.state === QuestionState.Open,
                        'bg-green-600': answer.state === QuestionState.Correct,
                        'bg-pink-600': answer.state === QuestionState.Wrong,
                      },
                    ])}
                  />
                  {currentQuestionIndex === index && (
                    <div className="absolute -left-0.5 -top-0.5 size-[14px] rounded-full border border-purple-700 lg:size-[16px]" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <Swiper
            className="mt-3 w-full"
            allowTouchMove={false}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {questions.map((question, questionIndex) => (
              <SwiperSlide key={questionIndex}>
                <p className="text-2xl font-extrabold lg:w-2/3">
                  {question.question}
                </p>
                <div
                  className={classNames([
                    'mt-6 grid gap-7',
                    {
                      'lg:grid-cols-2': question.image,
                    },
                  ])}
                >
                  {question.image && (
                    <div className="relative hidden min-h-[250px] lg:block">
                      <Picture
                        {...question.image}
                        className="rounded-lg object-contain"
                        fill
                      />
                    </div>
                  )}
                  <div
                    className={classNames([
                      'flex flex-col space-y-4',
                      {
                        'lg:w-2/3': !question.image,
                      },
                    ])}
                  >
                    {question.answers.map((answer, index) => (
                      <button
                        key={index}
                        disabled={
                          answers[questionIndex].state !== QuestionState.Open
                        }
                        className={classNames([
                          'rounded-md px-6 py-3 text-left transition-all',
                          {
                            'bg-green-600 font-bold text-white':
                              answers[questionIndex].correct ===
                                answer.answer &&
                              answers[questionIndex].state !==
                                QuestionState.Open,
                            'bg-pink-600 font-bold text-white':
                              answers[questionIndex].answered ===
                                answer.answer &&
                              answers[questionIndex].answered !==
                                answers[questionIndex].correct,
                            'bg-white hover:bg-blue-150':
                              answers[questionIndex].state ===
                              QuestionState.Open,
                            'bg-white opacity-50':
                              answers[questionIndex].state !==
                                QuestionState.Open &&
                              answers[questionIndex].correct !==
                                answer.answer &&
                              answers[questionIndex].answered !== answer.answer,
                          },
                        ])}
                        onClick={() => {
                          const answerCopy = [...answers]
                          answerCopy[questionIndex].state =
                            answer.answer === answerCopy[questionIndex].correct
                              ? QuestionState.Correct
                              : QuestionState.Wrong
                          answerCopy[questionIndex].answered = answer.answer
                          setAnswers(answerCopy)
                          setTimeout(() => {
                            setCurrentQuestionIndex(currentQuestionIndex + 1)
                            if (swiper) {
                              swiper.slideNext()
                            }
                          }, 1500)
                        }}
                      >
                        {answer.answer}
                      </button>
                    ))}
                  </div>
                  {question.image && (
                    <div className="relative h-[200px] lg:hidden">
                      <Picture
                        {...question.image}
                        className="rounded-lg object-contain"
                        fill
                      />
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
      {quizState === State.End && (
        <>
          {correctPercentage === 1 && (
            <AnswerScreen
              image={answerScreens.answer_3_3_image}
              title={replaceText(answerScreens.answer_3_3_title)}
              subtitle={replaceText(answerScreens.answer_3_3_subtitle)}
              correct={answers.reduce((x, a) => {
                if (a.state === QuestionState.Correct) {
                  return x + 1
                }
                return x
              }, 0)}
              total={answers.length}
              url={router.asPath}
            />
          )}
          {correctPercentage >= 0.5 && correctPercentage < 1 && (
            <AnswerScreen
              image={answerScreens.answer_2_3_image}
              title={replaceText(answerScreens.answer_2_3_title)}
              subtitle={replaceText(answerScreens.answer_2_3_subtitle)}
              correct={answers.reduce((x, a) => {
                if (a.state === QuestionState.Correct) {
                  return x + 1
                }
                return x
              }, 0)}
              total={answers.length}
              url={router.asPath}
            />
          )}
          {correctPercentage < 0.5 && (
            <AnswerScreen
              image={answerScreens.answer_1_3_image}
              title={replaceText(answerScreens.answer_1_3_title)}
              subtitle={replaceText(answerScreens.answer_1_3_subtitle)}
              correct={answers.reduce((x, a) => {
                if (a.state === QuestionState.Correct) {
                  return x + 1
                }
                return x
              }, 0)}
              total={answers.length}
              url={router.asPath}
            />
          )}
        </>
      )}
    </div>
  )
}

function AnswerScreen({
  image,
  title,
  subtitle,
  correct,
  total,
  url,
}: {
  image?: ImageType
  title: string
  subtitle?: string
  correct: number
  total: number
  url: string
}) {
  return (
    <div
      className={classNames([
        'grid  gap-7',
        {
          'md:grid-cols-2': image,
        },
      ])}
    >
      {image && (
        <div className="relative h-[200px] md:h-auto">
          <Picture {...image} className="rounded-lg object-contain" fill />
        </div>
      )}
      <div>
        <p className="text-2xl font-extrabold">
          <GradientText>
            {`[
      ${correct}
      ]`}
          </GradientText>{' '}
          von {total}
        </p>
        <p className="mt-2 text-2xl font-extrabold">{title}</p>
        {subtitle && <p className="text-lg">{subtitle}</p>}
        <div className="flex">
          <ClickTooltip text="Der Link wurde in deine Zwischenablage kopiert">
            <Button
              className="mt-8 flex items-center"
              onClick={() => share(url)}
            >
              <ShareNetwork
                weight="fill"
                className="mr-2.5 text-white"
                size={20}
              />
              Link teilen
            </Button>
          </ClickTooltip>
        </div>
      </div>
    </div>
  )
}
