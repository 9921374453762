import clsx from 'clsx'
import Picture from '../elements/Picture'
import { BardImage as BardImageType } from '@/src/@types/statamic'

const TOLERANCE = 0.1
const equalsWithTolerance = (a: number, b: number) =>
  Math.abs(a - b) <= TOLERANCE

export default function BardImage({ image, caption }: BardImageType) {
  return (
    <div>
      <div
        className={clsx({
          'aspect-h-3 aspect-w-4': equalsWithTolerance(image.ratio ?? 0, 1.3),
          'aspect-h-4 aspect-w-6': !equalsWithTolerance(image.ratio ?? 0, 1.3),
        })}
      >
        <Picture {...image} className="rounded-lg object-contain" fill />
      </div>
      {caption && <p className="mt-3 text-sm text-gray-600">{caption}</p>}
    </div>
  )
}
