import { createContext, useContext, useEffect, useId, useState } from 'react'

type UploadContextValue = {
  isUploading: boolean
  registerFile: (id: string) => void
  changeState: (id: string, state: FileState) => void
}

const UploadContext = createContext<UploadContextValue>({
  isUploading: false,
  registerFile: () => {},
  changeState: () => {},
})

export type FileState = 'idle' | 'uploading'

export function UploadContextProvider({
  children,
}: {
  children: (props: { isUploading: boolean }) => React.ReactNode
}) {
  const [files, setFiles] = useState<
    {
      id: string
      state: FileState
    }[]
  >([])
  const registerFile = (id: string) => {
    setFiles((prev) => [...prev, { id, state: 'idle' }])
  }

  const changeState = async (id: string, state: FileState) => {
    setFiles((prev) => {
      const file = prev.find((file) => file.id === id)
      if (!file) {
        return prev
      }
      return [
        ...prev.filter((file) => file.id !== id),
        {
          ...file,
          state: state,
        },
      ]
    })
  }
  const isUploading = files.some((file) => file.state === 'uploading')

  return (
    <UploadContext.Provider value={{ isUploading, registerFile, changeState }}>
      {children({ isUploading })}
    </UploadContext.Provider>
  )
}

export function useUploadContext() {
  const { isUploading, registerFile, changeState } = useContext(UploadContext)
  const id = useId()

  useEffect(() => {
    registerFile(id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isUploading,
    changeState: (state: FileState) => changeState(id, state),
  }
}
