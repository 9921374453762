import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useWatch } from 'react-hook-form'
import { ContactFormBlock } from '@/src/@types/statamic'
import Content from '@/src/components/elements/Content'
import GradientText from '@/src/components/elements/GradientText'
import Picture from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import EmailInput from '@/src/components/form/EmailInput'
import FirstNameInput from '@/src/components/form/FirstNameInput'
import Honeypot from '@/src/components/form/Honeypot'
import Label from '@/src/components/form/Label'
import MessageInput from '@/src/components/form/MessageInput'
import PhoneInput from '@/src/components/form/PhoneInput'
import UncontrolledCheckboxButtonGroup from '@/src/components/form/UncontrolledCheckboxButtonGroup'
import WhatsappIcon from '@/src/components/form/WhatsappIcon'
import LoadingSpinner from '@/src/components/icons/LoadingSpinner'
import PhoneIcon from '@/src/components/icons/PhoneIcon'
import styles from '/src/css/Masks.module.css'
import Container from '@/src/components/layout/Container'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

type RequestFrom = {
  name: string
  phone: string
  email: string
  interests: string[]
  message: string
  location: string
  address: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/leads`

export default function Component({
  title,
  text,
  images,
  string_location,
}: ContactFormBlock) {
  const { utmData } = useUtmCache()

  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [startedFillingOut, setStartedFillingOut] = useState(false)
  const services = [
    { value: 'Fahrlektionen', label: 'Fahrlektionen' },
    { value: 'Probelektion', label: 'Probelektion' },
    { value: 'VKU', label: 'VKU' },
    { value: 'Nothelferkurs', label: 'Nothelferkurs' },
    { value: 'Diverses', label: 'Diverses' },
  ]
  const formRef = useRef<HTMLFormElement>(null)
  const { handleSubmit, register, control } = useLaravelForm<RequestFrom>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      interests: [],
      message: '',
      location: string_location ? string_location : '',
      address: '', // honeypot
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const formValues = useWatch({
    control,
    defaultValue: {},
  })

  useEffect(() => {
    // check if a form property exists to ignore init of value
    if (formValues.name && !startedFillingOut) {
      setStartedFillingOut(true)
      TagManager.dataLayer({
        dataLayer: {
          event: 'lead_form_started',
          params: {
            origin: 'Website',
            site: window.location.href,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const oneImage = () => (
    <div className="relative flex w-full items-baseline justify-between">
      <svg
        viewBox="0 0 398 392"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-[10%] top-[40%] -z-20 w-[70%] md:left-auto md:right-[15%] md:top-[50px] md:w-[350px]"
      >
        <path
          opacity="0.5"
          d="M350.19 73.5975C259.952 -18.4438 161.041 -18.6569 72.2189 42.5274C-14.7622 105.146 -19.8246 227.992 39.0832 318.335C97.9911 408.677 229.153 408.199 309.231 358.487C389.309 308.775 440.428 165.639 350.19 73.5975Z"
          fill="url(#paint0_linear_3317_51345)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3317_51345"
            x1="398"
            y1="-1.1593e-05"
            x2="19.5141"
            y2="431.651"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9FAFF" />
            <stop offset="1" stopColor="#F3F5FF" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="99"
        height="98"
        viewBox="0 0 99 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-20%] top-[80%] -z-20 w-[30%] md:right-[-5%] md:top-[250px]"
      >
        <path
          d="M86.8086 18.2412C64.4396 -4.57131 39.9204 -4.62413 17.9023 10.5405C-3.6594 26.0605 -4.91431 56.5082 9.68834 78.8997C24.291 101.291 56.8047 101.173 76.6552 88.8514C96.5057 76.5302 109.178 41.0538 86.8086 18.2412Z"
          fill="url(#paint0_linear_3317_51346)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3317_51346"
            x1="98.6602"
            y1="0"
            x2="4.88573"
            y2="107.726"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F3F5FF" />
            <stop offset="1" stopColor="#E7EBFF" />
          </linearGradient>
        </defs>
      </svg>

      <div className="relative left-[45%] -z-10 w-3/5 pb-[5%] md:absolute md:left-auto md:right-[10%] md:top-[-40px] md:w-[250px] lg:right-[5%] xl:w-[300px]">
        <div
          className={`aspect-h-1 aspect-w-1 w-full bg-gradient-700 ${styles['mask-9']} ${styles['mask']}`}
        >
          <Picture
            priority
            {...images[0]}
            sizes="(min-width: 768px) 50vw, 100vw"
            fill
            className="object-cover"
          />
        </div>
      </div>
    </div>
  )

  const twoImages = () => (
    <div className="relative flex w-full items-baseline justify-between">
      <svg
        viewBox="0 0 398 392"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-[10%] top-[40%] -z-20 w-[70%] md:left-auto md:right-[20%] md:top-[100px] md:w-[300px]"
      >
        <path
          opacity="0.5"
          d="M350.19 73.5975C259.952 -18.4438 161.041 -18.6569 72.2189 42.5274C-14.7622 105.146 -19.8246 227.992 39.0832 318.335C97.9911 408.677 229.153 408.199 309.231 358.487C389.309 308.775 440.428 165.639 350.19 73.5975Z"
          fill="url(#paint0_linear_3317_51345)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3317_51345"
            x1="398"
            y1="-1.1593e-05"
            x2="19.5141"
            y2="431.651"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9FAFF" />
            <stop offset="1" stopColor="#F3F5FF" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        width="99"
        height="98"
        viewBox="0 0 99 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-8%] top-full -z-20 w-[30%] md:top-[30px]"
      >
        <path
          d="M86.8086 18.2412C64.4396 -4.57131 39.9204 -4.62413 17.9023 10.5405C-3.6594 26.0605 -4.91431 56.5082 9.68834 78.8997C24.291 101.291 56.8047 101.173 76.6552 88.8514C96.5057 76.5302 109.178 41.0538 86.8086 18.2412Z"
          fill="url(#paint0_linear_3317_51346)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3317_51346"
            x1="98.6602"
            y1="0"
            x2="4.88573"
            y2="107.726"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F3F5FF" />
            <stop offset="1" stopColor="#E7EBFF" />
          </linearGradient>
        </defs>
      </svg>

      <div className="relative left-[10%] -z-10 w-1/2 pb-[50%] md:absolute md:left-auto md:right-[20%] md:w-[200px] xl:w-[250px]">
        <div
          className={`aspect-h-1 aspect-w-1 w-full bg-gradient-700 ${styles['mask-9']} ${styles['mask']}`}
        >
          <Picture priority {...images[0]} fill className="object-cover" />
        </div>
      </div>

      <div className="absolute right-[-20%] top-[30%] -z-10 w-3/5 md:right-0 md:top-[150px] md:w-[225px] xl:w-[275px]">
        <div
          className={`aspect-h-1 aspect-w-1 w-full bg-gradient-700 ${styles['mask-9']} ${styles['mask']}`}
        >
          <Picture priority {...images[1]} fill className="object-cover" />
        </div>
      </div>
    </div>
  )

  return (
    <Container>
      <div className="relative">
        {images.length === 2 && twoImages()}
        {images.length === 1 && oneImage()}
        <div>
          <div className="md:w-1/2">
            <Title style={2}>{title}</Title>
            {text && !submitted && (
              <Content content={text} className="mt-8 max-w-full md:mt-10" />
            )}
          </div>
          {!submitted && (
            <form
              ref={formRef}
              onSubmit={handleSubmit(
                () => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'lead_form_sent',
                      params: {
                        origin: 'Website',
                        site: window.location.href,
                        value: 100,
                      },
                    },
                  })
                  setSubmitted(true)
                  formRef.current?.reset()
                  setSending(false)
                },
                (data) => {
                  setSending(true)
                  return data
                },
                () => setSending(false)
              )}
              method="POST"
              action={requestUrl}
              className="lg:w-3/4"
            >
              <div className="mt-10 grid gap-x-8 md:mt-14 md:grid-cols-2">
                <div className="grid content-start gap-5">
                  <Label
                    label="Vor- und Nachname"
                    {...register('name')}
                    style="inverted"
                  >
                    {(props) => <FirstNameInput {...props} />}
                  </Label>
                  <Label
                    label="Handynummer"
                    {...register('phone')}
                    style="inverted"
                  >
                    {(props) => <PhoneInput {...props} />}
                  </Label>
                  <Label label="E-Mail" {...register('email')} style="inverted">
                    {(props) => <EmailInput {...props} />}
                  </Label>
                  <Honeypot {...register('address')} />
                </div>
                <div className="mt-5 grid gap-5 md:mt-0">
                  <fieldset>
                    <legend className="text-sm">
                      Ich interessiere mich für:
                    </legend>
                    <div className="mt-[5px]">
                      <UncontrolledCheckboxButtonGroup
                        control={control}
                        name="interests"
                        options={services}
                        style="inverted"
                      />
                    </div>
                  </fieldset>
                  <Label
                    label="Nachricht"
                    {...register('message')}
                    style="inverted"
                  >
                    {(props) => {
                      props.className = props.className + ' h-[78px]'
                      return <MessageInput {...props} />
                    }}
                  </Label>
                </div>
              </div>
              <div className="flex justify-center pt-8 md:mt-0">
                <Button
                  type="submit"
                  status={sending ? 'active' : 'default'}
                  disabled={sending}
                  className="inline-flex items-center"
                >
                  {sending && <LoadingSpinner className="mr-1 size-4" />}
                  Formular absenden
                </Button>
              </div>
            </form>
          )}
          {submitted && (
            <div className="mt-10 rounded-2xl border border-blue-100 bg-gradient-100 px-5 py-8 text-lg font-extrabold md:w-2/3 md:p-9 md:text-2xl xl:w-auto xl:max-w-[690px]">
              <p>Vielen Dank für deine Anfrage!</p>
              <GradientText>
                [Wir setzen uns umgehend mit dir in Verbindung!]
              </GradientText>
            </div>
          )}
          <p className="mt-10 md:mt-14">
            Lieber klassisch per Whatsapp oder Telefon? Kein Problem!
          </p>
          <div className="mt-5 flex">
            <Link
              href={`https://wa.me/41315391065?text=${encodeURIComponent(
                'Liebes BLINK Team, '
              )}`}
              passHref
              legacyBehavior
            >
              <Button
                as="a"
                style="whatsapp"
                className="flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsappIcon className="mr-2 size-5 lg:size-6" />
                <span>Whatsapp</span>
              </Button>
            </Link>
            <Link href="tel:0315391065" passHref legacyBehavior>
              <Button
                as="a"
                className="ml-2 flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PhoneIcon className="mr-2 size-5 lg:size-6" />
                <span>Telefon</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}
