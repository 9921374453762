import Icon from '@/src/components/icons/Icon'

export default function ThumbsUpIcon({
  className = '',
}: {
  className?: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 53 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M51.3429 15.7774C50.8149 15.1796 50.1659 14.7009 49.4389 14.3729C48.7119 14.045 47.9235 13.8753 47.126 13.8751H34.499V10.1251C34.4962 7.63958 33.5076 5.25666 31.75 3.49911C29.9925 1.74157 27.6096 0.752946 25.124 0.750122C24.7758 0.750095 24.4345 0.847051 24.1383 1.03012C23.8421 1.21319 23.6027 1.47514 23.447 1.78661L14.5902 19.5001H4.49902C3.50481 19.5013 2.55164 19.8967 1.84863 20.5997C1.14561 21.3027 0.75016 22.2559 0.749023 23.2501V43.8751C0.75016 44.8693 1.14561 45.8225 1.84863 46.5255C2.55164 47.2285 3.50481 47.624 4.49902 47.6251H44.3135C45.684 47.6232 47.0068 47.1218 48.0342 46.2148C49.0616 45.3078 49.7233 44.0574 49.8952 42.6978L52.7077 20.1978C52.8064 19.4063 52.7358 18.6029 52.5005 17.8408C52.2652 17.0788 51.8706 16.3754 51.3429 15.7774ZM4.49902 23.2501H13.874V43.8751H4.49902V23.2501Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
