import { BardInlineList as BardInlineListType } from '@/src/@types/statamic'

export default function BardInlineList({ inline_list }: BardInlineListType) {
  return (
    <ul>
      {inline_list.map((list_el, index) => (
        <li key={index} className="inline-block">
          {list_el}
        </li>
      ))}
    </ul>
  )
}
