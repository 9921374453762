import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import CookieImage from '@/img/cookie.png'
import Button from '@/src/components/core/Button'
import useWindowScrolled from '@/src/hooks/useWindowScrolled'

export default function CookieBanner() {
  const scrolled = useWindowScrolled(200)
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    const cookieBannerClosed = window.localStorage.getItem(
      'cookie-banner-closed'
    )
    if (cookieBannerClosed !== 'true') {
      setShowCookieBanner(true)
    }
  }, [])

  if (!showCookieBanner) {
    return null
  }

  return (
    <div
      className={classNames(
        'fixed inset-x-4 bottom-4 z-50 rounded-lg border border-blue-150/20 bg-gradient-150 px-6 pb-6 pt-5 shadow-sm transition-opacity md:bottom-12 md:left-12 md:max-w-[440px]',
        {
          'opacity-100': !scrolled,
          'pointer-events-none opacity-0': scrolled,
        }
      )}
    >
      <Image
        src={CookieImage}
        alt="Cookie"
        width={96}
        height={71}
        className="absolute -top-8 right-5"
        unoptimized
      />
      <p className="font-extrabold">Wir verwenden Cookies!</p>
      <p className="mt-1 text-sm">
        Sie erleichtern dir die Benutzung dieser Webseite. Weitere Infos findest
        du in der Datenschutzerklärung.
      </p>
      <div className="mt-4 flex space-x-2">
        <Button
          size="small"
          onClick={() => {
            window.localStorage.setItem('cookie-banner-closed', 'true')
            setShowCookieBanner(false)
          }}
        >
          Schliessen
        </Button>
        <Button
          as={Link}
          version="purple-transparent"
          size="small"
          href="/datenschutz"
          target="_blank"
        >
          Mehr Infos
        </Button>
      </div>
    </div>
  )
}
