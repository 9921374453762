import { useState } from 'react'
import { TeamBlock } from '../../@types/statamic'
import { getSpecificTeamMembers, getTeamMembers } from '../../AdminApiClient'
import ArrowAction from '../../components/elements/ArrowAction'
import TeamMember from '../../components/elements/TeamMember'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'

type TeamBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamBlock) {
  return title
}

export async function getStaticProps({
  title,
  roles,
  opt_team_members,
}: TeamBlock) {
  let team
  if (opt_team_members && opt_team_members.length > 0) {
    team = await getSpecificTeamMembers(
      opt_team_members.map((team_member) => team_member.value)
    )
  } else {
    team = await getTeamMembers(
      roles.map((role) => role.value),
      8
    )
  }

  return { team, title }
}

export default function Component({ title, team }: TeamBlockProps) {
  const [showDescription, setShowDescription] = useState(-1)

  return (
    <div className="md:pb-12 lg:pb-16">
      <Container>
        <div className="relative">
          <svg
            className="absolute -right-32 top-8 -z-10 hidden w-[38.5rem] lg:block"
            aria-hidden="true"
            viewBox="0 0 595 618"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M414.254 603.654C414.254 603.654 575.392 565.852 592.674 329.143C609.956 92.4333 457.485 45.0425 405.086 17.1536C352.686 -10.7352 190.753 -11.4747 79.1124 88.409C-32.5282 188.293 -47.3856 544.641 178.863 599.287C341.392 638.543 414.254 603.654 414.254 603.654Z"
              fill="url(#team_blob_gradient)"
            />
            <defs>
              <linearGradient
                id="team_blob_gradient"
                x1="548.275"
                y1="672.583"
                x2="50.3136"
                y2="-45.839"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FBFBFF" />
                <stop offset="1" stopColor="#DAE0FF" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div>
          <Title style={2}>{title}</Title>
          <div className="mt-8 w-full md:mt-10">
            <div className="-mx-7 lg:mx-0">
              <ul className="flex snap-x snap-mandatory space-x-4 overflow-auto px-7 lg:grid lg:grid-flow-row lg:grid-cols-4 lg:gap-x-6 lg:gap-y-10 lg:space-x-0 lg:overflow-visible lg:px-0">
                {team.map((member, index) => (
                  <li
                    key={index}
                    className="w-80 max-w-[calc(100%-2rem)] shrink-0 snap-center lg:w-full lg:max-w-full lg:px-0"
                  >
                    <TeamMember
                      {...member}
                      showDescription={showDescription === index}
                      toggleDescription={() =>
                        showDescription === index
                          ? setShowDescription(-1)
                          : setShowDescription(index)
                      }
                      index={index}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-6 md:mt-12">
            <ArrowAction href="/ueber-uns">Zum kompletten Team</ArrowAction>
          </div>
        </div>
      </Container>
    </div>
  )
}
