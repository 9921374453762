'use client'

import { CaretDown } from '@phosphor-icons/react'
import * as Label from '@radix-ui/react-label'
import { VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import { forwardRef, useId } from 'react'
import { OverrideElementProps } from '@/core/lib/types'
import { cn } from '@/core/lib/utils'

const selectStyle = cva(
  [
    'rounded border pl-3 transition-colors duration-200 hover:duration-150 disabled:cursor-not-allowed',
    'bg-none [&:has(option[value=""]:checked)]:text-sm [&:has(option[value=""]:checked)]:text-gray-600',
  ],
  {
    variants: {
      color: {
        blue: [
          'border-blue-125 bg-blue-25',
          'focus:border-purple-600 focus:ring-1 focus:ring-purple-600',
          'disabled:border-transparent disabled:bg-blue-50 disabled:text-gray-600',
        ],
        white: [
          'border-blue-125 bg-white',
          'focus:border-purple-600 focus:ring-1 focus:ring-purple-600',
          'disabled:border-transparent disabled:text-gray-600',
        ],
      },
    },
    defaultVariants: {
      color: 'blue',
    },
  }
)

const selectLabelStyle = cva(undefined, {
  variants: {
    type: {
      float: [
        'absolute left-3 origin-left cursor-text text-sm text-gray-600 transition-all peer-disabled:text-gray-500',
        'top-[6px] scale-75',
        'peer-[:has(option[value=""]:checked)]:hidden',
      ],
      block: '-order-1 mb-1 block text-sm',
      srOnly: 'sr-only',
    },
  },
  defaultVariants: {
    type: 'float',
  },
})

export type SelectProps = OverrideElementProps<
  'select',
  VariantProps<typeof selectStyle> & {
    label: string
    labelType?: 'float' | 'block' | 'srOnly'
    showPlaceholder?: boolean
    placeholderText?: string
    unselectable?: boolean
    error?: string
    hideErrorMessage?: boolean
  }
>

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      color = 'blue',
      label,
      labelType = 'float',
      showPlaceholder = true,
      placeholderText,
      unselectable = true,
      error,
      hideErrorMessage,
      children,
      ...props
    },
    ref
  ) => {
    const selectId = useId()

    return (
      <div>
        <div className="relative flex w-full flex-col">
          <select
            ref={ref}
            id={selectId}
            className={cn(
              selectStyle({ color }),
              {
                'pb-[7.5px] pt-[22.5px]': labelType === 'float',
                '[&:has(option[value=""]:checked)]:pt-[17px] [&:has(option[value=""]:checked)]:pb-[16px]':
                  labelType === 'float',
                'py-[15px]': labelType === 'block' || labelType === 'srOnly',
                'border-red-600 pr-12 focus:ring-1 focus:ring-red-600 focus:border-red-600':
                  !!error,
              },
              'peer',
              className
            )}
            {...props}
          >
            {unselectable && (
              <option value="">
                {showPlaceholder && placeholderText
                  ? placeholderText
                  : showPlaceholder
                    ? label
                    : ''}
              </option>
            )}
            {children}
          </select>
          <Label.Root
            htmlFor={selectId}
            className={cn(selectLabelStyle({ type: labelType }))}
          >
            {label}
          </Label.Root>
          <div
            className={clsx('absolute right-3 flex items-center', {
              'inset-y-0': labelType !== 'block',
              'bottom-4': labelType === 'block',
            })}
          >
            <CaretDown size={20} weight="bold" className="text-purple-600" />
          </div>
        </div>
        {error && !hideErrorMessage && (
          <p className="ml-0.5 mt-1 text-xs text-red-600">{error}</p>
        )}
      </div>
    )
  }
)
// @ts-ignore
Select.displayName = 'core-select'

export { Select }
