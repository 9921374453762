import { GetStaticPropsContext } from 'next'
import BaseLayout from '../elements/BaseLayout'
import Catcher from '../elements/Catcher'
import MetaTags from '../MetaTags'
import RequestLesson from './RequestLesson'
import { GenericSchoolPage } from '@/src/@types/admin'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import { BaseDataType } from '@/src/@types/statamic'
import { PageProps } from '@/src/@types/util'
import Content from '@/src/blocks/Content/Component'
import QuickLinks from '@/src/blocks/QuickLinks/Component'
import BaseData from '@/src/graphql/BaseData.graphql'
import { ensureTrailingSlash } from '@/src/lib/str'
import { request } from '@/src/StatamicGraphQLClient'

const BASE_URL = process.env.BASE_URL

type Props = PageProps<typeof getStaticProps>

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams, PreviewData>,
  _values: GenericSchoolPage
) {
  const { navs, settings, globalCatcher } = await request<BaseDataType>(
    BaseData,
    { site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE },
    context.previewData?.statamicToken
  )

  const uri = context.params?.uri ? context.params?.uri?.join('/') : ''

  return {
    props: {
      navs,
      settings,
      globalCatcher,
      fullUrl: uri ? BASE_URL + '/' + ensureTrailingSlash(uri) : BASE_URL,
    },
  }
}

export default function SchoolPage({
  navs,
  settings,
  globalCatcher,
  fullUrl,
  name,
  slug: _slug,
  hasForm,
  linkedDirectBookable,
  linkedRequestable,
  swissPlaceId,
}: Props & GenericSchoolPage) {
  return (
    <>
      <MetaTags
        seoTitle={`BLINK Fahrschule in ${name}`}
        seoSummary={`BLINK ist deine Fahrschule in ${name} für Auto und Motorrad. Wir bringen dich professionell und effizient ans Ziel. Ready?`}
        title={`BLINK Fahrschule in ${name}`}
        description={`BLINK ist deine Fahrschule in ${name} für Auto und Motorrad. Wir bringen dich professionell und effizient ans Ziel. Ready?`}
        fullUrl={fullUrl}
      />
      <BaseLayout navs={navs} settings={settings}>
        <div className="space-y-20 pb-16 pt-24 lg:space-y-24 xl:pt-36">
          <Catcher {...globalCatcher} />
          <Content
            type="content"
            content={[
              {
                type: 'text',
                text: `<h1>Deine [Fahrschule in ${name}]</h1><p>Bist du bereit, deinen Führerschein anzupacken? Bei uns hast du ein top-motiviertes und sympathisches Team an deiner Seite, auf das du dich verlassen kannst. Wir machen für dich das Lernen so einfach, wie’s nur geht: Vom Nothelferkurs bis zur praktischen Prüfung!</p>${
                  !hasForm
                    ? `</br><p><strong>In ${name} sind wir aktuell leider noch nicht vertreten. Nachfolgend findest du eine Übersicht der nächstgelegenen BLINK Standorte.</strong></p>`
                    : ''
                }`,
              },
            ]}
            pageInfo={{
              settings,
              socialImage: null,
              fullUrl,
              blueprint: 'courses',
            }}
            sidebar={[]}
          />
          {hasForm && (
            <RequestLesson
              title={`Du möchtest Fahrstunden in ${name}?`}
              text={`In ${name} haben wir noch keinen offiziellen BLINK-Standort eröffnet. Dank unserem grossen Netzwerk an Fahrlehrer:innen, können wir dich aber vielleicht trotzdem an deiner Wunsch-Location abholen. Fragen kostet nichts!`}
              locationName={name}
              placeId={swissPlaceId}
            />
          )}
          {linkedDirectBookable.length > 0 && (
            <QuickLinks
              type="quick_links"
              title="BLINK Standorte in deiner Nähe"
              links={linkedDirectBookable.map((page) => {
                return {
                  link_link: page.url,
                  link_text: `${page.name} (${page.distance} km)`,
                }
              })}
            />
          )}
          {linkedRequestable.length > 0 && (
            <QuickLinks
              type="quick_links"
              title="Weitere Standorte auf Anfrage"
              links={linkedRequestable.map((page) => {
                return {
                  link_link: page.url,
                  link_text: `${page.name} (${page.distance} km)`,
                }
              })}
            />
          )}
        </div>
      </BaseLayout>
    </>
  )
}
