import { Dialog } from '@headlessui/react'
import { X } from '@phosphor-icons/react'
import { ChangeEvent } from 'react'
import useSWR from 'swr'
import {
  CourseType,
  ProvisionalDrivingLicenseDescriptions,
} from '@/src/@types/admin'
import { get } from '@/src/BlinkAdminApiClient'
import Picture from '@/src/components/elements/Picture'
import Input from '@/src/components/form/Input'

export default function LicenseDialog({
  show,
  close,
  userSelectedCanton,
  setUserSelectedCanton,
}: {
  show: boolean
  close: () => void
  userSelectedCanton: CourseType['provisionalDrivingLicenseDescription']
  setUserSelectedCanton: (
    newCanton: CourseType['provisionalDrivingLicenseDescription']
  ) => void
}) {
  const { data: provisionalDrivingLicenseDescriptions } = useSWR(
    'provisional-driving-license-descriptions',
    async () =>
      await get<ProvisionalDrivingLicenseDescriptions>(
        '/website/v2/provisional-driving-license-descriptions'
      )
  )

  return (
    <Dialog
      open={show}
      onClose={close}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      <div className="flex min-h-screen items-center justify-center">
        <Dialog.Overlay className="fixed inset-0 bg-blue-900 opacity-70" />
        <div className="z-10 m-5 max-w-4xl rounded-lg bg-white p-5 pt-4 md:p-10 md:pt-6">
          <div className="flex justify-between border-b border-blue-100 pb-4">
            <h2 className="text-xl font-extrabold md:text-4xl">
              Lernfahrausweisnummer
            </h2>
            <button type="button" onClick={close}>
              <X
                className="size-7"
                color="currentColor"
                weight="bold"
                alt="Schliessen"
              />
            </button>
          </div>
          <div className="mt-4">
            <p>In welchem Kanton wurde dein Lernfahrausweis ausgestellt?</p>
            {provisionalDrivingLicenseDescriptions && (
              <Input
                as="select"
                value={userSelectedCanton.canton.short}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const newCanton =
                    provisionalDrivingLicenseDescriptions.cantons.find(
                      (c) => c.canton.short === e.target.value
                    )
                  if (newCanton) {
                    setUserSelectedCanton(newCanton)
                  }
                }}
                className="mt-4"
              >
                <option value="">Wähle deinen Kanton</option>
                {provisionalDrivingLicenseDescriptions.cantons.map(
                  (location) => (
                    <option
                      key={location.canton.short}
                      value={location.canton.short}
                    >
                      {location.canton.german}
                    </option>
                  )
                )}
              </Input>
            )}
            {userSelectedCanton.image ? (
              <div className="relative mt-4 h-[500px] w-full">
                <Picture
                  permalink={userSelectedCanton.image}
                  sizes="500px"
                  alt={`Lernfahrausweisnummer Kanton ${userSelectedCanton.canton.german}`}
                  fill
                  className="object-contain object-top"
                />
              </div>
            ) : (
              <p className="mt-4 text-sm">
                Für diesen Kanton haben wir leider aktuell noch kein Muster.
              </p>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}
