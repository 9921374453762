import { ListBullets, X } from '@phosphor-icons/react'
import { ArrowRight } from '@phosphor-icons/react/dist/ssr'
import {
  DropdownMenuItem,
  DropdownMenuPortal,
} from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { BardTocHeading, BardSet } from '@/src/@types/statamic'
import Button from '@/src/components/core/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/src/components/core/Dropdown'

export default function BardToc({ content }: { content: BardSet[] }) {
  const headings = content
    .filter((el) => el.type === 'toc_heading')
    .map((heading) => {
      return {
        title: (heading as BardTocHeading).title,
        dept: (heading as BardTocHeading).dept.value,
        id: encodeURIComponent((heading as BardTocHeading).title).toLowerCase(),
      }
    })

  const tocRef = useRef<HTMLDivElement>(null)
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setShowDropdown(!entry.isIntersecting)
    })

    const tocRefElement = tocRef.current
    if (tocRefElement) {
      observer.observe(tocRefElement)
    }
    return () => {
      if (tocRefElement) {
        observer.unobserve(tocRefElement)
      }
    }
  }, [tocRef])

  return (
    <>
      <div ref={tocRef}>
        <h2 className="text-lg font-extrabold md:text-4xl">Navigation</h2>
        <div className="mt-2">
          {headings.map((heading) => (
            <a
              key={heading.id}
              href={`#${heading.id}`}
              className="flex items-center py-2 font-bold"
            >
              <ArrowRight
                size={20}
                weight="bold"
                className="mr-2 inline text-purple-500"
              />
              {heading.title}
            </a>
          ))}
        </div>
      </div>
      <DropdownMenu modal={false}>
        {showDropdown && (
          <>
            {createPortal(
              <div className="fixed inset-x-0 bottom-4 z-50 flex justify-center">
                <DropdownMenuTrigger asChild>
                  <Button
                    version="white"
                    icon={<ListBullets weight="bold" />}
                    className="shadow"
                  >
                    Navigation
                  </Button>
                </DropdownMenuTrigger>
              </div>,
              document.body
            )}
          </>
        )}
        <DropdownMenuPortal>
          <DropdownMenuContent
            color="light"
            side="top"
            align="center"
            sideOffset={5}
            collisionPadding={5}
            className="w-fit max-w-[calc(100vw-10px)] pb-2"
          >
            <div className="flex items-center justify-between border-b border-blue-50 py-2 pl-5 pr-3">
              <p>Zum Thema springen</p>
              <DropdownMenuItem className="cursor-pointer p-1 text-gray-600">
                <X weight="bold" size={20} />
              </DropdownMenuItem>
            </div>
            <div className="px-2 pt-4">
              {headings.map((heading) => (
                <DropdownMenuItem key={heading.id} asChild>
                  <a
                    key={heading.id}
                    href={`#${heading.id}`}
                    className={clsx(
                      'flex cursor-pointer items-center justify-between rounded px-3 py-2 font-bold data-[highlighted]:bg-blue-50',
                      {
                        'pl-3': heading.dept === 'h3',
                        'pl-6': heading.dept === 'h4',
                      }
                    )}
                  >
                    <span className="truncate"> {heading.title}</span>
                    <ArrowRight
                      size={20}
                      weight="bold"
                      className="ml-5 inline shrink-0 text-purple-500"
                    />
                  </a>
                </DropdownMenuItem>
              ))}
            </div>
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>
    </>
  )
}
