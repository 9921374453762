import Link from 'next/link'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import { ImageType, SliderHeroBlock } from '../../@types/statamic'
import Picture from '../elements/Picture'
import styles from '/src/css/Masks.module.css'
import ArrowIcon from '../icons/ArrowIcon'
import StarRating from './StarRating'
import { useBetterUseId } from '@/src/hooks/betterUseId'

export default function CompactSliderHeader({
  images,
  google_rating,
}: { images: ImageType[] } & Pick<SliderHeroBlock, 'google_rating'>) {
  const paginationId = `pagination-header-${useBetterUseId()}`
  const prevElementId = `prev-el-header-${useBetterUseId()}`
  const nextElementId = `next-el-header-${useBetterUseId()}`

  return (
    <div className="inset-0 -mx-4 md:absolute md:mx-0 md:pl-24">
      <div
        className={`aspect-h-2 aspect-w-3 relative w-full md:aspect-h-1 md:aspect-w-1`}
      >
        <div>
          <svg
            aria-hidden="true"
            className="absolute left-[85%] top-[120%] -z-10 w-[18%] md:bottom-[6%] md:left-0 md:top-auto md:w-1/2"
            viewBox="0 0 158 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
              fill="url(#paint0_linear_1896_9981)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_9981"
                x1="51.3641"
                y1="15.8074"
                x2="121.126"
                y2="151.98"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="235"
            height="231"
            viewBox="0 0 235 231"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-0 top-1/2 w-3/4 md:hidden"
          >
            <path
              opacity="0.3"
              d="M206.771 43.37C153.489 -10.8686 95.0869 -10.9942 42.6418 25.0608C-8.71637 61.9608 -11.7055 134.353 23.0768 187.59C57.8591 240.827 135.304 240.546 182.586 211.251C229.868 181.956 260.052 97.6085 206.771 43.37Z"
              fill="url(#paint0_linear_5118_36492)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5118_36492"
                x1="75.7875"
                y1="23.6775"
                x2="180.027"
                y2="227.999"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>
          <Swiper
            id="test"
            navigation={{
              prevEl: `#${prevElementId}`,
              nextEl: `#${nextElementId}`,
              hiddenClass: '!hidden',
              disabledClass: '!hidden',
            }}
            pagination={{
              el: `#${paginationId}`,
              bulletClass: 'h-3 w-3 inline-block rounded-full bg-blue-100 mx-1',
              clickable: true,
            }}
            modules={[Navigation, Pagination, A11y, Autoplay]}
            a11y={{
              prevSlideMessage: 'Vorheriges Bild',
              nextSlideMessage: 'Nächstes Bild',
              paginationBulletMessage: 'Gehe zu Bild {{index}}',
              lastSlideMessage: 'Dies ist das letzte Bild',
              firstSlideMessage: 'Dies ist das erste Bild',
            }}
            slidesPerView={1}
            className="h-full"
            loop
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`absolute inset-0 bg-gradient-800 md:bottom-[20%] md:top-[5%] ${styles['mask-8']} ${styles['mask-12']} ${styles['mask']}`}
                >
                  <Picture
                    priority
                    sizes="(min-width: 768px) 100vw, 50vw"
                    {...image}
                    fill
                    className="object-cover"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            id={prevElementId}
            type="button"
            className="group absolute -left-6 right-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 md:flex"
          >
            <ArrowIcon className="size-5 rotate-180 text-purple-700 group-hover:text-white" />
          </button>
          <button
            id={nextElementId}
            type="button"
            className="group absolute -right-6 left-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 md:flex"
          >
            <ArrowIcon className="size-5 text-purple-700 group-hover:text-white" />
          </button>
          <div
            id={paginationId}
            className="absolute inset-x-0 !bottom-[-5%] z-10 text-center md:!bottom-5 lg:!bottom-8 xl:!bottom-16"
          />
          {google_rating &&
            !!google_rating.rating &&
            google_rating.rating > 0 && (
              <>
                {(google_rating.rating_count && (
                  <Link
                    href={google_rating.rating_url}
                    className="absolute bottom-[8%] right-6 z-10 rounded-lg border border-blue-100 bg-blue-50 px-4 py-2 shadow md:bottom-[20%]"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StarRating rating={google_rating.rating} />
                    <p className="mt-1 text-xs md:text-sm">
                      {google_rating.rating_count} Bewertungen auf Google
                    </p>
                  </Link>
                )) || (
                  <div className="absolute bottom-[8%] right-6 z-10 rounded-lg border border-blue-100 bg-blue-50 px-4 py-2 shadow md:bottom-[20%]">
                    <StarRating rating={google_rating.rating} />
                    <p className="mt-1 text-xs md:text-sm">
                      {google_rating.rating_count} Bewertungen auf Google
                    </p>
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  )
}
