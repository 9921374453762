import classNames from 'classnames'
import {
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useId,
} from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { AllOptional } from '@/src/@types/util'
import ErrorMessage from '@/src/components/form/ErrorMessage'

function FloatingLabel(
  {
    label,
    children,
    className = '',
    error,
    style = 'white',
    id,
    hideErrorMessage = false,
    ...props
  }: {
    label: string
    onInput?: FormEventHandler<any>
    hideErrorMessage?: boolean
    id?: string
    children: (
      props: {
        className: string
        id: string
        placeholder: string
        ref: ForwardedRef<any>
        hasError: boolean
        style: 'inverted' | 'white'
      } & Omit<AllOptional<UseFormRegisterReturn>, 'ref'>
    ) => ReactNode
    className?: string
    error?: string
    style?: 'inverted' | 'white'
  } & AllOptional<UseFormRegisterReturn>,
  ref: ForwardedRef<any>
) {
  const generatedId = `${useId()}-input-field`
  id = id || generatedId

  return (
    <div className={`${className} relative w-full`}>
      {children({
        ...props,
        ref,
        id,
        placeholder: ' ',
        className: `peer pt-5`,
        hasError: !!error,
        style,
      })}
      <label
        htmlFor={id}
        className={classNames([
          'absolute left-3 top-1 origin-left scale-75 text-sm transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-sm peer-focus:top-1 peer-focus:scale-75 peer-focus:translate-y-0',
          {
            'text-gray-600': style === 'white',
            'text-blue-900': style === 'inverted',
            'text-pink-500 peer-placeholder-shown:text-pink-500 peer-focus:text-pink-500':
              error !== undefined,
          },
        ])}
      >
        {label}
      </label>
      {!hideErrorMessage && error && <ErrorMessage error={error} />}
    </div>
  )
}

export default forwardRef(FloatingLabel)
