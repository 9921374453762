import { ForwardedRef, forwardRef } from 'react'
import Input, { InputProps } from './Input'
import useDateMask from '@/src/components/core/lib/useDateMask'

function DateInput(
  props: Omit<InputProps, 'autoComplete' | 'type' | 'options' | 'as'>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const dateProps = useDateMask()
  return (
    <Input
      {...props}
      {...dateProps}
      ref={ref}
      options={{ date: true, delimiter: '.', datePattern: ['d', 'm', 'Y'] }}
      autoComplete="bday"
    />
  )
}

export default forwardRef(DateInput)
