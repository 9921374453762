import {
  BatchRequestDocument,
  RequestDocument,
  Variables,
  GraphQLClient,
} from 'graphql-request'

const graphqlUrl = process.env.GRAPHQL_URL

export async function batchRequests<
  T extends Array<any> = any[],
  V extends Variables = Variables,
>(documents: BatchRequestDocument<V>[], previewToken?: string): Promise<T> {
  const response: any = await Promise.all(
    documents.map((query) => {
      return request(query.document, query.variables, previewToken)
    })
  )

  return response as Promise<T>
}

export async function request<T = any, V extends Variables = Variables>(
  document: RequestDocument,
  variables?: V,
  previewToken?: string
): Promise<T> {
  const url = previewToken ? `${graphqlUrl}?token=${previewToken}` : graphqlUrl

  const client = new GraphQLClient(url, { fetch: fetch })

  return client.request(document, variables)
}
