import { createClient } from '@vercel/edge-config'

interface ConfigKeys {
  abTestings: ABTesting[]
  redirects: {
    source: string
    destination: string
    match_type: string
    type: number
    locale: string
  }[]
}

export type ABTesting = {
  main_page: {
    url: string
    locale: string
  }
  pages: {
    url: string
    locale: string
  }[]
}

export async function get<KEY extends keyof ConfigKeys>(key: KEY) {
  const edgeConfig = createClient(process.env.EDGE_CONFIG)
  const config = await edgeConfig.get<ConfigKeys[KEY]>(key)
  return config
}
