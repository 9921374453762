import { styled } from 'classname-variants/react'

const LabelOnly = styled('label', {
  base: 'text-sm',
  variants: {
    style: {
      white: 'text-gray-600',
      inverted: 'text-blue-900',
    },
    error: {
      true: '!text-pink-500 peer-placeholder-shown:text-pink-500 peer-focus:text-pink-500',
      false: '',
    },
  },
  defaultVariants: {
    style: 'white',
    error: false,
  },
})

export default LabelOnly
