import Link from 'next/link'
import { PageInfo } from '@/src/@types/models'
import Bard from '@/src/components/bard/Bard'
import CustomLink from '@/src/components/elements/CustomLink'
import JsonLd from '@/src/components/elements/JsonLd'
import StarRating from '@/src/components/elements/StarRating'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import TrackingCrossIcon from '@/src/components/icons/TrackingCrossIcon'
import locateMeService from '@/src/lib/locateMeService'
import {
  BardText,
  SliderHeroBlock,
  VideoHeroBlock,
} from '../../@types/statamic'
import Container from '../../components/layout/Container'
import clsx from 'clsx'
import styles from '/src/css/Masks.module.css'

export function getTitle({ title }: SliderHeroBlock) {
  return title
}

export function getDescription({ hero_content }: SliderHeroBlock) {
  const bardText = hero_content.find((bard) => bard.type === 'text') as BardText
  if (bardText) {
    const pTag = bardText.text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export default function Component({
  title,
  heading,
  hero_content,
  thumbnail,
  video,
  link_link,
  link_text,
  locate_me_button,
  google_rating,
  pageInfo,
}: VideoHeroBlock & { pageInfo: PageInfo }) {
  const locateMe = () => {
    const interactiveMap = document.getElementById('interactive_map-1')
    if (interactiveMap) {
      interactiveMap.scrollIntoView({ behavior: 'smooth' })
      locateMeService.locateMe()
    }
  }

  return (
    <div className="relative mt-[-30px] md:pb-20 xl:-mt-12 xl:min-h-[500px]">
      {pageInfo && google_rating && (
        <JsonLd
          data={{
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Blink AG',
            description: `BLINK Fahrschule - ${google_rating.place}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: google_rating.street,
              addressLocality: google_rating.place,
              postalCode: google_rating.zip,
            },
            aggregateRating:
              google_rating.rating && google_rating.rating > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: google_rating.rating,
                    ratingCount: google_rating.rating_count,
                    bestRating: 5,
                    worstRating: 1,
                  }
                : undefined,
            geo:
              google_rating.latitude && google_rating.longitude
                ? {
                    '@type': 'GeoCoordinates',
                    latitude: google_rating.latitude,
                    longitude: google_rating.longitude,
                  }
                : undefined,
            // priceRange: courses[0] ? `${courses[0].price} CHF` : undefined,
            // hasMap: `https://g.page/${gmbShortname}`,
            openingHours: ['Mo,Tu,We,Th,Fr 09:00-17:00', 'Sa 09:00-12:00'],
            telephone: google_rating.phone,
            logo: pageInfo.settings.logo?.permalink,
            url: pageInfo.fullUrl,
            image: pageInfo.socialImage
              ? [pageInfo.socialImage?.permalink]
              : undefined,
          }}
        />
      )}
      <div>
        <Container fullPageOnMobile>
          <div className="flex flex-col md:flex-row">
            <div
              className={`relative z-10 order-2 mt-4 w-full px-7 pt-6 md:order-1 md:mt-0 md:max-w-sm md:px-0 md:pt-12 lg:max-w-xl`}
            >
              <Title style={1}>{title}</Title>
              <Title style="headerCompact" className="mt-4 lg:mt-8">
                {heading}
              </Title>
              <Bard
                content={hero_content}
                className="mt-4 md:mt-5 lg:mt-7"
                fullWidth
              />
              {link_link && link_text && !locate_me_button && (
                <CustomLink href={link_link} passHref>
                  <Button as="a" className="mt-7 md:mt-7 lg:mt-10">
                    {link_text}
                  </Button>
                </CustomLink>
              )}
              {locate_me_button && link_text && (
                <Button
                  className="mt-7 inline-flex items-center md:mt-7 lg:mt-10"
                  onClick={() => locateMe()}
                >
                  <TrackingCrossIcon className="size-6" />
                  <span className="ml-2">{link_text}</span>
                </Button>
              )}
            </div>
            <div className="relative order-1 w-full flex-1 md:order-2 md:w-auto">
              <div className="aspect-h-2 aspect-w-3 relative md:hidden">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={thumbnail.permalink}
                  webkit-playsinline="true"
                  className={clsx([
                    'size-full object-cover object-center',
                    styles['mask'],
                    styles['mask-8'],
                  ])}
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
              <div className="absolute -bottom-8 -left-28 right-0 top-6 hidden overflow-hidden md:block">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={thumbnail.permalink}
                  webkit-playsinline="true"
                  className="size-full rounded-[16px] object-cover object-center"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
                <div className="absolute -inset-y-40 inset-x-0">
                  <div className="size-full bg-[linear-gradient(90deg,_#FFF_0%,_rgba(255,_255,_255,_0.6)_20%,_rgba(255,_255,_255,_0.3)_30%,_rgba(255,_255,_255,_0)_40%)]" />
                </div>
              </div>
              {google_rating &&
                !!google_rating.rating &&
                google_rating.rating > 0 && (
                  <>
                    {(google_rating.rating_url && (
                      <Link
                        href={google_rating.rating_url}
                        className="absolute -bottom-4 right-4 z-10 rounded-lg border border-blue-100 bg-blue-50 px-4 py-2 shadow md:-bottom-8 md:right-6 md:translate-y-1/2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StarRating rating={google_rating.rating} />
                        <p className="mt-1 text-xs md:text-sm">
                          {google_rating.rating_count} Bewertungen auf Google
                        </p>
                      </Link>
                    )) || (
                      <div className="absolute -bottom-4 right-4 z-10 rounded-lg border border-blue-100 bg-blue-50 px-4 py-2 shadow md:-bottom-8 md:right-6 md:translate-y-1/2">
                        <StarRating rating={google_rating.rating} />
                        <p className="mt-1 text-xs md:text-sm">
                          {google_rating.rating_count} Bewertungen auf Google
                        </p>
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}
