export function swissFormat(phone?: string): string {
  if (!phone) {
    return ''
  }

  if (phone.length === 11 && phone.startsWith('41')) {
    phone = '+' + phone
  }

  phone = normalizeInternational(phone)

  const matches = phone.match(/\+41(\d{2})(\d{3})(\d{2})(\d{2})/)

  if (!matches) {
    return ''
  }

  return `0${matches.slice(1).join(' ')}`
}

export function normalizeInternational(phone?: string): string {
  if (!phone) {
    return ''
  }

  phone = phone.replace(/\s/g, '')

  if (phone.startsWith('+41')) {
    return phone
  }

  if (phone.startsWith('0041')) {
    return phone.replace('0041', '+41')
  }

  return '+41' + phone.substring(1)
}
