import classNames from 'classnames'
import { ElementType, ForwardedRef, forwardRef } from 'react'
import Button, { ButtonProps } from '@/src/components/form/Button'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_BUTTON_TAG = 'button'

const availableSizeStyles = {
  small: 'h-8.5 w-8.5',
  huge: 'h-[100px] w-[100px] md:h-[120px] md:w-[120px]',
}

function IconButton<TAG extends ElementType = typeof DEFAULT_BUTTON_TAG>(
  {
    size = 'small',
    className,
    children,
    ...props
  }: {
    size?: keyof typeof availableSizeStyles
  } & Omit<ButtonProps, 'size'>,
  ref: ForwardedRef<TAG>
) {
  return (
    <Button
      size="none"
      ref={ref}
      {...props}
      className={classNames(
        className,
        availableSizeStyles[size],
        'inline-flex items-center justify-center rounded-full text-center'
      )}
    >
      {children}
    </Button>
  )
}

export default forwardRef(IconButton)
