import { Clock, Info, MapPin, ShareNetwork } from '@phosphor-icons/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { WabCourseType } from '@/src/@types/admin'
import Button from '@/src/components/core/Button'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '@/src/components/core/Dialog'
import Course from '@/src/components/elements/Course'
import { ClickTooltip } from '@/src/components/elements/Tooltip'
import share from '@/src/lib/share'

export default function WabCourse({ course }: { course: WabCourseType }) {
  const router = useRouter()
  const isFullyBooked = course.seatsAvailable === 0

  return (
    <Dialog>
      <div className="@container">
        <div
          className={classNames([
            'rounded-lg bg-blue-50 px-5 @3xl:px-6 py-5',
            {
              'opacity-70': isFullyBooked,
            },
          ])}
        >
          <div className="flex h-8 items-center justify-between">
            <div className="flex items-center">
              <p
                className={classNames([
                  'px-2 py-1 rounded text-xs font-bold',
                  {
                    'bg-blue-150': !isFullyBooked,
                    'bg-gray-700 text-white': isFullyBooked,
                  },
                ])}
              >
                WAB
              </p>
              {isFullyBooked && (
                <p className="ml-3 text-xs font-bold text-gray-800">
                  Ausgebucht
                </p>
              )}
              {(course.seatsAvailable === 2 || course.seatsAvailable === 1) && (
                <p className="ml-3 text-xs font-bold text-yellow-700">
                  {getSeatText(course.seatsAvailable)}
                </p>
              )}
            </div>
            <ClickTooltip
              text="Der Kurslink wurde in deine Zwischenablage kopiert"
              className="inline-flex"
            >
              <button
                type="button"
                onClick={() =>
                  share(
                    router.asPath,
                    { course: course.id },
                    `${course.type} ${course.locationDescription} | Kursdaten & Anmeldung`,
                    course.day
                  )
                }
                className="flex shrink-0 space-x-2 text-sm font-bold"
              >
                <ShareNetwork size={20} weight="fill" />
                <p>Teilen</p>
              </button>
            </ClickTooltip>
          </div>
          <div className="mt-5 flex flex-col space-y-6 @3xl:flex-row @3xl:justify-between @3xl:space-y-0">
            <div>
              <div
                className="space-y-3 @3xl:grid @3xl:gap-y-3 @3xl:space-y-0"
                style={{
                  gridTemplateColumns: 'auto 1fr',
                }}
              >
                <TimeslotDay
                  timeslot={{
                    day: course.day,
                    isoDay: dayjs(course.start).format('YYYY-MM-DD'),
                    slots: [
                      {
                        start: dayjs(course.start).format('HH:mm'),
                        end: dayjs(course.end).format('HH:mm'),
                      },
                    ],
                  }}
                  compact={false}
                  mapLink={course.map}
                  address={course.locationDescription}
                />
              </div>
              <div className="mt-5 flex space-x-1">
                <Info
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 opacity-60"
                />
                <p>Dieser Kurs wird von unserem Partner TCS durchgeführt.</p>
              </div>
            </div>
            <div className="flex items-end justify-between @3xl:ml-6 @3xl:flex-col">
              <div>
                <div className="items-center @3xl:flex @3xl:justify-end">
                  <p className="text-sm line-through @3xl:mr-2">
                    {course.price / 100} CHF
                  </p>
                  <p className="text-lg font-extrabold">
                    {(course.price - 3000) / 100} CHF
                  </p>
                </div>
                <p className="text-xs opacity-60 @3xl:text-end">
                  mit BLINK Rabatt
                </p>
              </div>
              {!isFullyBooked && (
                <DialogTrigger asChild>
                  <Button className="w-[140px] @3xl:w-[160px]">Anmelden</Button>
                </DialogTrigger>
              )}
            </div>
          </div>
        </div>
      </div>
      <DialogContent>
        <DialogHeader title="Das geht noch günstiger!" />
        <DialogBody className="flex flex-col">
          <p>
            Als TCS Mitglied hast du {(course.price - course.priceMember) / 100}{' '}
            CHF Rabatt auf deinen WAB Kurs. Wir schenken dir eine Mitgliedschaft
            inkl. Verkehrsrechtsschutzversicherung für ein ganzes Jahr. <br />
          </p>
          <dl className="mt-6 max-w-max space-y-2">
            <div className="grid grid-cols-2 gap-2">
              <dt>Preis inkl. BLINK Rabatt</dt>
              <dd className="text-right">{(course.price - 3000) / 100} CHF</dd>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <dt>TCS Mitgliedschaftsrabatt *</dt>
              <dd className="text-right">
                -{(course.price - course.priceMember) / 100} CHF
              </dd>
            </div>
            <div className="grid grid-cols-2 gap-2 border-t border-blue-100 pt-2 font-bold">
              <dt>Dein Preis</dt>
              <dd className="text-right">
                {(course.priceMember - 3000) / 100} CHF
              </dd>
            </div>
          </dl>
          <p className="mt-6">
            <strong className="font-bold">* Wichtig</strong>: Um vom TCS Rabatt
            zu profitieren, muss du dich vor der Buchung des WAB Kurses als TCS
            Mitglied anmelden.
          </p>
          <div className="mt-8 space-y-4 md:flex md:space-x-4 md:space-y-0 md:self-end">
            <Button
              as={Link}
              version="purple-outlined"
              href={course.registrationUrl}
              target="_blank"
            >
              zur Kursbuchung
            </Button>
            <Button
              as={Link}
              href="/tcs-mitgliedschaft-und-verkehrsrechtsschutz/"
              className="-order-1 md:order-none"
            >
              Mitglied werden
            </Button>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

function getSeatText(seats: number) {
  if (seats === 2) {
    return '2 Plätze frei'
  }

  if (seats === 1) {
    return '1 Platz frei'
  }
  return ''
}

function TimeslotDay({
  timeslot,
  compact,
  mapLink,
  address,
}: {
  timeslot: Parameters<typeof Course>[0]['course']['timeslots'][0]
  compact: boolean
  mapLink: string
  address: string
}) {
  return (
    <div
      className={classNames({
        'flex flex-col space-y-2 @3xl:contents @3xl:space-y-0': compact,
        'space-y-2': !compact,
      })}
    >
      <p className="text-lg font-extrabold">
        <time dateTime={timeslot.isoDay}>{timeslot.day}</time>
      </p>
      <div
        className={classNames(
          'flex flex-col space-y-2 @3xl:flex-row @3xl:space-x-4 @3xl:space-y-0',
          {
            '@3xl:ml-4': compact,
          }
        )}
      >
        <div className="flex space-x-1">
          <Clock
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 opacity-60"
          />
          <p>
            <time dateTime={timeslot.slots[0].start}>
              {timeslot.slots[0].start}
            </time>{' '}
            –{' '}
            <time dateTime={timeslot.slots[timeslot.slots.length - 1].end}>
              {timeslot.slots[timeslot.slots.length - 1].end}
            </time>{' '}
            Uhr
          </p>
        </div>
        {!compact && (
          <Link
            href={mapLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex space-x-1"
          >
            <MapPin
              size={20}
              weight="fill"
              className="mt-0.5 shrink-0 opacity-60"
            />
            <span>{address}</span>
          </Link>
        )}
      </div>
    </div>
  )
}
