import classNames from 'classnames'
import Link from 'next/link'
import {
  NavigationLink as NavigationLinkType,
  NavigationResult,
  Settings,
} from '../../@types/statamic'
import FacebookLogo from '../icons/FacebookLogo'
import InstagramLogo from '../icons/InstagramLogo'
import TiktokLogo from '../icons/TiktokLogo'
import Container from '../layout/Container'
import CustomLink from './CustomLink'
import Navigation from '@/src/lib/Navigation'

export default function Footer({
  navs,
  settings,
}: {
  navs: NavigationResult['navs']
  settings: Settings['settings']
}) {
  const footerNavigation = Navigation(navs).get('footer')
  const infoNavigation = footerNavigation.slice(0, -1)
  const locations = footerNavigation[footerNavigation.length - 1]
  const isBLINK =
    process.env.NEXT_PUBLIC_NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'default'

  if (!footerNavigation || footerNavigation.length === 0) {
    return null
  }

  return (
    <div className="relative overflow-hidden bg-blue-900 pb-5 pt-8 text-sm text-white lg:pb-16 lg:pt-20">
      <Container>
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
          <div className="lg:col-span-3">
            {settings.logo_inverted && (
              <Link
                href="/"
                className={classNames('block', {
                  'w-[150px] lg:w-[220px]': isBLINK,
                  'w-[130px] lg:w-[181px]': !isBLINK,
                })}
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={settings.logo_inverted.permalink}
                  alt={settings.logo_inverted.alt}
                  className="w-full"
                />
                <span className="sr-only">Startseite</span>
              </Link>
            )}

            <div className="mt-16 text-xl font-bold">Blink AG</div>
            {settings.address && (
              <address
                className="footer-address mt-5 text-sm not-italic"
                dangerouslySetInnerHTML={{ __html: settings.address }}
              />
            )}
          </div>
          <div>
            <div
              className="mb-4 text-sm font-bold text-white/50 lg:hidden"
              aria-hidden="true"
            >
              Folge uns
            </div>
            <ul className="flex space-x-3 lg:flex-col lg:space-x-0 lg:space-y-6">
              {settings.tiktok && (
                <li>
                  <a
                    href={settings.tiktok}
                    rel="noopener noreferrer"
                    className="flex items-center font-bold"
                    target="_blank"
                  >
                    <TiktokLogo className="mr-2 size-12" />
                    <span className="sr-only lg:not-sr-only">
                      Folge uns auf Tiktok
                    </span>
                  </a>
                </li>
              )}
              {settings.instagram && (
                <li>
                  <a
                    href={settings.instagram}
                    rel="noopener noreferrer"
                    className="flex items-center font-bold"
                    target="_blank"
                  >
                    <InstagramLogo className="mr-2 size-12" />
                    <span className="sr-only lg:not-sr-only">
                      Folge uns auf Instagram
                    </span>
                  </a>
                </li>
              )}
              {settings.facebook && (
                <li>
                  <a
                    href={settings.facebook}
                    rel="noopener noreferrer"
                    className="flex items-center font-bold"
                    target="_blank"
                  >
                    <FacebookLogo className="mr-2 size-12" />
                    <span className="sr-only lg:not-sr-only">
                      Folge uns auf Facebook
                    </span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="mt-28 grid grid-cols-1 gap-x-6 gap-y-16 lg:grid-cols-4">
          <div className="space-y-16">
            {infoNavigation.map((link, index) => (
              <div key={index}>
                <div className="text-sm font-bold text-white/50">
                  {link.page.title}
                </div>
                <ul className="mt-6">
                  {link.children.map((child, index) => (
                    <NavigationLink link={child} key={index} />
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="lg:col-span-3">
            <div className="text-sm font-bold text-white/50">
              {locations.page.title}
            </div>
            <ul className="mt-6 md:columns-2 lg:columns-3">
              {locations.children.map((child, index) => (
                <NavigationLink link={child} key={index} />
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </div>
  )
}

function NavigationLink({ link }: { link: NavigationLinkType }) {
  if ((link.children && link.children.length !== 0) || !link.page.url) {
    return (
      <li className="mt-6 break-inside-avoid first:mt-0">
        {(link.page.url && (
          <CustomLink href={link.page.url}>
            <a className="text-sm font-bold">{link.page.title}</a>
          </CustomLink>
        )) || <div className="text-sm font-bold">{link.page.title}</div>}
        <ul className="mt-4 font-medium">
          {link.children.map((link, index) => {
            return <NavigationLink key={index} link={link} />
          })}
        </ul>
      </li>
    )
  }

  return (
    <li className="mt-3 first:mt-0">
      <CustomLink href={link.page.url}>
        <a>{link.page.title}</a>
      </CustomLink>
    </li>
  )
}
