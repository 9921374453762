import { RadioGroup } from '@headlessui/react'
import { variants } from 'classname-variants'
import { VariantPropsOf } from 'classname-variants/react'
import LabelOnly from '@/src/components/form/LabelOnly'

const styles = variants({
  base: 'block cursor-pointer rounded border px-5 py-3.5 text-sm',
  variants: {
    checked: {
      true: '',
      false: '',
    },
    style: {
      inverted: 'border-blue-100',
    },
  },
  compoundVariants: [
    {
      variants: {
        checked: true,
        style: 'inverted',
      },
      className: 'bg-purple-500 text-white',
    },
    {
      variants: {
        checked: false,
        style: 'inverted',
      },
      className: 'bg-inverted',
    },
  ],
  defaultVariants: {
    style: 'inverted',
    checked: false,
  },
})

type Props = {
  options: {
    value: string | number
    label: string
  }[]
  label: string
  value: string | number
  onChange: (value: string | number) => void
}

export type RadioButtonsProps = Props &
  Omit<VariantPropsOf<typeof styles>, 'checked'>

export default function RadioButtons({
  options,
  label,
  value,
  onChange,
  style = 'inverted',
}: RadioButtonsProps) {
  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label as={LabelOnly} style={style}>
        {label}
      </RadioGroup.Label>
      <div className="-m-1 flex py-1">
        {options.map((option, index) => (
          <RadioGroup.Option value={option.value} key={index} className="p-1">
            {({ checked }) => (
              <div
                className={styles({
                  style,
                  checked,
                })}
              >
                {option.label}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
