import Head from 'next/head'

export default function MetaTag({
  name,
  property,
  content,
}:
  | {
      name?: never
      property: string
      content?: string | null
    }
  | {
      property?: never
      name: string
      content?: string | null
    }) {
  if (!content) {
    return null
  }

  if (property) {
    return (
      <Head>
        <meta key={property} property={property} content={content} />
      </Head>
    )
  }

  return (
    <Head>
      <meta key={property} name={name} content={content} />
    </Head>
  )
}
