import Icon from './Icon'

export default function BurgerIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line
            x1="1"
            y1="1"
            x2="24"
            y2="1"
            stroke="#05444C"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="1"
            y1="11"
            x2="24"
            y2="11"
            stroke="#05444C"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="1"
            y1="21"
            x2="24"
            y2="21"
            stroke="#05444C"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      }
    />
  )
}
