import assign from 'lodash-es/assign'
import flatMap from 'lodash-es/flatMap'
import groupBy from 'lodash-es/groupBy'
import { useEffect, useState } from 'react'
import { TeamFilterBlock } from '../../@types/statamic'
import { getTeamMembers } from '../../AdminApiClient'
import TeamMember from '../../components/elements/TeamMember'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import MaliyImage from './Maliy.jpg'
import {
  RadioGroupItem,
  RadioGroupRoot,
} from '@/src/components/core/RadioGroup'

type TeamBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamFilterBlock) {
  return title
}

export async function getStaticProps({ title }: TeamFilterBlock) {
  const team = await getTeamMembers()
  team.push({
    name: 'Maliy',
    slug: 'Maliy',
    roles: ['Office'],
    shortDescription: 'Office Hund',
    image: MaliyImage,
  })
  return { team, title }
}

export default function Component({ title, team }: TeamBlockProps) {
  const [showDescription, setShowDescription] = useState(-1)

  const roles = Object.values(
    groupBy(
      flatMap(team, (team) =>
        team.roles.map((role) => assign({}, team, { role }))
      ),
      'role'
    )
  ).map((teamByRole) => teamByRole[0].role)
  const [selectedRole, setSelectedRole] = useState('ALL')

  const filteredTeam = team.filter(
    (teammember) =>
      selectedRole === 'ALL' ||
      teammember.roles.some((role) => role === selectedRole)
  )
  useEffect(() => {
    setShowDescription(-1)
  }, [selectedRole])

  return (
    <div className="md:pb-12 lg:pb-16">
      <Container>
        <div className="relative flex flex-col items-baseline justify-between">
          <Title style={2}>{title}</Title>
          <div className="mt-4 flex w-full flex-col justify-between rounded-2xl bg-gradient-150 p-5 md:mt-8 md:flex-row md:bg-none md:p-0">
            <RadioGroupRoot
              value={selectedRole}
              onValueChange={setSelectedRole}
              className="-ml-2 mt-2 flex flex-wrap"
            >
              <RadioGroupItem
                value={'ALL'}
                size="filterTag"
                color="blue-outlined"
                showCheckbox={false}
                className="ml-2 mt-2"
              >
                Alle
              </RadioGroupItem>
              {roles.map((role) => (
                <RadioGroupItem
                  key={role}
                  value={role}
                  size="filterTag"
                  color="blue-outlined"
                  showCheckbox={false}
                  className="ml-2 mt-2"
                >
                  {role}
                </RadioGroupItem>
              ))}
            </RadioGroupRoot>
          </div>
          <div className="mt-4 w-full md:mt-8">
            <div className="-mx-7 lg:mx-0">
              <ul className="flex flex-wrap justify-center md:px-7 lg:grid lg:grid-flow-row lg:grid-cols-4 lg:gap-x-6 lg:gap-y-10 lg:space-x-0 lg:overflow-visible lg:px-0">
                {filteredTeam.map((member, index) => (
                  <li
                    key={index}
                    className="my-4 w-full max-w-[calc(100%-2rem)] shrink-0 snap-center md:mx-2 md:w-80 lg:m-0 lg:w-full lg:max-w-full lg:px-0"
                  >
                    <TeamMember
                      {...member}
                      showDescription={showDescription === index}
                      toggleDescription={() =>
                        showDescription === index
                          ? setShowDescription(-1)
                          : setShowDescription(index)
                      }
                      index={index}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
