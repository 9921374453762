import { Check, Info } from '@phosphor-icons/react'
import { DialogClose } from '@radix-ui/react-dialog'
import { TooltipPortal } from '@radix-ui/react-tooltip'
import classNames from 'classnames'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Controller, useWatch } from 'react-hook-form'
import Image10 from '@/blocks/Packets/10.png'
import Image5 from '@/blocks/Packets/5.png'
import PackagesIcon from '@/blocks/Packets/packages.png'
import ImageSingle from '@/blocks/Packets/single.png'
import {
  adminDeleteFile,
  adminGetFile,
  adminPostFile,
} from '@/src/BlinkAdminApiClient'
import Button from '@/src/components/core/Button'
import { ConfirmCheckbox } from '@/src/components/core/ConfirmCheckbox'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogSimple,
  DialogTrigger,
} from '@/src/components/core/Dialog'
import ErrorMessage from '@/src/components/core/ErrorMessage'
import FileUpload from '@/src/components/core/fileUpload/FileUpload'
import ImageUploadMask from '@/src/components/core/fileUpload/masks/ImageUploadMask'
import { Input } from '@/src/components/core/Input'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/src/components/core/Tooltip'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/leads`

type RequestForm = {
  first_name: string
  last_name: string
  phone: string
  email: string
  voucher: string
  interests: string[]
  driving_license_file: string
  location: string
  address: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const TypeNameMap = {
  single: 'Einzellektion',
  '5': '5er-Paket',
  '10': '10er-Paket',
}

const TypeTitleMap = {
  single: 'Anfrage für eine Einzellektion',
  '5': 'Anfrage für ein 5er-Paket',
  '10': 'Anfrage für ein 10er-Paket',
}

const TypeImageMap = {
  single: ImageSingle,
  '5': Image5,
  '10': Image10,
}

const Packet = ({
  type,
  highlighted = false,
  locationName,
  price,
  vkuPriceOriginal,
  vkuPricePacket,
  index,
  pricePerLesson,
  facts,
  bookable = true,
}: {
  type: 'single' | '5' | '10'
  price: number
  locationName: string
  highlighted?: boolean
  vkuPriceOriginal?: number
  vkuPricePacket?: number
  index: number
  pricePerLesson: number
  facts: string[]
  bookable?: boolean
}) => {
  const { utmData } = useUtmCache()

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [startedFillingOut, setStartedFillingOut] = useState(false)
  const [vkuSelected, setVkuSelected] = useState(false)

  const formRef = useRef<HTMLFormElement>(null)
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useLaravelForm<RequestForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      voucher: '',
      address: '', // honeypot
      interests: [],
      driving_license_file: '',
      location: locationName ?? undefined,
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const formValues = useWatch({
    control,
    defaultValue: {},
  })

  useEffect(() => {
    // check if a form property exists to ignore init of value
    if (formValues.first_name && !startedFillingOut) {
      setStartedFillingOut(true)
      TagManager.dataLayer({
        dataLayer: {
          event: 'packages_lead_started',
          params: {
            origin: 'Website',
            site: window.location.href,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  const preparedData = (data: RequestForm) => {
    setLoading(true)
    const preparedData: any = {
      ...data,
    }
    let interests
    switch (type) {
      case 'single':
        interests = [`Einzellektion für ${price} CHF`]
        break
      case '5':
        interests = [`5er-Paket für ${price} CHF`]
        break
      case '10':
        if (vkuSelected && vkuPricePacket) {
          interests = [`Starterpaket für ${vkuPricePacket} CHF`]
        } else {
          interests = [`10er-Paket für ${price} CHF`]
        }
        break
    }
    preparedData.interests = interests
    return preparedData
  }

  return (
    <Dialog
      onOpenChange={(open) => {
        if (open) {
          formRef.current?.reset()
          TagManager.dataLayer({
            dataLayer: {
              event: 'packages_lead_opened',
              params: {
                origin: 'Website',
                site: window.location.href,
              },
            },
          })
        } else {
          setSubmitted(false)
          setLoading(false)
          setStartedFillingOut(false)
          formRef.current?.reset()
        }
      }}
    >
      <DialogContent>
        {!submitted && (
          <>
            <DialogHeader
              title={TypeTitleMap[type]}
              description="Fülle das Formular aus und wir nehmen mit dir Kontakt auf."
            />
            <DialogBody>
              <form
                ref={formRef}
                onSubmit={handleSubmit(
                  () => {
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'packages_lead_sent',
                        params: {
                          origin: 'Website',
                          site: window.location.href,
                          value: 350,
                        },
                      },
                    })
                    setSubmitted(true)
                    formRef.current?.reset()
                    setLoading(false)
                  },
                  preparedData,
                  () => setLoading(false)
                )}
                method="POST"
                action={requestUrl}
              >
                <div className="relative rounded bg-blue-100 p-4 pr-40">
                  <p className="font-bold">Du bist schon bei BLINK? 👀</p>
                  <p className="mt-1">
                    Buche dein {type === 'single' ? 'Fahrlektion' : 'Paket'}{' '}
                    direkt im myBLINK, da geht’s schneller!
                  </p>
                  <Link
                    href="https://myblink.blinkdrive.ch/appointments"
                    target="_blank"
                    className="mt-4 block text-sm font-bold text-purple-600 hover:text-purple-800"
                  >
                    Im myBLINK buchen
                  </Link>
                  <Image
                    src={PackagesIcon}
                    alt="Packet buchen"
                    height={100}
                    width={130}
                    className="absolute bottom-0 right-4 object-contain object-center"
                    unoptimized
                  />
                </div>
                <p className="mt-10 text-xl font-extrabold">Kontakt</p>
                <div className="mt-4 grid gap-3 md:grid-cols-2">
                  <Input
                    type="first_name"
                    label="Vorname"
                    required
                    {...register('first_name')}
                  />
                  <Input
                    type="last_name"
                    label="Nachname"
                    required
                    {...register('last_name')}
                  />
                  <Input
                    type="email"
                    label="E-Mail"
                    required
                    {...register('email')}
                  />
                  <Input
                    type="phone"
                    label="Handynummer"
                    required
                    {...register('phone')}
                  />
                  <Input
                    type="voucher"
                    label="Gutscheincode (optional)"
                    {...register('voucher')}
                  />
                </div>
                <p className="mt-6 font-bold">Upload Lernfahrausweis</p>
                <p className="mt-2">
                  Damit wir dich später an die praktische Prüfung anmelden
                  können, benötigen wir Informationen aus deinem
                  Lernfahrausweis. Fotografiere bitte die Innenseite deines
                  Dokuments und lade es gleich hier hoch.
                </p>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="driving_license_file"
                    render={({ field: { value, onChange } }) => (
                      <FileUpload
                        value={value}
                        onChange={onChange}
                        adminGetFile={adminGetFile}
                        adminPostFile={adminPostFile}
                        adminDeleteFile={adminDeleteFile}
                      >
                        {(fileUploadChildrenProps) => (
                          <ImageUploadMask
                            {...fileUploadChildrenProps}
                            emptyText={() => 'Foto aufnehmen und hochladen'}
                            imageAlt="Lernfahrausweis Kat. B"
                          />
                        )}
                      </FileUpload>
                    )}
                  />
                  <ErrorMessage>
                    {errors.driving_license_file?.message as string}
                  </ErrorMessage>
                </div>
                {type === '10' && vkuPriceOriginal && vkuPricePacket && (
                  <div>
                    <div className="mt-10 flex items-center">
                      <p className="text-xl font-extrabold">
                        Verkehrskundeunterricht
                      </p>
                      <Tooltip>
                        <TooltipTrigger>
                          <Info
                            weight="fill"
                            size={20}
                            className="ml-2 text-purple-500"
                          />
                        </TooltipTrigger>
                        <TooltipPortal>
                          <TooltipContent side="bottom" className="max-w-md">
                            Der Verkehrskundeunterricht (VKU) ist ein
                            obligatorischer Kurs in deiner Fahrausbildung. Er
                            dauert insgesamt acht Stunden. Wir empfehlen dir,
                            den VKU gleich am Anfang deiner Fahrausbildung zu
                            machen.
                          </TooltipContent>
                        </TooltipPortal>
                      </Tooltip>
                    </div>
                    <ConfirmCheckbox
                      onCheckedChange={(checked) =>
                        setVkuSelected(checked === true)
                      }
                      className="mt-4"
                    >
                      Ich möchte den VKU für nur {vkuPricePacket - price} CHF
                      statt {vkuPriceOriginal} CHF zum Paket hinzufügen.
                    </ConfirmCheckbox>
                  </div>
                )}
                <div className="mt-8 flex justify-end space-x-4">
                  <DialogClose asChild>
                    <Button type="button" version="purple-ghost">
                      Abbrechen
                    </Button>
                  </DialogClose>
                  <Button type="submit" loading={loading}>
                    Unverbindlich anfragen
                  </Button>
                </div>
              </form>
            </DialogBody>
          </>
        )}
        {submitted && (
          <DialogSimple>
            <p className="text-xl font-extrabold">Vielen Dank!</p>
            <p className="mt-3">
              Wir haben deine Anfrage erhalten und melden uns schnellstmöglich
              bei dir.
            </p>
            <div className="mt-8 flex justify-end">
              <DialogClose asChild>
                <Button type="button" className="w-full lg:w-auto">
                  Schliessen
                </Button>
              </DialogClose>
            </div>
          </DialogSimple>
        )}
      </DialogContent>
      <li
        className={classNames([
          'flex flex-col overflow-hidden rounded-lg md:row-span-2 md:max-w-none',
          {
            'z-10 bg-purple-600 md:rounded-t-2xl md:rounded-b-none text-white md:-mx-8 md:-mt-10':
              highlighted,
            'bg-blue-50 md:rounded-none': !highlighted,
          },
          {
            'order-1 md:order-none': highlighted,
            'order-2 md:order-none': !highlighted,
            'md:rounded-l-2xl': index === 0,
            'md:rounded-r-2xl': index === 2,
          },
        ])}
      >
        <div
          className={clsx('relative', {
            'h-[220px]': !highlighted,
            'h-[260px]': highlighted,
          })}
        >
          <div
            className={clsx('relative z-10 flex justify-center', {
              'pt-2.5': !highlighted,
              'pt-5': highlighted,
            })}
          >
            <Image
              src={TypeImageMap[type]}
              alt={TypeNameMap[type]}
              width={300}
              height={200}
              sizes="33vw"
              unoptimized
            />
          </div>
          <svg
            className="absolute bottom-0 left-1/2 z-0 w-[120%] -translate-x-1/2"
            viewBox="0 0 408 324"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M396.015 -71.7848C279.024 -185.955 150.787 -186.219 35.6305 -110.325C-77.1389 -32.6518 -83.7022 119.73 -7.32927 231.792C69.0437 343.855 239.093 343.262 342.912 281.598C446.732 219.934 513.007 42.3851 396.015 -71.7848Z"
              fill={
                highlighted
                  ? `url(#tile-${index}-purple)`
                  : `url(#tile-${index}-white)`
              }
            />
            <defs>
              <linearGradient
                id={`tile-${index}-white`}
                x1="458"
                y1="-163.077"
                x2="-8.03056"
                y2="396.398"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E7EBFF" />
                <stop offset="1" stopColor="#E7EBFF" />
              </linearGradient>
              <linearGradient
                id={`tile-${index}-purple`}
                x1="635.182"
                y1="293.347"
                x2="-103.753"
                y2="-342.714"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#3A19A7" />
                <stop offset="1" stopColor="#5428A7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex flex-1 flex-col items-start justify-between p-10">
          <div>
            <p>{TypeNameMap[type]}</p>
            <h3 className="mt-2 text-2xl font-extrabold md:text-7xl">
              {pricePerLesson} CHF
              <span
                className={clsx('text-base font-medium', {
                  'text-gray-800': !highlighted,
                  'text-blue-50': highlighted,
                })}
              >
                /Lektion
              </span>
            </h3>
            <div className="mt-4 space-y-1">
              {facts.map((fact) => (
                <div key={fact} className="flex">
                  {bookable && <Check size={20} className="mr-2 shrink-0" />}
                  <p>{fact}</p>
                </div>
              ))}
            </div>
          </div>
          {bookable && (
            <DialogTrigger asChild>
              <Button
                version={highlighted ? 'white' : 'purple'}
                className="mt-6"
              >
                Jetzt anfragen
              </Button>
            </DialogTrigger>
          )}
        </div>
      </li>
    </Dialog>
  )
}

export default Packet
