import Container from '../../components/layout/Container'
import { QuickLinksBlock } from '@/src/@types/statamic'
import CustomLink from '@/src/components/elements/CustomLink'

export function getTitle({ title }: QuickLinksBlock) {
  return title
}

export default function Component({ title, links }: QuickLinksBlock) {
  return (
    <Container>
      <p className="font-bold md:text-xl">{title}</p>
      <div className="mt-2 flex flex-wrap">
        {links.map((link, index) => (
          <CustomLink key={index} href={link.link_link}>
            <a className="mb-3 mr-3 rounded-lg bg-gradient-100 px-5 pb-4 pt-[19px] text-lg font-extrabold text-purple-600 hover:bg-blue-100 hover:bg-none md:px-8 md:pb-5 md:pt-[25px] md:text-2xl">
              {link.link_text}
            </a>
          </CustomLink>
        ))}
      </div>
    </Container>
  )
}
