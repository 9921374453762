import { forwardRef } from 'react'
import { Input, InputProps } from '@/src/components/core/Input'
import useDateMask from '@/src/components/core/lib/useDateMask'

const DateInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'autoComplete' | 'kind' | 'options'>
>((props, ref) => {
  const dateProps = useDateMask()

  return (
    <Input
      ref={ref}
      {...props}
      {...dateProps}
      // options={{ date: true, delimiter: '.', datePattern: ['d', 'm', 'Y'] }}
      autoComplete="bday"
    />
  )
})
// @ts-ignore
DateInput.displayName = 'core-dateinput'

export { DateInput }
