import { Star, StarHalf } from '@phosphor-icons/react'

export default function StarRating({ rating }: { rating: number }) {
  const stars = []

  for (let i = 0; i < 5; i++) {
    stars.push(
      <li key={i} className="text-yellow-500">
        <RatingStar rating={rating - i} />
      </li>
    )
  }

  return (
    <div className="flex space-x-2.5">
      <ol
        aria-label={`${rating} von 5 Sterne`}
        className="flex items-center space-x-px"
      >
        {stars}
      </ol>
      <p
        className="pt-1 text-sm font-extrabold md:text-base"
        aria-hidden="true"
      >
        {rating} / 5
      </p>
    </div>
  )
}

function RatingStar({ rating }: { rating: number }) {
  if (rating < 0.25) {
    return <Star size={20} className="text-blue-150" />
  }

  if (rating < 0.75) {
    return <StarHalf size={20} weight="fill" />
  }

  return <Star size={20} weight="fill" />
}
