import CustomLink from '../elements/CustomLink'
import Button from '../form/Button'
import { BardLinkButton as BardLinkButtonType } from '@/src/@types/statamic'

export default function BardLinkButton({
  link_link,
  link_text,
  color,
}: BardLinkButtonType) {
  return (
    <div>
      <CustomLink href={link_link} passHref>
        <Button as="a" style={color.value}>
          {link_text}
        </Button>
      </CustomLink>
    </div>
  )
}
