import { ChangeEvent, ForwardedRef, forwardRef } from 'react'
import CheckboxButton, {
  CheckboxButtonProps,
} from '@/src/components/form/CheckboxButton'

function CheckboxButtonGroup<OptionValue = any>(
  {
    options,
    value = [],
    error = false,
    onChange = () => {},
    style = 'white',
  }: {
    value?: OptionValue[]
    options: Omit<CheckboxButtonProps<OptionValue>, 'error' | 'checked'>[]
    onChange?: (value: OptionValue[]) => void
    error?: boolean
    style?: 'white' | 'inverted'
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.checked) {
      return onChange([...value, options[index].value])
    }
    const newValue = [...value]
    newValue.splice(value.indexOf(options[index].value), 1)
    onChange(newValue)
  }
  return (
    <div className="m-[-5px] flex flex-wrap">
      {options.map(({ onChange: _onChange, ...props }, index) => (
        <div className="p-[5px]" key={index}>
          <CheckboxButton
            {...props}
            checked={value.includes(props.value)}
            ref={ref}
            onChange={(event) => {
              changeHandler(event, index)
            }}
            error={error}
            style={style}
          />
        </div>
      ))}
    </div>
  )
}

export default forwardRef(CheckboxButtonGroup)
