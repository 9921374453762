import { uniqBy } from 'lodash-es'
import { ReactNode, useState } from 'react'
import { unstable_serialize } from 'swr'
import WabCourse from '@/blocks/TcsWabCourses/WabCourse'
import { WabCourseType } from '@/src/@types/admin'
import { PageInfo } from '@/src/@types/models'
import { getWabCourses } from '@/src/BlinkAdminApiClient'
import useFilter, {
  weekdaySorting,
} from '@/src/components/core/hooks/useFilter'
import MultiSelect, { SelectOption } from '@/src/components/core/MultiSelect'
import Marker from '@/src/components/elements/Marker'
import SquareMap from '@/src/components/elements/SquareMap'
import Title from '@/src/components/elements/Title'
import PlusIcon from '@/src/components/icons/PlusIcon'
import Container from '@/src/components/layout/Container'
import Sticky from '@/src/components/layout/Sticky'
import useWabCourses from '@/src/hooks/useWabCourses'

export type WabCoursesBlock = {
  type: string
  location: {
    value: string
  }[]
}

export async function getStaticProps({
  location,
}: WabCoursesBlock & { map_only: boolean }) {
  const locationIds = location.map(({ value }) => value)
  const courses = await getWabCourses({ locations: locationIds })

  return {
    courses: courses ?? [],
    fallback: {
      [unstable_serialize(['wab-courses', locationIds])]: courses ?? [],
    },
    locationIds,
  }
}

export default function TcsWabCourses({
  locationIds,
}: Awaited<ReturnType<typeof getStaticProps>> & {
  pageInfo: PageInfo
}) {
  const [courses] = useWabCourses({ locations: locationIds })

  if (!courses || courses.length === 0) {
    return null
  }

  return (
    <Container>
      <div className="lg:grid lg:grid-cols-3 lg:gap-32">
        <div className="flex max-w-screen-md flex-col lg:col-span-2">
          <Title style={3} as="h2" className="[text-wrap:balance]">
            Daten &amp; Anmeldung
          </Title>
          <Filters courses={courses}>
            {(courses) => <Courses courses={courses} />}
          </Filters>
        </div>
        <Sidebar
          addresses={uniqBy(
            courses
              .map((course) => course.address)
              .filter((address): address is NonNullable<typeof address> =>
                Boolean(address)
              ),
            'id'
          )}
        />
      </div>
    </Container>
  )
}

function Sidebar({
  addresses,
}: {
  addresses: NonNullable<WabCourseType['address']>[]
}) {
  return (
    <Sticky stickyTopSpace={80}>
      <div className="hidden lg:block">
        <Title style={4} as="h3">
          {addresses.length > 1 ? '[Kursstandorte]' : '[Kursstandort]'}
        </Title>
        <div className="mt-4">
          {addresses.length > 0 && (
            <>
              <SquareMap
                markers={addresses.map(({ longitude, latitude }) => [
                  longitude,
                  latitude,
                ])}
              >
                {addresses.map((location, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                  >
                    {`<h4 class="font-bold text-sm">${location.info}</h4>${location.street}, ${location.zip} ${location.city}`}
                  </Marker>
                ))}
              </SquareMap>
              <div className="mt-4 flex flex-col gap-4">
                {addresses.map((address) => (
                  <address className="not-italic" key={address.id}>
                    {address.info && (
                      <span className="text-xl font-bold">{address.info}</span>
                    )}
                    {(address.street && address.city && address.zip && (
                      <p className="my-2">
                        {address.street}
                        <br />
                        {address.zip} {address.city}
                      </p>
                    )) || <br />}
                  </address>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Sticky>
  )
}

function Courses({ courses }: { courses: WabCourseType[] }) {
  const availableCourses = courses.filter((course) => course.seatsAvailable > 0)
  const unavailableCourses = courses.filter(
    (course) => course.seatsAvailable <= 0
  )
  return (
    <>
      <UnavailableCourses courses={unavailableCourses} />
      <AvailableCourses courses={availableCourses} />
    </>
  )
}

function AvailableCourses({ courses }: { courses: WabCourseType[] }) {
  const [limit, setLimit] = useState(5)

  if (courses.length === 0) {
    return null
  }

  const shownCourses = courses.slice(0, limit)

  return (
    <ul className="flex flex-col gap-4">
      {shownCourses.map((course) => (
        <li key={course.id}>
          <WabCourse course={course} />
        </li>
      ))}
      {shownCourses.length !== courses.length && (
        <div>
          <button
            type="button"
            onClick={() => setLimit(999)}
            className="flex w-full items-center justify-between rounded-md bg-gradient-100 p-5 font-bold text-purple-600 hover:text-purple-800"
          >
            Alle Kurse anzeigen
            <PlusIcon className="ml-2 size-5 shrink-0" />
          </button>
        </div>
      )}
    </ul>
  )
}

function UnavailableCourses({ courses }: { courses: WabCourseType[] }) {
  const [startIndex, setStartIndex] = useState(-1)

  if (courses.length === 0) {
    return null
  }

  const shownCourses = courses.slice(startIndex)

  return (
    <>
      <ul className="flex max-w-screen-md flex-col gap-4">
        {shownCourses.map((course) => (
          <li key={course.id}>
            <WabCourse course={course} />
          </li>
        ))}
      </ul>
      {shownCourses.length !== courses.length && (
        <div>
          <button
            type="button"
            onClick={() => setStartIndex(0)}
            className="flex w-full items-center justify-between rounded-md bg-gradient-100 p-5 font-bold text-purple-600 hover:text-purple-800"
          >
            Alle ausgebuchten Kurse anzeigen
            <PlusIcon className="ml-2 size-5 shrink-0" />
          </button>
        </div>
      )}
    </>
  )
}

function Filters({
  courses,
  children,
}: {
  courses: WabCourseType[]
  children: (courses: WabCourseType[]) => ReactNode
}) {
  const {
    filter: addressFilter,
    setFilter: setAddressFilter,
    options: addresses,
    matches: matchesAddressFilter,
  } = useFilter<WabCourseType>({
    options:
      courses?.reduce((acc, course) => {
        const address = course.locationDescription
        const existing = acc.find((a) => a.name === address)
        if (existing) {
          existing.count!++
        } else {
          acc.push({ name: address, count: 1 })
        }
        return acc
      }, [] as SelectOption[]) ?? [],
    filterFn: (course, filter) =>
      filter.some((filter) => filter.name === course.locationDescription),
  })

  const {
    filter: weekdayFilter,
    setFilter: setWeekdayFilter,
    options: weekdays,
    matches: matchesWeekdayFilter,
  } = useFilter<WabCourseType>({
    options:
      courses
        ?.reduce((acc, course) => {
          const weekday = course.weekday
          const existing = acc.find((a) => a.name === weekday)
          if (existing) {
            existing.count!++
          } else {
            acc.push({ name: weekday, count: 1 })
          }
          return acc
        }, [] as SelectOption[])
        .sort(
          (a, b) =>
            weekdaySorting.findIndex((ws) => a.name.startsWith(ws)) -
            weekdaySorting.findIndex((ws) => b.name.startsWith(ws))
        ) ?? [],
    filterFn: (course, filter) =>
      filter.some((filter) => filter.name === course.weekday),
  })

  const filteredCourses = courses.filter((course) => {
    return matchesAddressFilter(course) && matchesWeekdayFilter(course)
  })

  return (
    <>
      {(addresses.length > 1 || weekdays.length > 1) && (
        <div className="mt-8 @container">
          <div className="grid grid-cols-1 gap-4 @xl:grid-cols-2">
            {addresses.length > 1 && (
              <MultiSelect
                label="Kursstandort"
                selected={addressFilter}
                setSelected={setAddressFilter}
                options={addresses}
              />
            )}
            {weekdays.length > 1 && weekdays.length <= 5 && (
              <MultiSelect
                label="Wochentag"
                selected={weekdayFilter}
                setSelected={setWeekdayFilter}
                options={weekdays}
              />
            )}
          </div>
        </div>
      )}
      <div className="mt-8 flex flex-col gap-4">
        {children(filteredCourses)}
      </div>
    </>
  )
}
