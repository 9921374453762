import classNames from 'classnames'
import { VideoBlock } from '../../@types/statamic'
import ArrowAction from '../../components/elements/ArrowAction'
import Content from '../../components/elements/Content'
import Picture from '../../components/elements/Picture'
import Title from '../../components/elements/Title'
import VideoElement from '../../components/elements/Video'
import Container from '../../components/layout/Container'
import useGradientId from '../../hooks/useGradientId'
import { PageInfo } from '@/src/@types/models'

export function getTitle({ optional_title }: VideoBlock) {
  return optional_title
}

export function getDescription({ text }: VideoBlock) {
  if (text) {
    const pTag = text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export function getImage({ thumbnail }: VideoBlock) {
  return thumbnail
}

export default function Component({
  optional_title,
  video_only,
  text,
  thumbnail,
  video,
  link_link,
  link_text,
  pageInfo,
}: VideoBlock & { pageInfo: PageInfo }) {
  const [gradient1Id, gradient1Reference] = useGradientId()
  const [gradient2Id, gradient2Reference] = useGradientId()

  return (
    <div
      className={classNames({
        'md:pb-12 lg:pb-16': pageInfo.blueprint === 'pages',
      })}
    >
      <Container>
        {(video_only && (
          <div
            className={classNames('relative max-w-[800px]', {
              'mx-auto': pageInfo.blueprint === 'pages',
            })}
          >
            <svg
              width="315"
              height="303"
              viewBox="0 0 315 303"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-[-20%] top-[-10%] -z-10 w-[250px] md:bottom-[-20%] md:right-[-10%] md:top-auto md:w-auto"
            >
              <path
                opacity="0.5"
                d="M18.7299 234.262C18.7299 234.262 50.7783 311.833 170.661 301.197C290.543 290.56 301.84 210.387 311.529 181.888C321.218 153.39 308.395 72.2812 249.307 24.5425C190.219 -23.1961 10.654 -1.59883 1.73693 116.092C-4.66879 200.637 18.7299 234.262 18.7299 234.262Z"
                fill="url(#paint0_linear_3221_42167)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3221_42167"
                  x1="-4.85026"
                  y1="306.956"
                  x2="314.152"
                  y2="-0.809331"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBFBFF" />
                  <stop offset="1" stopColor="#DAE0FF" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="79"
              height="63"
              viewBox="0 0 79 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute -bottom-1/4 right-[-15%] hidden md:block"
            >
              <path
                opacity="0.5"
                d="M75.8881 37.9805C75.8881 37.9805 85.952 17.9428 63.4381 5.10807C35.4453 -10.8496 41.0879 20.9871 19.0051 16.537C-3.07769 12.0869 -6.16622 38.0454 16.5945 54.5978C39.3547 71.1501 64.717 61.9985 75.8881 37.9805Z"
                fill="url(#paint0_linear_3221_42168)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3221_42168"
                  x1="-9.43211"
                  y1="49.6809"
                  x2="80.2585"
                  y2="0.702652"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBFBFF" />
                  <stop offset="1" stopColor="#DAE0FF" />
                </linearGradient>
              </defs>
            </svg>

            {optional_title && (
              <Title style={3} as="h2" className="mb-8">
                {optional_title}
              </Title>
            )}
            <VideoElement url={video} title={optional_title} description={text}>
              <Picture
                {...thumbnail}
                className="rounded-lg"
                layout="responsive"
              />
            </VideoElement>
          </div>
        )) || (
          <div className="relative md:pt-16 lg:pt-20">
            <div className="relative flex flex-wrap items-start justify-between">
              <div className="w-full shrink-0 md:mr-6 md:max-w-sm">
                {optional_title && (
                  <Title style={3} as="h2" className="mb-5 md:mb-8">
                    {optional_title}
                  </Title>
                )}

                <Content content={text} />

                <div className="mt-6">
                  {link_link && link_text && (
                    <ArrowAction size="small" href={link_link}>
                      {link_text}
                    </ArrowAction>
                  )}
                </div>
              </div>

              <div className="relative mt-12 w-full md:mt-0 md:w-auto md:flex-1 lg:w-1/2 lg:flex-initial">
                <svg
                  className="absolute right-[-10%] top-[-15%] w-[8%]"
                  viewBox="0 0 62 79"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M40.6394 4.40124C40.6394 4.40124 21.8225 -7.79369 6.60252 13.1816C-12.3208 39.2613 19.942 37.1343 13.1035 58.5979C6.2651 80.0616 31.7301 85.9705 50.6724 65.1565C69.6146 44.3431 63.2916 18.132 40.6394 4.40124Z"
                    fill={gradient1Reference}
                  />
                  <defs>
                    <linearGradient
                      id={gradient1Id}
                      x1="42.9387"
                      y1="90.4893"
                      x2="4.06307"
                      y2="-4.01977"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FBFBFF" />
                      <stop offset="1" stopColor="#DAE0FF" />
                    </linearGradient>
                  </defs>
                </svg>

                <svg
                  className="absolute right-[-8%] top-[-20%] w-3/5"
                  viewBox="0 0 443 461"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M320.324 445.545C320.324 445.545 438.91 411.161 442.644 234.149C446.378 57.1363 330.959 27.7185 290.846 8.96516C250.732 -9.7882 130.065 -4.08595 50.7518 74.637C-28.5618 153.36 -25.8697 419.408 144.793 451.382C267.39 474.351 320.324 445.545 320.324 445.545Z"
                    fill={gradient2Reference}
                  />
                  <defs>
                    <linearGradient
                      id={gradient2Id}
                      x1="422.829"
                      y1="491.721"
                      x2="24.1129"
                      y2="-24.2637"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FBFBFF" />
                      <stop offset="1" stopColor="#DAE0FF" />
                    </linearGradient>
                  </defs>
                </svg>
                <VideoElement
                  url={video}
                  title={optional_title}
                  description={text}
                >
                  <Picture
                    {...thumbnail}
                    className="rounded-lg"
                    layout="responsive"
                  />
                </VideoElement>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}
