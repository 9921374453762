import { CaretLeft, CaretRight, Play, X } from '@phosphor-icons/react'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { useState } from 'react'
import { Navigation, Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import { VideoSliderBlock } from '../../@types/statamic'
import { cn } from '@/src/components/core/lib/utils'
import Content from '@/src/components/elements/Content'
import Picture from '@/src/components/elements/Picture'
import Container from '@/src/components/layout/Container'
import { useBetterUseId } from '@/src/hooks/betterUseId'

export default function Component({
  title,
  text,
  slider_videos,
}: VideoSliderBlock) {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0)
  const nextElementId = `next-el-${useBetterUseId()}`
  const prevElementId = `prev-el-${useBetterUseId()}`
  if (!slider_videos) return null

  const hasTitle = slider_videos.some((video) => video.title)
  const hasSubtitle = slider_videos.some((video) => video.subtitle)

  return (
    <Dialog.Root>
      <div className="md:pb-12 lg:pb-16">
        <Container className="relative" fullPageOnMobile>
          <h2 className="mx-5 text-2xl font-extrabold md:mx-0 md:text-7xl">
            {title}
          </h2>
          {text && <Content content={text} className="mx-5 mt-4 md:mx-0" />}
          <div className="relative mt-6">
            <Swiper
              navigation={{
                prevEl: `#${prevElementId}`,
                nextEl: `#${nextElementId}`,
                hiddenClass: '!hidden',
                disabledClass: '!hidden',
              }}
              modules={[Navigation, Pagination, A11y]}
              a11y={{
                prevSlideMessage: 'Vorheriges Video',
                nextSlideMessage: 'Nächstes Video',
                firstSlideMessage: 'Dies ist das erste Video',
                lastSlideMessage: 'Dies ist das letzte Video',
              }}
              slidesPerView={1.2}
              spaceBetween={20}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 26,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 26,
                  slidesOffsetBefore: 0,
                  slidesOffsetAfter: 0,
                },
              }}
              slidesOffsetBefore={20}
              slidesOffsetAfter={20}
            >
              {slider_videos.map((video, index) => (
                <SwiperSlide key={index} className="size-full">
                  <Dialog.Trigger
                    key={index}
                    onClick={() => setSelectedVideoIndex(index)}
                    className="block size-full"
                  >
                    <div className="aspect-h-4 aspect-w-3">
                      <div className="flex size-full items-center justify-center overflow-hidden rounded-lg p-5 text-white">
                        <Picture
                          sizes="(min-width: 768px) 50vw, 100vw"
                          {...video.thumbnail}
                          fill
                          className="-z-10 object-cover"
                        />
                        <div className="flex items-center">
                          <div className="rounded-full bg-purple-600 p-3.5 shadow-2xl shadow-purple-600">
                            <Play size={20} weight="fill" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {(video.title || video.subtitle) && (
                      <div className="mt-3 text-left">
                        {video.title && (
                          <p className="font-bold">{video.title}</p>
                        )}
                        {video.subtitle && (
                          <p className="text-sm text-gray-600">
                            {video.subtitle}
                          </p>
                        )}
                      </div>
                    )}
                  </Dialog.Trigger>
                </SwiperSlide>
              ))}
            </Swiper>
            <button
              id={prevElementId}
              type="button"
              className={cn(
                'group absolute left-0 right-auto top-1/2 z-10 hidden size-12 -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg transition-colors hover:bg-blue-50 md:flex',
                {
                  'top-[calc(50%-18px)]': hasTitle && !hasSubtitle,
                  'top-[calc(50%-16.5px)]': !hasTitle && hasSubtitle,
                  'top-[calc(50%-28.5px)]': hasTitle && hasSubtitle,
                }
              )}
            >
              <CaretLeft
                weight="bold"
                size={20}
                className="text-purple-600 group-hover:text-purple-800"
              />
            </button>
            <button
              id={nextElementId}
              type="button"
              className={cn(
                'group absolute left-auto right-0 top-1/2 z-10 hidden size-12 -translate-y-1/2 translate-x-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-lg transition-colors hover:bg-blue-50 md:flex',
                {
                  'top-[calc(50%-18px)]': hasTitle && !hasSubtitle,
                  'top-[calc(50%-16.5px)]': !hasTitle && hasSubtitle,
                  'top-[calc(50%-28.5px)]': hasTitle && hasSubtitle,
                }
              )}
            >
              <CaretRight
                weight="bold"
                size={20}
                className="text-purple-600 group-hover:text-purple-800"
              />
            </button>
          </div>
        </Container>
      </div>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cn(
            'fixed inset-0 z-50',
            'bg-black/95',
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
          )}
        />
        <Dialog.Content
          onCloseAutoFocus={(e) => e.preventDefault()}
          className="fixed inset-0 z-50 flex items-center justify-center"
        >
          <Dialog.Close className="absolute right-3 top-3 z-10 rounded-full bg-black/50 p-2.5 md:right-12 md:top-12">
            <X size={20} weight="bold" className="text-white" />
          </Dialog.Close>
          <div
            className={clsx(
              'flex size-full max-w-container flex-col items-center',
              {
                'justify-start':
                  slider_videos[selectedVideoIndex].format.value === 'portrait',
                'justify-center':
                  slider_videos[selectedVideoIndex].format.value ===
                  'landscape',
              }
            )}
          >
            <div
              className={clsx({
                'aspect-h-9 aspect-w-16 w-full':
                  slider_videos[selectedVideoIndex].format.value ===
                  'landscape',
                'h-full w-full':
                  slider_videos[selectedVideoIndex].format.value ===
                    'portrait' && slider_videos.length === 1,
                'h-[calc(100%-32px-44px-32px)] w-full':
                  slider_videos[selectedVideoIndex].format.value ===
                    'portrait' && slider_videos.length > 1,
              })}
            >
              <video
                className="size-full object-contain"
                controls
                autoPlay
                playsInline
                src={slider_videos[selectedVideoIndex].video}
              />
            </div>
            {slider_videos.length > 1 && (
              <div className="mt-8 flex space-x-6">
                {selectedVideoIndex > 0 ? (
                  <button
                    onClick={() =>
                      setSelectedVideoIndex(selectedVideoIndex - 1)
                    }
                    className="flex size-11 items-center justify-center rounded-full border border-white text-white"
                  >
                    <CaretLeft size={20} weight="bold" />
                  </button>
                ) : (
                  <div className="size-11" />
                )}
                {selectedVideoIndex < slider_videos.length - 1 ? (
                  <button
                    onClick={() =>
                      setSelectedVideoIndex(selectedVideoIndex + 1)
                    }
                    className="flex size-11 items-center justify-center rounded-full border border-white text-white"
                  >
                    <CaretRight size={20} weight="bold" />
                  </button>
                ) : (
                  <div className="size-11" />
                )}
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
