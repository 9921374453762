import { CaretRight, X } from '@phosphor-icons/react'
import { ShootingStar } from '@phosphor-icons/react/dist/ssr'
import {
  DropdownMenuItem,
  DropdownMenuPortal,
} from '@radix-ui/react-dropdown-menu'
import { useState } from 'react'
import { MyBLINK } from '@/src/@types/blinkadmin'
import {
  MergedSlot,
  useBookingContext,
} from '@/src/components/booking/BookingContext'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/src/components/core/Dropdown'
import { cn } from '@/src/components/core/lib/utils'
import { checkboxLabelStyle } from '@/src/components/core/RadioGroup'
import Picture from '@/src/components/elements/Picture'
import { formatGerman } from '@/src/lib/date'

export default function SlotItem({
  slot,
  slots,
  step,
}: {
  slot: MergedSlot
  slots: MyBLINK.BookableSlot[] | undefined
  step: 'halfWeek' | 'week'
}) {
  const {
    instructors,
    mergedSlot,
    removeSlot,
    confirmMergedSlot,
    confirmSlot,
    goToSummary,
  } = useBookingContext()

  const [open, setOpen] = useState(false)

  const slotInstructors = instructors?.filter((i) =>
    slot.userIds.includes(i.id)
  )

  const confirmUserSlot = (userId: string) => {
    confirmSlot(
      slots!.find((s) => s.start === slot.start && s.user === userId)!
    )
    goToSummary()
  }

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
        if (open) {
          // If the user clicks on another slot while the dropdown is open, we need to make sure that `removeSlot()` is called first.
          // That's why we want to run `confirmMergedSlot` after the first repaint.
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              confirmMergedSlot(
                slot,
                slots?.filter((s) => s.start === slot.start) ?? []
              )
            })
          })
        } else {
          removeSlot()
        }
      }}
      modal={step === 'week' ? false : true}
    >
      <DropdownMenuTrigger
        data-state={mergedSlot?.id === slot.id ? 'checked' : 'unchecked'}
        className={cn(
          checkboxLabelStyle({ size: 'medium' }),
          'relative w-full justify-center'
        )}
      >
        {formatGerman(new Date(slot.start), 'HH:mm')}
        {slot.isFavored.some((favored) => favored) && (
          <ShootingStar
            size={20}
            weight="fill"
            className="absolute left-1 top-1/2 -translate-y-1/2 text-purple-500 group-data-[state=checked]:text-white lg:left-2.5"
          />
        )}
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          color="light"
          side={step === 'week' ? 'right' : 'bottom'}
          align={step === 'week' ? 'start' : 'center'}
          sideOffset={5}
          collisionPadding={5}
          className="w-[300px] pb-2"
        >
          <div className="flex items-center justify-between border-b border-blue-50 py-2 pl-5 pr-3">
            <p>
              {slotInstructors!.length === 1
                ? 'Termin auswählen'
                : 'Termin auswählen bei'}
            </p>
            <button className="p-1 text-gray-600">
              <X
                weight="bold"
                size={20}
                onClick={() => {
                  removeSlot()

                  setOpen(false)
                }}
              />
            </button>
          </div>
          <div className="px-2 pt-4">
            {slotInstructors!.map((instructor, index) => (
              <DropdownMenuItem
                key={instructor.id}
                onSelect={(e) => e.preventDefault()}
                onClick={() => confirmUserSlot(instructor.id)}
                className="flex cursor-pointer items-center justify-between rounded py-3 pl-3 pr-2 data-[highlighted]:bg-blue-50"
              >
                <div className="flex items-center space-x-3">
                  <div className="relative size-11 shrink-0">
                    <Picture
                      permalink={instructor.photo}
                      alt={instructor.name}
                      fill
                      sizes="44px"
                      className="rounded-full object-cover object-top"
                    />
                  </div>
                  <div>
                    <div className="flex items-center space-x-2">
                      <p className="font-bold">{instructor.name}</p>
                      {slot.isFavored[index] && (
                        <ShootingStar
                          size={20}
                          weight="fill"
                          className="shrink-0 text-purple-500"
                        />
                      )}
                    </div>
                    <p className="text-xs text-gray-600">
                      {formatGerman(new Date(slot.start), 'dd.MM.yyyy, HH:mm')}{' '}
                      Uhr
                    </p>
                  </div>
                </div>
                <CaretRight
                  size={20}
                  weight="bold"
                  className="text-purple-600"
                />
              </DropdownMenuItem>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
