import Link from 'next/link'
import Picture from '../elements/Picture'
import { BardMagazine as BardMagazineType } from '@/src/@types/statamic'

export default function BardMagazine({
  magazine_pages,
}: BardMagazineType & { fullWidth?: boolean }) {
  return (
    <div className="-mx-7 mt-8 w-full md:mt-10 lg:mx-0">
      <div className="flex snap-x snap-mandatory space-x-4 overflow-auto px-7 pb-3 lg:grid lg:grid-flow-row lg:grid-cols-3 lg:gap-x-4 lg:gap-y-8 lg:space-x-0 lg:overflow-visible lg:px-0 lg:pb-0">
        {magazine_pages.map((entry, index) => (
          <article
            key={index}
            className="group flex w-80 max-w-[calc(100%-2rem)] shrink-0 snap-center lg:w-full lg:max-w-full"
          >
            <Link
              href={entry.uri}
              className="block flex-1 overflow-hidden rounded-lg bg-gradient-100 transition-all hover:bg-gradient-800 hover:text-white"
            >
              <div className="relative h-48 w-full transition-transform group-hover:scale-105 lg:h-40">
                <Picture
                  {...entry.image}
                  sizes="320px"
                  fill
                  className="object-cover"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-extrabold md:text-xl">
                  {entry.title}
                </h3>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </div>
  )
}
