import classNames from 'classnames'
import mapboxgl from 'mapbox-gl'
import {
  createContext,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import useVisible from '@/src/hooks/useVisible'
import { calcBoundsFromCoordinates } from '@/src/lib/mapBounds'

export const MapContext = createContext<{
  map?: MutableRefObject<mapboxgl.Map | null>
  zoom?: number
}>({})

export default function Map({
  className,
  center,
  markers,
  interactive = true,
  children,
}: {
  className?: string
  center?: { lng: number; lat: number }
  markers?: [number, number][]
  interactive?: boolean
  children?: ReactNode
}) {
  mapboxgl.accessToken = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN
  const mapContainer = useRef<HTMLDivElement>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const [lng, setLng] = useState<number>(center?.lng || 0)
  const [lat, setLat] = useState<number>(center?.lat || 0)
  const [zoom, setZoom] = useState(14)
  const visible = useVisible(mapContainer)

  useEffect(() => {
    if (!mapContainer.current) return
    if (!visible) return
    if (map.current) return
    setZoom(3)
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/blinked/cl74iunqi002414p9iwmidvd6',
      center: center ? [lng, lat] : undefined,
      zoom: zoom,
      bounds:
        markers && markers.length > 0
          ? calcBoundsFromCoordinates(markers)
          : undefined,
      fitBoundsOptions:
        markers && markers.length > 0
          ? { padding: 20, maxZoom: 14 }
          : undefined,
      interactive,
    })

    const move = () => {
      if (!map || !map.current) return
      setLng(parseFloat(map.current.getCenter().lng.toFixed(4)))
      setLat(parseFloat(map.current.getCenter().lat.toFixed(4)))
      setZoom(parseFloat(map.current.getZoom().toFixed(2)))
    }

    map.current.on('move', move)

    const resize = () => map.current?.resize()

    map.current.on('load', resize)
    map.current.scrollZoom.disable()

    return () => {
      if (map.current) {
        map.current.off('load', resize)
        map.current.off('move', move)
      }
    }
  }, [visible, center, lat, lng, mapContainer, markers, interactive, zoom])

  return (
    <MapContext.Provider value={{ map: map, zoom: zoom }}>
      <div
        ref={mapContainer}
        className={classNames(className, 'map')}
        onClick={() => {
          if (!map.current || !interactive) return
          map.current.scrollZoom.enable()
        }}
      />
      {map.current && <>{children}</>}
    </MapContext.Provider>
  )
}
