import axios from 'axios'
import { add, getDay, sub } from 'date-fns'
import { useRouter } from 'next/router'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import TagManager from 'react-gtm-module'
import { MyBLINK } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl, get, websiteUrl } from '@/src/BlinkAdminApiClient'
import { Voucher } from '@/src/components/form/VoucherInput'

export type MergedSlot = {
  id: string
  start: string
  end: string
  userIds: string[]
  isFavored: boolean[]
}

export enum BookingState {
  Start,
  LocationSelected,
  GearboxSelected,
  InstructorsSelected,
  MergedSlotSelected,
  SlotSelected,
  Summary,
}

export type BookingSelection = {
  config: {
    selectedDate: Date
    step: 'halfWeek' | 'week' | null
    dialogOpen: boolean
    currentDialogStep: 'location' | 'gearbox' | 'instructors'
    voucherValue: string
    voucher: Voucher | false | null
  }
} & (
  | Start
  | AddressSelected
  | GearboxSelected
  | InstructorsSelected
  | MergedSlotSelected
  | SlotSelected
)

type Start = {
  location: null
  address: null
  gearbox: null
  instructors: null
  mergedSlot: null
  slot: null
  config: {
    state: BookingState.Start
  }
}

type AddressSelected = {
  location: MyBLINK.BookableLocation
  address: MyBLINK.BookableAddress
  gearbox: null
  instructors: null
  mergedSlot: null
  slot: null
  config: {
    state: BookingState.LocationSelected
  }
}

type GearboxSelected = {
  location: MyBLINK.BookableLocation
  address: MyBLINK.BookableAddress
  gearbox: 'manual' | 'automatic'
  instructors: null
  mergedSlot: null
  slot: null
  config: {
    state: BookingState.GearboxSelected
  }
}

type InstructorsSelected = {
  location: MyBLINK.BookableLocation
  address: MyBLINK.BookableAddress
  gearbox: 'manual' | 'automatic'
  instructors: MyBLINK.BookableUser[]
  mergedSlot: null
  slot: null
  config: {
    state: BookingState.InstructorsSelected
  }
}

type MergedSlotSelected = {
  location: MyBLINK.BookableLocation
  address: MyBLINK.BookableAddress
  gearbox: 'manual' | 'automatic'
  instructors: MyBLINK.BookableUser[]
  mergedSlot: MergedSlot
  slot: null
  config: {
    state: BookingState.MergedSlotSelected
  }
}

type SlotSelected = {
  location: MyBLINK.BookableLocation
  address: MyBLINK.BookableAddress
  gearbox: 'manual' | 'automatic'
  instructors: MyBLINK.BookableUser[]
  mergedSlot: MergedSlot
  slot: MyBLINK.BookableSlot
  config: {
    state: BookingState.SlotSelected | BookingState.Summary
  }
}

export const BookingContext = createContext<
  | (BookingSelection & {
      confirmAddress(
        location: MyBLINK.BookableLocation,
        address: MyBLINK.BookableAddress
      ): void
      confirmGearbox(gearbox: 'manual' | 'automatic'): void
      confirmInstructors(instructor: MyBLINK.BookableUser[]): void
      confirmMergedSlot(
        mergedSlot: MergedSlot | null,
        matchingSlots: MyBLINK.BookableSlot[]
      ): void
      removeSlot(): void
      confirmSlot(slot: MyBLINK.BookableSlot | null): void
      goToSummary(): void
      goToBooking(): void
      changeSelectedDate(
        step: 'halfWeek' | 'week',
        direction: 'before' | 'next'
      ): void
      setSelectedDate(date: Date): void
      setStep(step: 'halfWeek' | 'week'): void
      setDialogStep(
        currentDialogStep: 'location' | 'gearbox' | 'instructors'
      ): void
      setDialogOpen(open: boolean): void
      setVoucherValue(voucherValue: string): void
      setVoucher(voucher: Voucher | false | null): void
    })
  | undefined
>(undefined)

export default function BookingContextProvider({
  children,
}: {
  children: ReactNode
}) {
  const router = useRouter()

  const [state, setState] = useState<BookingSelection>({
    location: null,
    address: null,
    gearbox: null,
    instructors: null,
    mergedSlot: null,
    slot: null,
    config: {
      state: BookingState.Start,
      selectedDate: new Date(),
      step: null,
      dialogOpen: false,
      currentDialogStep: 'location',
      voucherValue: '',
      voucher: null,
    },
  })
  const [voucherLoaded, setVoucherLoaded] = useState(false)
  const [urlQueryLoaded, setUrlQueryLoaded] = useState(false)

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'trial_booking_opened',
        params: {
          origin: 'Website',
          site: window.location.href,
        },
      },
    })
    const validateVoucher = async () => {
      const coupon = localStorage.getItem('trial_lesson_coupon')
      if (!coupon) {
        setVoucherLoaded(true)
        return
      }
      let voucher: Voucher | false
      try {
        voucher = (
          await axios.get<Voucher>(
            fullWebsiteUrl(`coupons/for-trial-booking/validity?code=${coupon}`)
          )
        ).data
      } catch (_) {
        voucher = false
      }
      setState((state) => {
        state = { ...state }
        state.config.voucherValue = coupon
        state.config.voucher = voucher
        return state
      })
      setVoucherLoaded(true)
    }
    validateVoucher()
  }, [])

  useEffect(() => {
    if (!voucherLoaded) return
    if (!router.isReady) return

    loadFromQuery()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, voucherLoaded])

  useEffect(() => {
    if (!urlQueryLoaded) return
    const query: { [key: string]: string | string[] } = {}
    if (state.location) {
      query.location = state.location.slug
    }
    if (state.address) {
      query.addressId = state.address.id.toString()
    }
    if (state.gearbox) {
      query.gearbox = state.gearbox
    }
    if (state.instructors) {
      query.instructorIds = state.instructors.map((instructor) => instructor.id)
    }

    router.replace(
      {
        pathname: router.asPath.split(/[?#]/)[0],
        query,
      },
      undefined,
      { shallow: true }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, urlQueryLoaded])

  const loadFromQuery = async () => {
    const queryLocation = router.query.location
    const queryAddressId = router.query.addressId
    const queryGearbox = router.query.gearbox
    const queryInstructorIds = router.query.instructorIds

    const locationSlug = parseQuery(queryLocation, 'string')
    if (!locationSlug) {
      confirmState(state)
      return
    }

    const location = (
      await axios.get<MyBLINK.BookableLocation>(
        fullWebsiteUrl(`booking/locations/${locationSlug}`)
      )
    ).data

    const addressId = parseQuery(queryAddressId, 'string') as string | null

    const addresses = await get<MyBLINK.BookableAddress[]>(
      websiteUrl(`booking/locations/${location.id}/addresses?type=pickup`)
    )

    let address: MyBLINK.BookableAddress | null = null
    if (addressId) {
      address =
        addresses.find((address) => address.id === parseInt(addressId)) ?? null
    }
    if (!address) {
      address = addresses[0]
    }
    if (!address) {
      confirmState(state)
      return
    }

    let newState = await selectAddress(location, address)

    const gearbox = parseQuery(queryGearbox, 'string') as 'manual' | 'automatic'

    if (!gearbox) {
      confirmState(newState)
      return
    }

    const locationGearboxes = await get<MyBLINK.GearboxAvailability>(
      websiteUrl(`booking/locations/${location.id}/gearboxes`)
    )

    if (
      (gearbox === 'manual' && !locationGearboxes.hasManual) ||
      (gearbox === 'automatic' && !locationGearboxes.hasAutomatic)
    ) {
      confirmState(newState)
      return
    }

    newState = await selectGearbox(gearbox, newState)

    const instructorIds = parseQuery(queryInstructorIds, 'array') as
      | string[]
      | null
    if (!instructorIds) {
      confirmState(newState)
      return
    }

    const instructors = await get<MyBLINK.BookableUser[]>(
      websiteUrl(`booking/locations/${location.id}/users?gearbox=${gearbox}`)
    )
    const selectedInstructors = instructors.filter((instructor) =>
      instructorIds.includes(instructor.id)
    )
    newState = selectInstructors(selectedInstructors, newState)
    setState(newState)
    setUrlQueryLoaded(true)
  }

  const parseQuery = (
    query: string | string[] | undefined,
    expected: 'string' | 'array'
  ) => {
    if (expected === 'string') {
      return typeof query === 'string' ? query : null
    } else if (expected === 'array') {
      if (Array.isArray(query)) {
        return query
      }
      if (typeof query === 'string') {
        return [query]
      }
    }
    return null
  }

  const confirmState = (state: BookingSelection) => {
    setUrlQueryLoaded(true)
    state.config.dialogOpen = true
    setState(state)
  }

  const confirmAddress = async (
    location: MyBLINK.BookableLocation,
    address: MyBLINK.BookableAddress
  ) => {
    const updatedState = await selectAddress(location, address)
    if (updatedState.gearbox && updatedState.instructors) {
      updatedState.config.dialogOpen = false
    }
    setState(updatedState)
  }

  const confirmGearbox = async (gearbox: 'manual' | 'automatic') => {
    const updatedState = await selectGearbox(gearbox)
    if (updatedState.instructors) {
      updatedState.config.dialogOpen = false
    }
    setState(updatedState)
  }

  const confirmInstructors = (instructors: MyBLINK.BookableUser[]) => {
    const updatedState = selectInstructors(instructors)
    updatedState.config.dialogOpen = false
    setState(updatedState)
  }

  const selectAddress = async (
    location: MyBLINK.BookableLocation,
    address: MyBLINK.BookableAddress
  ) => {
    let localState = Object.assign({}, state)

    TagManager.dataLayer({
      dataLayer: {
        event: 'trial_booking_location_step_done',
        params: {
          origin: 'Website',
          site: window.location.href,
          location: localState.location?.name,
        },
      },
    })

    localState = {
      location,
      address,
      gearbox: null,
      instructors: null,
      mergedSlot: null,
      slot: null,
      config: {
        ...state.config,
        state: BookingState.LocationSelected,
        currentDialogStep: 'gearbox',
      },
    }

    const gearboxAvailability = await get<MyBLINK.GearboxAvailability>(
      websiteUrl(`booking/locations/${location.id}/gearboxes`)
    )

    if (gearboxAvailability.hasAutomatic && !gearboxAvailability.hasManual) {
      return selectGearbox('automatic', localState)
    } else if (
      !gearboxAvailability.hasAutomatic &&
      gearboxAvailability.hasManual
    ) {
      return selectGearbox('manual', localState)
    } else if (
      state.gearbox === 'automatic' &&
      gearboxAvailability.hasAutomatic
    ) {
      return selectGearbox('automatic', localState)
    } else if (state.gearbox === 'manual' && gearboxAvailability.hasManual) {
      return selectGearbox('manual', localState)
    }
    return localState
  }

  const selectGearbox = async (
    gearbox: 'manual' | 'automatic',
    localState?: BookingSelection
  ) => {
    localState = localState ?? Object.assign({}, state)

    if (localState.config.state < BookingState.LocationSelected) {
      return localState
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'trial_booking_transmission_type_step_done',
        params: {
          origin: 'Website',
          site: window.location.href,
          location: localState.location?.name,
        },
      },
    })

    localState = {
      location: localState.location!,
      address: localState.address!,
      gearbox,
      instructors: null,
      mergedSlot: null,
      slot: null,
      config: {
        ...localState.config,
        state: BookingState.GearboxSelected,
        currentDialogStep: 'instructors',
      },
    }

    const availableInstructors = (
      await get<MyBLINK.BookableUser[]>(
        websiteUrl(
          `booking/locations/${localState.location.id}/users?gearbox=${gearbox}`
        )
      )
    ).filter((instructor) => {
      if (localState && localState.gearbox === 'automatic') {
        return instructor.gearboxAvailability.hasAutomatic
      } else {
        return instructor.gearboxAvailability.hasManual
      }
    })

    if (availableInstructors.length === 1) {
      return selectInstructors(availableInstructors, localState)
    } else if (
      state.instructors &&
      state.instructors.every((instructor) =>
        availableInstructors.some(
          (availableInstructor) => availableInstructor.id === instructor.id
        )
      )
    ) {
      return selectInstructors(state.instructors, localState)
    }
    return localState
  }

  const selectInstructors = (
    instructors: MyBLINK.BookableUser[],
    localState?: BookingSelection
  ) => {
    localState = localState ?? Object.assign({}, state)

    TagManager.dataLayer({
      dataLayer: {
        event: 'trial_booking_teacher_step_done',
        params: {
          origin: 'Website',
          site: window.location.href,
          location: localState.location?.name,
        },
      },
    })

    localState = {
      location: localState.location!,
      address: localState.address!,
      gearbox: localState.gearbox!,
      instructors,
      mergedSlot: null,
      slot: null,
      config: {
        ...localState.config,
        state: BookingState.InstructorsSelected,
      },
    }

    return localState
  }

  const removeSlot = () => {
    setState((state) => {
      if (!state.mergedSlot) {
        return state
      }

      return {
        location: state.location,
        address: state.address,
        gearbox: state.gearbox,
        instructors: state.instructors,
        mergedSlot: null,
        slot: null,
        config: {
          ...state.config,
          state: BookingState.InstructorsSelected,
        },
      }
    })
  }

  const confirmMergedSlot = (
    mergedSlot: MergedSlot,
    matchingSlots: MyBLINK.BookableSlot[]
  ) => {
    setState(selectMergedSlot(mergedSlot, matchingSlots))
  }

  const confirmSlot = (slot: MyBLINK.BookableSlot) => {
    if (slot) {
      setState(selectSlot(slot))
    } else {
      setState((state) => {
        if (!state.mergedSlot) {
          return state
        }

        return {
          location: state.location,
          address: state.address,
          gearbox: state.gearbox,
          instructors: state.instructors,
          mergedSlot: state.mergedSlot,
          slot: null,
          config: {
            ...state.config,
            state: BookingState.MergedSlotSelected,
          },
        }
      })
    }
  }

  const selectMergedSlot = (
    mergedSlot: MergedSlot,
    matchingSlots: MyBLINK.BookableSlot[],
    localState?: BookingSelection
  ) => {
    localState = localState ?? Object.assign({}, state)

    if (localState.config.state < BookingState.InstructorsSelected) {
      return localState
    }

    localState = {
      location: localState.location!,
      address: localState.address!,
      gearbox: localState.gearbox!,
      instructors: localState.instructors!,
      mergedSlot,
      slot: null,
      config: {
        ...localState.config,
        state: BookingState.MergedSlotSelected,
      },
    }

    return localState
  }

  const selectSlot = (
    slot: MyBLINK.BookableSlot,
    localState?: BookingSelection
  ) => {
    localState = localState ?? Object.assign({}, state)

    if (localState.config.state < BookingState.MergedSlotSelected) {
      return localState
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'trial_booking_date_step_done',
        params: {
          origin: 'Website',
          site: window.location.href,
          location: localState.location?.name,
        },
      },
    })

    localState = {
      location: localState.location!,
      address: localState.address!,
      gearbox: localState.gearbox!,
      instructors: localState.instructors!,
      mergedSlot: localState.mergedSlot!,
      slot,
      config: {
        ...localState.config,
        state: BookingState.SlotSelected,
      },
    }

    return localState
  }

  const goToSummary = () => {
    setState((state) => {
      state = { ...state }
      state.config.state = BookingState.Summary
      return state
    })
    window.scrollTo({ top: 0 })
  }

  const goToBooking = () => {
    removeSlot()
  }

  const changeSelectedDate = (
    step: 'halfWeek' | 'week',
    direction: 'before' | 'next'
  ) => {
    let newSelectedDate = state.config.selectedDate
    const weekday =
      getDay(state.config.selectedDate) === 0
        ? 7
        : getDay(state.config.selectedDate)
    if (direction === 'before') {
      if (step === 'halfWeek') {
        newSelectedDate = sub(state.config.selectedDate, {
          days: weekday === 7 ? 4 : 3,
        })
      } else {
        newSelectedDate = sub(state.config.selectedDate, {
          weeks: 1,
        })
      }
    } else {
      if (step === 'halfWeek') {
        newSelectedDate = add(state.config.selectedDate, {
          days: weekday === 4 ? 4 : 3,
        })
      } else {
        newSelectedDate = add(state.config.selectedDate, {
          weeks: 1,
        })
      }
    }
    setState((state) => {
      state = { ...state }
      state.config.selectedDate = newSelectedDate
      return state
    })
  }

  const setSelectedDate = (date: Date) => {
    setState((state) => {
      state = { ...state }
      state.config.selectedDate = date
      return state
    })
  }

  const setStep = (step: 'halfWeek' | 'week') => {
    setState((state) => {
      state = { ...state }
      state.config.step = step
      return state
    })
  }

  const setDialogStep = (
    currentDialogStep: 'location' | 'gearbox' | 'instructors'
  ) => {
    setState((state) => {
      state = { ...state }
      state.config.currentDialogStep = currentDialogStep
      return state
    })
  }

  const setDialogOpen = (open: boolean) => {
    setState((state) => {
      state = { ...state }
      state.config.dialogOpen = open
      return state
    })
  }

  const setVoucherValue = (voucherValue: string) => {
    setState((state) => {
      state = { ...state }
      state.config.voucherValue = voucherValue
      return state
    })
  }

  const setVoucher = (voucher: Voucher | false | null) => {
    setState((state) => {
      state = { ...state }
      state.config.voucher = voucher
      return state
    })
  }

  return (
    <BookingContext.Provider
      value={{
        ...state,
        confirmAddress,
        confirmGearbox,
        confirmInstructors,
        removeSlot,
        confirmMergedSlot,
        confirmSlot,
        goToSummary,
        goToBooking,
        changeSelectedDate,
        setSelectedDate,
        setStep,
        setDialogStep,
        setDialogOpen,
        setVoucherValue,
        setVoucher,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}

export const useBookingContext = () => {
  const context = useContext(BookingContext)

  if (context === undefined) {
    throw new Error(
      'useBookingContext must be used within a BookingContextProvider'
    )
  }

  return context
}
