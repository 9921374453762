import {
  arrow,
  shift,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  autoUpdate,
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import { Fragment, ReactNode, useRef, useState } from 'react'
import Caret from '../icons/Caret'

export function HoverTooltip({
  text,
  children,
  className = '',
}: {
  text: string
  children: ReactNode
  className?: string
}) {
  const [show, setShow] = useState(false)
  const caretRef = useRef(null)

  const {
    x,
    y,
    refs,
    middlewareData: { arrow: { x: arrowX } = {} },
    context,
  } = useFloating({
    open: show,
    onOpenChange: setShow,
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    middleware: [
      shift(),
      arrow({
        element: caretRef,
      }),
    ],
    strategy: 'fixed',
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
  ])

  return (
    <>
      <span
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {children}
      </span>
      <FloatingPortal>
        <Transition
          show={show}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div
            className="fixed z-20 mt-6 max-w-[500px] px-4 sm:px-0"
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className="flex flex-col rounded-lg bg-blue-900 p-5 text-sm text-white shadow-lg">
              <div
                ref={caretRef}
                className="absolute -top-3 h-4 w-8"
                style={{ left: arrowX }}
              >
                <Caret className="h-3.5 w-7 text-blue-900" />
              </div>
              {text}
            </div>
          </div>
        </Transition>
      </FloatingPortal>
    </>
  )
}

export function ClickTooltip({
  text,
  children,
  className = '',
}: {
  text: string
  children: ReactNode
  className?: string
}) {
  const [show, setShow] = useState(false)
  const caretRef = useRef(null)

  const {
    x,
    y,
    refs,
    middlewareData: { arrow: { x: arrowX } = {} },
    context,
  } = useFloating({
    open: show,
    onOpenChange: setShow,
    whileElementsMounted: autoUpdate,
    placement: 'bottom',
    middleware: [
      arrow({
        element: caretRef,
      }),
    ],
    strategy: 'fixed',
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
  ])

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {children}
      </div>
      <FloatingPortal>
        <Transition
          show={show}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <div
            className="fixed z-20 mt-6 max-w-[200px] px-4 sm:px-0"
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            <div className="flex flex-col rounded-lg bg-blue-900 p-5 text-sm text-white shadow-lg">
              <div
                ref={caretRef}
                className="absolute -top-3 h-4 w-8"
                style={{ left: arrowX }}
              >
                <Caret className="h-3.5 w-7 text-blue-900" />
              </div>
              {text}
            </div>
          </div>
        </Transition>
      </FloatingPortal>
    </>
  )
}
