import clsx from 'clsx'
import { createElement } from 'react'
import { BardTocHeading as BardTocHeadingType } from '@/src/@types/statamic'

export default function BardTocHeading({ title, dept }: BardTocHeadingType) {
  return createElement(
    dept.value,
    {
      id: encodeURIComponent(title).toLowerCase(),
      className: clsx('scroll-mt-20 font-extrabold', {
        '!-mb-[12px] !mt-[90px] text-4xl md:text-20xl': dept.value === 'h2',
        '!-mb-[22px] !mt-[64px] text-2xl md:text-10xl': dept.value === 'h3',
        '!-mb-[36px] !mt-[46px] text-lg md:text-4xl': dept.value === 'h4',
      }),
    },
    title
  )
}
