import classNames from 'classnames'
import { BoxBlock } from '@/src/@types/statamic'
import Bard from '@/src/components/bard/Bard'
import Picture from '@/src/components/elements/Picture'
import Container from '@/src/components/layout/Container'

export default function Component({ boxes }: BoxBlock) {
  return (
    <Container>
      <div
        className={classNames([
          'grid gap-7',
          {
            'md:grid-cols-2': boxes.length === 2,
          },
        ])}
      >
        {boxes.map((box, index) => (
          <div key={index}>
            <div
              className={classNames([
                'align-start rounded-[15px] bg-gradient-100',
                {
                  'p-[30px]': boxes.length === 2,
                  'p-[50px]': boxes.length === 1,
                },
              ])}
            >
              {box.image && (
                <div className="aspect-h-9 aspect-w-16 relative mb-7">
                  <Picture
                    {...box.image}
                    sizes="25vw"
                    fill
                    className="rounded-[10px] object-cover"
                  />
                </div>
              )}
              <p
                className={classNames([
                  'font-extrabold',
                  {
                    'text-2xl': boxes.length === 2,
                    'text-4xl': boxes.length === 1,
                  },
                ])}
              >
                {box.title}
              </p>
              <Bard className="mt-3" content={box.text} fullWidth />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
