import { Download } from '@phosphor-icons/react'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import Container from '@/src/components/layout/Container'

export default function Component() {
  return (
    <Container>
      <div className="grid gap-y-10">
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Logo
            name="BLINK"
            svgLink="/logos/blink.svg"
            pngLink="/logos/blink.png"
          />
          <Logo
            name="BLINK White"
            svgLink="/logos/blink_white.svg"
            pngLink="/logos/blink_white.png"
            imageBackground="bg-blue-900"
          />
          <Logo
            name="BLINK Bubble"
            svgLink="/logos/blink_bubble.svg"
            pngLink="/logos/blink_bubble.png"
          />
        </div>

        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Logo
            name="BLINK Icon"
            svgLink="/logos/blink_icon.svg"
            pngLink="/logos/blink_icon.png"
          />
          <Logo
            name="BLINK Icon Gradient"
            svgLink="/logos/blink_icon_gradient.svg"
            pngLink="/logos/blink_icon_gradient.png"
            className="md:col-start-3"
          />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Logo
            name="BLINK Fahrschule"
            svgLink="/logos/blink_fahrschule.svg"
            pngLink="/logos/blink_fahrschule.png"
          />
          <Logo
            name="BLINK Fahrschule White"
            svgLink="/logos/blink_fahrschule_white.svg"
            pngLink="/logos/blink_fahrschule_white.png"
            imageBackground="bg-blue-900"
          />
          <Logo
            name="BLINK Fahrschule Bubble"
            svgLink="/logos/blink_fahrschule_bubble.svg"
            pngLink="/logos/blink_fahrschule_bubble.png"
          />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Logo
            name="BLINK Partner"
            svgLink="/logos/blink_partner.svg"
            pngLink="/logos/blink_partner.png"
          />
          <Logo
            name="BLINK Partner White"
            svgLink="/logos/blink_partner_white.svg"
            pngLink="/logos/blink_partner_white.png"
            imageBackground="bg-blue-900"
          />
          <Logo
            name="BLINK Partner Bubble"
            svgLink="/logos/blink_partner_bubble.svg"
            pngLink="/logos/blink_partner_bubble.png"
          />
        </div>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <Logo
            name="Fahrschule by BLINK"
            svgLink="/logos/fahrschule_by_blink.svg"
            pngLink="/logos/fahrschule_by_blink.png"
          />
          <Logo
            name="Fahrschule by BLINK White"
            svgLink="/logos/fahrschule_by_blink_white.svg"
            pngLink="/logos/fahrschule_by_blink_white.png"
            imageBackground="bg-blue-900"
          />
          <Logo
            name="Fahrschule by BLINK Bubble"
            svgLink="/logos/fahrschule_by_blink_bubble.svg"
            pngLink="/logos/fahrschule_by_blink_bubble.png"
          />
        </div>
      </div>
    </Container>
  )
}

const Logo = ({
  name,
  svgLink,
  pngLink,
  imageBackground,
  className = '',
}: {
  name: string
  svgLink: string
  pngLink: string
  imageBackground?: string
  className?: string
}) => {
  return (
    <div
      className={classNames([
        'flex flex-col justify-center overflow-hidden rounded-lg border-2 border-blue-50',
        className,
      ])}
    >
      <div className={imageBackground}>
        <div className="relative mx-8 my-4 h-[150px]">
          <Image
            src={svgLink}
            alt={name}
            fill
            className="object-contain"
            unoptimized
          />
        </div>
      </div>

      <div className="border-t-2 border-blue-50 p-4">
        <p className="text-lg font-bold">{name}</p>
        <div className="mt-2 flex space-x-6">
          <Link
            href={svgLink}
            target="_blank"
            download
            className="flex items-center font-bold text-purple-600 hover:text-purple-800"
          >
            <Download weight="fill" size={24} className="mr-2" /> SVG
          </Link>
          <Link
            href={pngLink}
            target="_blank"
            download
            className="flex items-center font-bold text-purple-600 hover:text-purple-800"
          >
            <Download weight="fill" size={24} className="mr-2" />
            PNG
          </Link>
        </div>
      </div>
    </div>
  )
}
