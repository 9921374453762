import * as CoursePages from '../blueprints/CoursePages'
import * as Magazine from '../blueprints/Magazine'
import * as Pages from '../blueprints/Pages'
import * as Partners from '../blueprints/Partners'
import { pascalCase } from './file'
import { Blueprint } from '@/src/@types/infrastructur'

const blueprints: Record<string, Blueprint<any>> = {
  Pages: Pages,
  Magazine: Magazine,
  Partners: Partners,
  FirstAidCourses: CoursePages,
  CarDrivingSchools: CoursePages,
  BikeDrivingSchools: CoursePages,
  Vkus: CoursePages,
  Wabs: CoursePages,
}

export default function find<Name extends string>(blueprintName: Name) {
  const blueprint = blueprints[pascalCase(blueprintName)]

  if (!blueprint) {
    return undefined
  }

  return { ...blueprint, name: blueprintName }
}
