import classNames from 'classnames'
import CustomLink from './CustomLink'
import { NavigationLink } from '@/src/@types/statamic'

export default function SubNavigation({
  links,
  sidebar,
}: {
  links: NavigationLink
  sidebar?: NavigationLink
}) {
  const hasSidebar = sidebar && sidebar.children.length > 0
  const columns = determineColumns(links, sidebar) + (hasSidebar ? 1 : 0)

  const dropdownWidth = {
    1: 'w-[270px]',
    2: 'w-[540px]',
    3: 'w-[810px]',
    4: 'w-[1100px]',
    5: 'w-[1340px]',
  }[columns]

  return (
    <div className={classNames('flex', dropdownWidth)}>
      <div className="flex-1 pl-10">
        <div>
          <h3 className="text-2xs font-bold text-blue-900/50">
            {links.page.title}
          </h3>
          <div className="mt-8 columns-[240px] gap-0 space-y-8 whitespace-nowrap">
            <div className="font-medium">
              <ul>
                {links.children.map((link, index) => (
                  <SubNavigationLink key={index} link={link} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {hasSidebar && (
        <div className="-my-12 flex w-[300px] flex-col overflow-hidden px-8 py-12 text-white">
          <div>
            <h3 className="text-2xs font-bold text-white/70">
              {sidebar.page.title}
            </h3>
            <ul className="mt-8 font-bold leading-8">
              {sidebar.children.map((link, index) =>
                link.page.url ? (
                  <li key={index}>
                    <CustomLink href={link.page.url}>
                      <a className="hover:text-blue-100">{link.page.title}</a>
                    </CustomLink>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

function SubNavigationLink({ link }: { link: NavigationLink }) {
  if ((link.children && link.children.length !== 0) || !link.page.url) {
    return (
      <li className="mt-[22px] break-inside-avoid leading-7 first:mt-0">
        {(link.page.url && (
          <CustomLink href={link.page.url}>
            <a className="text-xs font-bold text-blue-900 hover:text-purple-800">
              {link.page.title}
            </a>
          </CustomLink>
        )) || (
          <h4 className="text-xs font-bold text-blue-900">{link.page.title}</h4>
        )}
        <ul className="mt-[5px] text-xs font-medium">
          {link.children.map((link, index) => {
            return <SubNavigationLink key={index} link={link} />
          })}
        </ul>
      </li>
    )
  }

  return (
    <li className="text-xs leading-7 first:mt-0">
      <CustomLink href={link.page.url}>
        <a className="hover:text-purple-800">{link.page.title}</a>
      </CustomLink>
    </li>
  )
}

function countLinks(link: NavigationLink): number {
  let count = 0

  link.children.forEach((child) => {
    count += child.children.length + 1
  })

  return count
}

export function determineColumns(
  links: NavigationLink,
  sidebar?: NavigationLink
): number {
  const numberOfSidebarLinks =
    sidebar && sidebar.children.length ? countLinks(sidebar) + 1 : 5

  const columns = Math.floor(countLinks(links) / numberOfSidebarLinks)

  if (columns === 0) {
    return 1
  }

  if (columns > 4) {
    return 4
  }

  return columns
}
