import { MapPin } from '@phosphor-icons/react'
import classNames from 'classnames'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { convertAbsoluteCoordinates } from '../../lib/calculations'
import Caret from '../icons/Caret'
import { LocationType } from '@/src/@types/models'

export default function LocationMarker({
  location,
  boundaries,
  map,
  highlight = true,
}: {
  location: Omit<LocationType, 'position'> & {
    position: { lng: number; lat: number }
  }
  boundaries: {
    west: number
    east: number
    north: number
    south: number
  }
  map: {
    width: number
    height: number
  }
  highlight?: boolean
}) {
  const [x, setX] = useState(
    convertAbsoluteCoordinates(
      location.position.lng,
      boundaries.west,
      boundaries.east,
      0,
      map.width
    )
  )

  const [y, setY] = useState(
    convertAbsoluteCoordinates(
      location.position.lat,
      boundaries.north,
      boundaries.south,
      0,
      map.height
    )
  )

  useEffect(() => {
    setY(
      convertAbsoluteCoordinates(
        location.position.lat,
        boundaries.north,
        boundaries.south,
        0,
        map.height
      )
    )

    setX(
      convertAbsoluteCoordinates(
        location.position.lng,
        boundaries.west,
        boundaries.east,
        0,
        map.width
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return (
    <div
      className="absolute"
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      <Link
        href={location.url}
        className={classNames([
          'peer z-10 flex h-9 w-7 origin-bottom -translate-x-1/2 -translate-y-full scale-75 items-center hover:cursor-pointer md:scale-100',
          {
            'text-blue-900': highlight,
            'text-purple-700': !highlight,
          },
        ])}
      >
        <MapPin className="size-7" weight="fill" />
        <span className="sr-only">{location.name}</span>
      </Link>

      <div
        className="absolute -z-10 block whitespace-nowrap rounded-lg bg-white px-5 py-2 text-center text-sm text-blue-900 opacity-0 drop-shadow-lg transition-all peer-hover:z-10 peer-hover:opacity-100"
        style={{
          WebkitTransform: 'translate3d(-50%, -115px, 0)',
        }}
      >
        {location.name}
        <Caret className="absolute inset-x-0 bottom-[-6px] mx-auto w-3 rotate-180 text-white" />
      </div>
    </div>
  )
}
