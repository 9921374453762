import Icon from './Icon'

export default function CourseMarker({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.69185 5.60807C8.69134 2.66214 12.7581 1.00494 17 1C21.2419 1.00494 25.3087 2.66214 28.3082 5.60807C31.3076 8.554 32.995 12.5481 33 16.7143C33 30.1602 18.4545 40.3164 17.8352 40.7419C17.5903 40.9099 17.2987 41 17 41C16.7013 41 16.4097 40.9099 16.1648 40.7419C15.5455 40.3164 1 30.1602 1 16.7143C1.00503 12.5481 2.69236 8.554 5.69185 5.60807Z"
            fill="#FBBF24"
            stroke="white"
          />
          <path
            d="M20.75 23.1908C21.1811 23.0209 21.599 22.819 22 22.5869V25.7501C22 25.9159 21.9341 26.0749 21.8169 26.1921C21.6997 26.3093 21.5407 26.3751 21.375 26.3751C21.2092 26.3751 21.0503 26.3093 20.933 26.1921C20.8158 26.0749 20.75 25.9159 20.75 25.7501V23.1908ZM21.6687 16.2822L17.2937 13.9486C17.1478 13.874 16.9784 13.8598 16.822 13.9089C16.6656 13.9581 16.5348 14.0667 16.4578 14.2114C16.3808 14.356 16.3636 14.5252 16.4101 14.6824C16.4566 14.8396 16.5629 14.9722 16.7062 15.0517L20.3594 17.0001L21.6875 16.2923L21.6687 16.2822ZM26.6687 13.9486L17.2937 8.94858C17.2033 8.90043 17.1024 8.87524 17 8.87524C16.8975 8.87524 16.7967 8.90043 16.7062 8.94858L7.33124 13.9486C7.23124 14.0019 7.14761 14.0813 7.0893 14.1785C7.03099 14.2757 7.00018 14.3868 7.00018 14.5001C7.00018 14.6135 7.03099 14.7246 7.0893 14.8218C7.14761 14.9189 7.23124 14.9984 7.33124 15.0517L9.49999 16.2087V19.9915C9.49934 20.2985 9.61232 20.5949 9.81718 20.8236C10.8406 21.9634 13.1336 23.8751 17 23.8751C18.282 23.8857 19.5544 23.6535 20.75 23.1908V17.2087L20.3594 17.0001L17 18.7915L10.4242 15.2814L8.95311 14.5001L17 10.2087L25.0469 14.5001L23.5797 15.2814H23.575L21.6875 16.2923C21.7825 16.3472 21.8614 16.4261 21.9163 16.5212C21.9711 16.6162 22 16.724 22 16.8337V22.5869C22.815 22.1164 23.5515 21.5214 24.1828 20.8236C24.3877 20.5949 24.5006 20.2985 24.5 19.9915V16.2087L26.6687 15.0517C26.7687 14.9984 26.8524 14.9189 26.9107 14.8218C26.969 14.7246 26.9998 14.6135 26.9998 14.5001C26.9998 14.3868 26.969 14.2757 26.9107 14.1785C26.8524 14.0813 26.7687 14.0019 26.6687 13.9486Z"
            fill="#78350F"
          />
        </svg>
      }
    />
  )
}
