import Icon from './Icon'

export default function PhoneIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.8415 10.4765L11.1823 8.90823C10.9913 8.82599 10.7827 8.79287 10.5756 8.8119C10.3684 8.83093 10.1694 8.9015 9.99649 9.01718L8.04001 10.3214C6.84426 9.73977 5.87647 8.77625 5.28961 7.58308L5.28976 7.58293L6.58935 5.59715C6.70248 5.42476 6.77111 5.22701 6.78911 5.0216C6.80711 4.8162 6.77392 4.60953 6.6925 4.42009L5.12283 0.757751C5.01599 0.509152 4.83149 0.301851 4.59696 0.166886C4.36243 0.0319222 4.0905 -0.0234434 3.82187 0.00907947C2.7663 0.144799 1.79623 0.660164 1.0929 1.4589C0.389572 2.25763 0.00107885 3.2851 0 4.34937C0 10.5527 5.04669 15.5994 11.25 15.5994C12.3143 15.5983 13.3418 15.2098 14.1405 14.5064C14.9392 13.8031 15.4546 12.833 15.5903 11.7774C15.6227 11.5088 15.5673 11.2369 15.4323 11.0024C15.2974 10.7679 15.0901 10.5834 14.8415 10.4765V10.4765Z"
            fill="currentColor"
          />
        </svg>
      }
    />
  )
}
