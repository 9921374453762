import {
  ArrowsCounterClockwise,
  Camera,
  DotsThreeOutlineVertical,
  Trash,
} from '@phosphor-icons/react'
import { DropdownMenuPortal } from '@radix-ui/react-dropdown-menu'
import Image from 'next/image'
import { ReactNode, useId } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/src/components/core/Dropdown'
import { FileUploadChildrenProps } from '@/src/components/core/fileUpload/FileUpload'

const ImageUploadMask = ({
  file,
  uploadFile,
  removeFile,
  emptyText,
  imageAlt,
}: FileUploadChildrenProps & {
  emptyText: () => ReactNode
  imageAlt: string
}) => {
  const inputId = useId()

  if (file.state === 'empty') {
    return (
      <>
        <input
          id={inputId}
          type="file"
          accept="image/*"
          className="sr-only"
          onChange={(e) => {
            if (!e.target || !e.target.files || e.target.files.length < 1) {
              return
            }
            uploadFile(e.target.files[0])
          }}
        />
        <label
          htmlFor={inputId}
          className="flex cursor-pointer flex-col items-center rounded border border-dashed border-blue-125 bg-blue-50 p-6"
        >
          <Camera size={24} weight="fill" className="text-purple-700" />
          <div>{emptyText()}</div>
        </label>
      </>
    )
  }

  if (file.state === 'fetchingInfo') {
    return (
      <div className="flex items-center justify-between overflow-hidden rounded border border-blue-125 bg-blue-50 pr-4">
        <div className="flex items-center space-x-6">
          <div className="relative size-24 shrink-0 bg-blue-100" />
          <div className="h-[21px] w-[180px] rounded bg-blue-100 text-sm text-gray-800" />
        </div>
        <div className="p-0.5">
          <DotsThreeOutlineVertical size={20} weight="fill" />
        </div>
      </div>
    )
  }

  if (file.state === 'uploading') {
    return (
      <div className="flex items-center overflow-hidden rounded border border-blue-125 bg-blue-50 pr-16">
        <div className="relative size-24 shrink-0">
          <Image
            src={file.previewUrl}
            alt={imageAlt}
            fill
            unoptimized
            className="size-full max-h-full max-w-full object-cover object-center"
          />
        </div>
        <div className="ml-6 h-1 w-full rounded-full bg-blue-125">
          <div className="h-1 w-[90%] origin-left animate-loading rounded-full bg-purple-600" />
        </div>
      </div>
    )
  }

  if (file.state === 'selected') {
    return (
      <div className="flex items-center justify-between overflow-hidden rounded border border-blue-125 bg-blue-50 pr-4">
        <div className="flex items-center space-x-6">
          <div className="relative size-24 shrink-0 bg-blue-100">
            <Image
              src={file.fileInfo.url}
              alt={imageAlt}
              fill
              unoptimized
              className="size-full max-h-full max-w-full object-cover object-center"
            />
          </div>
          <p className="text-sm text-gray-800">{file.fileInfo.fileName}</p>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger className="p-0.5">
            <DotsThreeOutlineVertical size={20} weight="fill" />
          </DropdownMenuTrigger>
          <DropdownMenuPortal>
            <DropdownMenuContent
              color="light"
              align="end"
              sideOffset={5}
              collisionPadding={5}
            >
              <DropdownMenuGroup>
                <DropdownMenuItem
                  color="light"
                  onClick={async () => {
                    await removeFile()
                  }}
                >
                  <Trash weight="fill" size={20} className="text-gray-500" />
                  <p>Löschen</p>
                </DropdownMenuItem>
                <DropdownMenuItem
                  color="light"
                  asChild
                  onSelect={(e) => e.preventDefault()}
                  className="cursor-pointer"
                >
                  <label>
                    <ArrowsCounterClockwise
                      weight="fill"
                      size={20}
                      className="text-gray-500"
                    />
                    <p>Ersetzen</p>
                    <input
                      type="file"
                      className="sr-only"
                      onChange={(e) => {
                        if (
                          !e.target ||
                          !e.target.files ||
                          e.target.files.length < 1
                        ) {
                          return
                        }
                        uploadFile(e.target.files[0])
                        close()
                      }}
                    />
                  </label>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      </div>
    )
  }

  return null
}

export default ImageUploadMask
