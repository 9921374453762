import { X } from '@phosphor-icons/react'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Controller } from 'react-hook-form'
import Button from '@/src/components/core/Button'
import { Checkbox } from '@/src/components/core/Checkbox'
import { DateInput } from '@/src/components/core/DateInput'
import { Input } from '@/src/components/core/Input'
import FileInput from '@/src/components/form/FileInput'
import LoadingSpinner from '@/src/components/icons/LoadingSpinner'
import useCollapsable from '@/src/hooks/useCollapsable'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

type RequestForm = {
  days: string[]
  day_times: string[]
  deadline: string
  first_name: string
  last_name: string
  street: string
  zip: string
  place: string
  email: string
  phone: string
  driving_license: FileList | null
  location_id: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/v1/bike-leads`

export default function TimePressure({
  courseState,
  location_id,
}: {
  courseState: 'available' | 'fullyBooked' | 'none'
  location_id: string
}) {
  const { utmData } = useUtmCache()

  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const { open, toggle, id: contentId } = useCollapsable(courseState === 'none')
  const days = [
    { value: 'mon', label: 'Montag', labelShort: 'Mo.' },
    { value: 'tue', label: 'Dienstag', labelShort: 'Di.' },
    { value: 'wed', label: 'Mittwoch', labelShort: 'Mi.' },
    { value: 'thu', label: 'Donnerstag', labelShort: 'Do.' },
    { value: 'fri', label: 'Freitag', labelShort: 'Fr.' },
    { value: 'sat', label: 'Samstag', labelShort: 'Sa.' },
  ]
  const dayTimes = [
    { value: 'morning', label: 'Vormittag' },
    { value: 'afternoon', label: 'Nachmittag' },
  ]
  const formRef = useRef<HTMLFormElement>(null)
  const { handleSubmit, register, control } = useLaravelForm<RequestForm>({
    defaultValues: {
      days: [],
      day_times: [],
      deadline: '',
      first_name: '',
      last_name: '',
      street: '',
      zip: '',
      place: '',
      email: '',
      phone: '',
      driving_license: null,
      location_id,
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const preparedData = (data: RequestForm) => {
    setSending(true)
    const preparedData: any = {
      ...data,
    }
    if (data.driving_license) {
      preparedData.driving_license = data.driving_license[0]
    }
    return preparedData
  }

  return (
    <div className="@container">
      <div
        className={classNames('mt-5 text-white bg-gradient-600', {
          'rounded-lg': !open,
          'rounded-t-lg': open,
        })}
      >
        {!success && (
          <div className="p-5 @xl:flex @xl:items-center @xl:justify-between @xl:space-x-8 @xl:p-6">
            <div>
              <div className="flex min-h-[36px] justify-between">
                {courseState === 'available' && (
                  <h3 className="text-xl font-extrabold">
                    Kein passender Termin dabei? 👀
                  </h3>
                )}
                {courseState === 'fullyBooked' && (
                  <h3 className="text-xl font-extrabold">
                    Alle Kurse ausgebucht? Kein Problem!
                  </h3>
                )}
                {courseState === 'none' && (
                  <h3 className="text-xl font-extrabold">
                    Wir organisieren Kurse extra für dich! 🔥
                  </h3>
                )}
                {open && (
                  <button type="button" className="p-1" {...toggle}>
                    <span className="sr-only">Formular schliessen</span>
                    <X size={24} />
                  </button>
                )}
              </div>
              {courseState === 'available' && (
                <p className="mt-2 @xl:mt-0">
                  Falls du unter Zeitdruck stehst, melde dich hier! Wir planen
                  laufend neue Kurse und richten uns nach euren
                  Terminvorschlägen.
                </p>
              )}
              {courseState === 'fullyBooked' && (
                <p className="mt-2 @xl:mt-0">
                  Sag uns, wann du Zeit hast und wir zaubern neue Termine! ✨
                </p>
              )}
              {courseState === 'none' && (
                <p className="mt-2 @xl:mt-0">
                  Falls du unter Zeitdruck stehst, melde dich hier! Wir planen
                  laufend neue Kurse und richten uns nach euren
                  Terminvorschlägen.
                </p>
              )}
            </div>
            {!open && (
              <Button
                type="button"
                version="white-outlined"
                className="mt-6 @xl:mt-0"
                {...toggle}
              >
                <span>Weitere Kurse anfragen</span>
              </Button>
            )}
          </div>
        )}
        {success && (
          <div className="p-5 @xl:p-6">
            <h3 className="text-xl font-extrabold">Danke für deine Anfrage!</h3>
            <p className="mt-2">
              Wir prüfen nun, ob wir mit deinen Verfügbarkeiten weitere Kurse
              zusammenstellen können. Sobald wir Neuigkeiten haben, melden wir
              uns wieder bei dir!
            </p>
          </div>
        )}
      </div>
      {open && (
        <div
          className="rounded-b-xl bg-blue-50 p-5 pb-8 @xl:px-6 @xl:pt-6"
          id={contentId}
        >
          {courseState === 'available' && (
            <p className="mb-8">
              Wir schalten regelmässig neue Kurse auf, die genau in deine Agenda
              passen! Hinterlasse uns dafür bitte deine Verfügbarkeiten. Sobald
              wir einen neuen Kurs zusammenstellen können, melden wir uns bei
              dir!
            </p>
          )}
          {courseState === 'fullyBooked' && (
            <p className="mb-8">
              Wir schalten regelmässig neue Kurse auf, die genau in deine Agenda
              passen! Hinterlasse uns dafür bitte deine Verfügbarkeiten. Sobald
              wir einen neuen Kurs zusammenstellen können, melden wir uns bei
              dir!
            </p>
          )}
          {courseState === 'none' && (
            <p className="mb-8">
              Wir organisieren Kurse, die genau in deine Agenda passen!
              Hinterlasse uns dafür bitte deine Verfügbarkeiten. Sobald wir
              einen neuen Kurs zusammenstellen können, melden wir uns bei dir!
            </p>
          )}
          <form
            ref={formRef}
            onSubmit={handleSubmit(
              () => {
                formRef.current?.reset()
                setSending(false)
                toggle.onClick()
                setSuccess(true)
              },
              preparedData,
              () => setSending(false)
            )}
            method="POST"
            action={requestUrl}
          >
            <p className="text-lg font-extrabold">
              Wann hättest du Zeit für einen Kurs?
            </p>
            <fieldset className="mt-4">
              <legend className="text-sm">Mögliche Kurstage</legend>
              <Controller
                control={control}
                name="days"
                render={({ field: { value, onChange } }) => (
                  <div className="mt-2 flex flex-wrap">
                    {days.map((day) => (
                      <Checkbox
                        key={day.value}
                        checked={value.includes(day.value)}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            return onChange([...value, day.value])
                          }
                          const newValue = [...value]
                          newValue.splice(value.indexOf(day.value), 1)
                          onChange(newValue)
                        }}
                        color="white"
                        className="mb-2 mr-2"
                      >
                        <span className="hidden @xl:block">{day.label}</span>
                        <span className="@xl:hidden">{day.labelShort}</span>
                      </Checkbox>
                    ))}
                  </div>
                )}
              />
            </fieldset>
            <fieldset className="mt-6">
              <legend className="text-sm">Mögliche Tageszeit</legend>
              <div className="mt-2">
                <Controller
                  control={control}
                  name="day_times"
                  render={({ field: { value, onChange } }) => (
                    <div className="mt-2 flex flex-wrap">
                      {dayTimes.map((dayTime) => (
                        <Checkbox
                          key={dayTime.value}
                          checked={value.includes(dayTime.value)}
                          onCheckedChange={(checked) => {
                            if (checked) {
                              return onChange([...value, dayTime.value])
                            }
                            const newValue = [...value]
                            newValue.splice(value.indexOf(dayTime.value), 1)
                            onChange(newValue)
                          }}
                          color="white"
                          className="mb-2 mr-2"
                        >
                          <span>{dayTime.label}</span>
                        </Checkbox>
                      ))}
                    </div>
                  )}
                />
              </div>
            </fieldset>
            <div className="mt-4 grid gap-3 @xl:grid-cols-2">
              <div>
                <p className="text-sm">
                  Wann musst du den Kurs spätestens absolviert haben?
                </p>
                <div className="mt-2">
                  <DateInput
                    label="Datum (TT.MM.JJJJ)"
                    {...register('deadline')}
                  />
                </div>
              </div>
            </div>
            <p className="mt-8 text-lg font-extrabold">Deine Daten</p>
            <div className="mt-4 grid gap-3 @xl:grid-cols-2">
              <Input
                label="Vorname"
                kind="firstName"
                {...register('first_name')}
              />
              <Input label="Name" kind="lastName" {...register('last_name')} />
              <Input
                label="Strasse + Nr."
                kind="street"
                {...register('street')}
              />
              <div className="grid grid-cols-4 gap-2">
                <Input label="PLZ" kind="zip" {...register('zip')} />
                <div className="col-span-3">
                  <Input label="Ort" kind="place" {...register('place')} />
                </div>
              </div>
              <Input label="E-Mail" kind="email" {...register('email')} />
              <Input label="Handynummer" kind="tel" {...register('phone')} />
            </div>
            <p className="mt-6 font-bold">Upload Lernfahrausweis (optional)</p>
            <p className="mt-2">
              Bitte fotografiere die Innenseite deines Lernfahrausweises und
              lade das Foto gleich hier hoch.
            </p>
            <div className="mt-4">
              <FileInput {...register('driving_license')} />
            </div>
            <div className="mt-12">
              <Button
                type="submit"
                status={sending ? 'active' : 'default'}
                disabled={sending}
                className="inline-flex items-center"
              >
                {sending && <LoadingSpinner className="mr-1 size-4" />}
                Unverbindlich anfragen
              </Button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}
