import Icon from '@/src/components/icons/Icon'

export default function ThumbsDownIcon({
  className = '',
}: {
  className?: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 53 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M52.7077 27.8024L49.8952 5.30225C49.7233 3.94261 49.0616 2.69225 48.0342 1.78529C47.0067 0.878326 45.684 0.376939 44.3135 0.375H4.49902C3.50481 0.376137 2.55164 0.77159 1.84863 1.47461C1.14561 2.17762 0.75016 3.13079 0.749023 4.125V24.75C0.75016 25.7442 1.14561 26.6974 1.84863 27.4004C2.55164 28.1034 3.50481 28.4989 4.49902 28.5H14.5902L23.447 46.2135C23.6027 46.525 23.8421 46.7869 24.1383 46.97C24.4345 47.1531 24.7758 47.25 25.124 47.25C27.6096 47.2472 29.9925 46.2585 31.75 44.501C33.5076 42.7435 34.4962 40.3605 34.499 37.875V34.125H47.126C47.9236 34.125 48.712 33.9554 49.4391 33.6274C50.1661 33.2994 50.8151 32.8206 51.343 32.2227C51.8708 31.6248 52.2655 30.9214 52.5008 30.1593C52.7361 29.3972 52.8066 28.5938 52.7077 27.8024ZM13.874 24.75H4.49902V4.125H13.874V24.75Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
