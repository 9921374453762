import { BardTerms as BardTermsType } from '@/src/@types/statamic'
import Picture from '@/src/components/elements/Picture'

export default function BardTerms({
  optional_title: title,
  terms,
}: BardTermsType) {
  return (
    <div>
      {title && <h3 className="mb-3 text-lg font-extrabold">{title}</h3>}
      <dl className="grid gap-6 md:grid-cols-2">
        {terms.map((term, index) => (
          <div key={index} className="rounded-lg bg-blue-50 px-5 py-4">
            <div className="flex h-12 items-center space-x-4">
              {term.icon && (
                <div className="size-12 rounded bg-blue-900 p-1.5">
                  <Picture
                    {...term.icon}
                    height={36}
                    width={36}
                    className="object-contain"
                    quality={100}
                  />
                </div>
              )}
              <dt className="text-lg font-bold">{term.term}</dt>
            </div>
            <dd className="mt-4">{term.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
