import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form'
import VoucherInput from './VoucherInput'

function UncontrolledVoucherInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  style = 'white',
  voucherChange,
  ...props
}: Parameters<typeof VoucherInput>[0] &
  UseControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onChange },
        fieldState: { invalid, error },
      }) => (
        <VoucherInput
          ref={ref}
          style={style}
          value={value}
          onChange={onChange}
          invalid={invalid}
          error={error}
          label={label}
          voucherChange={voucherChange}
          {...props}
        />
      )}
    />
  )
}
export default UncontrolledVoucherInput
