import { useEffect, useState } from 'react'

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export default function useBreakpointReached(
  breakpoint: keyof typeof breakpoints
): boolean {
  const [breakPointReached, setBreakpointReached] = useState(false)

  useEffect(() => {
    setBreakpointReached(window.innerWidth >= breakpoints[breakpoint])
    function handleResize() {
      setBreakpointReached(window.innerWidth >= breakpoints[breakpoint])
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoint])

  return breakPointReached
}
