import Head from 'next/head'
import MetaTag from './MetaTag'
import { ImageType } from '@/src/@types/statamic'
import { socialImageUrl } from '@/src/lib/imageLoader'

export default function MetaTags({
  seoTitle,
  image,
  title,
  description,
  seoSummary,
  date,
  fullUrl,
  noindex,
}: {
  seoTitle?: string | null
  image?: ImageType | null
  title?: string | null
  description?: string | null
  seoSummary?: string | null
  fullUrl?: string | null
  date?: string | null
  noindex?: boolean
}) {
  return (
    <>
      <MetaTag name="title" content={seoTitle} />
      <MetaTag property="og:title" content={title} />
      <MetaTag property="og:description" content={description} />
      <MetaTag name="description" content={seoSummary} />
      <MetaTag property="og:url" content={fullUrl} />
      <MetaTag property="og:updated_time" content={date} />
      {image && image.permalink && (
        <>
          <MetaTag property="og:image" content={socialImageUrl(image)} />
          <MetaTag
            property="og:image:secure_url"
            content={socialImageUrl(image)}
          />
          <MetaTag property="og:image:width" content="1200" />
          <MetaTag property="og:image:height" content="630" />
          <MetaTag property="og:image:alt" content={image.alt} />{' '}
          <MetaTag name="twitter:card" content="summary_large_image" />
          <MetaTag property="twitter:url" content={fullUrl} />
          <MetaTag name="twitter:title" content={title} />
          <MetaTag name="twitter:description" content={description} />
          <MetaTag name="twitter:image" content={socialImageUrl(image)} />
        </>
      )}
      <Head>
        {seoTitle && <title key="title">{seoTitle}</title>}
        {fullUrl && <link rel="canonical" href={fullUrl} />}
        {noindex && <meta name="robots" content="noindex" />}
        <meta property="og:type" content="website" />
      </Head>
    </>
  )
}
