import { X } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'
import { CatcherBlock } from '@/src/@types/statamic'
import IconButton from '@/src/components/form/IconButton'
import Container from '@/src/components/layout/Container'
import { replacePink } from '@/src/lib/replacePink'

export default function CatcherWithoutModal({
  countdown_end,
  title,
}: CatcherBlock) {
  const [closed, setClosed] = useState(false)
  const [countdown, setCountdown] = useState<
    | undefined
    | {
        days: number
        hours: number
        minutes: number
        seconds: number
      }
  >(undefined)

  const calculateCountdown = (interval?: NodeJS.Timeout) => {
    if (countdown_end) {
      const now = new Date().getTime()
      const end = new Date(countdown_end.replace(/\s/, 'T')).getTime()
      const distance = end - now
      if (distance < 0 || closed) {
        if (interval) {
          clearInterval(interval)
        }
        setClosed(true)
        return
      }
      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      })
    }
  }
  useEffect(() => {
    if (countdown_end) {
      calculateCountdown()
      const interval = setInterval(() => {
        calculateCountdown(interval)
      }, 1000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown_end])

  if (closed) {
    return <div className="-mb-20"></div>
  }

  return (
    <Container fullPageOnMobile>
      <div className="relative z-50 -mb-20 -mt-8 flex flex-col items-center justify-between bg-gradient-600 p-7 md:mt-0 md:flex-row md:rounded-[10px]">
        {countdown_end && countdown && (
          <div className="flex space-x-[11px] md:mr-[30px]">
            <div className="flex flex-col items-center">
              <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                {countdown.days.toString().padStart(2, '0')}
              </div>
              <p className="mt-0.5 text-xs text-white">Tage</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                {countdown.hours.toString().padStart(2, '0')}
              </div>
              <p className="mt-0.5 text-xs text-white">Stunden</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                {countdown.minutes.toString().padStart(2, '0')}
              </div>
              <p className="mt-0.5 text-xs text-white">Minuten</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                {countdown.seconds.toString().padStart(2, '0')}
              </div>
              <p className="mt-0.5 text-xs text-white">Sekunden</p>
            </div>
          </div>
        )}
        <p
          className="my-5 grow text-center text-lg font-extrabold text-white md:my-0 md:text-left md:text-2xl"
          dangerouslySetInnerHTML={{
            __html: replacePink(title),
          }}
        />
        <div className="flex shrink-0 items-center">
          <IconButton
            style="transparent-white"
            type="button"
            className="absolute right-2.5 top-2.5 ml-5 flex items-center justify-center md:static"
            onClick={() => setClosed(true)}
          >
            <X
              className="size-7"
              color="currentColor"
              weight="bold"
              alt="Schliessen"
            />
          </IconButton>
        </div>
      </div>
    </Container>
  )
}
