import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useWatch } from 'react-hook-form'
import { LotteryBlock } from '@/src/@types/statamic'
import Button from '@/src/components/core/Button'
import { Input } from '@/src/components/core/Input'
import { Select } from '@/src/components/core/Select'
import Content from '@/src/components/elements/Content'
import Checkbox from '@/src/components/form/Checkbox'
import Honeypot from '@/src/components/form/Honeypot'
import Container from '@/src/components/layout/Container'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

type RequestFrom = {
  first_name: string
  last_name: string
  salutation: string
  birthday: string
  street: string
  zip: string
  place: string
  phone: string
  email: string
  vehicle: string
  interests: string[]
  origin: string
  address: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/leads`

export default function Component({
  text,
  button_text,
  lottery_key,
  submitted_text,
}: LotteryBlock) {
  const { utmData } = useUtmCache()

  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [startedFillingOut, setStartedFillingOut] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const { handleSubmit, register, control } = useLaravelForm<RequestFrom>({
    defaultValues: {
      first_name: '',
      last_name: '',
      salutation: '',
      birthday: '',
      street: '',
      zip: '',
      place: '',
      phone: '',
      email: '',
      vehicle: '',
      interests: [],
      origin: 'Webseite',
      address: '', // honeypot
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const formValues = useWatch({
    control,
    defaultValue: {},
  })

  useEffect(() => {
    // check if a form property exists to ignore init of value
    if (formValues.first_name && !startedFillingOut) {
      setStartedFillingOut(true)
      TagManager.dataLayer({
        dataLayer: {
          event: 'lottery_form_started',
          params: {
            origin: 'Website',
            site: window.location.href,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  return (
    <Container>
      <div>
        <div>
          <div className="mt-8 max-w-[800px] rounded-xl bg-gradient-600 p-8 md:mt-10 md:p-10">
            {!submitted && (
              <>
                {text && <Content content={text} textColor="white" />}
                <form
                  ref={formRef}
                  onSubmit={handleSubmit(
                    () => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'lottery_form_sent',
                          params: {
                            origin: 'Website',
                            site: window.location.href,
                          },
                        },
                      })
                      setSubmitted(true)
                      formRef.current?.reset()
                      setSending(false)
                    },
                    (data) => {
                      setSending(true)
                      data.interests = [
                        `Wettbewerb-${lottery_key}`,
                        data.vehicle,
                      ]
                      return data
                    },
                    () => {
                      setSending(false)
                    }
                  )}
                  method="POST"
                  action={requestUrl}
                  className="mt-4 flex flex-col"
                >
                  <div className="grid gap-2 md:grid-cols-2 lg:gap-4">
                    <Input
                      label="Vorname"
                      kind="firstName"
                      required
                      errorMessageColor="white"
                      {...register('first_name')}
                    />
                    <Input
                      label="Nachname"
                      kind="lastName"
                      required
                      errorMessageColor="white"
                      {...register('last_name')}
                    />
                    <Select label="Anrede" required {...register('salutation')}>
                      <option value="Frau">Frau</option>
                      <option value="Herr">Herr</option>
                      <option value="Keine Angabe">Keine Angabe</option>
                    </Select>
                    <Input
                      label="E-Mail"
                      kind="email"
                      required
                      {...register('email')}
                      errorMessageColor="white"
                    />
                    <Honeypot {...register('address')} />
                    <Input
                      label="Handynummer"
                      kind="tel"
                      required
                      errorMessageColor="white"
                      {...register('phone')}
                    />
                    <Input
                      label="Strasse"
                      kind="street"
                      required
                      errorMessageColor="white"
                      {...register('street')}
                    />
                    <div className="grid grid-cols-4 gap-2">
                      <Input
                        label="PLZ"
                        kind="zip"
                        required
                        errorMessageColor="white"
                        {...register('zip')}
                      />
                      <div className="col-span-3">
                        <Input
                          label="Ort"
                          kind="place"
                          required
                          errorMessageColor="white"
                          {...register('place')}
                        />
                      </div>
                    </div>
                    <Input
                      label="Geburtstag"
                      errorMessageColor="white"
                      required
                      {...register('birthday')}
                    />
                    <Select label="Interesse" required {...register('vehicle')}>
                      <option value="Auto">Auto</option>
                      <option value="Motorrad">Motorrad</option>
                    </Select>
                  </div>
                  <div className="mt-5">
                    <Checkbox multiLine required>
                      <span className="text-white">
                        Ich habe die{' '}
                        <Link
                          href="https://blinkdrive.ch/teilnahmebedingungen-fuer-gewinnspiele/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Teilnahmebedingungen
                        </Link>{' '}
                        für das Gewinnspiel sowie die{' '}
                        <Link
                          href="https://blinkdrive.ch/datenschutz/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Datenschutzrichtlinien
                        </Link>{' '}
                        von BLINK gelesen und bin damit einverstanden.
                      </span>
                    </Checkbox>
                  </div>
                  <div className="mt-8 self-center">
                    <Button
                      type="submit"
                      version="white"
                      loading={sending}
                      className="inline-flex items-center"
                    >
                      {button_text}
                    </Button>
                  </div>
                </form>
              </>
            )}
            {submitted && (
              <Content content={submitted_text} textColor="white" />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
