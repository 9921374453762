import { NavigationLink, NavigationResult } from '@/src/@types/statamic'

export function filterNavs(
  navs: NavigationResult['navs']
): NavigationResult['navs'] {
  for (const i in navs) {
    navs[i].tree = navs[i].tree.filter(filterLink)
  }
  return navs
}

function filterLink(link: NavigationLink): boolean | NavigationLink {
  if (link.page.url && !link.page.published) {
    return false
  }
  if (link.children) {
    link.children = link.children.filter(filterLink)
  }
  return link
}
