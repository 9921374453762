import classNames from 'classnames'
import Map from '@/src/components/elements/Map'

export default function SquareMap({
  rounded = '5',
  ...props
}: { rounded?: '5' | '2xl' } & Parameters<typeof Map>[0]) {
  const className = classNames(props.className, 'h-full w-full')
  return (
    <div
      className={classNames([
        'aspect-h-1 aspect-w-1 relative w-full overflow-hidden',
        {
          'rounded-[5px]': rounded === '5',
          'rounded-2xl': rounded === '2xl',
        },
      ])}
    >
      <div>
        <Map {...props} className={className} />
      </div>
    </div>
  )
}
