import { Navigation, A11y, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import { TeamSliderBlock } from '../../@types/statamic'
import { getTeamMembers } from '../../AdminApiClient'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import ArrowAction from '@/src/components/elements/ArrowAction'
import Picture from '@/src/components/elements/Picture'
import { useBetterUseId } from '@/src/hooks/betterUseId'

type TeamSliderBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamSliderBlock) {
  return title
}

export async function getStaticProps({ title, roles }: TeamSliderBlock) {
  const team = await getTeamMembers(
    roles.map((role) => role.value),
    10
  )

  return { team, title }
}

export default function Component({ title, team }: TeamSliderBlockProps) {
  const scrollbarId = `scrollbar-${useBetterUseId()}`
  return (
    <div className="md:pb-5 lg:pb-20">
      <Container>
        <div className="relative">
          <svg
            width="460"
            height="454"
            viewBox="0 0 460 454"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-[30%] -z-10 size-80 md:left-1/2 md:h-auto lg:w-auto"
          >
            <path
              opacity="0.5"
              d="M404.742 85.2379C300.447 -21.3609 186.128 -21.6077 83.4691 49.2537C-17.0618 121.776 -22.9128 264.052 45.1716 368.683C113.256 473.315 264.85 472.761 357.403 415.186C449.955 357.611 509.037 191.837 404.742 85.2379Z"
              fill="url(#paint0_linear_2226_22821)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2226_22821"
                x1="469.647"
                y1="461.933"
                x2="-100.797"
                y2="285.596"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#BFC9FF" />
                <stop offset="1" stopColor="#E0E5FF" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="140"
            height="118"
            viewBox="0 0 140 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-[85%] top-full hidden md:block"
          >
            <path
              d="M138.49 57.3761C138.49 57.3761 148.406 17.5914 103.092 3.72476C46.7515 -13.5155 69.5886 41.0185 28.4072 41.9374C-12.7741 42.8562 -7.87155 90.4057 39.3739 110.806C86.6181 131.206 128.194 104.706 138.49 57.3761Z"
              fill="url(#paint0_linear_2226_22820)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2226_22820"
                x1="139.409"
                y1="117.757"
                x2="29.7115"
                y2="-29.1175"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F3F5FF" />
                <stop offset="1" stopColor="#E7EBFF" />
              </linearGradient>
            </defs>
          </svg>
          <div className="mb-20">
            <Title style={3}>{title}</Title>
            <div className="mt-8 flex overflow-auto md:mt-10">
              <Swiper
                modules={[Navigation, Scrollbar, A11y]}
                a11y={{
                  prevSlideMessage: 'Vorheriges Bild',
                  nextSlideMessage: 'Nächstes Bild',
                  paginationBulletMessage: 'Gehe zu Bild {{index}}',
                  lastSlideMessage: 'Dies ist das letzte Bild',
                  firstSlideMessage: 'Dies ist das erste Bild',
                }}
                scrollbar={{
                  el: `#${scrollbarId}`,
                  draggable: true,
                  dragClass: 'swiper-scrollbar-draggable',
                  dragSize: 20,
                }}
                slidesPerView={2.7}
                breakpoints={{
                  768: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 6,
                  },
                }}
              >
                {team.map(({ image, name, shortDescription }, index) => (
                  <SwiperSlide key={index}>
                    <div
                      key={index}
                      className="aspect-h-1 aspect-w-1 relative mx-1"
                    >
                      <Picture
                        sizes="(min-width: 1024px) 16vw, (min-width: 768px) 25vw, 37vw"
                        fill
                        permalink={image}
                        alt={`Bild von ${name}`}
                        className="rounded-2xl object-cover"
                      />
                      <div className="group absolute inset-0 flex animate-fade-in flex-col items-center justify-center rounded-2xl bg-transparent text-white opacity-70 transition duration-200 hover:bg-purple-700">
                        <p className="text-lg font-bold opacity-0 transition group-hover:opacity-100">
                          {name}
                        </p>
                        <p className="text-center opacity-0 transition group-hover:opacity-100">
                          {shortDescription}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              id={scrollbarId}
              className="mt-8 flex h-1 w-full items-center rounded-full bg-blue-100"
            ></div>
            <div className="mt-6 md:mt-12">
              <ArrowAction href="/ueber-uns">Zum kompletten Team</ArrowAction>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
