import { CourseLocation } from '../../@types/admin'
import { AdminResponse } from '../../@types/models'
import { UpcomingFirstAidCoursesBlock } from '../../@types/statamic'
import { get } from '../../AdminApiClient'
import Title from '../../components/elements/Title'
import UpcomingCourses from '../../components/elements/UpcomingCourses'
import Container from '../../components/layout/Container'

export function getTitle({ title }: UpcomingFirstAidCoursesBlock) {
  return title
}

type UpcomingFirstAidCoursesProps = Awaited<ReturnType<typeof getStaticProps>>

export async function getStaticProps({ title }: UpcomingFirstAidCoursesBlock) {
  const {
    admin: { locations },
  } = await get<AdminResponse<{ locations: CourseLocation[] }>>(
    'website/home',
    undefined,
    true
  )

  return {
    title,
    locations,
  }
}

export default function Component({
  title,
  locations,
}: UpcomingFirstAidCoursesProps) {
  return (
    <div className="md:pb-12 lg:pb-16">
      <Container>
        <Title style={2} className="max-w-sm">
          {title}
        </Title>
        <UpcomingCourses locations={locations} />
      </Container>
    </div>
  )
}
