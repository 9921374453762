import Icon from './Icon'

export default function WabMarker({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.69185 5.60807C8.69134 2.66214 12.7581 1.00494 17 1C21.2419 1.00494 25.3087 2.66214 28.3082 5.60807C31.3076 8.554 32.995 12.5481 33 16.7143C33 30.1602 18.4545 40.3164 17.8352 40.7419C17.5903 40.9099 17.2987 41 17 41C16.7013 41 16.4097 40.9099 16.1648 40.7419C15.5455 40.3164 1 30.1602 1 16.7143C1.00503 12.5481 2.69236 8.554 5.69185 5.60807Z"
            fill="#4ADE80"
            stroke="white"
          />
          <path
            d="M25.125 23.25H23.6945L18.9859 9.71484C18.9008 9.4696 18.7413 9.25697 18.5298 9.10647C18.3182 8.95597 18.0651 8.87507 17.8055 8.875H16.1945C15.935 8.87501 15.6819 8.9558 15.4704 9.10615C15.2589 9.2565 15.0994 9.46897 15.0141 9.71406L10.3055 23.25H8.875C8.70924 23.25 8.55027 23.3158 8.43306 23.4331C8.31585 23.5503 8.25 23.7092 8.25 23.875C8.25 24.0408 8.31585 24.1997 8.43306 24.3169C8.55027 24.4342 8.70924 24.5 8.875 24.5H25.125C25.2908 24.5 25.4497 24.4342 25.5669 24.3169C25.6842 24.1997 25.75 24.0408 25.75 23.875C25.75 23.7092 25.6842 23.5503 25.5669 23.4331C25.4497 23.3158 25.2908 23.25 25.125 23.25ZM14.4555 15.125H19.5445L20.8492 18.875H13.1508L14.4555 15.125Z"
            fill="#14532D"
          />
        </svg>
      }
    />
  )
}
