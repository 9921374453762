import { ForwardedRef, forwardRef } from 'react'
import Input, { InputProps } from './Input'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INPUT_TAG = 'input'

function MessageInput(
  props: Omit<InputProps<typeof INPUT_TAG>, 'autoComplete'>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return <Input {...props} ref={ref} as="textarea" />
}

export default forwardRef(MessageInput)
