import Icon from '@/src/components/icons/Icon'

export default function FacebookLogo({
  className = '',
}: {
  className?: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
            fill="#DADADA"
          />
          <path
            d="M26.1016 37.1115V24.0542H29.7059L30.1836 19.5546H26.1016L26.1077 17.3025C26.1077 16.1289 26.2192 15.5001 27.9048 15.5001H30.1581V11H26.5532C22.2231 11 20.6991 13.1828 20.6991 16.8536V19.5551H18V24.0547H20.6991V37.1115H26.1016Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
