import { ForwardedRef, useEffect, useState, forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { AllOptional } from '@/src/@types/util'
import Input from '@/src/components/form/Input'
import Label from '@/src/components/form/Label'

function Honeypot(
  props: AllOptional<UseFormRegisterReturn>,
  ref: ForwardedRef<any>
) {
  const [className, setClassName] = useState('')

  useEffect(() => {
    setClassName('ghqinvq')
  }, [])

  return (
    <Label label="" {...{ ...props, className }} style="inverted" ref={ref}>
      {(inputProps) => (
        <Input {...inputProps} autoComplete="nope" tabIndex="-1" />
      )}
    </Label>
  )
}

export default forwardRef(Honeypot)
