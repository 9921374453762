import { Disclosure, Transition } from '@headlessui/react'
import { CaretDown } from '@phosphor-icons/react'
import classNames from 'classnames'
import TagManager from 'react-gtm-module'
import Content from '../elements/Content'
import { BardAccordion as BardAccordionType } from '@/src/@types/statamic'

export default function BardAccordion({
  accordion,
  fullWidth,
}: BardAccordionType & { fullWidth?: boolean }) {
  const sendGTMEvent = (open: boolean, title: string) => {
    // open is false because accordion isn't yet open
    if (!open) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'accordion_open',
          params: {
            title,
            site: window.location.href,
          },
        },
      })
    }
  }

  return (
    <div
      className={classNames([
        'max-w-full md:text-xl',
        {
          'lg:max-w-[66%]': !fullWidth,
        },
      ])}
    >
      {accordion.map((item, index) => {
        const HeadingComponent: keyof Pick<
          JSX.IntrinsicElements,
          'h2' | 'h3' | 'h4'
        > = item.title_tag.value

        return (
          <div
            key={index}
            className={classNames([
              'border-t border-blue-100',
              {
                'border-b': index === accordion.length - 1,
              },
            ])}
          >
            <Disclosure>
              {({ open }) => (
                <>
                  <HeadingComponent
                    onClick={() => sendGTMEvent(open, item.title)}
                  >
                    <Disclosure.Button
                      className={classNames([
                        'flex w-full cursor-pointer items-center justify-between py-4',
                      ])}
                    >
                      <span className="text-left font-bold md:text-xl">
                        {item.title}
                      </span>
                      <CaretDown
                        size={20}
                        weight="bold"
                        className={classNames([
                          'shrink-0 transition duration-150',
                          {
                            'rotate-180': open,
                          },
                        ])}
                      />
                    </Disclosure.Button>
                  </HeadingComponent>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel
                      className={classNames([
                        'overflow-hidden transition-all duration-200',
                        {
                          'max-h-full pb-4 ease-in': open,
                          'max-h-0 ease-out': !open,
                        },
                      ])}
                    >
                      <Content content={item.text} />
                    </Disclosure.Panel>
                  </Transition>
                  {!open && <Content content={item.text} className="hidden" />}
                </>
              )}
            </Disclosure>
          </div>
        )
      })}
    </div>
  )
}
