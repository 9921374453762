import { ImageType } from '../../@types/statamic'
import Picture from '../elements/Picture'
import styles from '/src/css/Masks.module.css'

export default function HeaderVariations({ images }: { images: ImageType[] }) {
  switch (images.length) {
    case 1:
      return <SingleImage image={images[0]} />
    case 2:
      return <DoubleImages images={images} />
    case 3:
      return <TrippleImages images={images} />
    default:
      return null
  }
}

function SingleImage({ image }: { image: ImageType }) {
  return (
    <div className="-mx-4 md:mx-0 md:pl-24">
      <div
        className={`aspect-h-2 aspect-w-3 relative w-full md:aspect-h-1 md:aspect-w-1`}
      >
        <div>
          <svg
            aria-hidden="true"
            className="absolute left-[85%] top-[120%] w-[18%] md:-top-1/4 md:left-[-10%] md:w-1/2"
            viewBox="0 0 158 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
              fill="url(#paint0_linear_1896_9981)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_9981"
                x1="51.3641"
                y1="15.8074"
                x2="121.126"
                y2="151.98"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            aria-hidden="true"
            className="absolute bottom-[-40%] right-[10%] w-3/4 md:bottom-[-15%] md:right-[-30%]"
            viewBox="0 0 445 439"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
              fill="url(#paint0_linear_1896_11704)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_11704"
                x1="143.512"
                y1="44.9975"
                x2="342.03"
                y2="432.719"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            aria-hidden="true"
            className="absolute bottom-[-10%] left-[30%] hidden w-[10%] md:block"
            viewBox="0 0 94 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M81.4387 63.5991C81.4387 63.5991 104.477 47.5308 87.3338 19.9634C66.0187 -14.3121 53.243 24.122 31.5733 6.0475C9.90366 -12.027 -8.98295 14.724 6.2143 46.5336C21.411 78.3428 54.8067 83.3913 81.4387 63.5991Z"
              fill="url(#paint0_linear_1896_11698)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_11698"
                x1="-4.91442"
                y1="43.1421"
                x2="99.7933"
                y2="67.7627"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#B5C1FF" />
                <stop offset="1" stopColor="#DAE0FF" />
              </linearGradient>
            </defs>
          </svg>

          <div
            className={`absolute inset-0 bg-gradient-800 ${styles['mask-8']} ${styles['mask-11']} ${styles['mask']}`}
          >
            <Picture
              priority
              sizes="(max-width: 768px) 100vw, 33vw"
              {...image}
              fill
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function DoubleImages({ images }: { images: ImageType[] }) {
  return (
    <div className="aspect-h-1 aspect-w-1 relative -mr-12 md:mr-0 md:w-full">
      <div className="flex items-baseline">
        <svg
          aria-hidden="true"
          className="absolute -top-16 left-[30%] w-1/5 md:-top-1/4 md:left-[-17%] md:w-1/2"
          viewBox="0 0 158 155"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
            fill="url(#paint0_linear_1896_9981)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1896_9981"
              x1="51.3641"
              y1="15.8074"
              x2="121.126"
              y2="151.98"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>

        <svg
          aria-hidden="true"
          className="absolute bottom-[-1%] left-[-5px] w-[45%] md:left-[-10%] md:w-3/4"
          viewBox="0 0 445 439"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
            fill="url(#paint0_linear_1896_11704)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1896_11704"
              x1="143.512"
              y1="44.9975"
              x2="342.03"
              y2="432.719"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          aria-hidden="true"
          className="absolute bottom-[-5%] right-4 w-20 md:bottom-[30%] md:right-0 md:w-[15%]"
          viewBox="0 0 95 78"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M92.86 43.9159C92.86 43.9159 103.262 18.6217 74.7018 5.07677C39.1915 -11.7637 48.975 26.4401 21.7085 23.0535C-5.55805 19.667 -6.93305 51.5224 22.2683 69.5663C51.469 87.6101 81.4765 74.1527 92.86 43.9159Z"
            fill="url(#paint0_linear_1896_11723)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1896_11723"
              x1="26.6546"
              y1="66.7842"
              x2="69.0621"
              y2="-0.984578"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>

        <div className="w-1/3 self-end md:w-2/5">
          <div
            className={`aspect-h-1 aspect-w-1 relative left-[20px] w-full bg-gradient-700 md:left-[10%] lg:left-[20%] ${styles['mask-9']} ${styles['mask']}`}
          >
            <Picture
              priority
              sizes="(min-width: 768px) 40vw, 30vw"
              {...images[0]}
              fill
              className="object-cover"
            />
          </div>
        </div>
        <div
          className={`relative w-2/3 bg-gradient-800 md:-ml-24 md:mr-0 ${styles['mask-11']} ${styles['mask']}`}
        >
          <div className="aspect-h-1 aspect-w-1">
            <Picture
              sizes="60vw"
              priority
              {...images[1]}
              fill
              className="object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function TrippleImages({ images }: { images: ImageType[] }) {
  return (
    <div className="relative -mr-12 w-full px-5 lg:aspect-h-1 lg:aspect-w-1 md:px-0 md:pl-6 lg:pl-12">
      <div className="flex">
        <svg
          aria-hidden="true"
          className="absolute left-[10%] top-[-20%] w-[30%] md:top-[-70%] lg:-top-1/4 lg:w-[45%]"
          viewBox="0 0 158 155"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
            fill="url(#paint0_linear_1896_9981)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1896_9981"
              x1="51.3641"
              y1="15.8074"
              x2="121.126"
              y2="151.98"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          aria-hidden="true"
          className="absolute bottom-[-40%] right-[-15%] w-[85%] lg:bottom-[-10%]"
          viewBox="0 0 445 439"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
            fill="url(#paint0_linear_1896_11704)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1896_11704"
              x1="143.512"
              y1="44.9975"
              x2="342.03"
              y2="432.719"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>
        <div className="flex w-full items-baseline justify-between">
          <div
            className={`relative w-[65%] self-center bg-gradient-800 lg:left-[8%] lg:w-[45%] ${styles['mask-10']} ${styles['mask']}`}
          >
            <div className="aspect-h-1 aspect-w-1">
              <Picture
                priority
                sizes="(min-width: 768px) 25vw, 50vw"
                {...images[0]}
                fill
                className="object-cover"
              />
            </div>
          </div>
          <div
            className={`relative w-[30%] bg-gradient-700 lg:right-[-15%] lg:w-3/5 ${styles['mask-11']} ${styles['mask']}`}
          >
            <div className="aspect-h-1 aspect-w-1">
              <Picture
                priority
                sizes="(min-width: 768px) 25vw, 50vw"
                {...images[1]}
                fill
                className="object-cover"
              />
            </div>
          </div>
          <div
            className={`absolute bottom-[-15%] right-[5%] w-[35%] bg-gradient-600 md:right-0 lg:right-[15%] lg:top-[60%] ${styles['mask-9']} ${styles['mask']}`}
          >
            <div className="aspect-h-1 aspect-w-1">
              <Picture
                priority
                sizes="(max-width: 768px) 25vw, 50vw"
                {...images[2]}
                fill
                className="object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
