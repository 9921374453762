import uniqWith from 'lodash-es/uniqWith'
import { LocationType, LocationWithDistance, Place } from '../@types/models'
import { InteractiveMapType } from '../@types/statamic'

export async function search(query: string, limit = 5): Promise<Place[]> {
  let offset = 0

  let places: Place[] = []
  let potentiallyMoreResultsAvailable = true
  const queryLimit = limit * 2

  do {
    const matchingPlaces = await fetchRequest<Place[]>(
      `places/search?limit=${queryLimit}&offset=${offset}&q=${encodeURIComponent(
        query.trim()
      )}`
    )

    potentiallyMoreResultsAvailable = matchingPlaces.length === queryLimit
    offset += matchingPlaces.length

    places = uniqWith(
      [...places, ...matchingPlaces],
      (prevPlace: Place, nextPlace: Place) =>
        prevPlace.name === nextPlace.name &&
        prevPlace.canton === nextPlace.canton
    )
  } while (potentiallyMoreResultsAvailable && places.length < limit)

  return places.splice(0, limit)
}

export async function getLocations(map_type: string) {
  const locations = await fetchRequest<LocationType[]>(
    `locations?type=${map_type}`
  )

  return locations
}

export async function closestLocations(
  place: number | { lng: number; lat: number },
  type: InteractiveMapType,
  limit = 5,
  radius = 150,
  withAddresses = '',
  onlyWithBooking = false
): Promise<LocationWithDistance[]> {
  if (typeof place == 'number') {
    const locations = await fetchRequest<LocationWithDistance[]>(
      `locations/near?limit=${limit}&radius=${radius}&place_id=${place}&type=${type}&with-addresses=${withAddresses}${
        onlyWithBooking ? '&has-online-booking' : ''
      }`
    )
    return locations
  }

  const locations = await fetchRequest<LocationWithDistance[]>(
    `locations/near?limit=${limit}&radius=${radius}&longitude=${
      place.lng
    }&latitude=${place.lat}&type=${type}&with-addresses=${withAddresses}${
      onlyWithBooking ? '&has-online-booking' : ''
    }`
  )

  return locations
}

export async function getLocationsForCurrentPosition(
  type: InteractiveMapType,
  withAddresses?: string,
  onlyWithBooking: boolean = false
): Promise<LocationWithDistance[]> {
  if (!('geolocation' in window.navigator)) {
    return []
  }

  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(
      async (position) => {
        resolve(
          await closestLocations(
            {
              lng: position.coords.longitude,
              lat: position.coords.latitude,
            },
            type,
            undefined,
            undefined,
            withAddresses,
            onlyWithBooking
          )
        )
      },
      (error) => reject(error)
    )
  })
}

async function fetchRequest<T>(requestUri: string): Promise<T> {
  return (
    await fetch('https://admin.blinkdrive.ch/api' + `/website/v2/${requestUri}`)
  ).json()
}
