import Icon from '@/src/components/icons/Icon'

export default function MapWithPin({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.55784 1.58402C7.55377 0.57136 8.90405 0.00169924 10.3125 0C11.721 0.00169908 13.0712 0.57136 14.0672 1.58402C15.0631 2.59669 15.6233 3.96966 15.625 5.40179C15.625 10.0238 10.7955 13.515 10.5898 13.6613C10.5085 13.719 10.4117 13.75 10.3125 13.75C10.2133 13.75 10.1165 13.719 10.0352 13.6613C9.82955 13.515 5 10.0238 5 5.40179C5.00167 3.96966 5.56192 2.59669 6.55784 1.58402ZM11.3861 3.76872C11.0684 3.55288 10.6949 3.43768 10.3129 3.43768C10.0592 3.43767 9.80795 3.48847 9.57357 3.58719C9.33919 3.6859 9.12622 3.83059 8.94684 4.01299C8.76745 4.19539 8.62515 4.41193 8.52807 4.65026C8.43099 4.88858 8.38103 5.14401 8.38104 5.40196C8.38104 5.79046 8.49434 6.17024 8.70661 6.49326C8.91888 6.81629 9.22059 7.06805 9.57358 7.21673C9.92657 7.3654 10.315 7.4043 10.6897 7.32851C11.0645 7.25271 11.4087 7.06563 11.6789 6.79092C11.949 6.51621 12.133 6.16621 12.2076 5.78518C12.2821 5.40414 12.2438 5.00919 12.0976 4.65026C11.9514 4.29134 11.7038 3.98456 11.3861 3.76872ZM2.34842 6.26865L4.38378 5.75981C4.4047 6.18686 4.46235 6.603 4.5499 7.00675L3.125 7.36298V17.3245L6.875 16.387V11.5075C6.90394 11.5426 6.93288 11.5773 6.96179 11.6118C7.35912 12.0851 7.75886 12.5025 8.125 12.8557V16.4887L11.875 18.3637V13.4293C12.2358 13.1146 12.6711 12.7072 13.125 12.2199V18.5745L16.875 17.637V7.67549L15.8147 7.94056C15.9649 7.4997 16.0827 7.04087 16.1577 6.56635L17.3484 6.26866C17.5351 6.22198 17.7329 6.26393 17.8846 6.38237C18.0363 6.50082 18.125 6.68254 18.125 6.875V18.125C18.125 18.4118 17.9298 18.6618 17.6516 18.7313L12.6543 19.9807C12.6515 19.9814 12.6488 19.982 12.646 19.9827C12.6425 19.9835 12.6391 19.9843 12.6356 19.9851C12.4886 20.0179 12.3416 19.9958 12.2165 19.932L7.42728 17.5374L2.65158 18.7313C2.46487 18.778 2.26707 18.7361 2.11537 18.6176C1.96367 18.4992 1.875 18.3175 1.875 18.125V6.87499C1.875 6.5882 2.07019 6.33821 2.34842 6.26865Z"
            fill="currentColor"
          />
        </svg>
      }
    />
  )
}
