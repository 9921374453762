import { NavigationLink, NavigationResult } from '../@types/statamic'

export default function Navigation(navs: NavigationResult['navs']) {
  return {
    get(handle: string): NavigationLink[] {
      const navigation = navs.find((nav) => nav.handle === handle)

      if (navigation === undefined) {
        return []
      }

      return navigation.tree
    },
  }
}
