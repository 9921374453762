import Container from '../../components/layout/Container'
import { TrialLessonBlock } from '@/src/@types/statamic'
import Content from '@/src/components/elements/Content'
import CustomLink from '@/src/components/elements/CustomLink'
import Picture, {
  illustrationBackgrounds,
} from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import Caret from '@/src/components/icons/Caret'
import styles from '/src/css/Masks.module.css'

export function getTitle({ title }: TrialLessonBlock) {
  return title
}

export default function Component({
  image,
  title,
  heading_small,
  heading,
  text,
  link_link,
  link_text,
}: TrialLessonBlock) {
  return (
    <Container>
      <div className="relative">
        <svg
          width="489"
          height="507"
          viewBox="0 0 489 507"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-[-150px] top-[-350px] -z-10 w-[300px] md:left-0 md:top-[-35%] md:w-[30%]"
        >
          <path
            opacity="0.5"
            d="M340.639 495.236C340.639 495.236 473.017 464.181 487.215 269.72C501.412 75.2585 376.154 36.326 333.107 13.4148C290.06 -9.49642 157.029 -10.1039 65.3139 71.9524C-26.401 154.009 -38.6065 446.756 147.261 491.649C280.782 523.898 340.639 495.236 340.639 495.236Z"
            fill="url(#paint0_linear_3317_54138)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3317_54138"
              x1="433.025"
              y1="352.575"
              x2="10.0294"
              y2="113.506"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D6DCFF" />
              <stop offset="1" stopColor="#EEF1FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          width="177"
          height="170"
          viewBox="0 0 177 170"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-[140px] top-[-50px] -z-10 w-[130px] md:left-[30%] md:top-1/2 md:w-[12%]"
        >
          <path
            opacity="0.5"
            d="M162.837 126.767C195.069 65.2257 171.109 9.98126 102.937 1.10775C34.7654 -7.76576 -18.504 40.2062 6.11019 111.245C30.7244 182.284 130.605 188.309 162.837 126.767Z"
            fill="url(#paint0_linear_3317_54137)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3317_54137"
              x1="-12.095"
              y1="7.65724"
              x2="185.378"
              y2="161.232"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBFBFF" />
              <stop offset="1" stopColor="#DAE0FF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          width="95"
          height="112"
          viewBox="0 0 95 112"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-[230px] top-[-300px] -z-10 w-[70px] md:left-[40%] md:top-[-20%] md:w-[7%]"
        >
          <path
            opacity="0.5"
            d="M45.9868 0.735741C45.9868 0.735741 14.1452 -7.20107 3.04701 29.0659C-10.7513 74.1583 32.8951 55.8807 33.6304 88.8401C34.3658 121.8 72.4221 117.876 88.7493 80.063C105.076 42.2511 83.8672 8.97561 45.9868 0.735741Z"
            fill="url(#paint0_linear_3317_54139)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3317_54139"
              x1="90.0843"
              y1="118.804"
              x2="-8.64327"
              y2="6.46316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBFBFF" />
              <stop offset="1" stopColor="#DAE0FF" />
            </linearGradient>
          </defs>
        </svg>
        <div
          className={`absolute inset-0 top-[-250px] w-[230px] md:left-[15%] md:top-[-5%] md:w-1/4 ${
            image.illustration_background
              ? illustrationBackgrounds[image.illustration_background.value]
              : 'bg-gradient-600'
          } ${styles['mask-11']} ${styles['mask']}`}
        >
          <div className="aspect-h-1 aspect-w-1">
            <div className="absolute inset-0">
              <Picture
                priority
                sizes="(min-width: 768px) 50vw, 100vw"
                {...image}
                fill
                className="object-cover"
              />
            </div>
          </div>
        </div>
        <div className="mt-[270px] md:ml-[50%] md:mt-0">
          <Title style={3}>{title}</Title>
          <div className="relative mt-8 rounded-xl bg-gradient-100 p-10 md:mt-10">
            <Caret className="absolute inset-x-0 top-[-21px] mx-auto w-9 text-blue-100" />
            {heading_small && (
              <p className="mb-1 text-lg font-bold">{heading_small}</p>
            )}
            <p className="text-4xl font-extrabold">{heading}</p>
            {text && <Content content={text} className="mt-3" />}
            {link_link && link_text && (
              <div className="mt-8">
                <CustomLink href={link_link} passHref>
                  <Button as="a">{link_text}</Button>
                </CustomLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
