import Container from '../../components/layout/Container'
import { ShortLinksBlock } from '@/src/@types/statamic'
import CustomLink from '@/src/components/elements/CustomLink'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import ArrowIcon from '@/src/components/icons/ArrowIcon'

export function getTitle({ optional_title }: ShortLinksBlock) {
  return optional_title
}

export default function Component({
  optional_title,
  heading,
  links,
}: ShortLinksBlock) {
  return (
    <div className="md:pb-8">
      <Container>
        <div className="relative">
          <svg
            width="375"
            height="370"
            viewBox="0 0 375 370"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute left-1/2 top-0 -z-10 size-72 lg:size-auto"
          >
            <path
              d="M329.953 69.467C244.93 -17.4086 151.734 -17.6098 68.0454 40.1407C-13.9091 99.2446 -18.6789 215.197 36.8247 300.469C92.3283 385.741 215.911 385.29 291.361 338.368C366.811 291.445 414.976 156.343 329.953 69.467Z"
              fill="url(#paint0_linear_2204_25647)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2204_25647"
                x1="375"
                y1="-1.09424e-05"
                x2="17.6737"
                y2="406.799"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F9FAFF" />
                <stop offset="1" stopColor="#F3F5FF" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="197"
            height="194"
            viewBox="0 0 197 194"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute -right-7 top-[80%] -z-10 size-24 lg:left-[80%] lg:top-[20%] lg:size-auto"
          >
            <path
              d="M173.335 36.4233C128.67 -9.12778 79.7112 -9.23324 35.7465 21.0467C-7.30692 52.0364 -9.81267 112.833 19.3452 157.543C48.5031 202.253 113.425 202.017 153.062 177.414C192.698 152.812 218.001 81.9743 173.335 36.4233Z"
              fill="url(#paint0_linear_2204_25652)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2204_25652"
                x1="197"
                y1="0"
                x2="9.7556"
                y2="215.102"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F3F5FF" />
                <stop offset="1" stopColor="#E7EBFF" />
              </linearGradient>
            </defs>
          </svg>
          <div className="lg:mb-40">
            <div className="lg:mr-[50%]">
              {optional_title && (
                <Title style={5} as="h4" className="mb-5">
                  {optional_title}
                </Title>
              )}
              <Title style={3}>{heading}</Title>
            </div>
            <div className="mt-8 flex flex-wrap">
              {links
                .filter((link) => link.link_link && link.link_text)
                .map((link, index) => (
                  <CustomLink key={index} href={link.link_link} passHref>
                    <Button
                      as="a"
                      size="none"
                      className="group mb-4 mr-4 inline-flex items-center px-[30px] py-5 text-base leading-6"
                    >
                      <span className="pt-0.5">{link.link_text}</span>
                      <ArrowIcon className="ml-2 size-5 transition group-hover:translate-x-2" />
                    </Button>
                  </CustomLink>
                ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
