import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { useState } from 'react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Content from '../elements/Content'
import Picture from '../elements/Picture'
import { BardImageSteps as BardImageStepsType } from '@/src/@types/statamic'
import { useBetterUseId } from '@/src/hooks/betterUseId'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/a11y'

export default function BardImage({ steps }: BardImageStepsType) {
  const prevElementId = `prev-el-${useBetterUseId()}`
  const nextElementId = `next-el-${useBetterUseId()}`

  const [currentSlide, setCurrentSlide] = useState(0)

  return (
    <div className="relative pb-14">
      <Swiper
        navigation={{
          prevEl: `#${prevElementId}`,
          nextEl: `#${nextElementId}`,
          hiddenClass: '!hidden',
          disabledClass: '!hidden',
        }}
        modules={[Navigation, A11y]}
        a11y={{
          prevSlideMessage: 'Vorheriges Bild',
          nextSlideMessage: 'Nächstes Bild',
          paginationBulletMessage: 'Gehe zu Bild {{index}}',
          lastSlideMessage: 'Dies ist das letzte Bild',
          firstSlideMessage: 'Dies ist das erste Bild',
        }}
        slidesPerView={1}
        spaceBetween={20}
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
        className="rounded-lg"
      >
        {steps.map((step, index) => (
          <SwiperSlide key={index}>
            <div className="aspect-h-4 aspect-w-6 relative w-full">
              <Picture
                {...step.image}
                sizes="(min-width: 768px) 50vw, 80vw"
                fill
                className="rounded-lg bg-blue-900 object-contain"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="aspect-h-4 aspect-w-6 absolute inset-x-0 top-0 w-full">
        <button
          id={prevElementId}
          type="button"
          className="absolute left-3 top-1/2 z-10 flex size-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-md hover:bg-blue-50"
        >
          <ArrowLeft size={20} weight="bold" className="text-purple-600" />
        </button>
        <button
          id={nextElementId}
          type="button"
          className="absolute left-auto right-3 top-1/2 z-10 flex size-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white shadow-md hover:bg-blue-50"
        >
          <ArrowRight size={20} weight="bold" className="text-purple-600" />
        </button>
      </div>
      <div className="mt-4 flex items-start">
        <p className="mr-4 rounded bg-blue-125 px-2 py-1 text-sm font-bold">
          {currentSlide + 1}/{steps.length}
        </p>
        <Content
          content={steps[currentSlide]!.text}
          textSize="allSm"
          className="mt-[3px] prose-p:text-gray-800 lg:w-5/6"
        />
      </div>
    </div>
  )
}
