import classNames from 'classnames'
import { textSizes } from '../elements/Content'
import BardAccordion from './BardAccordion'
import BardAccordionBox from './BardAccordionBox'
import BardImage from './BardImage'
import BardText from './BardText'
import { PageInfo } from '@/src/@types/models'
import { BardSet } from '@/src/@types/statamic'
import BardAppLinks from '@/src/components/bard/BardAppLinks'
import BardCompactLinks from '@/src/components/bard/BardCompactLinks'
import BardCopyButton from '@/src/components/bard/BardCopyButton'
import BardCoupon from '@/src/components/bard/BardCoupon'
import BardDateOfBirthChecker from '@/src/components/bard/BardDateOfBirthChecker'
import BardGoogleRating from '@/src/components/bard/BardGoogleRating'
import BardImageSteps from '@/src/components/bard/BardImageSteps'
import BardInlineList from '@/src/components/bard/BardInlineList'
import BardLink from '@/src/components/bard/BardLink'
import BardLinkButton from '@/src/components/bard/BardLinkButton'
import BardMagazine from '@/src/components/bard/BardMagazine'
import BardQuiz from '@/src/components/bard/BardQuiz'
import BardTable from '@/src/components/bard/BardTable'
import BardTerms from '@/src/components/bard/BardTerms'
import BardToc from '@/src/components/bard/BardToc'
import BardTocHeading from '@/src/components/bard/BardTocHeading'
import BardVideos from '@/src/components/bard/BardVideos'

const bardSets: Record<BardSet['type'], (props: any) => JSX.Element | null> = {
  text: BardText,
  accordion: BardAccordion,
  image: BardImage,
  google_rating: BardGoogleRating,
  link_button: BardLinkButton,
  copy_button: BardCopyButton,
  link: BardLink,
  compact_links: BardCompactLinks,
  inline_list: BardInlineList,
  table: BardTable,
  date_of_birth_checker: BardDateOfBirthChecker,
  app_links: BardAppLinks,
  magazine_content: BardMagazine,
  image_steps: BardImageSteps,
  quiz: BardQuiz,
  accordion_box: BardAccordionBox,
  terms: BardTerms,
  videos: BardVideos,
  toc: BardToc,
  toc_heading: BardTocHeading,
  coupon: BardCoupon,
}

const styles = {
  normal: 'space-y-8 lg:space-y-12',
  narrow: 'space-y-4',
}

export default function Bard({
  content,
  className,
  fullWidth,
  style = 'normal',
  textSize = 'lg',
  sidebar = false,
  pageInfo,
}: {
  content: BardSet[]
  className?: string
  fullWidth?: boolean
  style?: keyof typeof styles
  textSize?: keyof typeof textSizes
  sidebar?: boolean
  pageInfo?: PageInfo
}) {
  return (
    <div className={classNames(className, styles[style])}>
      {content
        .filter((set: BardSet) => !!bardSets[set.type])
        .map((set, index) => {
          const { type, ...props } = set
          const Component = bardSets[type]
          return (
            <Component
              key={index}
              {...props}
              fullWidth={
                fullWidth ||
                pageInfo?.blueprint === 'magazine' ||
                pageInfo?.blueprint === 'partners'
              }
              textSize={textSize}
              sidebar={sidebar}
              pageInfo={pageInfo}
              content={content}
            />
          )
        })}
    </div>
  )
}
