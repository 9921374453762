export function convertAbsoluteCoordinates(
  absoluteCoordinate: number,
  absoluteStartCoordinate: number,
  absoluteEndCoordinate: number,
  relativeStartCoordinate: number,
  relativeEndCoordinate: number
) {
  return (
    ((absoluteCoordinate - absoluteStartCoordinate) *
      (relativeEndCoordinate - relativeStartCoordinate)) /
      (absoluteEndCoordinate - absoluteStartCoordinate) +
    relativeStartCoordinate
  )
}
