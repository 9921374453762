import { Navigation, Pagination, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import { GalleryBlock, ImageType } from '../../@types/statamic'
import Picture from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import ArrowIcon from '@/src/components/icons/ArrowIcon'
import Container from '@/src/components/layout/Container'
import { useBetterUseId } from '@/src/hooks/betterUseId'

export function getImage({ images }: GalleryBlock) {
  return images[0]
}

export default function Component({
  images,
  display_type,
  optional_title,
}: GalleryBlock) {
  const paginationId = `pagination-${useBetterUseId()}`
  const prevElementId = `prev-el-${useBetterUseId()}`
  const nextElementId = `next-el-${useBetterUseId()}`

  const gridImage = (image: ImageType) => (
    <div className="relative size-full">
      <Picture
        {...image}
        fill
        sizes="(min-width: 768px) 25vw, 90vw"
        className="rounded-xl object-cover"
      />
    </div>
  )

  return (
    <div className="md:pb-12 lg:pb-16">
      <Container fullPageOnMobile>
        {optional_title && (
          <Title style={2} className="mb-8 ml-7 md:mb-10 md:ml-0">
            {optional_title}
          </Title>
        )}
        {(display_type.value === 'swiper' || images.length !== 5) && (
          <div className="relative pb-14">
            <Swiper
              navigation={{
                prevEl: `#${prevElementId}`,
                nextEl: `#${nextElementId}`,
                hiddenClass: '!hidden',
                disabledClass: '!hidden',
              }}
              pagination={{
                el: `#${paginationId}`,
                bulletClass:
                  'h-3 w-3 inline-block rounded-full bg-blue-100 mx-1',
                clickable: true,
              }}
              modules={[Navigation, Pagination, A11y]}
              a11y={{
                prevSlideMessage: 'Vorheriges Bild',
                nextSlideMessage: 'Nächstes Bild',
                paginationBulletMessage: 'Gehe zu Bild {{index}}',
                lastSlideMessage: 'Dies ist das letzte Bild',
                firstSlideMessage: 'Dies ist das erste Bild',
              }}
              slidesPerView={1.2}
              spaceBetween={20}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
              }}
              loop
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="aspect-h-3 aspect-w-4 relative w-full">
                    <Picture
                      {...image}
                      sizes="(min-width: 768px) 50vw, 80vw"
                      fill
                      className="rounded-xl object-cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <button
              id={prevElementId}
              type="button"
              className="group absolute -left-6 right-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 lg:flex"
            >
              <ArrowIcon className="size-5 rotate-180 text-purple-700 group-hover:text-white" />
            </button>
            <button
              id={nextElementId}
              type="button"
              className="group absolute -right-6 left-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 lg:flex"
            >
              <ArrowIcon className="size-5 text-purple-700 group-hover:text-white" />
            </button>
            <div
              id={paginationId}
              className="absolute inset-x-0 bottom-0 text-center"
            />
          </div>
        )}
        {display_type.value === 'static' && images.length === 5 && (
          <div className="relative grid h-[720px] grid-cols-2 grid-rows-4 gap-3 lg:grid-cols-6 lg:grid-rows-3 lg:gap-5">
            <svg
              aria-hidden="true"
              className="absolute bottom-[-10%] right-[-20%] w-[70%] lg:bottom-[-15%] lg:right-[-5%] lg:w-1/2"
              viewBox="0 0 445 439"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
                fill="url(#paint0_linear_1896_11704)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1896_11704"
                  x1="143.512"
                  y1="44.9975"
                  x2="342.03"
                  y2="432.719"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D6DCFF" />
                  <stop offset="1" stopColor="#EEF1FF" />
                </linearGradient>
              </defs>
            </svg>
            <div className="col-span-1 lg:col-span-2">
              {gridImage(images[0])}
            </div>
            <div className="col-span-1 lg:col-span-2 lg:row-start-2">
              {gridImage(images[1])}
            </div>
            <div className="col-span-2 row-span-2 lg:col-span-4 lg:row-span-2">
              {gridImage(images[2])}
            </div>
            <div className="col-span-1 lg:col-span-3">
              {gridImage(images[3])}
            </div>
            <div className="col-span-1 lg:col-span-3">
              {gridImage(images[4])}
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}
