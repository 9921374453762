import Image from 'next/image'
import Link from 'next/link'
import Phone from '../../../public/img/phone.png'
import Title from '../../components/elements/Title'
import WhatsappIcon from '../../components/form/WhatsappIcon'
import Caret from '../../components/icons/Caret'
import Container from '../../components/layout/Container'
import useGradientId from '../../hooks/useGradientId'
import { swissFormat } from '../../lib/phone'
import whatsapp from '../../lib/whatsapp'
import Button from '@/src/components/form/Button'

export function getTitle({ title }: ContactWhatsappBlock) {
  return title
}

export type ContactWhatsappBlock = {
  type: string
  title: string
  phone: string
  whatsapp_text: string
}

export default function Component({
  title,
  phone,
  whatsapp_text,
}: ContactWhatsappBlock) {
  const [gradient1Id, gradient1Reference] = useGradientId()
  const [gradient2Id, gradient2Reference] = useGradientId()
  const [gradient3Id, gradient3Reference] = useGradientId()
  const [gradient4Id, gradient4Reference] = useGradientId()

  return (
    <div>
      <Container>
        <div className="flex flex-wrap">
          <div
            aria-hidden="true"
            className="relative w-full pb-12 pt-10 lg:w-1/2 lg:pb-24 lg:pt-12"
          >
            <svg
              className="absolute -left-16 w-2/5 lg:w-2/3"
              viewBox="0 0 595 618"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M414.253 603.654C414.253 603.654 575.392 565.852 592.674 329.143C609.956 92.4333 457.484 45.0425 405.085 17.1536C352.686 -10.7352 190.753 -11.4747 79.1119 88.409C-32.5287 188.293 -47.3861 544.641 178.863 599.287C341.392 638.543 414.253 603.654 414.253 603.654Z"
                fill={gradient1Reference}
              />
              <defs>
                <linearGradient
                  id={gradient1Id}
                  x1="526.711"
                  y1="429.999"
                  x2="11.8164"
                  y2="138.99"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D6DCFF" />
                  <stop offset="1" stopColor="#EEF1FF" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="absolute bottom-[-20%] left-1/2 w-[30%] lg:left-[60%]"
              viewBox="0 0 215 205"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M197.797 153.708C236.948 79.0565 207.845 12.0441 125.037 1.28035C42.2292 -9.48338 -22.4767 48.7074 7.42199 134.879C37.3206 221.051 158.645 228.359 197.797 153.708Z"
                fill={gradient2Reference}
              />
              <defs>
                <linearGradient
                  id={gradient2Id}
                  x1="-14.6917"
                  y1="9.225"
                  x2="224.928"
                  y2="195.833"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBFBFF" />
                  <stop offset="1" stopColor="#DAE0FF" />
                </linearGradient>
              </defs>
            </svg>

            <svg
              className="absolute left-1/2 top-0 w-[10%] lg:left-[60%]"
              viewBox="0 0 95 112"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M45.9859 0.787987C45.9859 0.787987 14.1442 -7.14882 3.04604 29.1181C-10.7522 74.2105 32.8941 55.9329 33.6295 88.8924C34.3649 121.852 72.4211 117.928 88.7483 80.1153C105.076 42.3034 83.8662 9.02785 45.9859 0.787987Z"
                fill={gradient3Reference}
              />
              <defs>
                <linearGradient
                  id={gradient3Id}
                  x1="90.0833"
                  y1="118.856"
                  x2="-8.64425"
                  y2="6.51541"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FBFBFF" />
                  <stop offset="1" stopColor="#DAE0FF" />
                </linearGradient>
              </defs>
            </svg>
            <div className="relative -mt-5 w-2/5 md:w-1/3 lg:ml-24 lg:w-1/2">
              <svg
                className="w-full"
                viewBox="0 0 381 369"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M359.084 98.1795C307.482 5.01621 154.506 -33.8805 69.0933 35.0389C-16.3192 103.958 -10.4674 215.267 22.2786 277.186C55.0246 339.105 106.204 390.566 238.797 359.854C371.389 329.143 410.686 191.343 359.084 98.1795Z"
                  fill={gradient4Reference}
                />
                <defs>
                  <linearGradient
                    id={gradient4Id}
                    x1="-76.9184"
                    y1="-65.3575"
                    x2="410.284"
                    y2="365.544"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#1D41FD" />
                    <stop offset="0.403539" stopColor="#501DEC" />
                    <stop offset="1" stopColor="#8D41ED" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="absolute inset-0 flex items-center px-4 sm:px-8 md:px-12">
                <div className="w-full">
                  <Image
                    src={Phone}
                    alt="Telefon Illustration"
                    layout="responsive"
                    unoptimized
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full md:ml-auto md:w-2/3 lg:w-1/2 lg:pt-12">
            <Title style={2}>{title}</Title>

            <div className="relative mt-8 flex flex-col items-end md:mt-10">
              <p className="w-full rounded-lg bg-gradient-100 p-8 md:text-xl">
                {whatsapp_text}
              </p>

              <Caret className="-mt-px mr-12 w-9 rotate-180 text-blue-100 lg:mr-24 " />
            </div>

            <div className="mt-6 flex flex-col items-end lg:mt-10">
              <Link
                href={whatsapp(whatsapp_text, phone)}
                passHref
                legacyBehavior
              >
                <Button
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="whatsapp"
                  size="none"
                  className="inline-flex items-center px-5 py-[11px] leading-[30px] md:px-10 md:py-5"
                >
                  <WhatsappIcon className="mr-2 size-5 lg:size-8" />
                  <span className="pt-1">Per Whatsapp senden</span>
                </Button>
              </Link>
              <p className="mt-6 text-sm md:text-base lg:mt-8">
                Lieber klassisch per Telefon?
                <br />
                Kein Problem! Du erreichst uns unter
                <br />
                <a
                  href={`tel:+${phone}`}
                  title="Anrufen"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {swissFormat(phone)}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
