import classNames from 'classnames'
import {
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useId,
} from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import { AllOptional } from '@/src/@types/util'
import ErrorMessage from '@/src/components/form/ErrorMessage'

function Label(
  {
    label,
    children,
    className = '',
    error,
    style = 'white',
    id,
    hideErrorMessage = false,
    ...props
  }: {
    label: string
    onInput?: FormEventHandler<any>
    hideErrorMessage?: boolean
    id?: string
    children: (
      props: {
        className: string
        id: string
        placeholder: string
        ref: ForwardedRef<any>
        hasError: boolean
        style: 'inverted' | 'white'
      } & Omit<AllOptional<UseFormRegisterReturn>, 'ref'>
    ) => ReactNode
    className?: string
    error?: string
    style?: 'inverted' | 'white'
  } & AllOptional<UseFormRegisterReturn>,
  ref: ForwardedRef<any>
) {
  const generatedId = `${useId()}-input-field`
  id = id || generatedId

  return (
    <div className={`${className} relative w-full`}>
      <label
        htmlFor={id}
        className={classNames([
          'text-sm',
          {
            'text-blue-900': style === 'white' || style === 'inverted',
            'text-pink-500 peer-placeholder-shown:text-pink-500 peer-focus:text-pink-500':
              error !== undefined,
          },
        ])}
      >
        {label}
      </label>
      {children({
        ...props,
        ref,
        id,
        placeholder: ' ',
        className: `py-2.5 mt-[5px]`,
        hasError: !!error,
        style,
      })}

      {!hideErrorMessage && error && <ErrorMessage error={error} />}
    </div>
  )
}

export default forwardRef(Label)
