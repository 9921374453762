import { HoverTooltip } from '../elements/Tooltip'
import TooltipInfoIcon from '../icons/TooltipInfoIcon'
import { BardTable as BardTableType } from '@/src/@types/statamic'

export default function BardTable({ table }: BardTableType) {
  return (
    <div className="grid gap-x-7 md:grid-cols-2">
      {table.map((table_entry, index) => (
        <div
          key={index}
          className="flex justify-between rounded-lg p-5 odd:bg-gradient-100 md:bg-gradient-100 md:p-6 md:fourth:bg-white md:fourth:bg-none md:before-fourth:bg-white md:before-fourth:bg-none"
        >
          <p className="flex-1 text-sm font-bold md:text-xl">
            {table_entry.text_left}
            {table_entry.tooltip && (
              <HoverTooltip text={table_entry.tooltip}>
                <TooltipInfoIcon className="ml-1 mt-[3px] size-4 -translate-y-0.5 cursor-pointer text-purple-700 md:mt-1.5 md:size-5 md:-translate-y-1" />
              </HoverTooltip>
            )}
          </p>
          {table_entry.text_right && (
            <p className="text-right text-sm md:text-lg">
              {table_entry.text_right}
            </p>
          )}
        </div>
      ))}
    </div>
  )
}
