import JsonLd from '../elements/JsonLd'
import Marker from '../elements/Marker'
import SquareMap from '../elements/SquareMap'
import { PageInfo } from '@/src/@types/models'
import GoogleRating, {
  GoogleRatingType,
} from '@/src/components/elements/GoogleRating'

export default function BardGoogleRating({
  google_rating,
  pageInfo,
  map_only,
}: {
  google_rating: GoogleRatingType
  pageInfo: PageInfo
  map_only: boolean
}) {
  if (!google_rating) {
    return <></>
  }

  return (
    <>
      {google_rating.latitude &&
        google_rating.latitude > 0 &&
        google_rating.longitude &&
        google_rating.longitude > 0 && (
          <SquareMap
            markers={[[google_rating.longitude, google_rating.latitude]]}
          >
            <Marker
              position={{
                lat: google_rating.latitude,
                lng: google_rating.longitude,
              }}
            >
              {`<h4 class="font-bold text-sm">BLINK AG</h4>${google_rating.street}</br>${google_rating.zip} ${google_rating.place}`}
            </Marker>
          </SquareMap>
        )}
      <GoogleRating {...google_rating} mapOnly={map_only} />
      {pageInfo && (
        <JsonLd
          data={{
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Blink AG',
            description: `BLINK Fahrschule - ${google_rating.place}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: google_rating.street,
              addressLocality: google_rating.place,
              postalCode: google_rating.zip,
            },
            aggregateRating:
              google_rating.rating && google_rating.rating > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: google_rating.rating,
                    ratingCount: google_rating.rating_count,
                    bestRating: 5,
                    worstRating: 1,
                  }
                : undefined,
            geo:
              google_rating.latitude && google_rating.longitude
                ? {
                    '@type': 'GeoCoordinates',
                    latitude: google_rating.latitude,
                    longitude: google_rating.longitude,
                  }
                : undefined,
            // priceRange: courses[0] ? `${courses[0].price} CHF` : undefined,
            // hasMap: `https://g.page/${gmbShortname}`,
            openingHours: ['Mo,Tu,We,Th,Fr 09:00-17:00', 'Sa 09:00-12:00'],
            telephone: google_rating.phone,
            logo: pageInfo.settings.logo?.permalink,
            url: pageInfo.fullUrl,
            image: pageInfo.socialImage
              ? [pageInfo.socialImage?.permalink]
              : undefined,
          }}
        />
      )}
    </>
  )
}
