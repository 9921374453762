import { LockSimpleOpen } from '@phosphor-icons/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Container from '../../components/layout/Container'
import MyblinkImage from '@/public/img/myBLINK_supportId.png'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import FloatingLabel from '@/src/components/form/FloatingLabel'
import Input from '@/src/components/form/Input'
import AppStoreIcon from '@/src/components/icons/AppStoreIcon'
import GooglePlayIcon from '@/src/components/icons/GooglePlayIcon'
import LoadingSpinner from '@/src/components/icons/LoadingSpinner'
import useLaravelForm from '@/src/hooks/useLaravelForm'

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/todo`

export default function Component() {
  const router = useRouter()
  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)

  const { handleSubmit, register, setValue } = useLaravelForm()

  useEffect(() => {
    if (router.isReady && !router.query.code) {
      // TODO: REMOVE WHEN PROD
      // router.push('/404')
    }
    if (router.isReady) {
      setValue('code', router.query.code as string)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  return (
    <Container>
      <Title style="bigH1" className="max-w-[600px]">
        Ein Geschenk der [Mobiliar Versicherung]
      </Title>
      <div className="grid gap-4 md:grid-cols-3">
        <div className="max-w-[600px] md:col-span-2">
          <p className="mt-6 md:text-lg">
            Bist du bereit für das Abenteuer «Führerschein»? Die Mobiliar
            Versicherung schenkt dir freien Zugang zur BLINK Theorie App mit den
            offiziellen Prüfungsfragen im Wert von 19 CHF. So kommst du safe
            durch die Theorieprüfung!
          </p>
          {!submitted && (
            <>
              <Title style={4} className="mt-8">
                [Gutschein-Code] einlösen: So funktionierts!
              </Title>
              <div className="prose max-w-none prose-ol:pl-4 marker:prose-ol:text-blue-900">
                <ol>
                  <li className="mt-4">
                    BLINK Theorie App herunterladen.
                    <div className="my-0 mt-1 flex space-x-5">
                      <Link
                        href="https://apps.apple.com/ch/app/blink-theorie-auto-motorrad/id1510360045"
                        target="_blank"
                      >
                        <AppStoreIcon className="h-10 w-[120px]" />
                      </Link>
                      <Link
                        href="https://play.google.com/store/apps/details?id=ch.blink.theory"
                        target="_blank"
                      >
                        <GooglePlayIcon className="h-10 w-[120px]" />
                      </Link>
                    </div>
                  </li>
                  <li>
                    App öffnen und unter Einstellungen die Support ID kopieren.
                  </li>
                  <li>
                    Support ID hier einfügen und auf «Freischalten» klicken.
                    <form
                      onSubmit={handleSubmit(
                        () => {
                          setSubmitted(true)
                          setSending(false)
                        },
                        (data) => {
                          setSending(true)
                          return data
                        },
                        () => {
                          setSending(false)
                          // TODO: REMOVE WHEN PROD
                          setSubmitted(true)
                        }
                      )}
                      method="POST"
                      action={requestUrl}
                    >
                      <div className="flex items-center space-x-6">
                        <FloatingLabel
                          label="Deine Support ID"
                          {...register('support_id')}
                          className="!w-[200px]"
                        >
                          {(props) => <Input {...props}></Input>}
                        </FloatingLabel>
                        <Button type="submit" className="flex items-center">
                          {sending && (
                            <LoadingSpinner className="mr-1 size-5 text-white" />
                          )}
                          {!sending && (
                            <LockSimpleOpen size={20} className="mr-1" />
                          )}
                          Freischalten
                        </Button>
                      </div>
                    </form>
                  </li>
                  <li>
                    Die BLINK Theorie App schliessen und neu öffnen. Jetzt
                    sollte die App freigeschaltet sein.
                  </li>
                </ol>
              </div>
            </>
          )}
          {submitted && (
            <div className="mt-8">
              <Title style={4} className="mt-8">
                [Yay], es hat geklappt! 🥳
              </Title>
              <p className="mt-2">
                Jetzt musst du nur noch die App neu starten und los geht&apos;s!
                Die Mobiliar Versicherung wünscht dir viel Spass und Erfolg beim
                Lernen. <br />
                <br />
                Hast du Fragen? Melde dich bei uns per WhatsApp oder E-Mail -
                wir sind gern für dich da! ... und natürlich würden wir uns sehr
                über eine gute Bewertung von dir im App Store freuen. 😇
              </p>
            </div>
          )}
        </div>
        {!submitted && (
          <div className="mt-8 w-[300px] rounded-lg border-4 border-purple-700 p-2">
            <Image
              src={MyblinkImage}
              alt="myBLINK support ID"
              layout="responsive"
              unoptimized
            />
          </div>
        )}
      </div>
    </Container>
  )
}
