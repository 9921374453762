import { CaretDown } from '@phosphor-icons/react'
import {
  DropdownMenuCheckboxItem,
  DropdownMenuItemIndicator,
} from '@radix-ui/react-dropdown-menu'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/src/components/core/Dropdown'

export type SelectOption = {
  name: string
  count?: number
}

// Rather opinionated, might restructure once we use it in more places
export default function MultiSelect({
  label,
  selected,
  setSelected,
  options,
}: {
  label: string
  selected: SelectOption[]
  setSelected: (selected: SelectOption[]) => void
  options: SelectOption[]
}) {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        disabled={options.length === 0}
        className="group flex h-[46px] items-center justify-between rounded border border-blue-125 bg-blue-25 p-3 text-left disabled:border-transparent disabled:bg-blue-50"
      >
        {selected.length === 0 && (
          <div>
            <span className="block text-sm">{label}</span>
          </div>
        )}
        {selected.length === options.length && (
          <div>
            <span className="block text-2xs text-gray-600">{label}</span>
            <span className="block text-sm">Alle</span>
          </div>
        )}
        {selected.length !== 0 && selected.length !== options.length && (
          <div className="min-w-0">
            <span className="block text-2xs text-gray-600">{label}</span>
            <span className="block truncate text-sm">
              {selected.length === 1 && selected[0].name}
              {selected.length !== 1 && `${selected.length} ausgewählt`}
            </span>
          </div>
        )}
        <CaretDown
          size={20}
          weight="bold"
          className="shrink-0 text-purple-500 group-disabled:text-purple-400"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        color="light"
        align="end"
        sideOffset={5}
        className="w-[--radix-dropdown-menu-trigger-width] min-w-[250px] rounded @xl:w-auto"
      >
        <DropdownMenuGroup>
          {options.map((option) => (
            <DropdownMenuCheckboxItem
              key={option.name}
              checked={selected.some((s) => s.name === option.name)}
              onCheckedChange={(checked) => {
                if (checked) {
                  setSelected([...selected, option])
                } else {
                  setSelected(selected.filter((s) => s.name !== option.name))
                }
              }}
              onSelect={(e) => e.preventDefault()}
              className="group flex cursor-pointer items-center justify-between rounded-sm px-2 py-3 data-[highlighted]:bg-blue-50"
            >
              <div className="flex items-center">
                <div className="box-border flex size-5 shrink-0 items-center justify-center rounded-sm border-2 border-purple-600 text-purple-600 group-data-[state=checked]:bg-purple-600 group-data-[state=checked]:text-white">
                  <DropdownMenuItemIndicator
                    forceMount
                    className="transition-opacity duration-150 data-[state=checked]:opacity-100 data-[state=unchecked]:opacity-0"
                  >
                    <svg
                      width="10"
                      height="9"
                      viewBox="0 0 10 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.63388 1.11612C10.122 1.60427 10.122 2.39573 9.63388 2.88388L4.63388 7.88388C4.14573 8.37204 3.35427 8.37204 2.86612 7.88388L0.366117 5.38388C-0.122039 4.89573 -0.122039 4.10427 0.366117 3.61612C0.854272 3.12796 1.64573 3.12796 2.13388 3.61612L3.75 5.23223L7.86612 1.11612C8.35427 0.627961 9.14573 0.627961 9.63388 1.11612Z"
                        fill="currentColor"
                      />
                    </svg>
                  </DropdownMenuItemIndicator>
                </div>
                <span className="ml-3 text-sm">{option.name}</span>
              </div>
              {option.count && (
                <span className="ml-4 text-sm text-gray-600">
                  {option.count}
                </span>
              )}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
