import copy from 'copy-to-clipboard'
import Button from '../form/Button'
import { BardCopyButton as BardCopyButtonType } from '@/src/@types/statamic'

export default function BardCopyButton({
  text_to_copy,
  button_text,
}: BardCopyButtonType) {
  return (
    <div>
      <Button onClick={() => copy(text_to_copy)}>{button_text}</Button>
    </div>
  )
}
