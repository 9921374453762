import { Dialog } from '@headlessui/react'
import { Fragment, ReactNode, useState } from 'react'
import PlayIcon from '../icons/PlayIcon'
import GradientText from './GradientText'

export default function Video({
  className = '',
  url,
  children,
  title,
  description,
}: {
  className?: string
  url: string
  title?: string
  description?: string
  children: ReactNode
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <div className={`${className} relative`}>
        {children}

        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="absolute inset-0 flex w-full items-center justify-center rounded-lg"
        >
          <span className="size-16 animate-splash rounded-full bg-pink-500">
            <PlayIcon className="size-16" />
          </span>
          <span className="sr-only">Video anzeigen</span>
        </button>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative mx-auto max-w-full rounded bg-white">
            {title && (
              <Dialog.Title className="sr-only">
                <GradientText highlight={false}>{title}</GradientText>
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description
                className="sr-only"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            <div className="aspect-h-9 aspect-w-16 h-full w-[80rem] max-w-full">
              <iframe
                frameBorder="0"
                allowFullScreen
                src={`${url}?autoplay=1&origin=https://blinkdrive.ch`}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}
