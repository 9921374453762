import { X } from '@phosphor-icons/react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { GlobalCatcher } from '@/src/@types/statamic'
import Button from '@/src/components/form/Button'
import IconButton from '@/src/components/form/IconButton'
import Container from '@/src/components/layout/Container'
import { replacePink } from '@/src/lib/replacePink'
import { ensureTrailingSlash } from '@/src/lib/str'

export default function Catcher({
  title,
  link,
  button_text,
  is_active,
  className = '-mb-16',
}: GlobalCatcher['globalCatcher'] & { className?: string }) {
  const router = useRouter()
  const [closed, setClosed] = useState(false)
  const closeCatcher = () => {
    setClosed(true)
    window.localStorage.setItem('catcherClosedAt', new Date().toISOString())
  }

  useEffect(() => {
    const catcherClosedAt = window.localStorage.getItem('catcherClosedAt')

    if (!catcherClosedAt) {
      return
    }

    if (dayjs(catcherClosedAt).isBefore(dayjs().subtract(5, 'minute'))) {
      return
    }

    setClosed(true)
  }, [setClosed])

  if (!title) {
    return null
  }

  if (closed) {
    return null
  }

  if (!is_active) {
    return null
  }

  const pathname = Array.isArray(router.query['uri'])
    ? router.query['uri'][0]
    : router.query['uri']

  if (
    pathname &&
    link &&
    ensureTrailingSlash(link) === '/' + ensureTrailingSlash(pathname)
  ) {
    return null
  }

  return (
    <Container fullPageOnMobile>
      <div
        className={classNames(
          'relative z-50 flex flex-col items-center justify-between bg-gradient-600 p-7 md:flex-row md:rounded-[10px]',
          className
        )}
      >
        <p
          className="my-5 grow text-center text-lg font-extrabold text-white md:my-0 md:text-left md:text-2xl"
          dangerouslySetInnerHTML={{
            __html: replacePink(title),
          }}
        />
        <div className="flex shrink-0 items-center">
          <Button as={Link} href={link} style="white" className="md:ml-[30px]">
            {button_text}
          </Button>
          <IconButton
            style="transparent-white"
            type="button"
            className="absolute right-2.5 top-2.5 ml-5 flex items-center justify-center md:static"
            onClick={closeCatcher}
          >
            <X
              className="size-7"
              color="currentColor"
              weight="bold"
              alt="Schliessen"
            />
          </IconButton>
        </div>
      </div>
    </Container>
  )
}
