'use client'

import { forwardRef, useEffect, useState } from 'react'
import { Input, InputProps } from '@/src/components/core/Input'

export type Voucher = {
  original_price: number
  new_price: number
  value: number
}

export type VoucherInputProps = InputProps & {
  validateText: string
  validate: () => Promise<Voucher | false>
  voucher: Voucher | false | null
  onVoucherChange: (voucher: Voucher | false | null) => void
}

const VoucherInput = forwardRef<HTMLInputElement, VoucherInputProps>(
  (
    { validateText, validate, voucher, onVoucherChange, value, ...props },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
      onVoucherChange(null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
      <div className="relative">
        <Input
          ref={ref}
          disabled={isLoading || !!voucher}
          error={voucher === false ? 'Ungültiger Code' : undefined}
          {...props}
        />
        {voucher === null && (
          <button
            type="button"
            className="absolute right-3 top-1/2 -translate-y-1/2 text-sm font-bold text-purple-600 hover:text-purple-600"
            onClick={async () => {
              setIsLoading(true)
              const voucher = await validate()
              onVoucherChange(voucher)
              setIsLoading(false)
            }}
          >
            {validateText}
          </button>
        )}
        {!!voucher && (
          <button
            type="button"
            className="mt-1 text-sm font-bold text-purple-600 hover:text-purple-600"
            onClick={() => {
              onVoucherChange(null)
            }}
          >
            Gutschein entfernen
          </button>
        )}
      </div>
    )
  }
)
// @ts-ignore
VoucherInput.displayName = 'core-voucher-input'

export { VoucherInput }
