import {
  CalendarBlank,
  Car,
  CaretLeft,
  Clock,
  Gear,
  MapPin,
  X,
} from '@phosphor-icons/react'
import { CheckCircle } from '@phosphor-icons/react/dist/ssr'
import axios from 'axios'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'
import { Controller } from 'react-hook-form'
import useSWR from 'swr'
import { MyBLINK } from '@/src/@types/blinkadmin'
import {
  adminDeleteFile,
  adminGetFile,
  adminPostFile,
  fullWebsiteUrl,
} from '@/src/BlinkAdminApiClient'
import {
  BookingState,
  useBookingContext,
} from '@/src/components/booking/BookingContext'
import Button from '@/src/components/core/Button'
import { ConfirmCheckbox } from '@/src/components/core/ConfirmCheckbox'
import { DateInput } from '@/src/components/core/DateInput'
import ErrorMessage from '@/src/components/core/ErrorMessage'
import FileUpload from '@/src/components/core/fileUpload/FileUpload'
import ImageUploadMask from '@/src/components/core/fileUpload/masks/ImageUploadMask'
import { UploadContextProvider } from '@/src/components/core/fileUpload/UploadContext'
import { Input } from '@/src/components/core/Input'
import { Voucher, VoucherInput } from '@/src/components/core/VoucherInput'
import Map from '@/src/components/elements/Map'
import Marker from '@/src/components/elements/Marker'
import Picture from '@/src/components/elements/Picture'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import { formatGerman } from '@/src/lib/date'

export default function Summary() {
  const {
    location,
    address,
    gearbox,
    instructors,
    slot,
    config,
    goToBooking,
    setVoucherValue,
    setVoucher,
  } = useBookingContext()
  const [booked, setBooked] = useState(false)
  const [bookedLessonResponse, setBookedLessonResponse] = useState<{
    success: 'true'
    lesson: string
    student: string
    lessonLink: string
    paymentLink: string
  } | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [voucherOpen, setVoucherOpen] = useState(false)

  let total = slot!.price

  if (config.voucher) {
    total = config.voucher.new_price
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useLaravelForm()

  const { data: locationInstructors } = useSWR<MyBLINK.BookableUser[]>(
    location && `booking/locations/${location.id}/users?gearbox=${gearbox}`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(
            `booking/locations/${location!.id}/users?gearbox=${gearbox}`
          )
        )
      ).data
  )

  useEffect(() => {
    if (isDirty) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'trial_booking_personal_data_step_started',
          params: {
            origin: 'Website',
            site: window.location.href,
            location: location?.name,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  const validateVoucher = async () => {
    let voucher: Voucher | false
    try {
      voucher = (
        await axios.get<Voucher>(
          fullWebsiteUrl(
            `coupons/for-trial-booking/validity?code=${config.voucherValue}`
          )
        )
      ).data
    } catch (_) {
      voucher = false
    }
    return voucher
  }

  if (config.state < BookingState.SlotSelected || !slot) {
    return null
  }

  const instructor = instructors.find((i) => i.id === slot.user)!

  let car: string
  if (gearbox === 'automatic') {
    car = instructor.preferredAutomaticVehicle?.name ?? 'Automat'
  } else {
    car = instructor.preferredManualVehicle?.name ?? 'Geschaltet'
  }

  const totalInstructors = locationInstructors?.length ?? 2
  const explicitySelectedUser = instructors.length === 1 && totalInstructors > 1

  if (booked) {
    return (
      <>
        <div className="flex space-x-3">
          <CheckCircle
            size={32}
            weight="fill"
            className="shrink-0 text-green-600"
          />
          <h1 className="text-2xl font-extrabold @6xl:text-4xl">
            Deine Buchung war erfolgreich!
          </h1>
        </div>
        <p className="mt-4">
          Gleich gibt es von uns noch eine Bestätigung per E-Mail. Falls nichts
          ankommt, schau bitte kurz im Spamordner nach. Und wenn da auch nichts
          ist, dann melde dich bitte bei uns.
        </p>
        <div className="mt-8 flex items-center space-x-4">
          {total > 0 && bookedLessonResponse?.paymentLink && (
            <Button as={Link} href={bookedLessonResponse.paymentLink}>
              Rechnung ansehen
            </Button>
          )}
          <Button
            as={Link}
            version={total > 0 ? 'purple-ghost' : 'purple'}
            href={bookedLessonResponse?.lessonLink}
          >
            Termin ansehen
          </Button>
        </div>
      </>
    )
  }

  return (
    <UploadContextProvider>
      {({ isUploading }) => (
        <>
          <Button
            type="button"
            version="purple-transparent"
            size="none"
            icon={<CaretLeft weight="bold" />}
            onClick={goToBooking}
          >
            Zurück
          </Button>
          <h1 className="mt-6 text-2xl font-extrabold @6xl:text-4xl">
            Zusammenfassung
          </h1>
          <p className="mt-2">
            Alles richtig? Bitte kontrolliere den Termin und ergänze deine
            Kontaktdaten.
          </p>
          <p className="mt-8 text-lg font-extrabold lg:text-xl">Termin</p>
          <div className="mt-4 space-y-6 @6xl:grid @6xl:grid-cols-2 @6xl:gap-x-8 @6xl:space-y-0">
            <div className="divide-y divide-blue-100">
              <p className="flex items-start space-x-2.5 py-3">
                <CalendarBlank
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-500"
                />
                <span>
                  {formatGerman(new Date(slot.start), 'cccc, dd. MMMM yyyy')}
                </span>
              </p>
              <p className="flex items-start space-x-2.5 py-3">
                <Clock
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-500"
                />
                <span>
                  {formatGerman(new Date(slot.start), 'HH:mm')}–
                  {formatGerman(new Date(slot.end), 'HH:mm')} Uhr (45 min)
                </span>
              </p>
              <p className="flex items-start space-x-2.5 py-3">
                <Car
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-500"
                />
                <span>Fahrlektion Kat. B</span>
              </p>
              <div className="flex items-start space-x-2.5 py-3">
                <div className="relative mt-0.5 size-5 shrink-0">
                  <Picture
                    permalink={instructor.photo}
                    alt={instructor.name}
                    fill
                    sizes="20px"
                    className="rounded-full object-cover object-top"
                  />
                </div>
                <p>{instructor.name}</p>
              </div>
              <p className="flex items-start space-x-2.5 py-3">
                <MapPin
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-500"
                />
                <span>{address.name}</span>
              </p>
              <p className="flex items-center space-x-2.5 py-3">
                <Gear
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-500"
                />
                <span>{car}</span>
              </p>
            </div>
            {address.longitude && address.latitude && (
              <div className="overflow-hidden rounded">
                <Map
                  markers={[[address.longitude, address.latitude]]}
                  interactive={false}
                  className="h-[200px] w-full @6xl:h-full"
                >
                  <Marker
                    position={{
                      lat: address.latitude,
                      lng: address.longitude,
                    }}
                    icon="lesson"
                    size="huge"
                  />
                </Map>
              </div>
            )}
          </div>
          <p className="mt-10 text-lg font-extrabold lg:text-xl">
            Kontaktdaten
          </p>
          <p className="mt-2">
            Wir benötigen nur noch deine Kontaktdaten und ein Foto deines
            Lernfahrausweises.
          </p>
          <form
            onSubmit={handleSubmit(
              (response) => {
                setIsLoading(false)
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'trial_booking_sent',
                    params: {
                      origin: 'Website',
                      site: window.location.href,
                      location: location.name,
                      value: 800,
                    },
                  },
                })
                setBooked(true)
                setBookedLessonResponse(response.data)
              },
              (data) => {
                setIsLoading(true)
                return data
              },
              () => setIsLoading(false)
            )}
            method="POST"
            action={fullWebsiteUrl(`booking/slots/${slot.id}`)}
          >
            <div className="mt-4 space-y-3 @6xl:grid @6xl:grid-cols-2 @6xl:gap-x-5 @6xl:gap-y-3 @6xl:space-y-0">
              <Input
                label="Vorname"
                required
                kind="firstName"
                {...register('first_name')}
              />
              <Input
                label="Nachname"
                required
                kind="lastName"
                {...register('last_name')}
              />
              <DateInput
                label="Geburtsdatum (DD.MM.JJJJ)"
                required
                {...register('birthday')}
              />
            </div>
            <div className="mt-5 space-y-3 @6xl:grid @6xl:grid-cols-2 @6xl:gap-x-5 @6xl:gap-y-3 @6xl:space-y-0">
              <Input
                label="Strasse + Nr."
                kind="street"
                required
                {...register('street')}
              />
              <div className="grid grid-cols-1 gap-x-5 gap-y-3 md:grid-cols-3">
                <Input
                  label="PLZ"
                  kind="zip"
                  required
                  {...register('postal_code')}
                />
                <div className="md:col-span-2">
                  <Input
                    label="Ort"
                    kind="place"
                    required
                    {...register('city')}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 space-y-3 @6xl:grid @6xl:grid-cols-2 @6xl:gap-x-5 @6xl:gap-y-3 @6xl:space-y-0">
              <Input
                label="Email"
                kind="email"
                required
                {...register('email')}
              />
              <Input
                label="Telefonnummer"
                required
                kind="tel"
                {...register('phone')}
              />
            </div>
            <div className="mt-6 lg:grid lg:grid-cols-2">
              <div>
                <p className="font-bold text-gray-800">Lernfahrausweis</p>
                <p className="mt-1">
                  Um den administrativen Aufwand zu verringern, kannst du hier
                  ein Foto der Innenseite deines Lernfahrausweises hochladen.
                </p>
                <div className="mt-3">
                  <Controller
                    control={control}
                    name="lfa"
                    render={({ field: { value, onChange } }) => (
                      <FileUpload
                        value={value}
                        onChange={onChange}
                        adminGetFile={adminGetFile}
                        adminPostFile={adminPostFile}
                        adminDeleteFile={adminDeleteFile}
                      >
                        {(fileUploadChildrenProps) => (
                          <ImageUploadMask
                            {...fileUploadChildrenProps}
                            emptyText={() => 'Foto aufnehmen und hochladen'}
                            imageAlt="Lernfahrausweis Kat. B"
                          />
                        )}
                      </FileUpload>
                    )}
                  />
                  <ErrorMessage>{errors.lfa?.message as string}</ErrorMessage>
                  <div className="mt-4">
                    <ConfirmCheckbox name="accepted" required>
                      Ich bestätige, dass ich im Besitz eines gültigen
                      Lernfahrausweises bin.
                    </ConfirmCheckbox>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-10 text-lg font-extrabold lg:text-xl">Kosten</p>
            <div className="mt-4 @6xl:grid @6xl:grid-cols-2 @6xl:gap-x-8 @6xl:space-y-0">
              <div>
                <p className="flex items-center justify-between pb-3">
                  <span>Probelektion Kat. B (45 min)</span>
                  <span className="whitespace-nowrap">{slot.price}.00 CHF</span>
                </p>
                {config.voucher && (
                  <>
                    <p className="flex items-center justify-between pb-3">
                      <span className="flex items-center space-x-1">
                        <span>Abzug Gutschein: {config.voucherValue}</span>
                        <button
                          type="button"
                          onClick={() => {
                            setVoucher(null)
                            setVoucherValue('')
                            setVoucherOpen(false)
                          }}
                        >
                          <X
                            size={20}
                            weight="bold"
                            className="text-gray-600"
                          />
                        </button>
                      </span>
                      <span className="whitespace-nowrap">
                        {(
                          config.voucher.new_price -
                          config.voucher.original_price
                        ).toFixed(2)}{' '}
                        CHF
                      </span>
                    </p>
                    <input
                      type="hidden"
                      defaultValue={config.voucherValue}
                      {...register('coupon')}
                    />
                  </>
                )}
                <p className="flex items-center justify-between whitespace-nowrap border-t border-blue-100 py-4 font-bold">
                  <span>Total</span>
                  <span>{total.toFixed(2)} CHF</span>
                </p>
                {!voucherOpen && !config.voucher && (
                  <button
                    onClick={() => setVoucherOpen(true)}
                    className="text-sm font-bold text-purple-600 hover:text-purple-800"
                  >
                    Gutschein einlösen
                  </button>
                )}
                {voucherOpen && !config.voucher && (
                  <VoucherInput
                    label="Gutscheincode"
                    value={config.voucherValue}
                    onChange={(e) => setVoucherValue(e.target.value)}
                    validateText="Anwenden"
                    voucher={config.voucher}
                    onVoucherChange={setVoucher}
                    validate={validateVoucher}
                  />
                )}
                {total > 0 && (
                  <p className="mt-4 text-sm text-gray-600">
                    Die Kosten werden dir in Rechnung gestellt und können bequem
                    online mit Kreditkarte, Twint und anderen gängigen
                    Zahlungsmitteln bezahlt werden.
                  </p>
                )}
              </div>
            </div>
            <div className="mt-8">
              <ConfirmCheckbox required {...register('accepts_conditions')}>
                Ich akzeptiere die{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                  href="https://blinkdrive.ch/allgemeine-geschaeftsbedingungen/"
                >
                  allgemeinen Geschäftsbedingungen
                </a>
                . Die{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                  href="https://blinkdrive.ch/datenschutz/"
                >
                  Datenschutzerklärung
                </a>{' '}
                habe ich gelesen und verstanden.
              </ConfirmCheckbox>
              <input
                type="hidden"
                defaultValue={explicitySelectedUser ? 'true' : 'false'}
                {...register('explicitySelectedUser')}
              />
              <Button
                type="submit"
                disabled={isUploading}
                loading={isLoading}
                className="mt-10"
              >
                Verbindlich buchen
              </Button>
            </div>
          </form>
        </>
      )}
    </UploadContextProvider>
  )
}
