import { ForwardedRef, forwardRef } from 'react'
import Input, { InputProps } from './Input'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INPUT_TAG = 'input'

function EmailInput(
  props: Omit<InputProps<typeof INPUT_TAG>, 'autoComplete'>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return <Input {...props} ref={ref} autoComplete="email" type="email" />
}

export default forwardRef(EmailInput)
