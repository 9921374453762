import { BardTable as BardTableType, PricesBlock } from '../../@types/statamic'
import { LocationPrices } from '@/src/@types/blinkadmin'
import { get } from '@/src/BlinkAdminApiClient'
import BardTable from '@/src/components/bard/BardTable'
import Title from '@/src/components/elements/Title'
import Container from '@/src/components/layout/Container'

export async function getStaticProps({ title, single_location }: PricesBlock) {
  const locationPrices = await get<LocationPrices>(
    `/website/v2/locations/${single_location.value}/prices`
  )

  if (!locationPrices) {
    return {
      title,
      prices: null,
    }
  }

  const pricesTable: BardTableType['table'] = []

  const firstAidCourse = locationPrices.courses?.find(
    (course) => course.type === 'eNothelfer'
  )

  if (firstAidCourse) {
    const firstAidPrice = formatCoursePrice(
      firstAidCourse.min,
      firstAidCourse.max
    )
    pricesTable.push({
      type: 'table_entry',
      text_left: 'Nothelferkurs',
      text_right: firstAidPrice[0],
      tooltip:
        firstAidPrice[1] === 'range'
          ? 'Der Preis kann sich je nach Tag, Zeit und Ort ändern.'
          : null,
    })
  }

  pricesTable.push(
    ...([
      {
        type: 'table_entry',
        text_left: 'Einzellektion (45 min)',
        text_right: `${locationPrices.lessonB45 / 100} CHF`,
        tooltip: null,
      },
      {
        type: 'table_entry',
        text_left: 'BLINK Theorie App',
        text_right: `${locationPrices.theoryApp / 100} CHF`,
        tooltip: null,
      },
      {
        type: 'table_entry',
        text_left: 'Versicherungspauschale',
        text_right: `${locationPrices.insuranceFee / 100} CHF`,
        tooltip:
          'Einmalige Versicherungspauschale (Vollkasko ohne Selbstbehalt). Fällt erst ab der zweiten Lektion an.',
      },
    ] as const)
  )

  const vkuCourse = locationPrices.courses?.find(
    (course) => course.type === 'VKU'
  )

  if (vkuCourse) {
    const vkuPrice = formatCoursePrice(vkuCourse.min, vkuCourse.max)
    pricesTable.push({
      type: 'table_entry',
      text_left: 'VKU',
      text_right: vkuPrice[0],
      tooltip:
        vkuPrice[1] === 'range'
          ? 'Der Preis kann sich je nach Tag, Zeit und Ort ändern.'
          : null,
    })
  }

  if (locationPrices.exams && locationPrices.exams.length > 0) {
    const minPrice = Math.min(
      ...locationPrices.exams.map((exam) => exam.withPreparation)
    )
    const maxPrice = Math.max(
      ...locationPrices.exams.map((exam) => exam.withPreparation)
    )
    const examPrice = formatCoursePrice(minPrice, maxPrice)
    pricesTable.push({
      type: 'table_entry',
      text_left: 'Prüfungspauschale',
      text_right: examPrice[0],
      tooltip:
        examPrice[1] === 'range'
          ? 'Dies beinhaltet Einfahren (ca. 45 min), die Prüfung mit dem Fahrschulauto und das Abschlussgespräch. Der Preis kann sich je nach Prüfungsamt ändern.'
          : 'Dies beinhaltet Einfahren (ca. 45 min), die Prüfung mit dem Fahrschulauto und das Abschlussgespräch.',
    })
  }

  return {
    title,
    prices: pricesTable,
  }
}

export default function Component({
  title,
  prices,
}: Awaited<ReturnType<typeof getStaticProps>>) {
  if (!prices) return null

  return (
    <Container>
      <Title as="h2" style={2}>
        {title}
      </Title>
      <div className="mt-8">
        <BardTable type="table" table={prices} />
      </div>
    </Container>
  )
}

const formatCoursePrice = (min: number, max: number) => {
  if (min === max) return [`${min / 100} CHF`, 'single']
  return [`${min / 100} bis ${max / 100} CHF`, 'range']
}
