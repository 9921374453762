import { GetStaticPropsContext } from 'next'
import { createElement, FunctionComponent, ReactNode } from 'react'
import { PageParams, PreviewData } from '../@types/infrastructur'
import { PageInfo } from '../@types/models'
import { ImageType, PageBuilder, StaticIds } from '../@types/statamic'
import staticBlocks from './staticBlocks'
import blocks from '@/blocks/index'
import { pascalCase } from '@/src/lib/str'

export type Block = Record<string, any> & { block: string }

export async function loadBlockProps(
  blocks: PageBuilder[] | undefined,
  page: GetStaticPropsContext<PageParams, PreviewData>
): Promise<Block[]> {
  if (!blocks) return []
  return Promise.all(
    blocks.map(async (data): Promise<Block | undefined> => {
      const block = getBlock(data.type)

      if (!block) {
        return undefined
      }

      const props = block.getStaticProps
        ? await block.getStaticProps(data, page)
        : data

      return { block: data.type, ...props }
    })
  ).then((blocks) =>
    blocks.filter((block): block is Block => block?.block !== undefined)
  )
}
export function renderBlock(
  block: Block,
  index: number,
  blocks: Block[],
  pageInfo: PageInfo
): ReactNode {
  let { block: name, ...props } = block
  if (name === 'static') {
    props = staticBlocks[props.static_id.value as StaticIds]

    // If provided static id does not exist
    if (!props) {
      return null
    }

    name = props.type
  }

  const Block = getBlock(name)?.default

  if (!Block) {
    return null
  }

  return createElement(Block, {
    ...props,
    index,
    blocks,
    pageInfo,
  })
}

function getBlock<T = any>(
  name: string
): {
  default: FunctionComponent<T> | FunctionComponent<{ type: string }>
  getStaticProps?: (
    props: any,
    context: GetStaticPropsContext<PageParams, PreviewData>
  ) => Promise<T>
  getTitle?: (props: any) => string | undefined
  getDescription?: (props: any) => string | undefined
  getImage?: (props: any) => ImageType | undefined
} {
  return blocks[pascalCase(name)] ?? blocks.DefaultBlock
}

export function getBlockTitle(block: Block): string | undefined {
  const { block: name } = block
  const getTitle = getBlock(name)?.getTitle

  if (!getTitle) {
    return undefined
  }

  return getTitle(block)
}

export function getBlockDescription(block: Block): string | undefined {
  const { block: name } = block
  const getDescription = getBlock(name)?.getDescription

  if (!getDescription) {
    return undefined
  }

  return getDescription(block)
}

export function getBlockImage(block: Block): ImageType | undefined {
  const { block: name } = block
  const getImage = getBlock(name)?.getImage

  if (!getImage) {
    return undefined
  }

  return getImage(block)
}
