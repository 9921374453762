import { Combobox, Transition } from '@headlessui/react'
import { MapPin } from '@phosphor-icons/react'
import classNames from 'classnames'
import round from 'lodash-es/round'
import Link from 'next/link'
import { Fragment, useEffect, useState } from 'react'
import LocationMarker from '../../components/elements/LocationMarker'
import SwissMap from '../../components/elements/SwissMap'
import Title from '../../components/elements/Title'
import LoadingSpinner from '../../components/icons/LoadingSpinner'
import TrackingCrossIcon from '../../components/icons/TrackingCrossIcon'
import useSVGElementSize from '../../hooks/useSvgElementSize'
import {
  getLocations,
  closestLocations,
  getLocationsForCurrentPosition,
  search,
} from '../../lib/places'
import { LocationType, LocationWithDistance, Place } from '@/src/@types/models'
import { InteractiveMapBlock } from '@/src/@types/statamic'
import IconButton from '@/src/components/form/IconButton'
import ArrowCounterClockwiseIcon from '@/src/components/icons/ArrowCounterClockwiseIcon'
import ArrowIcon from '@/src/components/icons/ArrowIcon'
import SearchIcon from '@/src/components/icons/SearchIcon'
import Container from '@/src/components/layout/Container'
import locateMeService from '@/src/lib/locateMeService'
import { Block } from '@/src/lib/PageBuilder'

type InteractiveMapBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: InteractiveMapBlock) {
  return title
}

export async function getStaticProps({
  type,
  title,
  map_type,
}: InteractiveMapBlock) {
  const locations = await getLocations(map_type.value)

  return { type, locations, title, map_type }
}

export default function InteractiveMap({
  type,
  locations,
  title,
  map_type,
  blocks,
  index,
}: InteractiveMapBlockProps & { index: number; blocks: Block[] }) {
  const [mapRef, mapSize] = useSVGElementSize<SVGSVGElement>()
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null)

  const [suggestedLocations, setSuggestedLocations] = useState<
    LocationWithDistance[]
  >([])

  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [foundPlaces, setFoundPlaces] = useState<Place[]>([])

  const boundaries = {
    north: 47.808264,
    south: 45.818031,
    east: 10.491944,
    west: 5.956303,
  }

  useEffect(() => {
    // Only register first interactive map to locateMeService
    if (blocks.slice(0, index).filter((b) => b.block === type).length === 0) {
      locateMeService.register(loadClosestLocationsBasedOnPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadPlaces = async (query: string) => {
    setSearchQuery(query)
    const places = await search(query)
    setFoundPlaces(places)
  }

  const loadClosestLocations = async (place: Place | null) => {
    setIsLoading(true)
    setSelectedPlace(place)
    setSuggestedLocations(
      place !== null ? await closestLocations(place.id, map_type.value) : []
    )
    setIsLoading(false)
  }

  const loadClosestLocationsBasedOnPosition = async () => {
    setIsLoading(true)
    setSelectedPlace(null)
    try {
      setSuggestedLocations(
        await getLocationsForCurrentPosition(map_type.value)
      )
    } catch (_) {
      setSuggestedLocations([])
    } finally {
      setIsLoading(false)
    }
  }

  const selectPlace = (place: Place) => {
    setSelectedPlace(place)
    setFoundPlaces([])
    setSearchQuery('')
    loadClosestLocations(place)
  }

  return (
    <Container>
      <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-3 lg:gap-24">
        <div>
          <Title
            style={2}
            className="!mb-10 max-w-md lg:w-[42vw] lg:max-w-none 2xl:w-[40rem]"
          >
            {title}
          </Title>
          <Combobox
            value={selectedPlace}
            onChange={(place) => {
              if (place) {
                selectPlace(place)
              }
            }}
          >
            {({ open }) => (
              <div
                className={classNames([
                  'flex max-w-md bg-gradient-150 py-1 lg:w-full',
                  {
                    'rounded-t rounded-br': open,
                    rounded: !open,
                  },
                ])}
              >
                <div className="flex-1">
                  <div className="relative my-1">
                    <div className="relative w-full overflow-hidden focus:outline-none focus-visible:ring-2">
                      <Combobox.Input
                        className="w-full border-none bg-gradient-150 pb-[7px] pl-3 pr-10 pt-2 font-bold leading-5 text-blue-900 placeholder:text-blue-900 focus:ring-0 placeholder:focus:text-gray-600"
                        displayValue={() => ''}
                        onChange={(event) => loadPlaces(event.target.value)}
                        placeholder={
                          selectedPlace ? selectedPlace.name : 'PLZ oder Ort'
                        }
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 -mt-1 flex items-center pr-2 pt-1">
                        <SearchIcon className="size-6" aria-hidden="true" />
                      </Combobox.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Combobox.Options className="absolute z-20 max-h-60 w-full overflow-auto rounded-b bg-gradient-150 py-1 text-sm">
                        {foundPlaces.length === 0 ? (
                          <div className="relative cursor-default select-none p-2 text-center text-blue-900">
                            {searchQuery !== ''
                              ? 'Keine Suchergebnisse gefunden'
                              : 'Bitte gib deinen Standort ein'}
                          </div>
                        ) : (
                          foundPlaces.map((place, index) => (
                            <Combobox.Option
                              key={index}
                              className={({ active, selected }) =>
                                `relative cursor-pointer select-none px-4 py-2 hover:bg-gradient-600 hover:text-white ${
                                  selected || active
                                    ? 'bg-gradient-600 text-white'
                                    : 'text-blue-900'
                                }`
                              }
                              value={place}
                            >
                              <div>{place.name}</div>
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </div>
                <div className="my-2 min-h-full w-0.5 shrink-0 bg-blue-100" />
                <div className="flex items-center justify-center px-3 py-1">
                  <IconButton
                    type="button"
                    style="transparent"
                    onClick={loadClosestLocationsBasedOnPosition}
                    className="flex size-full items-center justify-center"
                  >
                    <TrackingCrossIcon className="size-6" />
                  </IconButton>
                </div>
              </div>
            )}
          </Combobox>
          {isLoading && (
            <div className="mt-2 flex items-center">
              <LoadingSpinner className="mr-3 size-5" />
              Standorte werden geladen
            </div>
          )}
          {!isLoading && suggestedLocations.length > 0 && (
            <div className="mt-10 flex flex-col">
              <h3 className="text-sm">Die nächsten Standorte:</h3>
              <ul className="mt-4 space-y-2">
                {suggestedLocations.map((location) => (
                  <li key={location.id}>
                    <Link
                      href={location.url}
                      className="group flex items-center bg-gradient-100 px-4 py-3 text-blue-900"
                    >
                      <MapPin className="mb-1 mr-3 size-6" weight="fill" />
                      <span className="flex grow items-baseline">
                        <span className="mr-2 text-xl font-bold">
                          {location.city}
                        </span>
                        <span>{round(location.distance)} km</span>
                      </span>
                      <ArrowIcon className="group-hover: size-5 text-purple-600 transition group-hover:translate-x-2" />
                    </Link>
                  </li>
                ))}
              </ul>
              <div
                className="group mt-4 flex cursor-pointer items-center self-end font-bold text-purple-600 hover:text-purple-800"
                onClick={() => {
                  setSuggestedLocations([])
                  setSelectedPlace(null)
                }}
              >
                <ArrowCounterClockwiseIcon className="size-5 transition-transform duration-500 group-hover:rotate-[-360deg]" />
                <p className="ml-1 pt-1">Suche zurücksetzen</p>
              </div>
            </div>
          )}
        </div>
        <div className="col-span-2 -mx-10 md:mx-0 md:ml-auto md:w-4/5 lg:mt-24 lg:w-full">
          <div className="relative">
            <SwissMap ref={mapRef} highlightCanton={selectedPlace?.canton} />
            {locations
              .filter(
                (location) =>
                  (suggestedLocations.length === 0 ||
                    suggestedLocations.some(
                      (suggestedLocation) =>
                        location.id === suggestedLocation.id
                    )) &&
                  location.position.lat &&
                  location.position.lng
              )
              .map((location) => (
                <LocationMarker
                  highlight={suggestedLocations.length !== 0}
                  key={location.id}
                  location={
                    location as Omit<LocationType, 'position'> & {
                      position: { lng: number; lat: number }
                    }
                  }
                  boundaries={boundaries}
                  map={mapSize}
                />
              ))}
          </div>
        </div>
      </div>
    </Container>
  )
}
