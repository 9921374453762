import { Info } from '@phosphor-icons/react'
import { TooltipPortal } from '@radix-ui/react-tooltip'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import TagManager from 'react-gtm-module'
import { useWatch } from 'react-hook-form'
import { TcsFormBlock } from '@/src/@types/statamic'
import Button from '@/src/components/core/Button'
import { Input } from '@/src/components/core/Input'
import { Select } from '@/src/components/core/Select'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/src/components/core/Tooltip'
import Content from '@/src/components/elements/Content'
import Checkbox from '@/src/components/form/Checkbox'
import Honeypot from '@/src/components/form/Honeypot'
import Container from '@/src/components/layout/Container'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

type RequestFrom = {
  first_name: string
  last_name: string
  salutation: string
  birthday: string
  street: string
  zip: string
  place: string
  phone: string
  email: string
  interests: string[]
  origin: string
  address: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/leads`

export default function Component({ text, button_text }: TcsFormBlock) {
  const { utmData } = useUtmCache()

  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [startedFillingOut, setStartedFillingOut] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const { handleSubmit, register, control } = useLaravelForm<RequestFrom>({
    defaultValues: {
      first_name: '',
      last_name: '',
      salutation: '',
      birthday: '',
      street: '',
      zip: '',
      place: '',
      phone: '',
      email: '',
      interests: ['TCS Mitgliedschaft'],
      origin: 'Webseite',
      address: '', // honeypot
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const formValues = useWatch({
    control,
    defaultValue: {},
  })

  useEffect(() => {
    // check if a form property exists to ignore init of value
    if (formValues.first_name && !startedFillingOut) {
      setStartedFillingOut(true)
      TagManager.dataLayer({
        dataLayer: {
          event: 'tcs_form_started',
          params: {
            origin: 'Website',
            site: window.location.href,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues])

  return (
    <Container>
      <div>
        <div>
          <div className="mt-8 max-w-[800px] rounded-xl bg-gradient-600 p-8 md:mt-10 md:p-10">
            {!submitted && (
              <>
                {text && <Content content={text} textColor="white" />}
                <form
                  ref={formRef}
                  onSubmit={handleSubmit(
                    () => {
                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'tcs_form_sent',
                          params: {
                            origin: 'Website',
                            site: window.location.href,
                          },
                        },
                      })
                      setSubmitted(true)
                      formRef.current?.reset()
                      setSending(false)
                    },
                    (data) => {
                      setSending(true)
                      return data
                    },
                    () => {
                      setSending(false)
                    }
                  )}
                  method="POST"
                  action={requestUrl}
                  className="mt-4 flex flex-col"
                >
                  <div className="grid gap-2 md:grid-cols-2 lg:gap-4">
                    <Input
                      label="Vorname"
                      kind="firstName"
                      errorMessageColor="white"
                      {...register('first_name')}
                    />
                    <Input
                      label="Nachname"
                      kind="lastName"
                      errorMessageColor="white"
                      {...register('last_name')}
                    />
                    <Select label="Anrede" {...register('salutation')}>
                      <option value="Frau">Frau</option>
                      <option value="Herr">Herr</option>
                      <option value="Keine Angabe">Keine Angabe</option>
                    </Select>
                    <Input
                      label="E-Mail"
                      kind="email"
                      {...register('email')}
                      errorMessageColor="white"
                    />
                    <Honeypot {...register('address')} />
                    <Input
                      label="Handynummer"
                      kind="tel"
                      errorMessageColor="white"
                      {...register('phone')}
                    />
                    <Input
                      label="Strasse"
                      kind="street"
                      errorMessageColor="white"
                      {...register('street')}
                    />
                    <div className="grid grid-cols-4 gap-2">
                      <Input
                        label="PLZ"
                        kind="zip"
                        errorMessageColor="white"
                        {...register('zip')}
                      />
                      <div className="col-span-3">
                        <Input
                          label="Ort"
                          kind="place"
                          errorMessageColor="white"
                          {...register('place')}
                        />
                      </div>
                    </div>
                    <Input
                      label="Geburtstag"
                      errorMessageColor="white"
                      {...register('birthday')}
                    />
                  </div>
                  <div className="mt-5">
                    <Checkbox multiLine required>
                      <span className="text-white">
                        Ich habe die AGBs des TCS gelesen und bin damit
                        einverstanden (
                        <Link
                          href="https://www.tcs.ch/assets/docs/agb_de/soc/socmot-2018_de.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Mitgliedschaft
                        </Link>
                        ,{' '}
                        <Link
                          href="https://www.tcs.ch/mam/Digital-Media/PDF/Terms-and-Conditions/tcs-verkehrsrechtsschutz-standard-tcs-verkehrsrechtsschutz-plus-allgemein-versicherungsbedingungen-ausgabe-2018-stand-januar-2022.pdf"
                          target="_blank"
                          rel="noopener naoreferrer"
                          className="underline"
                        >
                          Verkehrsrechtsschutz
                        </Link>
                        ,{' '}
                        <Link
                          href="https://www.tcs.ch/assets/docs/agb_de/soc/statuten-2021_de.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Statuten
                        </Link>
                        ,{' '}
                        <Link
                          href="https://www.tcs.ch/de/datenschutz.php?viewmode=blank&viewmode=blank&viewmode=blank&viewmode=blank&viewmode=blank&viewmode=blank&viewmode=blank"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          Datenschutz
                        </Link>
                        ).
                        <Tooltip>
                          <TooltipTrigger>
                            <Info
                              weight="fill"
                              size={16}
                              className="ml-1 translate-y-0.5 text-white"
                            />
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent side="bottom" className="max-w-md">
                              TCS Mitgliedschaft und der TCS
                              Verkehrsrechtsschutz verlängern sich jeweils
                              automatisch um ein Jahr, wenn sie nicht drei
                              Monate vor Ablauf schriftlich gekündigt werden.
                              Erneuerung der TCS Mitgliedschaft gilt der
                              sektionsabhängige Beitrag (Einzeldeckung mit
                              Pannenhilfe: CHF 93.– bis CHF 111.– im Jahr;
                              Jugendliche mit Pannenhilfe: CHF 69.– bis CHF 81.–
                              im Jahr). Für eine Erneuerung des TCS
                              Verkehrsrechtsschutzes Standard gilt der
                              ordentliche Preis (Einzeldeckung: CHF 69.– als
                              TCS-Mitglied, CHF 78.– als Nicht-Mitglied im Jahr;
                              Jugendliche: CHF 60.– als TCS-Mitglied, CHF 78.–
                              als Nicht-Mitglied im Jahr).
                            </TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </span>
                    </Checkbox>
                  </div>
                  <div className="mt-8 self-center">
                    <Button
                      type="submit"
                      version="white"
                      loading={sending}
                      className="inline-flex items-center"
                    >
                      {button_text}
                    </Button>
                  </div>
                </form>
              </>
            )}
            {submitted && (
              <div className="text-lg text-white">
                <p className="text-2xl font-bold">
                  Vielen Dank für deine Anmeldung!
                </p>
                <p className="mt-2">
                  Du wirst die Unterlagen mit deiner TCS Mitgliedernummer
                  innerhalb von wenigen Tagen per Post erhalten.
                  <br /> <br />
                  <b>Wichtig</b>: Diese Mitgliedernummer benötigst du, um vom
                  Rabatt zu profitieren. Melde dich also erst für den WAB Kurs
                  an, wenn du sie erhalten hast.💡
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
