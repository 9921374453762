import { Dialog } from '@headlessui/react'
import Link from 'next/link'
import { useState } from 'react'
import { TeamMember as TeamMemberType } from '../../@types/admin'
import Caret from '../icons/Caret'
import LinkedInIcon from '../icons/LinkedInIcon'
import PlayIcon from '../icons/PlayIcon'
import PlusIcon from '../icons/PlusIcon'
import Picture from './Picture'

export default function TeamMember({
  image,
  name,
  shortDescription,
  description,
  linkedin,
  video,
  showDescription,
  toggleDescription,
  index,
}: TeamMemberType & {
  showDescription: boolean
  toggleDescription: () => void
  index: number
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-blue-900 opacity-[85%]" />
          <div className="relative mx-5 max-w-full rounded-xl bg-white">
            <div className="aspect-h-9 aspect-w-16 h-full w-[80rem] max-w-full">
              <iframe
                frameBorder="0"
                allowFullScreen
                src={`${video}?autoplay=1&origin=https://blinkdrive.ch`}
              />
            </div>
            <div className="p-10">
              <p className="text-sm font-medium lg:text-base">
                {shortDescription}
              </p>
              <p className="text-4xl font-extrabold lg:text-10xl">{name}</p>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="aspect-h-2 aspect-w-3 relative w-full lg:h-auto">
        <div>
          <Picture
            fill
            permalink={image}
            alt={`Bild von ${name}`}
            className="rounded-lg object-cover"
          />
          <div className="absolute bottom-0 w-full text-right">
            <div className="-mb-7 mr-8 flex justify-end">
              {video && (
                <button
                  type="button"
                  className="size-12 rounded-full bg-gradient-800"
                  onClick={() => setIsOpen(true)}
                >
                  <PlayIcon className="size-12" />
                </button>
              )}
              {linkedin && !video && (
                <Link
                  href={linkedin}
                  className="flex size-12 justify-center rounded-full bg-gradient-800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon className="h-12 w-5 text-white" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col-reverse">
        {(description && (
          <button
            type="button"
            className="mt-1 flex cursor-pointer items-center"
            onClick={toggleDescription}
          >
            <span className="text-lg font-extrabold lg:text-2xl">{name}</span>
            <PlusIcon
              className={`ml-2 size-5 text-purple-700 transition duration-100 lg:size-6 ${
                showDescription ? 'rotate-45' : ''
              }`}
            />
          </button>
        )) || <p className="mt-1 text-lg font-extrabold lg:text-2xl">{name}</p>}
        <p className="mt-2">{shortDescription}</p>
      </div>

      <div
        aria-hidden={!showDescription}
        className={`${showDescription ? '' : 'hidden'} mt-4`}
      >
        <Caret className="-mb-px ml-5 w-9 text-blue-100" />
        <div
          className={`${
            index % 4 === 0 || index % 4 === 1 ? 'lg:-ml-8' : 'lg:ml-[-328px]'
          } rounded-lg bg-gradient-150 p-8  lg:w-[37.5rem]`}
        >
          {description}
        </div>
      </div>
    </>
  )
}
