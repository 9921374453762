import { CaretLeft, CaretRight, Play, X } from '@phosphor-icons/react'
import * as Dialog from '@radix-ui/react-dialog'
import clsx from 'clsx'
import { useState } from 'react'
import { BardVideos as BardVideosType } from '@/src/@types/statamic'
import { cn } from '@/src/components/core/lib/utils'
import Picture from '@/src/components/elements/Picture'

export default function BardVideos({ videos }: BardVideosType) {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0)

  return (
    <Dialog.Root>
      <div className="grid gap-7 lg:grid-cols-2">
        {videos.map(({ title, subtitle, thumbnail }, index) => (
          <Dialog.Trigger
            key={index}
            onClick={() => setSelectedVideoIndex(index)}
            className="aspect-h-3 aspect-w-4"
          >
            <div className="flex size-full items-end justify-start overflow-hidden rounded-lg p-5 text-white">
              <Picture
                sizes="(min-width: 768px) 50vw, 100vw"
                {...thumbnail}
                fill
                className="-z-10 object-cover"
              />
              <div className="absolute inset-0 -z-10 bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-blue-900/80 to-blue-900/0 to-50%" />
              <div className="z-0 flex items-center">
                <div className="rounded-full bg-purple-500 p-2.5">
                  <Play size={20} weight="fill" />
                </div>
                <div className="ml-4 text-left">
                  <p className="font-bold">{title}</p>
                  {subtitle && <p>{subtitle}</p>}
                </div>
              </div>
            </div>
          </Dialog.Trigger>
        ))}
      </div>
      <Dialog.Portal>
        <Dialog.Overlay
          className={cn(
            'fixed inset-0 z-50',
            'bg-black/95',
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
          )}
        />
        <Dialog.Content className="fixed inset-0 z-50 flex items-center justify-center">
          <Dialog.Close className="absolute right-3 top-3 z-10 rounded-full bg-black/50 p-2.5 lg:right-12 lg:top-12">
            <X size={20} weight="bold" className="text-white" />
          </Dialog.Close>
          <div
            className={clsx(
              'flex size-full max-w-container flex-col items-center',
              {
                'justify-start':
                  videos[selectedVideoIndex].format.value === 'portrait',
                'justify-center':
                  videos[selectedVideoIndex].format.value === 'landscape',
              }
            )}
          >
            <div
              className={clsx({
                'aspect-h-9 aspect-w-16 w-full':
                  videos[selectedVideoIndex].format.value === 'landscape',
                'h-full w-full':
                  videos[selectedVideoIndex].format.value === 'portrait' &&
                  videos.length === 1,
                'h-[calc(100%-32px-44px-32px)] w-full':
                  videos[selectedVideoIndex].format.value === 'portrait' &&
                  videos.length > 1,
              })}
            >
              <video
                className="size-full object-contain"
                controls
                autoPlay
                playsInline
                src={videos[selectedVideoIndex].video}
              />
            </div>
            {videos.length > 1 && (
              <div className="mt-8 flex space-x-6">
                {selectedVideoIndex > 0 ? (
                  <button
                    onClick={() =>
                      setSelectedVideoIndex(selectedVideoIndex - 1)
                    }
                    className="flex size-11 items-center justify-center rounded-full border border-white text-white"
                  >
                    <CaretLeft size={20} weight="bold" />
                  </button>
                ) : (
                  <div className="size-11" />
                )}
                {selectedVideoIndex < videos.length - 1 ? (
                  <button
                    onClick={() =>
                      setSelectedVideoIndex(selectedVideoIndex + 1)
                    }
                    className="flex size-11 items-center justify-center rounded-full border border-white text-white"
                  >
                    <CaretRight size={20} weight="bold" />
                  </button>
                ) : (
                  <div className="size-11" />
                )}
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
