import { CheckCircle, XCircle } from '@phosphor-icons/react'
import classNames from 'classnames'
import {
  ComponentPropsWithoutRef,
  createElement,
  ElementType,
  ForwardedRef,
  forwardRef,
  useEffect,
  useId,
  useState,
} from 'react'
import { FieldError, RefCallBack } from 'react-hook-form'
import Button from './Button'
import { get } from '@/src/BlinkAdminApiClient'
import ErrorMessage from '@/src/components/form/ErrorMessage'

const DEFAULT_INPUT_TAG = 'input' as const

export type Voucher = {
  original_price: number
  new_price: number
  value: number
}

export type InputProps<TAG extends ElementType = typeof DEFAULT_INPUT_TAG> = {
  as?: TAG
  style?: 'inverted' | 'white'
  ref: RefCallBack
  value: string
  onChange: (...event: any[]) => void
  invalid: boolean
  error: FieldError | undefined
  voucherChange: (voucher: Voucher | undefined) => void
} & Omit<ComponentPropsWithoutRef<TAG>, 'style'> &
  (
    | {
        voucherType: 'course'
        courseId: string
      }
    | {
        voucherType: 'trial_lesson'
        locationId: number
      }
  )

function VoucherInput<TAG extends ElementType = typeof DEFAULT_INPUT_TAG>(
  {
    value,
    style = 'white',
    voucherType,
    courseId,
    locationId,
    as,
    label,
    onChange,
    invalid,
    error,
    voucherChange,
    ...props
  }: InputProps<TAG>,
  ref: ForwardedRef<HTMLElement>
) {
  const generatedId = `${useId()}-input-field`

  const defaultType = as !== undefined && as !== 'input' ? props.type : 'text'

  const [initial, setInitial] = useState(true)
  const [_loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (initial && value) {
      verifiyVoucher()
      setInitial(false)
      return
    }
    voucherChange(undefined)
    setIsValid(undefined)
    setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const verifiyVoucher = async () => {
    setLoading(true)
    let voucher = undefined
    if (voucherType === 'course') {
      voucher = await get<Voucher>(
        `/website/v2/coupons/for-courses/${courseId}/validity`,
        { code: value }
      )
    }
    if (voucherType === 'trial_lesson') {
      voucher = await get<Voucher>(
        `/website/v2/coupons/for-trial-booking/${locationId}/validity`,
        { code: value }
      )
    }

    setIsValid(!!voucher)
    voucherChange(voucher)
    setLoading(false)
  }

  return (
    <div>
      <div className="relative w-full">
        {createElement(as || DEFAULT_INPUT_TAG, {
          ...props,
          id: generatedId,
          ref,
          className: classNames(
            props.className,
            { 'text-pink-500': invalid },
            'block w-full outline-0 px-3 border-blue-100 border py-2 rounded font-bold focus:outline-0 focus:ring-1 focus:ring-purple-700',
            {
              'bg-white': style === 'white',
              'bg-gradient-100': style === 'inverted',
            },
            'peer pt-5'
          ),
          inputMode: props.type === 'tel' ? 'tel' : props.inputMode,
          type: props.type || defaultType,
          value,
          onChange,
          placeholder: ' ',
          disabled: isValid,
        })}
        <label
          htmlFor={generatedId}
          className={classNames([
            'absolute left-3 top-1 origin-left scale-75 text-sm transition-all peer-placeholder-shown:top-4.5 cursor-text peer-placeholder-shown:scale-100 peer-placeholder-shown:text-sm peer-focus:top-1 peer-focus:scale-75',
            {
              'text-gray-600': style === 'white',
              'text-blue-900': style === 'inverted',
              'text-pink-500 peer-placeholder-shown:text-pink-500 peer-focus:text-pink-500':
                error !== undefined,
            },
          ])}
        >
          {label}
        </label>
        {error?.message && <ErrorMessage error={error.message} />}
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          {isValid === true && (
            <CheckCircle size={24} weight="fill" className="text-green-600" />
          )}
          {isValid === false && (
            <XCircle size={24} weight="fill" className="text-pink-600" />
          )}
          {value && isValid === undefined && (
            <Button
              type="button"
              size="none"
              style="transparent"
              className={classNames([
                'inline-flex items-center',
                {
                  hidden: !value || isValid !== undefined,
                },
              ])}
              onClick={verifiyVoucher}
            >
              überprüfen
            </Button>
          )}
        </div>
      </div>
      {isValid === true && (
        <Button
          type="button"
          size="none"
          style="transparent"
          className="ml-1 py-1 text-sm font-bold"
          onClick={() => onChange('')}
        >
          Gutschein entfernen
        </Button>
      )}
    </div>
  )
}

// @ts-expect-error: TODO: Switch to the core VoucherInput component
export default forwardRef(VoucherInput)
