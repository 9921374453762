'use client'

import { Icon } from '@phosphor-icons/react'
import { VariantProps, cva } from 'class-variance-authority'
import {
  ComponentPropsWithoutRef,
  ElementType,
  ForwardedRef,
  ReactElement,
  ReactNode,
  cloneElement,
  createElement,
  forwardRef,
} from 'react'
import { cn } from '@/core/lib/utils'

const DEFAULT_BUTTON_ELEMENT = 'button'

const buttonStyle = cva(
  'flex items-center justify-center rounded-full border-2 transition-colors duration-200 hover:duration-150',
  {
    variants: {
      size: {
        normal: 'px-5 pb-[9px] pt-2.5 text-sm font-bold',
        small: 'px-3 pb-1 pt-[5px] text-sm font-bold',
        icon: 'p-3',
        'icon-small': 'p-[7px]',
        none: 'p-0 text-sm font-bold',
      },
      version: {
        purple: [
          'border-purple-600 bg-purple-600 text-white',
          'hover:border-purple-800 hover:bg-purple-800',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-purple-300 data-[disabled]:bg-purple-300',
        ],
        'purple-outlined': [
          'border-purple-600 text-purple-600',
          'hover:bg-blue-50',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-purple-500 data-[disabled]:bg-transparent data-[disabled]:text-purple-500',
        ],
        'purple-transparent': [
          'border-transparent bg-transparent text-purple-600',
        ],
        'purple-ghost': [
          'border-purple-50 bg-purple-50 text-purple-600',
          'hover:border-purple-100 hover:bg-purple-100',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-transparent data-[disabled]:bg-transparent data-[disabled]:text-purple-400',
        ],
        white: [
          'border-white bg-white text-purple-600',
          'hover:border-blue-50 hover:bg-blue-50',
          'data-[disabled]:cursor-not-allowed data-[disabled]:text-gray-600',
        ],
        'white-outlined': [
          'border-white text-white',
          'hover:bg-white/10',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-blue-100 data-[disabled]:text-blue-100',
        ],
        'white-ghost': [
          'border-transparent bg-white/10 text-white',
          'hover:bg-white/20',
          'data-[disabled]:cursor-not-allowed data-[disabled]:bg-transparent data-[disabled]:text-blue-100',
        ],
        green: [
          'border-green-600 bg-green-600 text-white',
          'hover:border-green-800 hover:bg-green-800',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-green-200 data-[disabled]:bg-green-200',
        ],
        pink: [
          'border-pink-600 bg-pink-600 text-white',
          'hover:border-pink-800 hover:bg-pink-800',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-pink-200 data-[disabled]:bg-pink-200',
        ],
        'gray-ghost': [
          'border-gray-100 bg-gray-100 text-gray-700',
          'hover:border-gray-200 hover:bg-gray-200',
          'data-[disabled]:cursor-not-allowed data-[disabled]:border-transparent data-[disabled]:bg-transparent data-[disabled]:text-gray-400',
        ],
      },
    },
    defaultVariants: {
      size: 'normal',
      version: 'purple',
    },
  }
)

const Button = <TAG extends ElementType = typeof DEFAULT_BUTTON_ELEMENT>(
  {
    as,
    size = 'normal',
    version = 'purple',
    icon,
    iconPosition = 'left',
    disabled,
    loading,
    children,
    ...props
  }: {
    as?: TAG
    icon?: ReactElement<Icon>
    iconPosition?: 'left' | 'right'
    loading?: boolean
    children?: ReactNode
  } & VariantProps<typeof buttonStyle> &
    Omit<ComponentPropsWithoutRef<TAG>, 'as' | 'children'>,
  ref: ForwardedRef<TAG>
) => {
  const component = as || DEFAULT_BUTTON_ELEMENT
  return createElement(
    component,
    Object.assign(
      {},
      {
        ...props,
        disabled: disabled || loading,
        'data-disabled': disabled ? '' : undefined,
        className: cn(buttonStyle({ size, version }), props.className),
        ref: ref,
      }
    ),
    <>
      {loading && (
        <div className="animate-icon-slide-in overflow-hidden pr-2">
          <svg
            className="size-4 shrink-0 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
      {icon &&
        iconPosition === 'left' &&
        cloneElement(icon, {
          // TODO: Proper typing
          //@ts-ignore
          size: 20,
          className: children && 'mr-2',
        })}
      {children && (
        <span className="whitespace-nowrap text-center">{children}</span>
      )}
      {icon &&
        iconPosition === 'right' &&
        cloneElement(icon, {
          // TODO: Proper typing
          //@ts-ignore
          size: 20,
          className: children && 'ml-2',
        })}
    </>
  )
}

export default forwardRef(Button)
