import shuffle from 'lodash-es/shuffle'
import trim from 'lodash-es/trim'
import { TeamMember } from './@types/admin'
import { TeamRole } from './@types/statamic'
import { Mandatory } from './@types/util'

const API_ADMIN_URL = process.env.NEXT_PUBLIC_BLINK_ADMIN_URL
const NOHE_API_ADMIN_URL = process.env.API_ADMIN_URL

export async function get<Response = unknown>(
  url: string,
  params?: Record<string, string>,
  // TODO: Remove once UpcomingFirstAidCourses endpoint is available in Blink
  nohe?: boolean
): Promise<Response> {
  url = trim(url, '/')

  if (params) {
    url += '?' + new URLSearchParams(params).toString()
  }

  return await (
    await fetch(`${nohe ? NOHE_API_ADMIN_URL : API_ADMIN_URL}/${url}`)
  ).json()
}

export async function getTeamMembers(
  roles: TeamRole[] = [],
  limit?: number
): Promise<Mandatory<TeamMember, 'image'>[]> {
  const members = await get<TeamMember[]>(
    `api/website/v2/team-members?filter[roles]=${roles.join(',')}`
  )

  return shuffle(
    members.filter(
      (member): member is Mandatory<TeamMember, 'image'> => !!member.image
    )
  ).slice(0, limit)
}

export async function getSpecificTeamMembers(
  teamMemberIds: string[]
): Promise<Mandatory<TeamMember, 'image'>[]> {
  const members = await get<TeamMember[]>(
    `api/website/v2/team-members?filter[members]=${teamMemberIds.join(',')}`
  )

  return members.filter(
    (member): member is Mandatory<TeamMember, 'image'> => !!member.image
  )
}
