import { useEffect, useState } from 'react'

export default function useVisible(ref: React.RefObject<HTMLElement>) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const io = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (!entry.isIntersecting) continue
        setVisible(true)
        io.disconnect()
        break
      }
    })

    if (ref.current) io.observe(ref.current)
  }, [ref])

  return visible
}
