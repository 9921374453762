import Icon from './Icon'

export default function SaleIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="25" fill="currentColor" />
          <path
            d="M7.54846 26.254L10.3667 25.3207L16.0529 32.1893L13.1678 33.1447L12.372 32.1227L9.86568 32.9527L9.85945 34.2403L7.05234 35.1699L7.54846 26.254ZM9.82976 28.5517L9.82924 30.7151L11.1103 30.2908L9.82976 28.5517Z"
            fill="white"
          />
          <path
            d="M18.1976 28.6484L19.0423 31.1993L16.4692 32.0514L13.6803 23.6301L16.2535 22.7779L17.8435 27.579L17.8769 27.5679L18.5278 24.8678L21.5132 23.8792L20.4937 27.1463L23.4424 29.7422L20.4459 30.7345L18.231 28.6373L18.1976 28.6484Z"
            fill="white"
          />
          <path
            d="M26.5342 28.8913C25.747 29.152 25.1087 29.178 24.6193 28.9692C24.1275 28.753 23.7635 28.2885 23.5274 27.5756L22.8339 25.4814L22.0096 25.7543L21.4009 23.9164L22.2141 23.6471L21.7788 22.3326L24.274 21.5063L24.7093 22.8207L25.9792 22.4002L26.5878 24.2382L25.3179 24.6587L25.8344 26.2182C25.9278 26.5004 26.0512 26.6738 26.2044 26.7385C26.3627 26.7932 26.5494 26.7849 26.7648 26.7136C26.8465 26.6865 26.9294 26.6508 27.0136 26.6065C27.0978 26.5621 27.1635 26.5239 27.2105 26.4918L27.7934 28.2518C27.6794 28.3555 27.506 28.4665 27.2732 28.5848C27.0479 28.7006 26.8015 28.8028 26.5342 28.8913Z"
            fill="white"
          />
          <path
            d="M26.6909 22.1645L29.2641 21.3124L31.2045 27.1716L28.6313 28.0238L26.6909 22.1645ZM26.0857 20.4861C26.0267 20.3078 26.0047 20.1297 26.0199 19.9516C26.0401 19.7636 26.0899 19.5906 26.1694 19.4324C26.2563 19.2718 26.3703 19.131 26.5116 19.01C26.6504 18.8817 26.8126 18.7868 26.9982 18.7253C27.1765 18.6663 27.3559 18.6481 27.5364 18.6707C27.7145 18.6859 27.8826 18.7332 28.0408 18.8127C28.2064 18.8897 28.3496 18.9988 28.4706 19.14C28.5965 19.2714 28.689 19.4262 28.748 19.6044C28.8095 19.7901 28.8301 19.9769 28.81 20.1649C28.7948 20.3429 28.7437 20.5123 28.6568 20.6729C28.5749 20.8237 28.467 20.9583 28.3332 21.0768C28.1994 21.1952 28.0434 21.284 27.8651 21.343C27.6795 21.4045 27.4939 21.4289 27.3084 21.4161C27.1229 21.4034 26.9486 21.3623 26.7854 21.2927C26.6273 21.2132 26.4852 21.1078 26.3593 20.9764C26.2384 20.8352 26.1472 20.6717 26.0857 20.4861Z"
            fill="white"
          />
          <path
            d="M37.867 21.6896C38.0343 22.1946 38.0902 22.6746 38.0349 23.1297C37.987 23.5823 37.8526 23.9977 37.6317 24.3757C37.4158 24.7439 37.1269 25.0662 36.7652 25.3425C36.4109 25.6164 36.011 25.8271 35.5654 25.9747C35.1273 26.1198 34.6806 26.1894 34.2255 26.1836C33.7777 26.1753 33.3573 26.0879 32.9643 25.9214C32.5689 25.7474 32.2131 25.4944 31.8971 25.1623C31.5886 24.8277 31.3507 24.408 31.1834 23.903C31.0187 23.4054 30.9615 22.934 31.0118 22.4889C31.0671 22.0338 31.2027 21.6222 31.4187 21.254C31.6346 20.8858 31.921 20.5685 32.2777 20.302C32.6394 20.0257 33.0394 19.8149 33.4775 19.6698C33.9231 19.5223 34.3697 19.4527 34.8175 19.4609C35.2702 19.4594 35.6931 19.5418 36.0861 19.7083C36.4865 19.8724 36.841 20.1217 37.1496 20.4563C37.4631 20.781 37.7023 21.1921 37.867 21.6896ZM34.1932 21.8309C33.911 21.9243 33.7127 22.0971 33.5984 22.3492C33.4916 22.5988 33.4812 22.8536 33.5673 23.1135C33.6582 23.3883 33.8248 23.5927 34.067 23.7268C34.3092 23.8608 34.5714 23.8812 34.8536 23.7877C35.1357 23.6942 35.334 23.5215 35.4483 23.2694C35.5626 23.0173 35.5742 22.7538 35.4832 22.4791C35.3971 22.2191 35.2293 22.0234 34.9797 21.8918C34.7375 21.7577 34.4754 21.7374 34.1932 21.8309Z"
            fill="white"
          />
          <path
            d="M37.8518 18.4685L40.3581 17.6385L40.5721 18.2846L40.5944 18.2772C40.6716 18.0126 40.8292 17.7544 41.0671 17.5026C41.3101 17.2408 41.6209 17.0472 41.9997 16.9218C42.4081 16.7865 42.7706 16.7489 43.0871 16.8089C43.4111 16.8664 43.6904 16.9882 43.925 17.1742C44.1646 17.3503 44.3621 17.5733 44.5174 17.8433C44.6802 18.1108 44.8095 18.3893 44.9054 18.6789L46.0859 22.2435L43.5015 23.0994L42.4686 19.9803C42.3703 19.6833 42.2531 19.4913 42.1173 19.4045C41.9888 19.3152 41.8317 19.3013 41.6461 19.3628C41.4753 19.4193 41.3575 19.5366 41.2929 19.7146C41.2332 19.8827 41.2464 20.0967 41.3324 20.3566L42.3653 23.4756L39.7921 24.3278L37.8518 18.4685Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
