import Image from 'next/image'
import Link from 'next/link'
import BookImage from '@/img/book.png'
import { BookTrialLessonButtonBlock } from '@/src/@types/statamic'

export default function Component({ link }: BookTrialLessonButtonBlock) {
  return (
    <Link href={link}>
      <div className="fixed bottom-0 right-0 z-50 flex rounded-tl-[30px] bg-pink-500 px-[25px] pb-3 pt-4 md:rounded-tl-[10px] md:pt-5">
        <div className="relative h-[37px] w-[38px] pt-0.5 md:h-[48px] md:w-[50px]">
          <Image
            src={BookImage}
            sizes="(min-width: 768px) 50px, 38px"
            alt="Probelektion buchen"
            fill
            className="object-contain"
            unoptimized
          />
        </div>
        <div className="ml-3">
          <p className="text-2xs font-bold text-white md:text-xs">
            Probelektion
          </p>
          <p className="font-extrabold text-white md:text-2xl md:leading-6">
            59 CHF
          </p>
        </div>
      </div>
    </Link>
  )
}
