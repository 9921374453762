import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl'

type LatLng = [number, number]

function getSWCoordinates(coordinatesCollection: LatLng[]): LngLatLike {
  const lowestLng = Math.min(
    ...coordinatesCollection.map((coordinates) => coordinates[0])
  )
  const lowestLat = Math.min(
    ...coordinatesCollection.map((coordinates) => coordinates[1])
  )

  return [lowestLng, lowestLat]
}

function getNECoordinates(coordinatesCollection: LatLng[]): LngLatLike {
  const highestLng = Math.max(
    ...coordinatesCollection.map((coordinates) => coordinates[0])
  )
  const highestLat = Math.max(
    ...coordinatesCollection.map((coordinates) => coordinates[1])
  )

  return [highestLng, highestLat]
}

export function calcBoundsFromCoordinates(
  coordinatesCollection: LatLng[]
): LngLatBoundsLike {
  return [
    getSWCoordinates(coordinatesCollection),
    getNECoordinates(coordinatesCollection),
  ]
}
