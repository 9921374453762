import { MagnifyingGlass, X } from '@phosphor-icons/react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { SwissPlaceSearchResult } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl } from '@/src/BlinkAdminApiClient'
import {
  Combobox,
  ComboboxInput,
  ComboboxItem,
  ComboboxList,
} from '@/src/components/core/Combobox'

export default function PlaceSearch({
  selectedPlace,
  setSelectedPlace,
}: {
  selectedPlace: SwissPlaceSearchResult | null
  setSelectedPlace: (place: SwissPlaceSearchResult | null) => void
}) {
  const [placeSearchValue, setPlaceSearchValue] = useState('')
  const [debouncedPlaceSearchValue, setDebouncedPlaceSearchValue] = useState('')
  const [isDebouncing, setIsDebouncing] = useState(false)

  const { data: places, isLoading } = useSWR<SwissPlaceSearchResult[]>(
    debouncedPlaceSearchValue && `places/search?q=${debouncedPlaceSearchValue}`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(`places/search?q=${debouncedPlaceSearchValue}`)
        )
      ).data
  )

  // debounce place search value by 500ms
  useEffect(() => {
    setIsDebouncing(true)
    const timeout = setTimeout(() => {
      setIsDebouncing(false)
      setDebouncedPlaceSearchValue(placeSearchValue)
    }, 200)

    return () => {
      clearTimeout(timeout)
      setIsDebouncing(false)
    }
  }, [placeSearchValue])

  return (
    <>
      {!selectedPlace && (
        <Combobox
          value={placeSearchValue}
          setValue={setPlaceSearchValue}
          shouldFilter={false}
          label="PLZ oder Ort suchen"
        >
          <ComboboxInput label="PLZ oder Ort suchen" />
          <ComboboxList>
            {(isLoading || isDebouncing) && !places?.length && (
              <p className="flex items-center justify-center space-x-2 py-2">
                <svg
                  className="size-4 animate-spin text-purple-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Suche...</span>
              </p>
            )}
            {places?.map((place) => (
              <ComboboxItem
                key={place.id}
                value={place.id.toString()}
                onSelect={() => setSelectedPlace(place)}
              >
                {place.name}
              </ComboboxItem>
            ))}
            {!isLoading && !isDebouncing && places && places.length === 0 && (
              <p className="py-2 text-center text-sm">
                Keine Suchergebnisse gefunden
              </p>
            )}
          </ComboboxList>
        </Combobox>
      )}
      {selectedPlace && (
        <div className="relative flex justify-between rounded border border-blue-125 bg-blue-25 px-[11px] py-[17px]">
          <div className="flex">
            <MagnifyingGlass
              size={20}
              weight="bold"
              className="shrink-0 text-purple-500"
            />
            <p className="absolute left-[40px] top-[2px] scale-75 text-sm">
              Ort
            </p>
            <p className="absolute bottom-[8.5px] left-[42px] font-bold">
              {selectedPlace.name}
            </p>
          </div>
          <button
            className="shrink-0 text-purple-500"
            onClick={() => setSelectedPlace(null)}
          >
            <X size={20} weight="bold" />
          </button>
        </div>
      )}
    </>
  )
}
