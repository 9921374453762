import copy from 'copy-to-clipboard'

export default function share(
  pathname = '',
  query = {},
  title?: string,
  text?: string
) {
  const pathOnly = pathname.split(/[?#]/)[0]
  const url = new URL(
    `${window.location.protocol}//${window.location.host}${pathOnly}`
  )

  const shareData = {
    title,
    text,
    url: `${url}?${new URLSearchParams(query).toString()}`,
  }

  if (navigator.share) {
    navigator.share(shareData).catch(() => {
      copy(`${url}?${new URLSearchParams(query).toString()}`)
    })
  } else {
    copy(`${url}?${new URLSearchParams(query).toString()}`)
  }
}

export function shareV2({
  url,
  title,
  text,
}: {
  url: string
  title?: string
  text?: string
}) {
  const shareData = {
    url,
    title,
    text,
  }

  if (navigator.share) {
    navigator.share(shareData)
  } else {
    copyToClipboard(url)
  }
}

export function copyToClipboard(text: string) {
  if (!window.isSecureContext) {
    // eslint-disable-next-line no-console
    console.error('Copy to clipboard is not available in insecure contexts')
    return
  }
  return window.navigator.clipboard.writeText(text)
}

export const supportsShare = () => {
  return !!navigator.share
}
