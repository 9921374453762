import { Disclosure, Transition } from '@headlessui/react'
import { CaretDown } from '@phosphor-icons/react'
import classNames from 'classnames'
import { Fragment } from 'react'
import TagManager from 'react-gtm-module'
import Content from '../elements/Content'
import Picture from '../elements/Picture'
import { BardAccordionBox as BardAccordionBoxType } from '@/src/@types/statamic'
import useBreakpointReached from '@/src/hooks/useBreakpointReached'

export default function BardAccordionBox({
  accordion_box: accordion,
  fullWidth,
}: BardAccordionBoxType & { fullWidth?: boolean }) {
  const mdBreakpointReached = useBreakpointReached('md')

  const sendGTMEvent = (open: boolean, title: string) => {
    // open is false because accordion isn't yet open
    if (!open) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'accordion_open',
          params: {
            title,
            site: window.location.href,
          },
        },
      })
    }
  }

  return (
    <div
      className={classNames([
        'max-w-full space-y-4',
        {
          'lg:max-w-[66%]': !fullWidth,
        },
      ])}
    >
      {accordion.map((item, index) => {
        const HeadingComponent: keyof Pick<
          JSX.IntrinsicElements,
          'h2' | 'h3' | 'h4'
        > = item.title_tag.value

        return (
          <Fragment key={index}>
            {mdBreakpointReached && (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="group relative flex min-h-[96px] w-full cursor-pointer items-start justify-between overflow-hidden rounded-lg border border-blue-100 bg-blue-50 px-6 py-4 text-left hover:bg-blue-100"
                      onClick={() => sendGTMEvent(open, item.title)}
                    >
                      <div className="absolute left-6 top-4 flex items-center">
                        <div className="relative size-16">
                          <Picture
                            {...item.image}
                            sizes="64px"
                            fill
                            className="object-contain"
                          />
                        </div>
                      </div>
                      <div className="ml-[96px] py-1">
                        <HeadingComponent className="text-lg font-bold">
                          {item.title}
                        </HeadingComponent>
                        {!open && <p className="mt-1">{item.preview_text}</p>}
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            className={classNames([
                              'mt-1 overflow-hidden pr-4 transition-all duration-200',
                              {
                                'max-h-full ease-in': open,
                                'max-h-0 ease-out': !open,
                              },
                            ])}
                          >
                            <Content
                              content={item.text}
                              textSize="allBase"
                              className="max-w-full lg:max-w-full"
                            />
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                      <CaretDown
                        size={20}
                        weight="bold"
                        className={classNames([
                          'mt-2 shrink-0 opacity-80 transition duration-150',
                          {
                            'rotate-180': open,
                          },
                        ])}
                      />
                    </Disclosure.Button>
                    {!open && (
                      <Content content={item.text} className="hidden" />
                    )}
                  </>
                )}
              </Disclosure>
            )}
            {!mdBreakpointReached && (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="group relative flex w-full cursor-pointer flex-col overflow-hidden rounded-lg border border-blue-100 bg-blue-50 px-5 py-4 text-left"
                      onClick={() => sendGTMEvent(open, item.title)}
                    >
                      <div className="absolute left-5 top-4 size-16">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="relative size-16">
                            <Picture
                              {...item.image}
                              sizes="64px"
                              fill
                              className="object-contain"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full justify-end">
                        <div className="flex h-16 items-center">
                          <CaretDown
                            size={20}
                            weight="bold"
                            className={classNames([
                              'mt-2 shrink-0 opacity-80 transition duration-150',
                              {
                                'rotate-180': open,
                              },
                            ])}
                          />
                        </div>
                      </div>
                      <div className="mt-2.5">
                        <HeadingComponent className="font-bold">
                          {item.title}
                        </HeadingComponent>
                        {!open && (
                          <p className="mt-1 pr-4">{item.preview_text}</p>
                        )}
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel
                            className={classNames([
                              'mt-1 overflow-hidden pr-4 transition-all duration-200',
                              {
                                'max-h-full ease-in': open,
                                'max-h-0 ease-out': !open,
                              },
                            ])}
                          >
                            <Content
                              content={item.text}
                              textSize="allBase"
                              className="max-w-full lg:max-w-full"
                            />
                          </Disclosure.Panel>
                        </Transition>
                      </div>
                    </Disclosure.Button>
                    {!open && (
                      <Content content={item.text} className="hidden" />
                    )}
                  </>
                )}
              </Disclosure>
            )}
          </Fragment>
        )
      })}
    </div>
  )
}
