import { Tiles as TilesType } from '../../@types/statamic'
import ArrowAction from '../../components/elements/ArrowAction'
import Content from '../../components/elements/Content'
import Picture from '../../components/elements/Picture'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'

export function getTitle({ optional_title }: TilesType) {
  return optional_title
}

export default function Component({ optional_title, tiles }: TilesType) {
  return (
    <div className="md:pb-12 lg:pb-16">
      <Container>
        {optional_title && (
          <div className="lg:w-1/2">
            <Title style={2}>{optional_title}</Title>
          </div>
        )}
        <ul className="mt-8 flex snap-x snap-mandatory grid-flow-row grid-cols-1 gap-4 overflow-x-auto pb-3 md:mt-10 md:grid-cols-2 lg:grid lg:grid-cols-3 lg:pb-0">
          {tiles.map((tile, index) => (
            <li
              key={index}
              className={`${
                tile.image ? 'lg:row-span-2' : 'lg:row-span-1'
              } flex min-w-[300px] snap-center flex-col overflow-hidden rounded-lg bg-gradient-100 md:min-w-[350px] lg:min-w-0`}
            >
              {tile.image && (
                <div className="relative flex flex-1 flex-col justify-center overflow-visible">
                  <svg
                    className="absolute bottom-0 left-1/2 w-[120%] -translate-x-1/2"
                    viewBox="0 0 408 324"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M396.015 -71.7848C279.024 -185.955 150.787 -186.219 35.6305 -110.325C-77.1389 -32.6518 -83.7022 119.73 -7.32927 231.792C69.0437 343.855 239.093 343.262 342.912 281.598C446.732 219.934 513.007 42.3851 396.015 -71.7848Z"
                      fill={`url(#tile-${index})`}
                    />
                    <defs>
                      <linearGradient
                        id={`tile-${index}`}
                        x1="458"
                        y1="-163.077"
                        x2="-8.03056"
                        y2="396.398"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F3F5FF" />
                        <stop offset="1" stopColor="#E7EBFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className="relative px-10">
                    <Picture
                      {...tile.image}
                      layout="responsive"
                      className="object-scale-down"
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-1 flex-col justify-end p-8">
                <Title style={3}>{tile.title}</Title>
                <div className="mt-2 md:mt-4">
                  <Content content={tile.text} textSize="sm" />
                </div>
                {tile.link_link && tile.link_text && (
                  <div className="mt-2 md:mt-4">
                    <ArrowAction size="small" href={tile.link_link}>
                      {tile.link_text}
                    </ArrowAction>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}
