import classNames from 'classnames'
import {
  ComponentPropsWithoutRef,
  createElement,
  ElementType,
  ForwardedRef,
  forwardRef,
} from 'react'

const DEFAULT_INPUT_TAG = 'input' as const

export type InputProps<TAG extends ElementType = typeof DEFAULT_INPUT_TAG> = {
  as?: TAG
  style?: 'inverted' | 'white'
  hasError?: boolean
} & Omit<ComponentPropsWithoutRef<TAG>, 'style'>

function Input<TAG extends ElementType = typeof DEFAULT_INPUT_TAG>(
  { hasError = false, style = 'white', as, ...props }: InputProps<TAG>,
  ref: ForwardedRef<HTMLElement>
) {
  const defaultType = as !== undefined && as !== 'input' ? props.type : 'text'
  return createElement(
    as || DEFAULT_INPUT_TAG,
    Object.assign({}, props, {
      ref,
      className: classNames(
        props.className,
        { 'text-pink-500': hasError },
        'block w-full outline-0 focus:ring-1 focus:ring-purple-700 focus:outline-0 px-3 border-blue-100 border py-2 rounded font-bold',
        {
          'bg-white': style === 'white',
          'bg-gradient-100': style === 'inverted',
        }
      ),
      inputMode: props.type === 'tel' ? 'tel' : props.inputMode,
      type: props.type || defaultType,
    })
  )
}
export default forwardRef(Input)
