import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useSWR from 'swr'
import HeroBlob from '../HeroBlob/Component'
import { get } from '@/src/BlinkAdminApiClient'

export default function Component() {
  const router = useRouter()

  const coupon = router.query?.coupon

  const { data: author, isLoading } = useSWR(!!coupon && coupon, async () =>
    get<{ first_name: string | null } | undefined>(
      `/website/v2/coupon-donors/${coupon}`
    )
  )

  useEffect(() => {
    if (author?.first_name) {
      localStorage.setItem('trial_lesson_coupon', coupon as string)
    }
  }, [coupon, author])

  if (isLoading) {
    return (
      <HeroBlob
        type="hero_blob"
        title="Gratis Probelektion"
        heading="[Vorname] hat dich eingeladen!"
        locate_me_button={false}
        compact={true}
        hero_content={[
          {
            type: 'text',
            text: 'Buche eine kostenlose Probelektion und lerne BLINK während 45 Minuten ganz unverbindlich kennen!',
          },
        ]}
        link_text="Gratis Probelektion buchen"
        link_link="/"
      />
    )
  }

  if (author?.first_name) {
    return (
      <HeroBlob
        type="hero_blob"
        title="Gratis Probelektion"
        heading={`[${author.first_name}] hat dich eingeladen!`}
        locate_me_button={false}
        compact={true}
        hero_content={[
          {
            type: 'text',
            text: 'Buche eine kostenlose Probelektion und lerne BLINK während 45 Minuten ganz unverbindlich kennen!',
          },
        ]}
        link_text="Gratis Probelektion buchen"
        link_link="/probelektion"
      />
    )
  }

  return (
    <HeroBlob
      type="hero_blob"
      title="Gratis Probelektion"
      heading="Gutschein code nicht gefunden"
      locate_me_button={false}
      compact={true}
      hero_content={[
        {
          type: 'text',
          text: 'Der Gutscheincode ist nicht gültig. Bitte überprüfe den Link und versuche es erneut.',
        },
      ]}
    />
  )
}
