import mapboxgl from 'mapbox-gl'
import { useContext, useEffect, useRef } from 'react'
import { MapContext } from '@/src/components/elements/Map'

const icons = {
  default: "bg-[url('/img/markers/marker.svg')]",
  lesson: "bg-[url('/img/markers/lesson_marker.svg')]",
  pickup: "bg-[url('/img/markers/lesson_marker.svg')]",
  'pgs-pickup': "bg-[url('/img/markers/lesson_marker.svg')]",
  'parking-lot': "bg-[url('/img/markers/lesson_marker.svg')]",
  course: "bg-[url('/img/markers/course_marker.svg')]",
  'exam-office': "bg-[url('/img/markers/exam_marker.svg')]",
  wab: "bg-[url('/img/markers/wab_marker.svg')]",
}

export function getIconBasedOnLocationType(
  locationType?: string
): keyof typeof icons {
  if (locationType && Object.keys(icons).includes(locationType)) {
    return locationType as keyof typeof icons
  }
  return 'default'
}

export default function Marker({
  position,
  children,
  icon = 'default',
  size = 'normal',
}: {
  position: { lat: number; lng: number }
  children?: string
  icon?: keyof typeof icons
  size?: 'normal' | 'large' | 'huge'
}) {
  const context = useContext(MapContext)

  const marker = useRef<mapboxgl.Marker | null>(null)

  useEffect(() => {
    if (!context.map) {
      return
    }
    if (!context.map.current) {
      return
    }
    if (marker.current) {
      marker.current.remove()
    }
    let popup = undefined

    if (children) {
      popup = new mapboxgl.Popup({ offset: 20 }).setHTML(children)
    }

    const markerDiv = document.createElement('svg')
    markerDiv.className = `${icons[icon]} marker bg-center bg-no-repeat`
    switch (size) {
      case 'normal':
        markerDiv.style.width = '20px'
        markerDiv.style.height = '28px'
        break
      case 'large':
        markerDiv.style.width = '30px'
        markerDiv.style.height = '42px'
        break
      case 'huge':
        markerDiv.style.width = '40px'
        markerDiv.style.height = '51px'
        break
    }

    marker.current = new mapboxgl.Marker(markerDiv)
      .setLngLat(position)
      .setPopup(popup)
      .addTo(context.map.current)

    return () => {
      if (!marker.current) return
      marker.current.remove()
    }
  }, [children, position, context.map, icon, size])
  return null
}
