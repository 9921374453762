'use client'

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { forwardRef } from 'react'
import { cn } from '@/core/lib/utils'

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuContent = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    color?: 'dark' | 'light'
  }
>(({ className, sideOffset = 2, color = 'dark', ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-50 min-w-[9rem] overflow-hidden rounded-lg shadow-md',
        'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        {
          'bg-blue-900 text-white': color === 'dark',
          'bg-white border border-blue-100': color === 'light',
        },
        className
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))
// @ts-ignore
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuGroup = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Group> & {
    inset?: boolean
  }
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Group
    ref={ref}
    className={cn('p-1', className)}
    {...props}
  />
))
// @ts-ignore
DropdownMenuGroup.displayName = DropdownMenuPrimitive.Item.displayName

const DropdownMenuItem = forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    color?: 'dark' | 'light'
  }
>(({ className, color = 'dark', ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'flex cursor-pointer select-none items-center space-x-3 rounded p-2 outline-none transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      {
        'focus:bg-blue-100/20': color === 'dark',
        'focus:bg-blue-50': color === 'light',
      },
      className
    )}
    {...props}
  />
))
// @ts-ignore
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuGroup,
}
