import Link from 'next/link'
import { BardCompactLinks as BardCompactLinksType } from '@/src/@types/statamic'

export default function BardCompactLinks({ links }: BardCompactLinksType) {
  return (
    <div className="flex flex-col">
      {links.map((link, index) => (
        <Link
          key={index}
          href={link.link}
          className="text-lg font-bold leading-[27px] text-purple-600 hover:text-purple-800"
        >
          {link.text}
        </Link>
      ))}
    </div>
  )
}
