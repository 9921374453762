import { GetStaticPropsContext } from 'next'
import { SWRConfig } from 'swr'
import { BaseDataType, PartnersPost } from '../@types/statamic'
import { PageProps } from '../@types/util'
import BaseLayout from '../components/elements/BaseLayout'
import CookieBanner from '../components/elements/CookieBanner'
import FaqJsonLd from '../components/elements/FaqJsonLd'
import JsonLd from '../components/elements/JsonLd'
import Picture from '../components/elements/Picture'
import Container from '../components/layout/Container'
import BaseData from '../graphql/BaseData.graphql'
import DefaultPageQuery from '../graphql/DefaultPageQuery.graphql'
import { get } from '../lib/edgeConfig'
import { filterNavs } from '../lib/filterNavs'
import {
  getBlockDescription,
  getBlockImage,
  getBlockTitle,
  loadBlockProps,
  renderBlock,
} from '../lib/PageBuilder'
import { batchRequests } from '../StatamicGraphQLClient'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import Catcher from '@/src/components/elements/Catcher'
import MetaTags from '@/src/components/MetaTags'
import { getAbVariation } from '@/src/lib/abTesting'
import { ensureTrailingSlash } from '@/src/lib/str'

const BASE_URL = process.env.BASE_URL

type Props = PageProps<typeof getStaticProps>

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams, PreviewData>
) {
  const currentUri = `/${context.params?.uri?.join('/') || ''}`

  const [{ entry }, { navs, settings, globalCatcher }] = await batchRequests<
    [PartnersPost, BaseDataType]
  >(
    [
      {
        document: DefaultPageQuery,
        variables: {
          uri: currentUri,
          site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
        },
      },
      {
        document: BaseData,
        variables: { site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE },
      },
    ],
    context.previewData?.statamicToken
  )
  const abTestings = await get('abTestings')

  const filteredNavs = filterNavs(navs)
  const blocks = await loadBlockProps(entry.page_builder, context)

  if (!entry.social_title) {
    entry.social_title = entry.seo_title
  }
  if (!entry.social_text) {
    entry.social_text = entry.seo_summary
  }
  if (!entry.social_image) {
    entry.social_image = entry.image
  }

  if (
    !entry.social_title ||
    !entry.seo_summary ||
    !entry.social_text ||
    !entry.social_image
  ) {
    for (const block of blocks) {
      const title = getBlockTitle(block)
      if (title && !entry.social_title) {
        entry.social_title = title
      }

      const text = getBlockDescription(block)
      if (text && !entry.social_text) {
        entry.social_text = text
      }
      if (text && !entry.seo_summary) {
        entry.seo_summary = text
      }

      const image = getBlockImage(block)
      if (image && !entry.social_image) {
        entry.social_image = image
      }

      if (
        entry.social_title &&
        entry.seo_summary &&
        entry.social_text &&
        entry.social_image
      ) {
        break
      }
    }
  }

  const uri = context.params?.uri ? context.params?.uri?.join('/') : ''

  return {
    props: {
      blocks,
      navs: filteredNavs,
      settings,
      globalCatcher,
      variation: getAbVariation(uri, abTestings),
      title: entry.title,
      image: entry.image || null,
      partner_icon: entry.partner_icon,
      isoDate: entry.isoDate,
      seoTitle: entry.seo_title || null,
      seoSummary: entry.seo_summary || null,
      socialTitle: entry.social_title || null,
      socialText: entry.social_text || null,
      socialImage: entry.social_image || null,
      isoModifiedDate: entry.isoModifiedDate,
      date: entry.date,
      fullUrl: BASE_URL + '/' + ensureTrailingSlash(uri),
      baseUrl: BASE_URL,
    },
  }
}
export default function Partners({
  blocks,
  navs,
  title,
  image,
  partner_icon,
  isoDate,
  isoModifiedDate,
  settings,
  globalCatcher,
  seoTitle,
  seoSummary,
  socialTitle,
  socialText,
  socialImage,
  date,
  fullUrl,
  baseUrl,
}: Props) {
  return (
    <>
      <MetaTags
        seoTitle={seoTitle}
        seoSummary={seoSummary}
        title={socialTitle}
        description={socialText}
        fullUrl={fullUrl}
        date={date}
        image={socialImage}
      />
      <JsonLd
        data={{
          '@context': 'https://schema.org',
          '@type': 'NewsArticle',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': fullUrl,
          },
          headline: title,
          image: [image?.permalink],
          datePublished: isoDate,
          dateModified: isoModifiedDate,
          author: {
            '@type': 'Organization',
            name: 'Blink AG',
            url: baseUrl,
          },
          publisher: {
            '@type': 'Organization',
            name: 'Blink AG',
            logo: {
              '@type': 'ImageObject',
              url: settings.logo?.permalink,
            },
          },
        }}
      />
      <CookieBanner />
      <FaqJsonLd blocks={blocks} />
      <BaseLayout navs={navs} settings={settings}>
        <div>
          <Container>
            <div className="h-16 w-full xl:h-24" />
            <header className="relative h-[13.125rem] w-full lg:h-[35.5rem]">
              <Picture
                {...image}
                fill
                priority
                className="rounded-xl object-cover"
              />
            </header>
            <div className="relative mx-auto max-w-blog">
              <div className="absolute bottom-[-30px] right-[10%] z-20 rounded-[15px] bg-white px-10 py-5 lg:bottom-[-50px] lg:right-0 lg:px-[77px] lg:py-[37px]">
                <div className="relative h-10 w-[100px] lg:h-16 lg:w-[146px]">
                  <Picture
                    {...partner_icon}
                    sizes="(min-width: 1024px) 146px, 100px"
                    fill
                    priority
                    className="object-contain"
                  />
                </div>
              </div>
            </div>
          </Container>
          <div className="relative mx-auto mt-[50px] overflow-hidden pb-16 lg:mt-[70px]">
            <div className="mx-auto max-w-blog space-y-20 px-1 lg:space-y-24">
              <Catcher {...globalCatcher} />
              {blocks.map((block, index) => (
                <SWRConfig
                  key={index}
                  value={{ fallback: block.fallback ?? {} }}
                >
                  <>
                    {renderBlock(block, index, blocks, {
                      settings,
                      socialImage,
                      fullUrl,
                      blueprint: 'partners',
                    })}
                  </>
                </SWRConfig>
              ))}
            </div>
          </div>
        </div>
      </BaseLayout>
    </>
  )
}
