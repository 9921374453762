import Icon from './Icon'

export default function ArrowCounterClockwiseIcon({
  className,
}: {
  className: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.3032 15.3034C13.8956 16.7078 11.9883 17.4965 9.99986 17.4965C8.01142 17.4965 6.10417 16.7078 4.69652 15.3034C4.5793 15.1862 4.51345 15.0272 4.51345 14.8614C4.51345 14.6956 4.5793 14.5367 4.69652 14.4194C4.81374 14.3022 4.97272 14.2364 5.13849 14.2364C5.30426 14.2364 5.46324 14.3022 5.58046 14.4194C6.45454 15.2935 7.56818 15.8888 8.78056 16.1299C9.99294 16.3711 11.2496 16.2473 12.3916 15.7743C13.5337 15.3012 14.5098 14.5001 15.1965 13.4723C15.8833 12.4445 16.2499 11.2361 16.2499 10C16.2499 8.76388 15.8833 7.55551 15.1965 6.5277C14.5098 5.4999 13.5337 4.69882 12.3916 4.22577C11.2496 3.75272 9.99294 3.62895 8.78056 3.87011C7.56818 4.11126 6.45454 4.70651 5.58046 5.58059L5.24572 5.91533L6.67879 7.3484C6.76618 7.43581 6.82569 7.54718 6.8498 7.66841C6.8739 7.78964 6.86152 7.9153 6.81421 8.0295C6.76691 8.1437 6.6868 8.24131 6.58403 8.30998C6.48125 8.37866 6.36042 8.41532 6.23682 8.41533H2.48682C2.32106 8.41533 2.16209 8.34948 2.04488 8.23227C1.92767 8.11506 1.86182 7.95609 1.86182 7.79033V4.04033C1.86182 3.91671 1.89847 3.79587 1.96715 3.69309C2.03583 3.5903 2.13345 3.51019 2.24765 3.46289C2.36186 3.41559 2.48753 3.40321 2.60877 3.42733C2.73001 3.45145 2.84138 3.51098 2.92879 3.59839L4.36182 5.03142L4.69652 4.69672C6.10305 3.29019 8.01071 2.5 9.99985 2.5C11.989 2.5 13.8967 3.29017 15.3032 4.6967C16.7097 6.10323 17.4999 8.0109 17.4999 10C17.4999 11.9892 16.7097 13.8968 15.3032 15.3034Z"
            fill="currentColor"
            stroke="#currentColor"
          />
        </svg>
      }
    />
  )
}
