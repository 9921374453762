import classNames from 'classnames'
import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  useId,
} from 'react'

const styles = {
  white: 'bg-white text-blue-900',
  inverted: 'bg-gradient-100 text-blue-900',
}

export type CheckboxButtonProps<OptionValue> = {
  label: string
  value: OptionValue
  checked?: boolean
  error?: boolean
  style?: 'white' | 'inverted'
} & Omit<ComponentPropsWithoutRef<'input'>, 'style' | 'value' | 'checked'>

function CheckboxButton<OptionValue = any>(
  {
    label,
    value,
    checked = false,
    error = false,
    style = 'white',
    onChange,
    ...props
  }: CheckboxButtonProps<OptionValue>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const id = 'checkbox-' + useId()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    Object.assign(event.target, { value })
    onChange?.(event)
  }

  return (
    <div>
      <input
        type="checkbox"
        className="peer sr-only"
        id={id}
        ref={ref}
        onChange={handleChange}
        checked={checked}
        {...props}
      />
      <label
        htmlFor={id}
        className={classNames(
          {
            'text-pink-500': error,
          },
          styles[style],
          'block cursor-pointer rounded border border-blue-100 px-5 py-3.5 text-sm peer-checked:bg-purple-500 peer-checked:bg-none peer-checked:font-bold peer-checked:text-white'
        )}
      >
        {label}
      </label>
    </div>
  )
}

export default forwardRef(CheckboxButton)
