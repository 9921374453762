import { ComponentPropsWithoutRef, KeyboardEvent } from 'react'

export default function useDateMask(): {
  onKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void
  inputMode: ComponentPropsWithoutRef<'input'>['inputMode']
  type: ComponentPropsWithoutRef<'input'>['type']
} {
  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!/^[0-9.]$/.test(event.key)) {
      event.preventDefault()

      return
    }

    if (event.key === '.') {
      return handleDot(event)
    }

    return handleDigits(event)
  }

  return { onKeyPress, inputMode: 'decimal', type: 'text' }
}

const handleDot = (event: KeyboardEvent<HTMLInputElement>) => {
  const digitsSinceLastDot =
    event.currentTarget.value.split('.').pop()?.length || 0
  const dateParts = event.currentTarget.value.split('.')
  const numberOfDots = dateParts.length - 1
  if (digitsSinceLastDot === 0) {
    event.preventDefault()

    return
  }

  if (numberOfDots === 2) {
    event.preventDefault()

    return
  }

  const lastChild = dateParts.pop() || ''

  dateParts.push(lastChild.padStart(2, '0'))

  event.currentTarget.value = `${dateParts.join('.')}`
}

const handleDigits = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.currentTarget.value.length === 10) {
    event.preventDefault()

    return
  }
  const digitsSinceLastDot =
    event.currentTarget.value.split('.').pop()?.length || 0
  const dateParts = event.currentTarget.value.split('.')
  const numberOfDots = dateParts.length - 1

  if (digitsSinceLastDot === 2 && numberOfDots < 2) {
    event.currentTarget.value += '.'

    return
  }
  if (dateParts.length > 0 && parseInt(dateParts[0]) > 31) {
    dateParts[0] = '31'
  }

  if (dateParts.length > 1 && parseInt(dateParts[1]) > 12) {
    dateParts[1] = '12'
  }

  event.currentTarget.value = `${dateParts.join('.')}`
  if (digitsSinceLastDot === 2 && numberOfDots < 2) {
    event.currentTarget.value += '.'
  }

  return
}
