import { Dialog } from '@headlessui/react'
import { X } from '@phosphor-icons/react'
import { isNumber } from 'lodash-es'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { CatcherBlock } from '@/src/@types/statamic'
import Button from '@/src/components/form/Button'
import Checkbox from '@/src/components/form/Checkbox'
import EmailInput from '@/src/components/form/EmailInput'
import FirstNameInput from '@/src/components/form/FirstNameInput'
import FloatingLabel from '@/src/components/form/FloatingLabel'
import Honeypot from '@/src/components/form/Honeypot'
import IconButton from '@/src/components/form/IconButton'
import LastNameInput from '@/src/components/form/LastNameInput'
import PhoneInput from '@/src/components/form/PhoneInput'
import LoadingSpinner from '@/src/components/icons/LoadingSpinner'
import Container from '@/src/components/layout/Container'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'
import { replacePink } from '@/src/lib/replacePink'

export type CatcherWithModalProps = CatcherBlock & {
  button: string
  modal_title: string
  modal_text: string
  lead_key: string
}
type RequestForm = {
  first_name: string
  last_name: string
  phone: string
  email: string
  location: string
  address: string
  utm_term: string | undefined
  utm_source: string | undefined
  utm_medium: string | undefined
  ref: string | undefined
}

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/leads`

export default function CatcherWithModal({
  title,
  countdown_end,
  show_after,
  button,
  modal_text,
  modal_title,
  lead_key,
}: CatcherWithModalProps) {
  const { utmData } = useUtmCache()

  const [closed, setClosed] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [sending, setSending] = useState(false)
  const [autoOpenTimeout, setAutoOpenTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined)

  const [countdown, setCountdown] = useState<
    | undefined
    | {
        days: number
        hours: number
        minutes: number
        seconds: number
      }
  >(undefined)

  const formRef = useRef<HTMLFormElement>(null)
  const { handleSubmit, register } = useLaravelForm<RequestForm>({
    defaultValues: {
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      location: lead_key || '',
      address: '', // honeypot
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  useEffect(() => {
    if (countdown_end) {
      calculateCountdown()
      const interval = setInterval(() => {
        calculateCountdown(interval)
      }, 1000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown_end])

  useEffect(() => {
    if (isNumber(show_after)) {
      const timeout = setTimeout(() => {
        setIsOpen(true)
      }, show_after * 1000)
      setAutoOpenTimeout(timeout)
    }
  }, [show_after])

  useEffect(() => {
    if (isOpen) {
      formRef.current?.reset()

      // disable auto open if user already openend the dialog
      if (autoOpenTimeout) {
        clearTimeout(autoOpenTimeout)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    // disable auto open if user closed banner
    if (closed && autoOpenTimeout) {
      clearTimeout(autoOpenTimeout)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closed])

  const calculateCountdown = (interval?: NodeJS.Timeout) => {
    if (countdown_end) {
      const now = new Date().getTime()
      const end = new Date(countdown_end.replace(/\s/, 'T')).getTime()
      const distance = end - now
      if (distance < 0 || closed) {
        if (interval) {
          clearInterval(interval)
        }
        setClosed(true)
        return
      }
      setCountdown({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000),
      })
    }
  }

  const closeDialog = () => {
    setIsOpen(false)
    setSubmitted(false)
  }

  if (closed) {
    return <div className="-mb-20"></div>
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => closeDialog()}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          <Dialog.Overlay className="fixed inset-0 bg-blue-900 opacity-[85%]" />
          <div className="relative m-5 flex max-w-[600px] flex-col justify-between rounded-[20px] bg-gradient-600 px-5 py-10 text-white md:px-9">
            <div className="absolute right-5 top-5 md:right-6 md:top-6">
              <button type="button" onClick={() => closeDialog()}>
                <X
                  className="size-7"
                  color="currentColor"
                  weight="bold"
                  alt="Schliessen"
                />
              </button>
            </div>

            <div>
              <h2
                className="mt-7 text-center text-4xl font-extrabold md:text-10xl"
                dangerouslySetInnerHTML={{
                  __html: replacePink(modal_title),
                }}
              />
              <p className="mt-4 text-center text-sm md:text-base">
                {modal_text}
              </p>
              {submitted && (
                <p className="mt-10 text-center text-lg font-extrabold md:text-2xl">
                  Vielen Dank für deine Anfrage!
                </p>
              )}
            </div>

            {!submitted && (
              <form
                ref={formRef}
                onSubmit={handleSubmit(
                  () => {
                    setSubmitted(true)
                    formRef.current?.reset()
                    setSending(false)
                  },
                  (data) => {
                    setSending(true)
                    return data
                  },
                  () => setSending(false)
                )}
                method="POST"
                action={requestUrl}
                className="mt-5"
              >
                <div className="mb-5 grid gap-[10px] text-blue-900 md:grid-cols-2">
                  <FloatingLabel label="Vorname" {...register('first_name')}>
                    {(props) => <FirstNameInput {...props} autoFocus />}
                  </FloatingLabel>
                  <FloatingLabel label="Name" {...register('last_name')}>
                    {(props) => <LastNameInput {...props} />}
                  </FloatingLabel>
                  <FloatingLabel label="E-Mail" {...register('email')}>
                    {(props) => <EmailInput {...props} />}
                  </FloatingLabel>
                  <Honeypot {...register('address')} />
                  <FloatingLabel label="Handynummer" {...register('phone')}>
                    {(props) => <PhoneInput {...props} />}
                  </FloatingLabel>
                </div>
                <Checkbox multiLine required>
                  Ich akzeptiere die{' '}
                  <Link
                    href="/allgemeine-geschaeftsbedingungen"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    allgemeinen Geschäftsbedingungen
                  </Link>
                  . Die{' '}
                  <Link
                    href="/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    Datenschutzerklärung
                  </Link>{' '}
                  habe ich gelesen und verstanden.
                </Checkbox>
                <div className="flex justify-center pt-8 md:mt-0">
                  <Button
                    type="submit"
                    style="white"
                    status={sending ? 'active' : 'default'}
                    disabled={sending}
                    className="inline-flex items-center"
                  >
                    {sending && <LoadingSpinner className="mr-1 size-4" />}
                    {button}
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <div className="flex justify-center pt-8 md:mt-0">
                <Button style="white" onClick={() => closeDialog()}>
                  Schliessen
                </Button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      <Container fullPageOnMobile>
        <div className="relative z-50 -mb-20 -mt-8 flex flex-col items-center justify-between bg-gradient-600 p-7 md:mt-0 md:flex-row md:rounded-[10px]">
          {countdown_end && countdown && (
            <div className="flex space-x-[11px] md:mr-[30px]">
              <div className="flex flex-col items-center">
                <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                  {countdown.days.toString().padStart(2, '0')}
                </div>
                <p className="mt-0.5 text-xs text-white">Tage</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                  {countdown.hours.toString().padStart(2, '0')}
                </div>
                <p className="mt-0.5 text-xs text-white">Stunden</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                  {countdown.minutes.toString().padStart(2, '0')}
                </div>
                <p className="mt-0.5 text-xs text-white">Minuten</p>
              </div>
              <div className="flex flex-col items-center">
                <div className="flex size-[50px] items-center justify-center rounded-full bg-pink-500 pt-1 text-2xl font-extrabold text-white">
                  {countdown.seconds.toString().padStart(2, '0')}
                </div>
                <p className="mt-0.5 text-xs text-white">Sekunden</p>
              </div>
            </div>
          )}
          <p
            className="my-5 grow text-center text-lg font-extrabold text-white md:my-0 md:text-left md:text-2xl"
            dangerouslySetInnerHTML={{
              __html: replacePink(title),
            }}
          />
          <div className="flex shrink-0 items-center">
            <Button
              style="white"
              className="md:ml-[30px]"
              onClick={() => setIsOpen(true)}
            >
              {button}
            </Button>
            <IconButton
              style="transparent-white"
              type="button"
              className="absolute right-2.5 top-2.5 ml-5 flex items-center justify-center md:static"
              onClick={() => setClosed(true)}
            >
              <X
                className="size-7"
                color="currentColor"
                weight="bold"
                alt="Schliessen"
              />
            </IconButton>
          </div>
        </div>
      </Container>
    </>
  )
}
