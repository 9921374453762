import { CourseLocation } from '../../@types/admin'
import ArrowIcon from '../icons/ArrowIcon'
import ArrowAction from './ArrowAction'
import CustomLink from './CustomLink'
import Title from './Title'

export default function UpcomingCourses({
  locations,
}: {
  locations: CourseLocation[]
}) {
  return (
    <ul className="mt-8 grid gap-4 md:mt-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-7 xl:grid-cols-4">
      {locations.map((location) => (
        <li
          key={location.name}
          className="flex flex-1 flex-wrap items-center justify-between space-y-[10px] rounded-lg bg-gradient-100 px-8 py-6 md:space-y-[18px] md:py-8"
        >
          <Title style={4} as="h3">
            {location.name}
          </Title>
          <ul className="order-1 mt-4 w-full space-y-2 md:order-none md:mt-0">
            {location.courses.map((course) => (
              <li key={course.slug}>
                <CustomLink href={location.link + '?course=' + course.slug}>
                  <a className="text-sm hover:underline md:text-base">
                    {course.period.replace('-', '–')}
                  </a>
                </CustomLink>
              </li>
            ))}
          </ul>
          <div className="md:w-full">
            <div className="hidden md:block">
              <ArrowAction href={location.link} size="small">
                Alle anzeigen
              </ArrowAction>
            </div>
            <div className="md:hidden">
              <CustomLink href={location.link}>
                <a>
                  <ArrowIcon className="size-5 text-purple-700" />
                  <span className="sr-only">Alle anzeigen</span>
                </a>
              </CustomLink>
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}
