import axios, { AxiosResponse, Method } from 'axios'
import { SyntheticEvent, useState } from 'react'
import {
  Path,
  useForm,
  SubmitErrorHandler,
  UseFormRegister,
  UseFormRegisterReturn,
  FieldValues,
  UseFormProps,
} from 'react-hook-form'
import { LaravelValidationErrorResponse } from '../@types/laravel'

export declare type LaravelFormRegister<TFieldValues extends FieldValues> = (
  name: Parameters<UseFormRegister<TFieldValues>>[0],
  options?: Parameters<UseFormRegister<TFieldValues>>[1]
) => UseFormRegisterReturn & { error?: string }

export default function useLaravelForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
>(props?: UseFormProps<TFieldValues, TContext>) {
  const {
    clearErrors,
    setError,
    handleSubmit: formHandleSubmit,
    formState,
    reset,
    register,
    ...results
  } = useForm<TFieldValues, TContext>({
    ...props,
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
  })
  const [errorMessage, setErrorMessage] = useState<string>()

  async function sendForm<T extends Promise<ReturnData>, ReturnData = any>(
    serverCall: () => T
  ): Promise<ReturnData> {
    try {
      setErrorMessage(undefined)
      clearErrors()
      return await serverCall()
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        setErrorMessage(
          'Ein Fehler ist aufgetreten, bitte versuche es später nocheinmal.'
        )
        throw error
      }

      if (!isValidationError(error.response)) {
        setErrorMessage(
          'Ein Fehler ist aufgetreten, bitte versuche es später nocheinmal.'
        )
        throw error
      }

      setErrorMessage('Die Eingabe ist ungültig')

      Object.entries(error.response.data.errors).forEach(([field, errors]) => {
        errors.forEach((error) => {
          setError(field as Path<TFieldValues>, {
            type: 'server',
            message: error,
          })
        })
      })

      throw new Error('Validation error')
    }
  }

  function convertToFormData(data: TFieldValues | FormData) {
    if (data instanceof FormData) {
      return data
    }

    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((nestedValue) => {
          formData.append(`${key}[]`, nestedValue)
        })
        return
      }

      if (value === null || value === undefined) {
        return
      }
      formData.append(key, value)
    })

    return formData
  }

  function handleSubmit(
    success: (response: AxiosResponse) => void,
    prepareData?: (
      values: TFieldValues
    ) => (FieldValues & TFieldValues) | FormData,
    error?: SubmitErrorHandler<TFieldValues>,
    method?: Method,
    _contentType: string = 'application/json'
  ) {
    const defaultErrorHandler: SubmitErrorHandler<TFieldValues> = (
      _errors
    ) => {}
    const errorHandler = error || defaultErrorHandler

    return (event: SyntheticEvent<HTMLFormElement>) => {
      clearErrors()
      return formHandleSubmit(async (data, event) => {
        const newData = prepareData ? prepareData(data) : data
        const formMethod = method || event?.target.method || 'POST'

        const response = await sendForm(() =>
          axios({
            method: method || event?.target.method || 'POST',
            url: event?.target.action,
            responseType: 'json',
            data: formMethod !== 'GET' ? convertToFormData(newData) : newData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
        )
        reset()
        success(response)
      }, errorHandler)(event).catch((_error) => {
        errorHandler(formState.errors)
      })
    }
  }
  const newRegister: LaravelFormRegister<TFieldValues> = (name, options) => {
    const props = register(name, options)

    return { ...props, error: formState.errors[name]?.message as string }
  }

  return {
    clearErrors,
    setError,
    errorMessage,
    handleSubmit,
    formState,
    register: newRegister,
    ...results,
  }
}

export function isValidationError(
  response?: AxiosResponse
): response is AxiosResponse<LaravelValidationErrorResponse> {
  return response?.status === 422
}
