import Icon from './Icon'

export default function PlusIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6655 14.7248C20.2116 14.7248 20.6542 14.2822 20.6542 13.7361L20.6541 11.0637C20.6541 10.5176 20.2115 10.075 19.6655 10.075L14.1209 10.0746L14.1209 4.53573C14.1209 3.9897 13.6782 3.54706 13.1322 3.54705L10.4598 3.54701C9.91377 3.54701 9.47111 3.98967 9.47111 4.53572L9.4712 10.0745L3.92655 10.0747C3.38057 10.0748 2.93796 10.5173 2.93789 11.0633L2.93755 13.7357C2.93748 14.2818 3.38014 14.7245 3.92622 14.7245L9.47093 14.7246L9.47102 20.2747C9.47102 20.8208 9.91371 21.2634 10.4598 21.2634L13.1325 21.2632C13.6785 21.2632 14.1211 20.8205 14.1211 20.2745L14.121 14.725L19.6655 14.7248Z"
            className="fill-current"
          />
        </svg>
      }
    />
  )
}
