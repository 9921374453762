import { useState } from 'react'
import { SelectOption } from '@/src/components/core/MultiSelect'

export const weekdaySorting = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
]

export default function useFilter<
  ItemType,
  T extends SelectOption = SelectOption,
>({
  options,
  filterFn,
}: {
  options: SelectOption[]
  filterFn: (item: ItemType, filter: SelectOption[]) => boolean
}) {
  const [filter, setFilter] = useState<T[]>([])

  const matches = (item: ItemType) =>
    filter.length === 0 || filterFn(item, filter)

  return { filter, setFilter, options, matches }
}
