import { Info } from '@phosphor-icons/react'
import { DialogClose } from '@radix-ui/react-dialog'
import axios from 'axios'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { MyBLINK } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl } from '@/src/BlinkAdminApiClient'
import { useBookingContext } from '@/src/components/booking/BookingContext'
import Button from '@/src/components/core/Button'
import { DialogBody, DialogHeader } from '@/src/components/core/Dialog'
import {
  RadioGroupItem,
  RadioGroupRoot,
} from '@/src/components/core/RadioGroup'

export default function Vehicle() {
  const { location, gearbox, instructors, confirmGearbox } = useBookingContext()

  const [selectedGearbox, setSelectedGearbox] = useState<
    'automatic' | 'manual' | null
  >(gearbox)

  const { data: gearboxAvailability, isLoading } =
    useSWR<MyBLINK.GearboxAvailability>(
      location && `booking/locations/${location.id}/gearboxes`,
      async () =>
        (
          await axios.get(
            fullWebsiteUrl(`booking/locations/${location!.id}/gearboxes`)
          )
        ).data
    )

  const instructorHasGearbox = useMemo(() => {
    if (selectedGearbox && instructors?.length === 1) {
      if (selectedGearbox === 'automatic') {
        return instructors[0].gearboxAvailability.hasAutomatic
      }
      if (selectedGearbox === 'manual') {
        return instructors[0].gearboxAvailability.hasManual
      }
    }
    return undefined
  }, [selectedGearbox, instructors])

  const confirmSelection = () => {
    if (selectedGearbox) {
      confirmGearbox(selectedGearbox)
    }
  }

  return (
    <>
      <DialogHeader
        title="Automat oder Geschaltet?"
        description="Etwa 90% unserer Fahrschüler:innen fahren mit Automat."
      />
      <DialogBody>
        {isLoading && !gearboxAvailability && (
          <p className="flex items-center justify-center space-x-2 py-2">
            <svg
              className="size-4 animate-spin text-purple-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            <span>Lade Getriebe...</span>
          </p>
        )}
        {gearboxAvailability && (
          <>
            {gearboxAvailability.hasAutomatic &&
              !gearboxAvailability.hasManual && (
                <p className="mb-4">
                  In <span className="font-bold">{location!.name}</span> kannst
                  du nur automat fahren. Wechsle den Standort, falls du lieber
                  geschaltet fahren möchtest.
                </p>
              )}
            {!gearboxAvailability.hasAutomatic &&
              gearboxAvailability.hasManual && (
                <p className="mb-4">
                  In <span className="font-bold">{location!.name}</span> kannst
                  du nur geschaltet fahren. Wechsle den Standort, falls du
                  lieber <a href=""></a>utomatik fahren möchtest.
                </p>
              )}
            <RadioGroupRoot
              value={selectedGearbox ?? undefined}
              onValueChange={(newGearbox) =>
                setSelectedGearbox(newGearbox as 'automatic' | 'manual')
              }
            >
              <div className="flex space-x-4">
                {gearboxAvailability.hasAutomatic && (
                  <RadioGroupItem value="automatic" color="blue-outlined">
                    Automat
                  </RadioGroupItem>
                )}
                {gearboxAvailability.hasManual && (
                  <RadioGroupItem value="manual" color="blue-outlined">
                    Geschaltet
                  </RadioGroupItem>
                )}
              </div>
            </RadioGroupRoot>
            {instructorHasGearbox === false && (
              <div className="mt-7 flex space-x-2.5 rounded bg-gray-100 p-3">
                <Info
                  size={20}
                  weight="fill"
                  className="mt-0.5 shrink-0 text-gray-700"
                />
                <p>
                  {instructors![0].name} fährt leider nicht{' '}
                  {selectedGearbox === 'automatic' ? 'automat' : 'geschaltet'},
                  willst du trotzdem wechseln?
                </p>
              </div>
            )}
            <div className="mt-8 flex justify-end space-x-4">
              <DialogClose asChild>
                <Button version="purple-ghost">Abbrechen</Button>
              </DialogClose>
              <Button onClick={confirmSelection}>Getriebe bestätigen</Button>
            </div>
          </>
        )}
      </DialogBody>
    </>
  )
}
