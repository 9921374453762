import { AnimatePresence, motion } from 'framer-motion'
import { useRouter } from 'next/router'
import { Fragment, ReactNode } from 'react'
import { NavigationResult, Settings } from '../../@types/statamic'
import useWindowScrolled from '../../hooks/useWindowScrolled'
import Navigation from '../../lib/Navigation'
import Footer from './Footer'
import TopDesktopNavigation from './TopDesktopNavigation'
import TopMobileNavigation from './TopMobileNavigation'

export default function BaseLayout({
  children,
  navs,
  settings,
}: {
  children: ReactNode
  navs: NavigationResult['navs']
  settings: Settings['settings']
}) {
  const router = useRouter()

  const scrolled = useWindowScrolled(10)

  // prevent transitioning on change of query params
  const transitionKey = router.asPath.split('?')[0]

  const navigations = Navigation(navs)

  const primaryNavigation = navigations.get('navigation')
  const secondaryNavigation = navigations.get('secondary')

  return (
    <Fragment>
      <div className="relative flex min-h-screen flex-col">
        <div
          className={`${
            scrolled ? 'bg-white shadow-lg' : ''
          } fixed inset-x-0 top-0 z-10`}
        >
          <div className="hidden xl:block">
            <TopDesktopNavigation
              logo={settings.logo}
              scrolled={scrolled}
              mainNavigation={primaryNavigation}
              secondaryNavigation={secondaryNavigation}
            />
          </div>
          <div className="xl:hidden">
            <TopMobileNavigation
              logo={settings.logo}
              scrolled={scrolled}
              mainNavigation={primaryNavigation}
              secondaryNavigation={secondaryNavigation}
            />
          </div>
        </div>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            className="relative z-0 flex-1"
            key={transitionKey}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            {children}
          </motion.div>
        </AnimatePresence>
        <footer>
          <Footer navs={navs} settings={settings} />
        </footer>
      </div>
    </Fragment>
  )
}
