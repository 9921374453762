import { AxiosResponse } from 'axios'
import { ReactNode, useEffect, useState } from 'react'
import { useUploadContext } from './UploadContext'
import { MediaResponse } from '@/src/@types/blinkadmin'

export type FileInfo =
  | {
      state: 'empty' | 'fetchingInfo'
      fileInfo: undefined
      previewUrl: undefined
      error: string | undefined
    }
  | {
      state: 'uploading'
      fileInfo: undefined
      previewUrl: string
      error: string | undefined
    }
  | {
      state: 'selected'
      fileInfo: MediaResponse
      previewUrl: undefined
      error: string | undefined
    }

export type FileUploadChildrenProps = {
  file: FileInfo
  uploadFile: (file: File) => Promise<void>
  removeFile: () => Promise<void>
}

export default function FileUpload({
  value,
  defaultValue,
  onChange = () => {},
  adminGetFile,
  adminPostFile,
  adminDeleteFile,
  children,
}: {
  value?: string | null
  defaultValue?: string
  onChange?: (fileId: string | null) => void
  adminGetFile: (fileId: string) => Promise<AxiosResponse<MediaResponse>>
  adminPostFile: (formData: FormData) => Promise<AxiosResponse<MediaResponse>>
  adminDeleteFile: (fileId: string) => Promise<any>
  children: (props: FileUploadChildrenProps) => ReactNode
}) {
  const { changeState } = useUploadContext()

  const [controlledFileId, setControlledFileId] = useState(
    value || defaultValue || null
  )

  const [file, setFile] = useState<FileInfo>(
    value || defaultValue
      ? {
          state: 'fetchingInfo',
          fileInfo: undefined,
          previewUrl: undefined,
          error: undefined,
        }
      : {
          state: 'empty',
          fileInfo: undefined,
          previewUrl: undefined,
          error: undefined,
        }
  )

  const fileId = value || controlledFileId

  const setFileId = (fileId: string | null) => {
    onChange(fileId)
    setControlledFileId(fileId)
  }

  useEffect(() => {
    const getFileInfo = async (fileId: string) => {
      const response = await adminGetFile(fileId)
      setFile({
        state: 'selected',
        fileInfo: response.data,
        previewUrl: undefined,
        error: undefined,
      })
    }

    if (fileId && file.state === 'empty') {
      setFile({
        state: 'fetchingInfo',
        fileInfo: undefined,
        previewUrl: undefined,
        error: undefined,
      })
      getFileInfo(fileId)
    } else if (fileId && file.state == 'fetchingInfo') {
      getFileInfo(fileId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const uploadFile = async (file: File) => {
    setFile({
      state: 'uploading',
      fileInfo: undefined,
      previewUrl: URL.createObjectURL(file),
      error: undefined,
    })
    setFileId(null)
    changeState('uploading')

    const formData = new FormData()
    formData.append('file', file)
    const response = await adminPostFile(formData).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      setFile({
        state: 'empty',
        fileInfo: undefined,
        previewUrl: undefined,
        error: undefined,
      })
      changeState('idle')
    })

    if (!response) return

    setFileId(response.data.uuid)
    changeState('idle')
    setFile({
      state: 'selected',
      fileInfo: response.data,
      previewUrl: undefined,
      error: undefined,
    })
  }

  const removeFile = async () => {
    if (fileId) {
      await adminDeleteFile(fileId)
    }
    setFile({
      state: 'empty',
      fileInfo: undefined,
      previewUrl: undefined,
      error: undefined,
    })
    setFileId(null)
  }

  return (
    <>
      {children({ file, uploadFile, removeFile })}
      <input type="hidden" value={fileId ? fileId : ''} hidden />
    </>
  )
}
