'use client'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { cn } from '@/core/lib/utils'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    color?: 'dark' | 'light'
    shadow?: boolean
    arrow?: boolean
    padding?: boolean
  }
>(
  (
    {
      className,
      sideOffset = 4,
      color = 'dark',
      shadow = false,
      arrow = true,
      padding = true,
      children,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'relative z-50 max-w-xs rounded text-sm font-medium',
        'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        {
          'bg-blue-900 text-white': color === 'dark',
          'bg-white border border-blue-100': color === 'light',
          'shadow-xl': shadow,
          'px-4 py-3': padding,
        },
        className
      )}
      {...props}
    >
      {children}
      {arrow && (
        <PopoverPrimitive.Arrow asChild>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx({
              'text-blue-900': color === 'dark',
              'text-white': color === 'light',
            })}
          >
            <path
              d="M0 0L6.24742 7.13991C6.64583 7.59524 7.35416 7.59524 7.75258 7.13991L14 0H0Z"
              fill="currentColor"
            />
          </svg>
        </PopoverPrimitive.Arrow>
      )}
    </PopoverPrimitive.Content>
  )
)
// @ts-ignore
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverContent }
