import { ShareNetwork } from '@phosphor-icons/react'
import { PopoverPortal } from '@radix-ui/react-popover'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { get } from '@/src/BlinkAdminApiClient'
import Button from '@/src/components/core/Button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/src/components/core/Popover'
import { copyToClipboard, shareV2, supportsShare } from '@/src/lib/share'

export default function BardCoupon() {
  const router = useRouter()

  const slug = router.query?.slug

  const { data: coupon, isLoading } = useSWR(!!slug && slug, async () =>
    get<{ code: string | null } | undefined>(
      `/website/v2/students/${slug}/coupon`
    )
  )

  return (
    <div>
      <h3 className="text-xl font-extrabold">Gutschein-Link</h3>
      {coupon?.code && (
        <>
          <div className="mt-6 flex flex-col space-y-3 rounded-lg bg-blue-50 py-3 pl-4 pr-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0">
            <p className="text-gray-600">
              blinkdrive.ch/gratis-probelektion/?coupon=
              <span className="font-bold text-blue-900">{coupon.code}</span>
            </p>
            {(supportsShare() && (
              <Button
                icon={<ShareNetwork weight="fill" />}
                onClick={() =>
                  shareV2({
                    url: `https://blinkdrive.ch/gratis-probelektion/?coupon=${coupon.code}`,
                  })
                }
              >
                Link teilen
              </Button>
            )) || (
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    icon={<ShareNetwork weight="fill" />}
                    onClick={() =>
                      copyToClipboard(
                        `https://blinkdrive.ch/gratis-probelektion/?coupon=${coupon.code}`
                      )
                    }
                  >
                    Link teilen
                  </Button>
                </PopoverTrigger>
                <PopoverPortal>
                  <PopoverContent>Empfehlungslink kopiert!</PopoverContent>
                </PopoverPortal>
              </Popover>
            )}
          </div>
          <p className="mt-4 text-sm text-gray-600">
            Der Gutschein ist nur für Neukund:innen und ist nicht mit anderen
            Angeboten kumulierbar.
          </p>
        </>
      )}
      {!isLoading && !coupon?.code && (
        <p className="mt-6 text-gray-600">
          Gutscheincode konnte nicht geladen werden.
        </p>
      )}
    </div>
  )
}
