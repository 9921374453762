import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next'
import { PropsWithChildren } from 'react'
import { PageParams, PreviewData } from '../@types/infrastructur'
import AvailablePages from '../graphql/AvailablePages.graphql'
import BlueprintByUri from '../graphql/BlueprintByUri.graphql'
import blueprintFinder from '../lib/blueprintFinder'
import genericPageFinder from '../lib/genericPageFinder'
import { request } from '../StatamicGraphQLClient'

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams, PreviewData>
): Promise<GetStaticPropsResult<any>> {
  const { entry } = await request<{
    entry?: { blueprint: string; published: boolean }
  }>(
    BlueprintByUri,
    {
      uri: `/${context.params?.uri?.join('/') || ''}`,
      site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
    },
    context.previewData?.statamicToken
  )

  if (
    entry &&
    (entry.published ||
      (!entry.published && context.previewData?.statamicToken))
  ) {
    const handler = blueprintFinder(entry.blueprint)

    if (!handler) {
      return {
        notFound: true,
      }
    }

    const staticProps = await handler.getStaticProps(context)

    // if there are no props its a redirect or page not found
    if ('props' in staticProps) {
      staticProps.props.blueprint = entry.blueprint
    }

    return {
      revalidate: 60 * 60,
      ...staticProps,
    }
  }

  const adminSiteUrl = `${
    process.env.NEXT_PUBLIC_BLINK_ADMIN_URL
  }/api/website/v2/generic-pages/${context.params?.uri?.join('/') || ''}`

  const adminSite = await fetch(adminSiteUrl)

  if (adminSite.ok) {
    const values = await adminSite.json()
    const handler = genericPageFinder(values.type)

    if (!handler) {
      return {
        notFound: true,
      }
    }

    const staticProps = await handler.getStaticProps(context, values)

    return {
      revalidate: 60 * 60,
      props: {
        generic: true,
        ...values,
        // @ts-ignore
        ...staticProps.props,
      },
    }
  }

  return {
    notFound: true,
  }
}

export async function getStaticPaths(): Promise<
  GetStaticPathsResult<PageParams>
> {
  const {
    entries: { data: pages },
  } = await request<{
    entries: { data: { blueprint: string; uri: string }[] }
  }>(AvailablePages, { site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE })

  const paths = pages
    .filter((entry) => {
      return blueprintFinder(entry.blueprint) !== undefined
    })
    .map((entry) => {
      return {
        params: {
          uri: entry.uri.split('/').splice(1),
        },
      }
    })

  return {
    paths,
    fallback: 'blocking',
  }
}

export default function Page(params: PropsWithChildren<any>) {
  if (params.generic) {
    return genericPageFinder(params.type)?.default(params)
  }

  return blueprintFinder(params.blueprint)?.default(params)
}
