import useSWR, { unstable_serialize } from 'swr'
import { WabCourseType } from '@/src/@types/admin'
import { getWabCourses } from '@/src/BlinkAdminApiClient'

export default function useWabCourses(
  params: Parameters<typeof getWabCourses>[0]
) {
  const key = unstable_serialize(['wab-courses', params?.locations])
  const { data } = useSWR(key, () => getWabCourses(params))

  return [data as WabCourseType[], key] as const
}
