import { FaqBlock } from '@/src/@types/statamic'
import JsonLd from '@/src/components/elements/JsonLd'

export default function Content({ faq_questions }: FaqBlock) {
  return (
    <JsonLd
      data={{
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: faq_questions.map((question) => {
          return {
            '@type': 'Question',
            name: question.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.answer,
            },
          }
        }),
      }}
    />
  )
}
