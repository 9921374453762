import { Car, CaretUp, RocketLaunch, UsersThree } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useBookingContext } from '@/src/components/booking/BookingContext'
import Gearbox from '@/src/components/booking/overview/Gearbox'
import Instructors from '@/src/components/booking/overview/Instructors'
import Location from '@/src/components/booking/overview/location/Location'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@/src/components/core/Dialog'
import Picture from '@/src/components/elements/Picture'

export default function Overview() {
  const {
    location,
    address,
    gearbox,
    instructors,
    slot,
    config,
    setDialogStep,
    setDialogOpen,
  } = useBookingContext()

  useEffect(() => {
    if (!address) {
      setDialogStep('location')
    } else if (!gearbox) {
      setDialogStep('gearbox')
    } else if (!instructors) {
      setDialogStep('instructors')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, gearbox, instructors])

  const [closed, setClosed] = useState<boolean | null>(null)
  const [showHint, setShowHint] = useState(false)

  if (!config.dialogOpen && closed === false) {
    setClosed(true)
    setShowHint(true)
  } else if (closed !== false && config.dialogOpen) {
    setClosed(false)
    setShowHint(false)
  }

  const displayGearbox = () => {
    if (instructors && instructors.length === 1) {
      const instructor = instructors[0]
      if (gearbox === 'automatic' && instructor.preferredAutomaticVehicle) {
        return instructor.preferredAutomaticVehicle.name
      }
      if (gearbox === 'manual' && instructor.preferredManualVehicle) {
        return instructor.preferredManualVehicle.name
      }
    }

    switch (gearbox) {
      case 'automatic':
        return 'Automat'
      case 'manual':
        return 'Geschaltet'
      default:
        return '-'
    }
  }

  const getInstructorName = () => {
    if (!instructors) {
      return '-'
    }
    if (instructors.length === 1) {
      return instructors[0].name
    }
    return `Egal, Team ${location!.name}`
  }

  return (
    <Dialog open={config.dialogOpen} onOpenChange={setDialogOpen}>
      <div className="flex flex-col">
        <div
          className={clsx('lg:rounded-lg lg:bg-white lg:p-5', {
            'lg:border lg:border-blue-125':
              (!address || !gearbox || !instructors) && showHint,
          })}
        >
          <h2 className="text-xl font-extrabold">Deine Auswahl</h2>
          <div className="divide-y divide-blue-100">
            <div className="py-4">
              <p className="text-sm text-gray-700">Standort/Treffpunkt</p>
              <div className="mt-1 flex items-baseline justify-between">
                <p>{address?.name ?? '-'}</p>
                <DialogTrigger
                  onClick={() => setDialogStep('location')}
                  className="ml-6 text-sm font-bold text-purple-600 hover:text-purple-800"
                >
                  {address ? 'Ändern' : 'Auswählen'}
                </DialogTrigger>
              </div>
            </div>
            <div className="py-4">
              <p className="text-sm text-gray-700">Auto/Getriebe</p>
              <div className="mt-1 flex items-baseline justify-between">
                <p>{displayGearbox()}</p>
                {location && (
                  <DialogTrigger
                    onClick={() => setDialogStep('gearbox')}
                    className="ml-6 text-sm font-bold text-purple-600 hover:text-purple-800"
                  >
                    {gearbox ? 'Ändern' : 'Auswählen'}
                  </DialogTrigger>
                )}
              </div>
            </div>
            <div className="pt-4">
              <p className="text-sm text-gray-700">Fahrlehrer:in</p>
              <div className="mt-1 flex items-start justify-between">
                {!instructors && <p>{getInstructorName()}</p>}
                {instructors && instructors.length === 1 && (
                  <div className="flex items-center space-x-2">
                    <div className="relative size-8 shrink-0">
                      <Picture
                        permalink={instructors[0].photo}
                        alt={instructors[0].name}
                        fill
                        sizes="32px"
                        className="rounded-full object-cover object-top"
                      />
                    </div>
                    <p>{getInstructorName()}</p>
                  </div>
                )}
                {instructors && instructors.length > 1 && (
                  <div>
                    <p>{getInstructorName()}</p>
                    <div className="mt-1 flex -space-x-2">
                      {instructors.map((instructor, index) => (
                        <div
                          key={instructor.id}
                          className={clsx(
                            'relative size-8 shrink-0 overflow-hidden rounded-full',
                            {
                              'opacity-20': slot && slot.user !== instructor.id,
                            }
                          )}
                          style={{
                            zIndex: instructors.length - index,
                          }}
                        >
                          <Picture
                            permalink={instructor.photo}
                            alt={instructor.name}
                            fill
                            sizes="32px"
                            className="object-cover object-top"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {gearbox && (
                  <DialogTrigger
                    onClick={() => setDialogStep('instructors')}
                    className="ml-6 mt-1.5 text-sm font-bold text-purple-600 hover:text-purple-800"
                  >
                    {instructors ? 'Ändern' : 'Auswählen'}
                  </DialogTrigger>
                )}
              </div>
            </div>
          </div>
        </div>
        {(!address || !gearbox || !instructors) && showHint && (
          <div className="relative -order-1 mb-2 rounded bg-blue-900 p-5 text-white lg:order-none lg:mt-2">
            <CaretUp
              size={24}
              weight="fill"
              className="absolute bottom-0 left-5 translate-y-1/2 rotate-180 text-blue-900 lg:bottom-auto lg:top-0 lg:-translate-y-1/2 lg:rotate-0"
            />
            {!address && (
              <>
                <p className="flex space-x-2 font-bold">
                  <RocketLaunch size={24} weight="fill" className="shrink-0" />
                  <span>Los geht’s!</span>
                </p>
                <p className="mt-3 text-sm">
                  Wähle einen Standort aus, um mit der Terminbuchung zu starten.
                </p>
                <DialogTrigger
                  onClick={() => setDialogStep('location')}
                  className="mt-4 text-sm font-bold"
                >
                  Standort auswählen
                </DialogTrigger>
              </>
            )}
            {address && !gearbox && (
              <>
                <p className="flex space-x-2 font-bold">
                  <Car size={24} weight="fill" className="shrink-0" />
                  <span>Automat oder Geschaltet?</span>
                </p>
                <p className="mt-3 text-sm">
                  Möchtest du Automat fahren oder lieber selber schalten?
                </p>
                <DialogTrigger
                  onClick={() => setDialogStep('gearbox')}
                  className="mt-4 text-sm font-bold"
                >
                  Getriebe auswählen
                </DialogTrigger>
              </>
            )}
            {address && gearbox && !instructors && (
              <>
                <p className="flex space-x-2 font-bold">
                  <UsersThree size={24} weight="fill" className="shrink-0" />
                  <span>Wähle deine:n Fahrlehrer:in!</span>
                </p>
                <p className="mt-3 text-sm">
                  Bevor wir dir Termine für Fahrstunden anzeigen können, musst
                  du deinen Fahrlehrer oder deine Fahrlehrerin wählen.
                </p>
                <DialogTrigger
                  onClick={() => setDialogStep('instructors')}
                  className="mt-4 text-sm font-bold"
                >
                  Fahrlehrer:in auswählen
                </DialogTrigger>
              </>
            )}
          </div>
        )}
      </div>
      <DialogContent>
        {config.currentDialogStep === 'location' && <Location />}
        {config.currentDialogStep === 'gearbox' && <Gearbox />}
        {config.currentDialogStep === 'instructors' && <Instructors />}
      </DialogContent>
    </Dialog>
  )
}
