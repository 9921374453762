import last from 'lodash-es/last'
import { useRouter } from 'next/router'
import { useEffect, useId, useState } from 'react'
import { NavigationLink } from '@/src/@types/statamic'

export default function useNavigation<DropdownType = any>(
  navigation: NavigationLink[],
  getDropdownData: (index: number) => DropdownType
) {
  const router = useRouter()
  const id = useId()

  const activeNav = (nav: NavigationLink): boolean => {
    if (
      (nav.page.url && router.asPath.startsWith(nav.page.url)) ||
      (nav.children && nav.children.some((navChild) => activeNav(navChild)))
    ) {
      return true
    }

    return false
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', onMouseLeaveNavigation)

    return () =>
      router.events.off('routeChangeComplete', onMouseLeaveNavigation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])
  const [activeDropdowns, setActiveDropdowns] = useState<number[]>([])
  const [animatingOut, setAnimatingOut] = useState(false)
  const resetDropdownState = () => {
    setActiveDropdowns([])
    setAnimatingOut(false)
  }

  let animatingOutTimeout: NodeJS.Timeout
  const onMouseEnterNavItem = (index: number) => {
    if (last(activeDropdowns) === index) {
      return
    }
    if (animatingOutTimeout) {
      clearTimeout(animatingOutTimeout)
      resetDropdownState()
    }
    setActiveDropdowns([...activeDropdowns, index])
    setAnimatingOut(false)
  }

  const onMouseLeaveNavigation = () => {
    setAnimatingOut(true)
    animatingOutTimeout = setTimeout(resetDropdownState, 150)
  }

  const currentIndex = last(activeDropdowns)
  const currentDropdown: DropdownType | null =
    currentIndex !== undefined ? getDropdownData(currentIndex) : null
  const previousIndex = activeDropdowns[activeDropdowns.length - 2]
  const previousDropdown: DropdownType | null =
    previousIndex !== undefined ? getDropdownData(previousIndex) : null
  const direction =
    currentDropdown &&
    previousDropdown &&
    currentIndex !== undefined &&
    previousIndex !== undefined
      ? currentIndex > previousIndex
        ? 'right'
        : 'left'
      : null

  const linkAria = (index: number) => ({
    onFocus: () => onMouseEnterNavItem(index),
    'aria-expanded': currentIndex === index,
    'aria-controls': currentIndex === index ? id : undefined,
  })

  return {
    direction,
    currentIndex,
    onMouseEnterNavItem,
    onMouseLeaveNavigation,
    animatingOut,
    activeNav,
    currentDropdown,
    previousDropdown,
    linkAria,
    dropdownId: id,
  } as const
}
