'use client'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { VariantProps, cva } from 'class-variance-authority'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cn } from '@/core/lib/utils'

export const checkboxLabelStyle = cva(
  'group flex items-center border transition-colors duration-200 hover:duration-150',
  {
    variants: {
      size: {
        normal: 'rounded px-5 pb-3.5 pt-[15px] text-sm',
        medium: 'rounded px-3 pb-2.5 pt-[11px] text-sm',
        small: 'rounded px-3 pb-[7px] pt-2 text-sm',
        filterTag: 'rounded-full px-4 pb-1.5 pt-[7px] text-sm',
      },
      color: {
        blue: [
          'border-blue-125 bg-blue-25',
          'hover:border-blue-150 hover:bg-blue-50',
          'data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-600 data-[state=checked]:text-white',
          'disabled:cursor-not-allowed disabled:border-blue-100 disabled:text-gray-500',
        ],
        white: [
          'border-blue-125 bg-white',
          'hover:border-blue-150 hover:bg-blue-25',
          'data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-600 data-[state=checked]:text-white',
          'disabled:cursor-not-allowed disabled:border-blue-100 disabled:text-gray-500',
        ],
        'blue-outlined': [
          'border-blue-125 bg-blue-25',
          'hover:border-blue-150 hover:bg-blue-50',
          'data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-50',
          'disabled:cursor-not-allowed disabled:border-blue-100 disabled:text-gray-500',
        ],
        'white-outlined': [
          'border-blue-125 bg-white',
          'hover:border-blue-150 hover:bg-blue-25',
          'data-[state=checked]:border-purple-600',
          'disabled:cursor-not-allowed disabled:border-blue-100 disabled:text-gray-500',
        ],
        transparent: [
          'border-transparent px-0',
          'disabled:cursor-not-allowed disabled:border-blue-100 disabled:text-gray-500',
        ],
      },
    },
    defaultVariants: {
      size: 'normal',
      color: 'blue',
    },
  }
)

const indicatorWrapperStyle = cva(
  'mr-2 box-border flex size-5 shrink-0 items-center justify-center rounded-full border border-blue-150 bg-blue-25',
  {
    variants: {
      color: {
        blue: 'text-purple-600 group-disabled:border-blue-100',
        white: 'text-purple-600 group-disabled:border-white',
        'blue-outlined':
          'text-white group-data-[state=checked]:border-purple-600 group-data-[state=checked]:bg-purple-600',
        'white-outlined':
          'text-white group-data-[state=checked]:border-purple-600 group-data-[state=checked]:bg-purple-600',
        transparent:
          'text-white group-data-[state=checked]:border-purple-600 group-data-[state=checked]:bg-purple-600',
      },
    },
  }
)

const RadioGroupRoot = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => (
  <RadioGroupPrimitive.Root className={cn(className)} {...props} ref={ref} />
))
// @ts-ignore
RadioGroupRoot.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> &
    VariantProps<typeof checkboxLabelStyle> & {
      showCheckbox?: boolean
      allowWhitespaceWrap?: boolean
    }
>(
  (
    {
      className,
      size = 'normal',
      color = 'blue',
      showCheckbox = true,
      allowWhitespaceWrap = false,
      children,
      ...props
    },
    ref
  ) => (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(checkboxLabelStyle({ size, color }), className)}
      {...props}
    >
      {showCheckbox && (
        <div className={cn(indicatorWrapperStyle({ color }))}>
          <RadioGroupPrimitive.Indicator
            forceMount
            className="transition-opacity duration-150 data-[state=checked]:opacity-100 data-[state=unchecked]:opacity-0"
          >
            <svg
              width="10"
              height="9"
              viewBox="0 0 10 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.63388 1.11612C10.122 1.60427 10.122 2.39573 9.63388 2.88388L4.63388 7.88388C4.14573 8.37204 3.35427 8.37204 2.86612 7.88388L0.366117 5.38388C-0.122039 4.89573 -0.122039 4.10427 0.366117 3.61612C0.854272 3.12796 1.64573 3.12796 2.13388 3.61612L3.75 5.23223L7.86612 1.11612C8.35427 0.627961 9.14573 0.627961 9.63388 1.11612Z"
                fill="currentColor"
              />
            </svg>
          </RadioGroupPrimitive.Indicator>
        </div>
      )}
      <span
        className={cn('w-full', {
          'whitespace-nowrap': !allowWhitespaceWrap,
        })}
      >
        {children}
      </span>
    </RadioGroupPrimitive.Item>
  )
)
// @ts-ignore
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroupRoot, RadioGroupItem }
