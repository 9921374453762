import JsonLd from './JsonLd'
import {
  BardAccordion,
  BardAccordionBox,
  ContentBardSet,
} from '@/src/@types/statamic'
import { Block } from '@/src/lib/PageBuilder'

export default function FaqJsonLd({ blocks }: { blocks: Block[] }) {
  const questions = getFaqsFromContentBlocks(blocks)

  if (questions.length === 0) return null

  return (
    <JsonLd
      data={{
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: questions.map((item) => {
          return {
            '@type': 'Question',
            name: item.question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: item.answer,
            },
          }
        }),
      }}
    />
  )
}

function getFaqsFromContentBlocks(blocks: Block[]) {
  const questions: {
    question: string
    answer: string
  }[] = []

  const contentBlocks = blocks.filter((block) => block.type === 'content')

  for (const block of contentBlocks) {
    const faqAccordions: BardAccordion[] = block.content.filter(
      (content: ContentBardSet) =>
        content.type === 'accordion' && content.is_faq
    )
    for (const accordion of faqAccordions) {
      questions.push(
        ...accordion.accordion.map((item) => {
          return {
            question: item.title,
            answer: item.text,
          }
        })
      )
    }

    const faqAccordionBoxes: BardAccordionBox[] = block.content.filter(
      (content: ContentBardSet) =>
        content.type === 'accordion_box' && content.is_faq
    )
    for (const accordion of faqAccordionBoxes) {
      questions.push(
        ...accordion.accordion_box
          .filter((item) => item.text || item.preview_text)
          .map((item) => {
            return {
              question: item.title,
              answer: item.text || item.preview_text || '',
            }
          })
      )
    }
  }

  return questions
}
