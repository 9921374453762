import ArrowAction from '../elements/ArrowAction'
import { BardLink as BardLinkType } from '@/src/@types/statamic'

export default function BardLink({ link, text }: BardLinkType) {
  return (
    <div>
      <ArrowAction href={link}>{text}</ArrowAction>
    </div>
  )
}
