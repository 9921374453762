'use client'

import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { cn } from '@/core/lib/utils'

const ConfirmCheckbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    color?: 'blue' | 'white'
  }
>(({ className, color = 'blue', children, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn('group flex items-baseline space-x-3', className)}
    {...props}
  >
    <div
      className={cn(
        'box-border flex size-5 shrink-0 items-center justify-center rounded-sm border border-blue-150 bg-blue-25',
        {
          'group-disabled:border-white': color === 'white',
          'group-disabled:border-blue-100': color === 'blue',
        }
      )}
    >
      <CheckboxPrimitive.Indicator
        forceMount
        className="transition-opacity duration-150 data-[state=checked]:opacity-100 data-[state=unchecked]:opacity-0"
      >
        <svg
          width="10"
          height="9"
          viewBox="0 0 10 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.63388 1.11612C10.122 1.60427 10.122 2.39573 9.63388 2.88388L4.63388 7.88388C4.14573 8.37204 3.35427 8.37204 2.86612 7.88388L0.366117 5.38388C-0.122039 4.89573 -0.122039 4.10427 0.366117 3.61612C0.854272 3.12796 1.64573 3.12796 2.13388 3.61612L3.75 5.23223L7.86612 1.11612C8.35427 0.627961 9.14573 0.627961 9.63388 1.11612Z"
            fill="#7835EC"
          />
        </svg>
      </CheckboxPrimitive.Indicator>
    </div>
    <span className="text-left">{children}</span>
  </CheckboxPrimitive.Root>
))
// @ts-ignore
ConfirmCheckbox.displayName = CheckboxPrimitive.Root.displayName

export { ConfirmCheckbox }
