import CatcherWithModal, {
  CatcherWithModalProps,
} from '@/blocks/Catcher/CatcherWithModal'
import CatcherWithoutModal from '@/blocks/Catcher/CatcherWithoutModal'
import { CatcherBlock } from '@/src/@types/statamic'

export default function Catcher(props: CatcherBlock) {
  if (
    !props.modal_text ||
    !props.modal_title ||
    !props.lead_key ||
    !props.button
  ) {
    return <CatcherWithoutModal {...props} />
  }

  return <CatcherWithModal {...(props as CatcherWithModalProps)} />
}
