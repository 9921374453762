import Icon from './Icon'

export default function LessonMarker({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.69185 5.60807C8.69134 2.66214 12.7581 1.00494 17 1C21.2419 1.00494 25.3087 2.66214 28.3082 5.60807C31.3076 8.554 32.995 12.5481 33 16.7143C33 30.1602 18.4545 40.3164 17.8352 40.7419C17.5903 40.9099 17.2987 41 17 41C16.7013 41 16.4097 40.9099 16.1648 40.7419C15.5455 40.3164 1 30.1602 1 16.7143C1.00503 12.5481 2.69236 8.554 5.69185 5.60807Z"
            fill="#6A29EC"
            stroke="white"
          />
          <path
            d="M25.75 15.75H24.9062L22.7359 10.8672C22.6377 10.6463 22.4775 10.4586 22.2748 10.3268C22.0721 10.1951 21.8355 10.125 21.5938 10.125H12.4062C12.1645 10.125 11.9279 10.1951 11.7252 10.3268C11.5225 10.4586 11.3623 10.6463 11.2641 10.8672L9.09375 15.75H8.25C8.08424 15.75 7.92527 15.8158 7.80806 15.9331C7.69085 16.0503 7.625 16.2092 7.625 16.375C7.625 16.5408 7.69085 16.6997 7.80806 16.8169C7.92527 16.9342 8.08424 17 8.25 17H8.875V23.25C8.875 23.5815 9.0067 23.8995 9.24112 24.1339C9.47554 24.3683 9.79348 24.5 10.125 24.5H12C12.3315 24.5 12.6495 24.3683 12.8839 24.1339C13.1183 23.8995 13.25 23.5815 13.25 23.25V22H20.75V23.25C20.75 23.5815 20.8817 23.8995 21.1161 24.1339C21.3505 24.3683 21.6685 24.5 22 24.5H23.875C24.2065 24.5 24.5245 24.3683 24.7589 24.1339C24.9933 23.8995 25.125 23.5815 25.125 23.25V17H25.75C25.9158 17 26.0747 16.9342 26.1919 16.8169C26.3092 16.6997 26.375 16.5408 26.375 16.375C26.375 16.2092 26.3092 16.0503 26.1919 15.9331C26.0747 15.8158 25.9158 15.75 25.75 15.75ZM13.25 19.5H12C11.8342 19.5 11.6753 19.4342 11.5581 19.3169C11.4408 19.1997 11.375 19.0408 11.375 18.875C11.375 18.7092 11.4408 18.5503 11.5581 18.4331C11.6753 18.3158 11.8342 18.25 12 18.25H13.25C13.4158 18.25 13.5747 18.3158 13.6919 18.4331C13.8092 18.5503 13.875 18.7092 13.875 18.875C13.875 19.0408 13.8092 19.1997 13.6919 19.3169C13.5747 19.4342 13.4158 19.5 13.25 19.5ZM20.75 19.5C20.5842 19.5 20.4253 19.4342 20.3081 19.3169C20.1908 19.1997 20.125 19.0408 20.125 18.875C20.125 18.7092 20.1908 18.5503 20.3081 18.4331C20.4253 18.3158 20.5842 18.25 20.75 18.25H22C22.1658 18.25 22.3247 18.3158 22.4419 18.4331C22.5592 18.5503 22.625 18.7092 22.625 18.875C22.625 19.0408 22.5592 19.1997 22.4419 19.3169C22.3247 19.4342 22.1658 19.5 22 19.5H20.75Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
