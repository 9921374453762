import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import clsx from 'clsx'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Link from 'next/link'
import { useCallback, useEffect, useRef, useState } from 'react'
import { OffersBlock } from '@/src/@types/statamic'
import Button from '@/src/components/core/Button'
import Content from '@/src/components/elements/Content'
import Picture from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import Container from '@/src/components/layout/Container'
import useVisible from '@/src/hooks/useVisible'

export default function Component(offersBlock: OffersBlock) {
  return (
    <div className="md:pb-12 lg:pb-16">
      <Container fullPageOnMobile>
        <div className="lg:hidden">
          <Mobile {...offersBlock} />
        </div>
        <div className="hidden lg:block">
          <Desktop {...offersBlock} />
        </div>
      </Container>
    </div>
  )
}

function Mobile({ title, text, offers }: OffersBlock) {
  const [emblaRef, emblaApi] = useEmblaCarousel()
  const [prevDisabled, setPrevDisabled] = useState(true)
  const [nextDisabled, setNextDisabled] = useState(true)

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevDisabled(!emblaApi.canScrollPrev())
    setNextDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  const prev = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const next = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  return (
    <>
      <div className="mx-5">
        <Title style={2} className="mb-3">
          {title}
        </Title>
        {text && <Content content={text} />}
      </div>
      <div className="mt-6 overflow-hidden" ref={emblaRef}>
        <div className="flex select-none">
          {offers.map((offer) => (
            <div className="ml-5 min-w-0 flex-[0_0_80%] last:mr-5 md:flex-[0_0_40%]">
              <div className="aspect-h-2 aspect-w-3">
                <Picture
                  sizes="(min-width: 768px) 40vw, 80vw"
                  {...offer.image}
                  fill
                  className="rounded-lg object-cover"
                />
              </div>
              <h3 className="mt-4 text-2xl font-extrabold">{offer.title}</h3>
              <p className="mt-2">{offer.text}</p>
              <Link
                href={offer.link}
                className="mt-4 block font-bold text-purple-600 hover:text-purple-800"
              >
                Mehr erfahren
              </Link>
            </div>
          ))}
        </div>
      </div>
      {!(prevDisabled && nextDisabled) && (
        <div className="mr-5 mt-6 flex justify-end space-x-3">
          <Button
            size="icon-small"
            icon={<CaretLeft weight="bold" />}
            onClick={prev}
            version="purple-outlined"
            disabled={prevDisabled}
          />
          <Button
            size="icon-small"
            icon={<CaretRight weight="bold" />}
            onClick={next}
            version="purple-outlined"
            disabled={nextDisabled}
          />
        </div>
      )}
    </>
  )
}

let accordionSkipTimeout: NodeJS.Timeout | null = null

function Desktop({ title, text, offers }: OffersBlock) {
  const [activeOffer, setActiveOffer] = useState(0)
  const ref = useRef<HTMLDivElement>(null)
  const visible = useVisible(ref)

  useEffect(() => {
    if (accordionSkipTimeout) clearTimeout(accordionSkipTimeout)
    if (!visible) return
    accordionSkipTimeout = setTimeout(() => {
      setActiveOffer(activeOffer === offers.length - 1 ? 0 : activeOffer + 1)
    }, 10000)
  }, [visible, activeOffer, offers])

  return (
    <div ref={ref} className="grid grid-cols-2 gap-14">
      <div>
        <Title style={2}>{title}</Title>
        {text && <Content content={text} className="mt-4" />}
        <div className="mt-8">
          <Accordion
            type="single"
            value={activeOffer.toString()}
            onValueChange={(value) => {
              setActiveOffer(parseInt(value, 10))
            }}
          >
            {offers.map((offer, index) => (
              <AccordionItem value={index.toString()}>
                <div
                  className={clsx(
                    "relative border-b border-blue-100 py-5 after:absolute after:inset-x-0 after:-bottom-px after:border-b after:content-['_']",
                    {
                      'after:border-purple-600 after:origin-left after:animate-accordion-loading':
                        activeOffer === index && visible,
                      'after:border-transparent': activeOffer !== index,
                    }
                  )}
                >
                  <AccordionTrigger className="-my-5 w-full py-5 text-left">
                    <h3 className="text-4xl font-extrabold">{offer.title}</h3>
                  </AccordionTrigger>
                  <AccordionContent className="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                    <p className="mt-2">{offer.text}</p>
                    <Link
                      href={offer.link}
                      className="mt-4 inline-block font-bold text-purple-600 hover:text-purple-800"
                    >
                      Mehr erfahren
                    </Link>
                  </AccordionContent>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
      <div className="relative size-full">
        {offers.map((offer, index) => (
          <Picture
            sizes="50vw"
            {...offer.image}
            fill
            className={clsx(
              'rounded-lg object-cover transition-opacity duration-300',
              {
                'opacity-100': activeOffer === index,
                'opacity-0': activeOffer !== index,
              }
            )}
          />
        ))}
      </div>
    </div>
  )
}
