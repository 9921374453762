import { StaticIds } from '../@types/statamic'

const staticBlocks: { [key in StaticIds]: any } = {
  benefits: {
    type: 'tiles',
    title: 'Deine Vorteile bei [BLINK Nothelferkurse]',
    tiles: [
      {
        image: {
          id: 'assets::vorteile_credits.png',
          permalink: 'https://cms.blinkdrive.ch/assets/vorteile_credits.png',
          height: 247,
          width: 341,
          orientation: 'landscape',
          ratio: 1.3805668016194332,
          is_image: false,
          last_modified: '2022-04-25 12:06:20',
          size_bytes: 98211,
          focus_css: '50% 50%',
          alt: 'BLINK Guthaben einlösen für deine Fahrausbildung',
          is_illustration: false,
          illustration_background: null,
        },
        text: '<p>Sichere dir 50 CHF Rabatt auf Theorie App, VKU oder Fahrlektionen.</p>',
        title: '50 CHF BLINK Guthaben',
        link_link: '/blink-guthaben',
        link_text: 'Mehr erfahren',
      },
      {
        image: {
          id: 'assets::vorteile_theorie_app.png',
          permalink:
            'https://cms.blinkdrive.ch/assets/vorteile_theorie_app.png',
          height: 282,
          width: 310,
          orientation: 'landscape',
          ratio: 1.099290780141844,
          is_image: false,
          last_modified: '2022-04-25 12:08:49',
          size_bytes: 1454075,
          focus_css: '50% 50%',
          alt: 'BLINK Theorie App mit den offiziellen Prüfungsfragen für die Theorie Prüfung',
          is_illustration: false,
          illustration_background: null,
        },
        text: '<p>Hol dir mit deinem 50 CHF Guthaben die Theorie App mit den echten Prüfungsfragen!</p>',
        title: 'BLINK Theorie App',
        link_link: '/blink-theorie-app',
        link_text: 'Mehr erfahren',
      },
      {
        image: {
          id: 'assets::vorteile_sehen.png',
          permalink: 'https://cms.blinkdrive.ch/assets/vorteile_sehen.png',
          height: 184,
          width: 238,
          orientation: 'landscape',
          ratio: 1.2934782608695652,
          is_image: false,
          last_modified: '2022-04-25 12:09:11',
          size_bytes: 579219,
          focus_css: '50% 50%',
          alt: 'Gratis Sehtest für das Lernfahrgesuch',
          is_illustration: false,
          illustration_background: null,
        },
        text: '<p>Bei VIU gibt&#039;s für dich einen gratis Sehtest und weitere exklusive Angebote!</p>',
        title: 'Kostenloser Sehtest',
        link_link: '/deals/viu-eyewear/',
        link_text: 'Mehr erfahren',
      },
    ],
  },
}

export default staticBlocks
