import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import 'swiper/css/autoplay'
import { ImageType } from '../../@types/statamic'
import Picture from '../elements/Picture'
import styles from '/src/css/Masks.module.css'
import ArrowIcon from '../icons/ArrowIcon'
import { useBetterUseId } from '@/src/hooks/betterUseId'

export default function SliderHeader({ images }: { images: ImageType[] }) {
  const paginationId = `pagination-header-${useBetterUseId()}`
  const prevElementId = `prev-el-header-${useBetterUseId()}`
  const nextElementId = `next-el-header-${useBetterUseId()}`

  return (
    <div className="-mx-4 md:mx-0 md:pl-24">
      <div
        className={`aspect-h-2 aspect-w-3 relative w-full md:aspect-h-1 md:aspect-w-1`}
      >
        <div>
          <svg
            aria-hidden="true"
            className="absolute left-[85%] top-[120%] w-[18%] md:-top-1/4 md:left-[-10%] md:w-1/2"
            viewBox="0 0 158 155"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M138.447 28.9544C103.024 -7.25606 64.1952 -7.3399 29.3275 16.7309C-4.81746 41.366 -6.80473 89.6957 16.3199 125.238C39.4446 160.78 90.9331 160.592 122.368 141.034C153.803 121.477 173.871 65.1649 138.447 28.9544Z"
              fill="url(#paint0_linear_1896_9981)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_9981"
                x1="51.3641"
                y1="15.8074"
                x2="121.126"
                y2="151.98"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            aria-hidden="true"
            className="absolute bottom-[-40%] right-[10%] w-3/4 md:bottom-[-15%] md:right-[-30%]"
            viewBox="0 0 445 439"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M391.544 82.4217C290.65 -20.6551 180.058 -20.8938 80.7473 47.6263C-16.5055 117.752 -22.1657 255.328 43.6986 356.502C109.563 457.676 256.214 457.141 345.748 401.469C435.282 345.796 492.438 185.499 391.544 82.4217Z"
              fill="url(#paint0_linear_1896_11704)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1896_11704"
                x1="143.512"
                y1="44.9975"
                x2="342.03"
                y2="432.719"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D6DCFF" />
                <stop offset="1" stopColor="#EEF1FF" />
              </linearGradient>
            </defs>
          </svg>
          <Swiper
            id="test"
            navigation={{
              prevEl: `#${prevElementId}`,
              nextEl: `#${nextElementId}`,
              hiddenClass: '!hidden',
              disabledClass: '!hidden',
            }}
            pagination={{
              el: `#${paginationId}`,
              bulletClass: 'h-3 w-3 inline-block rounded-full bg-blue-100 mx-1',
              clickable: true,
            }}
            modules={[Navigation, Pagination, A11y, Autoplay]}
            a11y={{
              prevSlideMessage: 'Vorheriges Bild',
              nextSlideMessage: 'Nächstes Bild',
              paginationBulletMessage: 'Gehe zu Bild {{index}}',
              lastSlideMessage: 'Dies ist das letzte Bild',
              firstSlideMessage: 'Dies ist das erste Bild',
            }}
            slidesPerView={1}
            className="h-full"
            loop
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`absolute inset-0 bg-gradient-800 ${styles['mask-8']} ${styles['mask-11']} ${styles['mask']}`}
                >
                  <Picture priority {...image} fill className="object-cover" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            id={prevElementId}
            type="button"
            className="group absolute -left-6 right-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 md:flex"
          >
            <ArrowIcon className="size-5 rotate-180 text-purple-700 group-hover:text-white" />
          </button>
          <button
            id={nextElementId}
            type="button"
            className="group absolute -right-6 left-auto top-[calc(50%-2.5rem)] z-10 hidden size-12 cursor-pointer items-center justify-center rounded-full bg-gradient-100 hover:bg-gradient-600 md:flex"
          >
            <ArrowIcon className="size-5 text-purple-700 group-hover:text-white" />
          </button>
          <div
            id={paginationId}
            className="absolute inset-x-0 !bottom-[-5%] z-10 text-center"
          />
        </div>
      </div>
    </div>
  )
}
