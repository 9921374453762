import classNames from 'classnames'
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react'

const Checkbox = (
  {
    multiLine = false,
    children,
    value: _value,
    defaultChecked: _defaultChecked,
    ...props
  }: {
    multiLine?: boolean
    children: ReactNode
  } & Omit<ComponentPropsWithoutRef<'input'>, 'type'>,
  ref?: ForwardedRef<HTMLInputElement>
) => {
  const newProps = Object.assign({}, props, {
    className:
      'h-5 w-5 text-purple-700 focus:outline-purple-700 flex-shrink-0 disabled:text-gray-200 hover:cursor-pointer hover:disabled:cursor-not-allowed border-2 border-purple-700 rounded',
    type: 'checkbox',
  })

  return (
    <label
      className={classNames('relative flex', {
        'items-start': multiLine,
        'items-center': !multiLine,
        'pointer-events-none': props.disabled,
        'cursor-pointer': !props.disabled,
      })}
    >
      <input {...newProps} type="checkbox" ref={ref} />
      <span
        className={classNames('ml-2 flex-1 text-sm', {
          '-mt-1 block': multiLine,
        })}
      >
        {children}
      </span>
    </label>
  )
}

export default forwardRef(Checkbox)
