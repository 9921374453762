import { GetStaticPropsContext } from 'next'
import Link from 'next/link'
import { ImageType, MagazineBlock } from '../../@types/statamic'
import ArrowAction from '../../components/elements/ArrowAction'
import Picture from '../../components/elements/Picture'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import MagazineQuery from '../../graphql/MagazineQuery.graphql'
import useGradientId from '../../hooks/useGradientId'
import { request } from '../../StatamicGraphQLClient'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'

export function getTitle({ title }: MagazineBlock) {
  return title
}

export function getImage({ image }: MagazineBlock) {
  return image
}

export async function getStaticProps(
  { limit, category, ...props }: MagazineBlock,
  page: GetStaticPropsContext<PageParams, PreviewData>
) {
  const {
    entries: { data: entries },
  } = await request<{
    entries: {
      data: {
        uri: string
        title: string
        image: ImageType
        preview_text?: string
        date: string
      }[]
    }
  }>(
    MagazineQuery,
    {
      limit,
      site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
      category: category ? category.value : 'magazine',
    },
    page.previewData?.statamicToken
  )

  return {
    ...props,
    entries,
  }
}

export default function Component({
  title,
  link_link,
  link_text,
  entries,
}: Awaited<ReturnType<typeof getStaticProps>>) {
  const [gradient1Id, gradient1Reference] = useGradientId()
  const [gradient2Id, gradient2Reference] = useGradientId()

  return (
    <div className="relative pt-12 md:pb-12 md:pt-16 lg:pb-16 lg:pt-20">
      <div className="absolute left-[-2%] top-0 flex w-[70%] items-start lg:left-[10%] lg:w-[30%]">
        <svg
          className="mt-[1%] w-4/5"
          viewBox="0 0 370 385"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M257.927 375.703C257.927 375.703 358.129 352.196 368.876 205.001C379.622 57.8057 284.81 28.3362 252.226 10.9938C219.642 -6.34855 118.945 -6.80839 49.5227 55.3032C-19.8999 117.415 -29.1387 339.006 111.552 372.988C212.619 397.398 257.927 375.703 257.927 375.703Z"
            fill={gradient1Reference}
          />
          <defs>
            <linearGradient
              id={gradient1Id}
              x1="379.053"
              y1="10.0844"
              x2="-56.1333"
              y2="342.548"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3F5FF" />
              <stop offset="1" stopColor="#E7EBFF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          className="w-1/5"
          viewBox="0 0 121 115"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            d="M111.318 86.2263C133.352 44.3488 116.973 6.75643 70.3696 0.718243C23.7662 -5.31995 -12.6497 27.3237 4.17702 75.6639C21.0037 124.004 89.2839 128.104 111.318 86.2263Z"
            fill={gradient2Reference}
          />
          <defs>
            <linearGradient
              id={gradient2Id}
              x1="-8.26833"
              y1="5.175"
              x2="126.258"
              y2="110.279"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FBFBFF" />
              <stop offset="1" stopColor="#DAE0FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <Container>
        <div className="relative flex flex-wrap items-baseline justify-between">
          <Title style={2}>{title}</Title>
          <div className="mt-8 w-full md:order-1 md:mt-10">
            <div className="-mx-7 lg:mx-0">
              <div className="flex snap-x snap-mandatory space-x-4 overflow-auto px-7 pb-3 lg:grid lg:grid-flow-row lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8 lg:space-x-0 lg:overflow-visible lg:px-0 lg:pb-0">
                {entries.map((entry, index) => (
                  <article
                    key={index}
                    className="group flex w-80 max-w-[calc(100%-2rem)] shrink-0 snap-center lg:w-full lg:max-w-full"
                  >
                    <Link
                      href={entry.uri}
                      className="block flex-1 overflow-hidden rounded-lg bg-gradient-100 transition-all hover:bg-gradient-800 hover:text-white"
                    >
                      <div className="relative h-56 w-full transition-transform group-hover:scale-105 lg:h-64">
                        <Picture
                          {...entry.image}
                          sizes="320px"
                          fill
                          className="object-cover"
                        />
                      </div>
                      <div className="p-8">
                        <time
                          dateTime={entry.date}
                          className="block text-sm font-bold md:text-base"
                        >
                          {entry.date}
                        </time>
                        <h3 className="mt-4 text-lg font-extrabold md:text-2xl">
                          {entry.title}
                        </h3>
                        <p className="mt-4 text-sm md:text-base">
                          {entry.preview_text}
                        </p>
                      </div>
                    </Link>
                  </article>
                ))}
              </div>
            </div>
          </div>
          {link_link && link_text && (
            <div className="mt-6 self-center md:mt-0">
              <ArrowAction href={link_link}>{link_text}</ArrowAction>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
