import { BagSimple, MapPin } from '@phosphor-icons/react'
import { useState } from 'react'
import Container from '../../components/layout/Container'
import { JobsBlock } from '@/src/@types/statamic'
import ArrowAction from '@/src/components/elements/ArrowAction'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import ArrowIcon from '@/src/components/icons/ArrowIcon'

export function getTitle({ title }: JobsBlock) {
  return title
}

export default function Component({ title, jobs, expand_text }: JobsBlock) {
  const [limit, setLimit] = useState(4)

  return (
    <Container>
      <Title style={2}>{title}</Title>
      <div className="mt-8 grid grid-cols-1 gap-y-6 md:mt-10">
        {jobs.slice(0, limit).map((job, index) => (
          <div
            key={index}
            className="flex flex-col justify-between rounded-[15px] bg-gradient-100 p-7 md:flex-row md:items-center md:px-10 md:py-7"
          >
            <div>
              <p className="text-lg font-extrabold md:text-2xl">{job.name}</p>
              <div className="mt-3 md:flex">
                {job.workload && (
                  <p className="my-1 md:my-0 md:mr-5">
                    <BagSimple className="mr-2 inline size-6" weight="fill" />
                    {job.workload}
                  </p>
                )}
                {job.location && (
                  <p className="my-1 md:my-0">
                    <MapPin className="mr-2 inline size-6" weight="fill" />
                    {job.location}
                  </p>
                )}
              </div>
            </div>
            {job.link_link && job.link_text && (
              <div className="mt-4 md:mt-0">
                <ArrowAction href={job.link_link}>{job.link_text}</ArrowAction>
              </div>
            )}
          </div>
        ))}
      </div>
      {jobs.length > limit && (
        <div className="mt-5 text-center">
          <Button
            style="transparent"
            className="group inline-flex items-center lg:text-base"
            onClick={() => setLimit(jobs.length)}
          >
            {expand_text}
            <ArrowIcon className="ml-3 size-5 rotate-90 transition-transform duration-300 group-hover:translate-y-1" />
          </Button>
        </div>
      )}
    </Container>
  )
}
