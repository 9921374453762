import clsx from 'clsx'
import { GetStaticPropsContext } from 'next'
import { SWRConfig } from 'swr'
import { BaseDataType, Pages } from '../@types/statamic'
import { PageProps } from '../@types/util'
import BaseLayout from '../components/elements/BaseLayout'
import CookieBanner from '../components/elements/CookieBanner'
import FaqJsonLd from '../components/elements/FaqJsonLd'
import BaseData from '../graphql/BaseData.graphql'
import DefaultPageQuery from '../graphql/DefaultPageQuery.graphql'
import { get } from '../lib/edgeConfig'
import { filterNavs } from '../lib/filterNavs'
import {
  getBlockDescription,
  getBlockImage,
  getBlockTitle,
  loadBlockProps,
  renderBlock,
} from '../lib/PageBuilder'
import { batchRequests } from '../StatamicGraphQLClient'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import Catcher from '@/src/components/elements/Catcher'
import MetaTags from '@/src/components/MetaTags'
import { getAbVariation } from '@/src/lib/abTesting'
import { ensureTrailingSlash } from '@/src/lib/str'

const BASE_URL = process.env.BASE_URL

type Props = PageProps<typeof getStaticProps>

export async function getStaticProps(
  context: GetStaticPropsContext<PageParams, PreviewData>
) {
  const currentUri = `/${context.params?.uri?.join('/') || ''}`

  const [{ entry }, { navs, settings, globalCatcher }] = await batchRequests<
    [Pages, BaseDataType]
  >(
    [
      {
        document: DefaultPageQuery,
        variables: {
          uri: currentUri,
          site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
        },
      },
      {
        document: BaseData,
        variables: { site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE },
      },
    ],
    context.previewData?.statamicToken
  )
  const abTestings = await get('abTestings')

  const filteredNavs = filterNavs(navs)
  const blocks = await loadBlockProps(entry.page_builder, context)

  if (!entry.social_title) {
    entry.social_title = entry.seo_title
  }
  if (!entry.social_text) {
    entry.social_text = entry.seo_summary
  }

  if (
    !entry.social_title ||
    !entry.seo_summary ||
    !entry.social_text ||
    !entry.social_image
  ) {
    for (const block of blocks) {
      const title = getBlockTitle(block)
      if (title && !entry.social_title) {
        entry.social_title = title
      }

      const text = getBlockDescription(block)
      if (text && !entry.social_text) {
        entry.social_text = text
      }
      if (text && !entry.seo_summary) {
        entry.seo_summary = text
      }

      const image = getBlockImage(block)
      if (image && !entry.social_image) {
        entry.social_image = image
      }

      if (
        entry.social_title &&
        entry.seo_summary &&
        entry.social_text &&
        entry.social_image
      ) {
        break
      }
    }
  }

  const uri = context.params?.uri ? context.params?.uri?.join('/') : ''

  return {
    props: {
      blocks,
      navs: filteredNavs,
      settings,
      variation: getAbVariation(uri, abTestings),
      globalCatcher,
      seoTitle: entry.seo_title || null,
      seoSummary: entry.seo_summary || null,
      socialTitle: entry.social_title || null,
      socialText: entry.social_text || null,
      socialImage: entry.social_image || null,
      date: entry.date,
      fullUrl: uri ? BASE_URL + '/' + ensureTrailingSlash(uri) : BASE_URL,
      noindex: entry.noindex,
    },
  }
}

export default function Home({
  blocks,
  navs,
  settings,
  globalCatcher,
  seoTitle,
  seoSummary,
  socialTitle,
  socialText,
  socialImage,
  date,
  fullUrl,
  noindex,
}: Props) {
  return (
    <>
      <MetaTags
        seoTitle={seoTitle}
        seoSummary={seoSummary}
        title={socialTitle}
        description={socialText}
        fullUrl={fullUrl}
        date={date}
        image={socialImage}
        noindex={noindex}
      />
      <CookieBanner />
      <FaqJsonLd blocks={blocks} />
      <BaseLayout navs={navs} settings={settings}>
        <div
          className={clsx('space-y-20 pb-16 pt-24 lg:space-y-24 xl:pt-36', {
            'lg:bg-blue-100': blocks.some(
              (block) => block.block === 'book_trial_lesson'
            ),
          })}
        >
          <Catcher {...globalCatcher} />
          {blocks.map((block, index) => (
            <div key={index} className="relative">
              <span
                id={`${block.block}-${
                  blocks.slice(0, index).filter((b) => b.block === block.block)
                    .length + 1
                }`}
                className="absolute -top-20"
              ></span>
              <SWRConfig value={{ fallback: block.fallback ?? {} }}>
                {renderBlock(block, index, blocks, {
                  settings,
                  socialImage,
                  fullUrl,
                  blueprint: 'pages',
                })}
              </SWRConfig>
            </div>
          ))}
        </div>
      </BaseLayout>
    </>
  )
}
