import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment } from 'react'
import { Flipped, Flipper } from 'react-flip-toolkit'
import { ImageType, NavigationLink } from '../../@types/statamic'
import Button from '../form/Button'
import Container from '../layout/Container'
import CustomLink from './CustomLink'
import PhoneButton from './PhoneButton'
import WhatsappButton from './WhatsappButton'
import SubNavigation, {
  determineColumns,
} from '@/src/components/elements/SubNavigation'
import useNavigation from '@/src/hooks/useNavigation'

export default function TopDesktopNavigation({
  logo,
  scrolled = false,
  mainNavigation,
  secondaryNavigation,
}: {
  logo?: ImageType
  scrolled?: boolean
  mainNavigation: NavigationLink[]
  secondaryNavigation: NavigationLink[]
}) {
  const router = useRouter()

  return (
    <Container>
      <div
        className={classNames('flex h-16 items-center transition-all', {
          'xl:h-24': !scrolled,
          'xl:h-16': scrolled,
        })}
      >
        {logo && (
          <Link
            href="/"
            className={classNames([
              'block',
              {
                'w-24 xl:w-32':
                  process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'default',
                'w-[92px]':
                  process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'nohe',
              },
            ])}
            onContextMenu={(e) => {
              e.preventDefault()
              router.push('/assets')
            }}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src={logo.permalink} alt={logo.alt} className="w-full" />
            <span className="sr-only">Startseite</span>
          </Link>
        )}
        <MainNavigation navigation={mainNavigation} />
        <SecondaryNavigation navigation={secondaryNavigation} />

        <ul className="ml-8 flex items-center space-x-2">
          <li>
            <CustomLink
              href="https://myblink.blinkdrive.ch"
              passHref
              newTab={false}
            >
              <Button as="a" size="small">
                myBLINK
              </Button>
            </CustomLink>
          </li>
          {process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'default' && (
            <>
              <li>
                <PhoneButton />
              </li>
              <li>
                <WhatsappButton />
              </li>
            </>
          )}
        </ul>
      </div>
    </Container>
  )
}

function determineDropdownOffset(
  index: number,
  links: NavigationLink,
  sidebar?: NavigationLink
) {
  const columns = determineColumns(links, sidebar)

  if (columns <= 2) {
    return 0
  }

  if (index === 0) {
    return 40 * (columns - 2)
  }
  if (index === 1) {
    return 0
  }
  if (index === 2) {
    return -40 * (columns - 2)
  }

  if (index === 3) {
    return -90 * (columns - 2)
  }

  return 0
}

function SecondaryNavigation({ navigation }: { navigation: NavigationLink[] }) {
  const {
    direction,
    currentIndex,
    onMouseEnterNavItem,
    onMouseLeaveNavigation,
    animatingOut,
    activeNav,
    currentDropdown,
    previousDropdown,
    linkAria,
    dropdownId,
  } = useNavigation(navigation, (index) => navigation[index].children)

  return (
    <ul
      className="ml-auto flex items-center text-sm"
      onMouseLeave={onMouseLeaveNavigation}
    >
      {navigation.map((link, index) => (
        <li
          key={index}
          className="relative text-sm font-medium"
          onMouseEnter={() => onMouseEnterNavItem(index)}
        >
          {link.page.url ? (
            <CustomLink href={link.page.url}>
              <a
                className={classNames([
                  'block cursor-pointer p-4 hover:text-purple-800',
                  {
                    'text-purple-600':
                      navigation[index] &&
                      activeNav({
                        page: navigation[index].page,
                        children: navigation[index].children.slice(0, 1),
                      }),
                  },
                ])}
                {...linkAria(index)}
              >
                {link.page.title}
              </a>
            </CustomLink>
          ) : (
            <button
              type="button"
              className={classNames([
                'cursor-pointer p-4 font-medium hover:text-purple-800',
                {
                  'text-purple-600':
                    navigation[index] &&
                    activeNav({
                      page: navigation[index].page,
                      children: navigation[index].children.slice(0, 1),
                    }),
                },
              ])}
              {...linkAria(index)}
            >
              {link.page.title}
            </button>
          )}
          {currentIndex === index &&
            currentDropdown &&
            currentDropdown.length > 0 && (
              <Fragment>
                <div
                  className={classNames(
                    'absolute left-1/2 top-full -translate-x-1/5'
                  )}
                  style={{
                    perspective: '1000px',
                    marginLeft: '1px',
                  }}
                  id={dropdownId}
                >
                  <div
                    className={classNames('origin-top-left', {
                      'animate-dropdown-out': !direction && animatingOut,
                      'animate-dropdown-in': !direction && !animatingOut,
                    })}
                    style={{ animationFillMode: 'forwards' }}
                  >
                    <Flipped flipId="dropdown">
                      <div
                        className={`relative mt-2 origin-top-left overflow-hidden rounded-lg bg-white py-10 shadow-lg-no-offset`}
                      >
                        <Flipped inverseFlipId="dropdown" scale>
                          <div className="relative">
                            <div
                              style={{
                                animationTimingFunction: 'linear',
                                animationFillMode: 'forwards',
                              }}
                            >
                              <div className="w-[270px]">
                                <div className="flex-1 pl-10">
                                  <div className="columns-[240px] gap-0 space-y-8 whitespace-nowrap">
                                    <div className="font-medium">
                                      <ul>
                                        {currentDropdown.map((link, index) => (
                                          <li key={index}>
                                            <CustomLink
                                              href={link.page.url || '#'}
                                            >
                                              <a className="text-xs text-blue-900 hover:text-purple-800">
                                                {link.page.title}
                                              </a>
                                            </CustomLink>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {previousDropdown &&
                              previousDropdown.length > 0 && (
                                <div
                                  aria-hidden="true"
                                  className={classNames(
                                    'absolute left-0 top-0',
                                    {
                                      'animate-nav-fade-out-right':
                                        direction && direction === 'left',
                                      'animate-nav-fade-out-left':
                                        direction && direction === 'right',
                                      'animate-fade-out': !direction,
                                    }
                                  )}
                                  style={{
                                    animationTimingFunction: 'linear',
                                    animationFillMode: 'forwards',
                                  }}
                                >
                                  <ul>
                                    {previousDropdown.map((link, index) => (
                                      <li key={index}>
                                        <CustomLink href={link.page.url || '#'}>
                                          <a className="text-xs font-bold text-blue-900 hover:text-purple-800">
                                            {link.page.title}
                                          </a>
                                        </CustomLink>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </Flipped>
                      </div>
                    </Flipped>
                  </div>
                </div>
                <div
                  className={classNames(
                    'absolute left-1/2 top-[calc(100%-16px)] -translate-x-1/2'
                  )}
                  style={{ perspective: '1000px' }}
                  id={`${dropdownId}-bg`}
                >
                  <div
                    className={classNames('origin-top-left', {
                      'animate-dropdown-out': !direction && animatingOut,
                      'animate-dropdown-in': !direction && !animatingOut,
                    })}
                    style={{ animationFillMode: 'forwards' }}
                  >
                    <Flipped flipId="dropdown-caret">
                      <div className="z-10 w-16 translate-x-3 overflow-hidden">
                        <div className="size-6 origin-bottom-left rotate-45 bg-white shadow-lg-no-offset" />
                      </div>
                    </Flipped>
                  </div>
                </div>
              </Fragment>
            )}
        </li>
      ))}
    </ul>
  )
}

function MainNavigation({ navigation }: { navigation: NavigationLink[] }) {
  const {
    direction,
    currentIndex,
    onMouseEnterNavItem,
    onMouseLeaveNavigation,
    animatingOut,
    activeNav,
    currentDropdown,
    previousDropdown,
    linkAria,
    dropdownId,
  } = useNavigation(
    navigation,
    (index) =>
      [navigation[index].children[0], navigation[index].children[1]] as const
  )
  return (
    <Flipper flipKey={currentIndex}>
      <ul
        className="ml-8 flex items-center text-sm"
        onMouseLeave={onMouseLeaveNavigation}
      >
        {navigation.map((link, index) => (
          <li
            key={index}
            className="group relative p-4"
            onMouseEnter={() => onMouseEnterNavItem(index)}
          >
            {link.page.url ? (
              <CustomLink href={link.page.url}>
                <a
                  className={classNames([
                    'cursor-pointer text-sm font-bold group-hover:text-purple-800',
                    {
                      'text-purple-600':
                        navigation[index] &&
                        activeNav({
                          page: navigation[index].page,
                          children: navigation[index].children.slice(0, 1),
                        }),
                    },
                  ])}
                  {...linkAria(index)}
                >
                  {link.page.title}
                </a>
              </CustomLink>
            ) : (
              <button
                type="button"
                className="cursor-pointer text-sm font-bold"
                {...linkAria(index)}
              >
                {link.page.title}
              </button>
            )}
            {currentIndex === index &&
              currentDropdown &&
              currentDropdown[0] && (
                <Fragment>
                  <div
                    className={classNames(
                      'absolute left-1/2 top-full -translate-x-1/5'
                    )}
                    style={{
                      perspective: '1000px',
                      marginLeft: determineDropdownOffset(
                        index,
                        currentDropdown[0],
                        currentDropdown[1]
                      ),
                    }}
                    id={dropdownId}
                  >
                    <div
                      className={classNames('origin-top-left', {
                        'animate-dropdown-out': !direction && animatingOut,
                        'animate-dropdown-in': !direction && !animatingOut,
                      })}
                      style={{ animationFillMode: 'forwards' }}
                    >
                      <Flipped flipId="dropdown">
                        <div
                          className={`relative mt-2 origin-top-left overflow-hidden rounded-lg bg-white py-10 shadow-lg-no-offset`}
                        >
                          {currentDropdown[1] &&
                            currentDropdown[1].children.length > 0 && (
                              <div className="absolute right-0 top-0 h-full w-[300px] bg-gradient-700 px-8" />
                            )}
                          <Flipped inverseFlipId="dropdown" scale>
                            <div className="relative">
                              <div
                                style={{
                                  animationTimingFunction: 'linear',
                                  animationFillMode: 'forwards',
                                }}
                              >
                                <SubNavigation
                                  links={currentDropdown[0]}
                                  sidebar={currentDropdown[1]}
                                />
                              </div>
                              {previousDropdown && previousDropdown[0] && (
                                <div
                                  aria-hidden="true"
                                  className={classNames(
                                    'absolute left-0 top-0',
                                    {
                                      'animate-nav-fade-out-right':
                                        direction && direction === 'left',
                                      'animate-nav-fade-out-left':
                                        direction && direction === 'right',
                                      'animate-fade-out': !direction,
                                    }
                                  )}
                                  style={{
                                    animationTimingFunction: 'linear',
                                    animationFillMode: 'forwards',
                                  }}
                                >
                                  <SubNavigation
                                    links={previousDropdown[0]}
                                    sidebar={previousDropdown[1]}
                                  />
                                </div>
                              )}
                            </div>
                          </Flipped>
                        </div>
                      </Flipped>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'absolute left-1/2 top-[calc(100%-16px)] -translate-x-1/2'
                    )}
                    style={{ perspective: '1000px' }}
                    id={`${dropdownId}-bg`}
                  >
                    <div
                      className={classNames('origin-top-left', {
                        'animate-dropdown-out': !direction && animatingOut,
                        'animate-dropdown-in': !direction && !animatingOut,
                      })}
                      style={{ animationFillMode: 'forwards' }}
                    >
                      <Flipped flipId="dropdown-caret">
                        <div className="z-10 w-16 translate-x-3 overflow-hidden">
                          <div className="size-6 origin-bottom-left rotate-45 bg-white shadow-lg-no-offset" />
                        </div>
                      </Flipped>
                    </div>
                  </div>
                </Fragment>
              )}
          </li>
        ))}
      </ul>
    </Flipper>
  )
}
