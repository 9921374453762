import { CaretRight, ShootingStar } from '@phosphor-icons/react'
import { CaretLeft } from '@phosphor-icons/react/dist/ssr'
import axios from 'axios'
import clsx from 'clsx'
import { format, getDay, isSameDay, setDay } from 'date-fns'
import { useMemo } from 'react'
import useSWR from 'swr'
import { MyBLINK } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl } from '@/src/BlinkAdminApiClient'
import {
  BookingState,
  MergedSlot,
  useBookingContext,
} from '@/src/components/booking/BookingContext'
import SlotItem from '@/src/components/booking/SlotItem'
import Button from '@/src/components/core/Button'
import { cn } from '@/src/components/core/lib/utils'
import { formatGerman } from '@/src/lib/date'

export default function Slots({ step }: { step: 'halfWeek' | 'week' }) {
  const {
    location,
    address,
    gearbox,
    instructors,
    config,
    confirmInstructors,
    changeSelectedDate,
    setSelectedDate,
  } = useBookingContext()

  const createQueryParams = () => {
    let correctedDate: Date
    if (step === 'week') {
      correctedDate = setDay(config.selectedDate, 1)
    } else {
      const weekday =
        getDay(config.selectedDate) === 0 ? 7 : getDay(config.selectedDate)
      correctedDate = setDay(config.selectedDate, weekday < 4 ? 1 : 4)
    }
    let params = ''
    for (const instructor of instructors!) {
      params += `users[]=${instructor.id}&`
    }
    params += `gearbox=${gearbox}&`
    params += `week=${format(correctedDate, 'yyyy-MM-dd')}&`
    params += `address=${address!.id}`
    return params
  }

  const { data: slots } = useSWR<MyBLINK.BookableSlot[]>(
    location &&
      address &&
      gearbox &&
      instructors &&
      `booking/locations/${location.id}/slots?${createQueryParams()}`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(
            `booking/locations/${location!.id}/slots?${createQueryParams()}`
          )
        )
      ).data
  )

  const { data: availableInstructors } = useSWR<MyBLINK.BookableUser[]>(
    location && `booking/locations/${location.id}/users?gearbox=${gearbox}`
  )

  const mergedSlots = useMemo(() => {
    if (!slots) return
    const mergedSlots: MergedSlot[] = []
    for (const slot of slots) {
      const existingSlot = mergedSlots.find((s) => s.start === slot.start)
      if (existingSlot) {
        existingSlot.id = `${existingSlot.id}-${slot.id}`
        existingSlot.userIds.push(slot.user)
        existingSlot.isFavored.push(slot.isFavored)
      } else {
        mergedSlots.push({
          id: slot.id,
          start: slot.start,
          end: slot.end,
          userIds: [slot.user],
          isFavored: [slot.isFavored],
        })
      }
    }
    return mergedSlots.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    )
  }, [slots])

  const hasFavoriteSlots = useMemo(() => {
    if (!mergedSlots) return false
    return mergedSlots
      .filter((slot) => {
        if (step === 'week') return true
        return (
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 0, step)
          ) ||
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 1, step)
          ) ||
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 2, step)
          )
        )
      })
      .some((slot) => slot.isFavored.some((favored) => favored))
  }, [config.selectedDate, mergedSlots, step])

  return (
    <>
      {instructors && instructors.length === 1 && (
        <p className="mt-2">
          Wähle eine freie Lektion (45 min) bei{' '}
          <span className="font-bold">{instructors[0].name}</span> aus.
        </p>
      )}
      {instructors && instructors.length > 1 && (
        <p className="mt-2">
          Wähle eine freie Lektion (45 min) beim Team{' '}
          <span className="font-bold">{location.name}</span> aus.
        </p>
      )}
      <div className="mt-8 flex items-center space-x-2">
        <button
          className={cn(
            'rounded p-2 text-purple-500 active:bg-blue-50 lg:hover:text-purple-700',
            {
              'text-purple-300 active:bg-transparent lg:hover:text-purple-300':
                config.state < BookingState.InstructorsSelected,
            }
          )}
          onClick={() => {
            changeSelectedDate(step, 'before')
          }}
          disabled={config.state < BookingState.InstructorsSelected}
        >
          <CaretLeft weight="bold" size={20} />
        </button>
        <button
          onClick={() => setSelectedDate(new Date())}
          className="hidden font-bold text-purple-600 active:text-purple-800 md:block lg:hover:text-purple-800"
        >
          Heute
        </button>
        <button
          className={cn(
            'rounded p-2 text-purple-500 active:bg-blue-50 lg:hover:text-purple-700',
            {
              'text-purple-300 active:bg-transparent lg:hover:text-purple-300':
                config.state < BookingState.InstructorsSelected,
            }
          )}
          onClick={() => {
            changeSelectedDate(step, 'next')
          }}
          disabled={config.state < BookingState.InstructorsSelected}
        >
          <CaretRight weight="bold" size={20} />
        </button>
        <p className="font-bold">
          {formatGerman(config.selectedDate, 'MMMM yyyy')}
        </p>
      </div>
      <div className="-mx-2 mt-8 grid grid-cols-3 divide-x divide-blue-100 @6xl:grid-cols-6">
        {[...Array(step === 'halfWeek' ? 3 : 6)].map((_, dayIndex) => (
          <div key={dayIndex} className="px-2">
            <div className="sticky top-[74px] z-10 flex flex-col items-center bg-white pb-3 text-center lg:top-0">
              <p
                className={clsx(
                  'flex size-8 items-center justify-center rounded-full',
                  {
                    'bg-blue-100': isSameDay(
                      new Date(),
                      getDateFromIndex(config.selectedDate, dayIndex, step)
                    ),
                  }
                )}
              >
                {formatGerman(
                  getDateFromIndex(config.selectedDate, dayIndex, step),
                  'cccccc'
                )}
              </p>
              <p className="font-bold">
                {formatGerman(
                  getDateFromIndex(config.selectedDate, dayIndex, step),
                  'dd.MM'
                )}
              </p>
            </div>
            <div className="space-y-3">
              {config.state < BookingState.InstructorsSelected && (
                <>
                  {[...Array(hashedNumber(config.selectedDate, dayIndex))].map(
                    (_, i) => (
                      <div
                        key={i}
                        className="h-[44px] rounded bg-gray-200"
                      ></div>
                    )
                  )}
                </>
              )}
              {config.state >= BookingState.InstructorsSelected && !slots && (
                <>
                  {[...Array(hashedNumber(config.selectedDate, dayIndex))].map(
                    (_, i) => (
                      <div
                        key={i}
                        className="h-[44px] animate-pulse rounded bg-gray-200 duration-1000"
                      ></div>
                    )
                  )}
                </>
              )}
              {config.state >= BookingState.InstructorsSelected &&
                mergedSlots && (
                  <>
                    {mergedSlots
                      .filter((s) =>
                        isSameDay(
                          new Date(s.start),
                          getDateFromIndex(config.selectedDate, dayIndex, step)
                        )
                      )
                      .map((slot) => (
                        <SlotItem
                          key={slot.id}
                          slot={slot}
                          slots={slots}
                          step={step}
                        />
                      ))}
                    {mergedSlots.filter((s) =>
                      isSameDay(
                        new Date(s.start),
                        getDateFromIndex(config.selectedDate, dayIndex, step)
                      )
                    ).length === 0 && (
                      <div className="flex h-[44px] items-center justify-center rounded bg-gray-50 text-sm text-gray-600">
                        Ausgebucht
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        ))}
      </div>
      {hasFavoriteSlots && instructors && instructors.length > 1 && (
        <p className="mt-8 flex space-x-2">
          <ShootingStar
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-500"
          />
          <span>Diese Termine passen dem Team {location.name} am besten.</span>
        </p>
      )}
      {hasFavoriteSlots && instructors && instructors.length === 1 && (
        <p className="mt-8 flex space-x-2">
          <ShootingStar
            size={20}
            weight="fill"
            className="mt-0.5 shrink-0 text-purple-500"
          />
          <span>Diese Termine passen {instructors[0].name} am besten.</span>
        </p>
      )}
      {mergedSlots?.filter((slot) => {
        if (step === 'week') return true
        return (
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 0, step)
          ) ||
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 1, step)
          ) ||
          isSameDay(
            new Date(slot.start),
            getDateFromIndex(config.selectedDate, 2, step)
          )
        )
      }).length === 0 &&
        instructors &&
        instructors.length === 1 && (
          <div className="mt-8 bg-blue-50 p-5">
            <p className="hidden @6xl:block">
              {instructors[0].name} hat in dieser Woche keine freien Termine.
            </p>
            <p className="@6xl:hidden">
              {instructors[0].name} hat an den angezeigten Tagen keine freien
              Termine.
            </p>
            <div className="mt-4 grid gap-4 @xl:flex">
              <Button
                onClick={() => {
                  changeSelectedDate(step, 'next')
                }}
                className="hidden @6xl:block"
              >
                Zur nächsten Woche
              </Button>
              <Button
                onClick={() => {
                  changeSelectedDate(step, 'next')
                }}
                className="@6xl:hidden"
              >
                Nächste Tage anzeigen
              </Button>
              {availableInstructors && availableInstructors.length > 1 && (
                <Button
                  onClick={() => {
                    confirmInstructors(availableInstructors)
                  }}
                  version="purple-outlined"
                >
                  Alle Fahrlehrer:innen anzeigen
                </Button>
              )}
            </div>
          </div>
        )}
    </>
  )
}

const getDateFromIndex = (
  date: Date,
  dayIndex: number,
  step: 'halfWeek' | 'week'
) => {
  const weekday = getDay(date) === 0 ? 7 : getDay(date)
  if (step === 'halfWeek' && weekday > 3) {
    return setDay(date, dayIndex + 4)
  } else {
    return setDay(date, dayIndex + 1)
  }
}

const hashedNumber = (selectedDate: Date, dayIndex: number) => {
  const time = selectedDate.getTime() / 1000 / 60 / 60 / 24
  return Math.round(((time * (dayIndex + 2)) % 3) + 2)
}
