import Script from 'next/script'
import { useId } from 'react'

export default function JsonLd({ data }: { data: { [key: string]: any } }) {
  const scriptId = useId()

  if (!scriptId) return <></>

  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      id={`script-${scriptId}`}
      type="application/ld+json"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}
