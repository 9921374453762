class LocateMeService {
  private _locateMe: (() => void) | undefined

  public register(locateMe: () => void) {
    this._locateMe = locateMe
  }

  public locateMe() {
    if (this._locateMe) {
      this._locateMe()
    }
  }
}

const locateMeService = new LocateMeService()

export default locateMeService
