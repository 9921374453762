import { DialogClose } from '@radix-ui/react-dialog'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import axios from 'axios'
import Image from 'next/image'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { MyBLINK } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl } from '@/src/BlinkAdminApiClient'
import { useBookingContext } from '@/src/components/booking/BookingContext'
import AllInstructorsPicture from '@/src/components/booking/overview/all_instructors.jpg'
import Button from '@/src/components/core/Button'
import { DialogBody, DialogHeader } from '@/src/components/core/Dialog'
import Picture from '@/src/components/elements/Picture'

export default function Instructors() {
  const { location, gearbox, instructors, confirmInstructors } =
    useBookingContext()

  const [selectedInstructors, setSelectedInstructors] = useState<
    MyBLINK.BookableUser[] | null
  >(instructors)

  const radioGroupValue = useMemo(() => {
    if (!selectedInstructors) {
      return undefined
    }
    if (selectedInstructors.length === 1) {
      return selectedInstructors[0].id.toString()
    }
    return 'any'
  }, [selectedInstructors])

  const { data: locationInstructors, isLoading } = useSWR<
    MyBLINK.BookableUser[]
  >(
    location && `booking/locations/${location.id}/users?gearbox=${gearbox}`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(
            `booking/locations/${location!.id}/users?gearbox=${gearbox}`
          )
        )
      ).data
  )

  const availableInstructors = useMemo(() => {
    if (!locationInstructors) {
      return null
    }
    if (gearbox === 'automatic') {
      return locationInstructors.filter(
        (instructor) => instructor.gearboxAvailability.hasAutomatic
      )
    }
    if (gearbox === 'manual') {
      return locationInstructors.filter(
        (instructor) => instructor.gearboxAvailability.hasManual
      )
    }
    return locationInstructors
  }, [locationInstructors, gearbox])

  const confirmSelection = () => {
    if (selectedInstructors) {
      confirmInstructors(selectedInstructors)
    }
  }

  return (
    <>
      <DialogHeader title="Mit wem möchtest du fahren?" />
      <DialogBody>
        {isLoading && !availableInstructors && (
          <p className="flex items-center justify-center space-x-2 py-2">
            <svg
              className="size-4 animate-spin text-purple-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            <span>Lade Fahrlehrer:innen...</span>
          </p>
        )}
        {availableInstructors && (
          <>
            <RadioGroupPrimitive.Root
              value={radioGroupValue}
              onValueChange={(id) => {
                if (id === 'any') {
                  setSelectedInstructors(availableInstructors)
                  return
                }
                const instructor = availableInstructors?.find(
                  (a) => a.id.toString() === id
                )
                setSelectedInstructors(instructor ? [instructor] : null)
              }}
              className="grid grid-cols-2 gap-4 sm:grid-cols-3"
            >
              {availableInstructors.map((instructor) => (
                <RadioGroupPrimitive.Item
                  key={instructor.id}
                  value={instructor.id}
                  className="flex flex-col justify-between rounded-lg border border-blue-125 bg-blue-25 p-2 data-[state=checked]:border-purple-600"
                >
                  <div className="aspect-h-3 aspect-w-4 relative w-full overflow-hidden rounded">
                    <Picture
                      permalink={instructor.photo}
                      alt={instructor.name}
                      fill
                      sizes="180px"
                      className="object-cover object-top"
                    />
                  </div>
                  <div className="mt-2 flex w-full items-center justify-between">
                    <p className="text-left">{instructor.name}</p>
                    <RadioGroupPrimitive.Indicator
                      forceMount
                      className="group box-border flex size-5 shrink-0 items-center justify-center rounded-full border border-blue-150 bg-white data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-600"
                    >
                      <div className="transition-opacity duration-150 group-data-[state=checked]:opacity-100 group-data-[state=unchecked]:opacity-0">
                        <svg
                          width="10"
                          height="9"
                          viewBox="0 0 10 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.63388 1.11612C10.122 1.60427 10.122 2.39573 9.63388 2.88388L4.63388 7.88388C4.14573 8.37204 3.35427 8.37204 2.86612 7.88388L0.366117 5.38388C-0.122039 4.89573 -0.122039 4.10427 0.366117 3.61612C0.854272 3.12796 1.64573 3.12796 2.13388 3.61612L3.75 5.23223L7.86612 1.11612C8.35427 0.627961 9.14573 0.627961 9.63388 1.11612Z"
                            fill="#FFF"
                          />
                        </svg>
                      </div>
                    </RadioGroupPrimitive.Indicator>
                  </div>
                </RadioGroupPrimitive.Item>
              ))}
              {availableInstructors.length !== 1 && (
                <RadioGroupPrimitive.Item
                  key="any"
                  value="any"
                  className="rounded-lg border border-blue-125 bg-blue-25 p-2 data-[state=checked]:border-purple-600"
                >
                  <div className="aspect-h-3 aspect-w-4 w-full">
                    <Image
                      src={AllInstructorsPicture}
                      alt="Alle Fahrlehrer:innen"
                      fill
                      className="object-cover object-top"
                      unoptimized
                    />
                  </div>
                  <div className="mt-2 flex items-center justify-between">
                    <p>Egal - Team {location!.name}</p>
                    <RadioGroupPrimitive.Indicator
                      forceMount
                      className="group box-border flex size-5 shrink-0 items-center justify-center rounded-full border border-blue-150 bg-white data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-600"
                    >
                      <div className="transition-opacity duration-150 group-data-[state=checked]:opacity-100 group-data-[state=unchecked]:opacity-0">
                        <svg
                          width="10"
                          height="9"
                          viewBox="0 0 10 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.63388 1.11612C10.122 1.60427 10.122 2.39573 9.63388 2.88388L4.63388 7.88388C4.14573 8.37204 3.35427 8.37204 2.86612 7.88388L0.366117 5.38388C-0.122039 4.89573 -0.122039 4.10427 0.366117 3.61612C0.854272 3.12796 1.64573 3.12796 2.13388 3.61612L3.75 5.23223L7.86612 1.11612C8.35427 0.627961 9.14573 0.627961 9.63388 1.11612Z"
                            fill="#FFF"
                          />
                        </svg>
                      </div>
                    </RadioGroupPrimitive.Indicator>
                  </div>
                </RadioGroupPrimitive.Item>
              )}
            </RadioGroupPrimitive.Root>
            <div className="mt-8 flex justify-end space-x-4">
              <DialogClose asChild>
                <Button version="purple-ghost">Abbrechen</Button>
              </DialogClose>
              <Button onClick={confirmSelection}>
                Fahrlehrer:in bestätigen
              </Button>
            </div>
          </>
        )}
      </DialogBody>
    </>
  )
}
