import { Crosshair } from '@phosphor-icons/react'
import { BardText, HeroBlobBlock } from '../../@types/statamic'
import HeaderVariations from '../../components/elements/HeaderVariantions'
import Container from '../../components/layout/Container'
import Button from '@/core/Button'
import Bard from '@/src/components/bard/Bard'
import CompactHeaderVariations from '@/src/components/elements/CompactHeaderVariantions'
import CustomLink from '@/src/components/elements/CustomLink'
import Title from '@/src/components/elements/Title'
import locateMeService from '@/src/lib/locateMeService'

export function getTitle({ title }: HeroBlobBlock) {
  return title
}

export function getDescription({ hero_content }: HeroBlobBlock) {
  const bardText = hero_content.find((bard) => bard.type === 'text') as BardText
  if (bardText) {
    const pTag = bardText.text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export function getImage({ images }: HeroBlobBlock) {
  return images && images[0]
}

export default function Component({
  title,
  heading,
  hero_content,
  images,
  compact,
  link_link,
  link_text,
  locate_me_button,
}: HeroBlobBlock) {
  const topSpacingOnMobile =
    images && images.length > 1 ? 'pt-12' : 'pt-6 md:pt-12'

  const locateMe = () => {
    const interactiveMap = document.getElementById('interactive_map-1')
    if (interactiveMap) {
      interactiveMap.scrollIntoView({ behavior: 'smooth' })
      locateMeService.locateMe()
    }
  }

  return (
    <div className="relative mt-[-30px] xl:-mt-12 xl:min-h-[450px]">
      <div>
        <Container fullPageOnMobile>
          <div className="flex flex-col md:flex-row">
            <div
              className={`${topSpacingOnMobile} relative z-10 order-2 w-full px-7 md:order-1 md:max-w-sm md:px-0 lg:max-w-xl`}
            >
              <Title style={1}>{title}</Title>
              <Title
                style={compact ? 'headerCompact' : 'header'}
                className="mt-4 lg:mt-8"
              >
                {heading}
              </Title>
              <Bard
                content={hero_content}
                className="mt-4 md:mt-5 lg:mt-7"
                fullWidth
              />
              {link_link && link_text && !locate_me_button && (
                <CustomLink href={link_link} passHref>
                  <Button as="a" className="mt-7 w-fit md:mt-7 lg:mt-10">
                    {link_text}
                  </Button>
                </CustomLink>
              )}
              {locate_me_button && link_text && (
                <Button
                  className="mt-7 md:mt-7 lg:mt-10"
                  onClick={() => locateMe()}
                  icon={<Crosshair />}
                >
                  {link_text}
                </Button>
              )}
            </div>
            {images && images.length !== 0 && (
              <div className="relative order-1 w-full flex-1 md:order-2 md:w-auto">
                {(compact && <CompactHeaderVariations images={images} />) || (
                  <HeaderVariations images={images} />
                )}
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}
