import Icon from './Icon'

export default function PlayIcon({
  className,
  playClass = 'text-white',
}: {
  className: string
  circleClass?: string
  playClass?: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            className={`fill-current ${playClass}`}
            d="M31.4321 22.2966C32.7011 23.0776 32.7011 24.9223 31.4321 25.7032L21.6481 31.7241C20.3156 32.5441 18.6 31.5854 18.6 30.0208L18.6 17.979C18.6 16.4144 20.3156 15.4557 21.6481 16.2757L31.4321 22.2966Z"
          />
        </svg>
      }
    />
  )
}
