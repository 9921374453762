import {
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form'
import CheckboxButtonGroup from '@/src/components/form/CheckboxButtonGroup'

export default function UncontrolledCheckboxButtonGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  options,
  name,
  style = 'white',
  ...props
}: Parameters<typeof CheckboxButtonGroup>[0] &
  UseControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onChange },
        fieldState: { invalid, error },
      }) => (
        <>
          <CheckboxButtonGroup
            {...props}
            ref={ref}
            value={value}
            onChange={(value) => {
              onChange(value)
            }}
            options={options}
            error={invalid}
            style={style}
          />
          {error && (
            <p className="mt-1 pl-2.5 text-xs text-pink-500">{error.message}</p>
          )}
        </>
      )}
    />
  )
}
