import { X } from '@phosphor-icons/react'
import uniq from 'lodash-es/uniq'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useId, useState } from 'react'
import TagManager from 'react-gtm-module'
import { unstable_serialize } from 'swr'
import { CourseType } from '@/src/@types/admin'
import { PageInfo } from '@/src/@types/models'
import { getCourses, getLocationName } from '@/src/BlinkAdminApiClient'
import useFilter, {
  weekdaySorting,
} from '@/src/components/core/hooks/useFilter'
import { formatGerman } from '@/src/components/core/lib/date'
import MultiSelect, { SelectOption } from '@/src/components/core/MultiSelect'
import Course from '@/src/components/elements/Course'
import TimePressure from '@/src/components/elements/course/TimePressure'
import GoogleRating, {
  GoogleRatingType,
} from '@/src/components/elements/GoogleRating'
import GradientText from '@/src/components/elements/GradientText'
import JsonLd from '@/src/components/elements/JsonLd'
import Map from '@/src/components/elements/Map'
import Marker from '@/src/components/elements/Marker'
import SquareMap from '@/src/components/elements/SquareMap'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import IconButton from '@/src/components/form/IconButton'
import ArrowIcon from '@/src/components/icons/ArrowIcon'
import MapWithPin from '@/src/components/icons/MapWithPin'
import PlusIcon from '@/src/components/icons/PlusIcon'
import ThumbsDownIcon from '@/src/components/icons/ThumbsDownIcon'
import ThumbsUpIcon from '@/src/components/icons/ThumbsUpIcon'
import Container from '@/src/components/layout/Container'
import Sticky from '@/src/components/layout/Sticky'
import useCourses from '@/src/hooks/useCourses'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

export type CoursesBlock = {
  type: string
  course_type: {
    value:
      | 'digital-firstaid'
      | 'firstaid'
      | 'bike-part-1-2'
      | 'bike-full'
      | 'bike-part-3'
      | 'bike-exam-preparation'
      | 'vku'
      | 'wab'
  }[]
  location: {
    value: string
  }[]
  address: {
    value: string
  }[]
  google_rating: GoogleRatingType | null
  sidebar_title: string | null
  map_only: boolean
  show_timepressure: boolean
}

export async function getStaticProps({
  location,
  address,
  course_type,
  google_rating,
  sidebar_title,
  map_only,
  show_timepressure,
}: CoursesBlock) {
  const locationIds = location.map(({ value }) => value)
  const addressIds = address ? address.map(({ value }) => value) : []
  const typeIds = course_type.map(({ value }) => value)
  const courses = await getCourses({
    locations: locationIds,
    addresses: addressIds,
    types: typeIds,
  })

  let locationName = null
  if (locationIds[0]) {
    locationName = await getLocationName(locationIds[0])
  }

  return {
    courseType: course_type[0].value,
    courses,
    googleRating: google_rating,
    locationName,
    sidebarTitle: sidebar_title,
    fallback: {
      [unstable_serialize(['courses', typeIds, locationIds])]: courses,
    },
    typeIds,
    locationIds,
    addressIds,
    mapOnly: map_only ? map_only : false,
    showTimepressure: show_timepressure ? show_timepressure : false,
  }
}

const initialRegistrationFormState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  postal_code: '',
  date_of_birth: '',
  voucher: '',
  color: '',
  driver_license: '',
  language: '',
  accepts_conditions: false,
}

export default function Courses({
  courseType,
  googleRating,
  locationName,
  sidebarTitle,
  locationIds,
  addressIds,
  typeIds,
  pageInfo,
  mapOnly,
  showTimepressure,
}: Awaited<ReturnType<typeof getStaticProps>> & {
  pageInfo: PageInfo
}) {
  const router = useRouter()
  const { utmData } = useUtmCache()

  const activeCourseSlug =
    typeof router.query.course === 'string' ? router.query.course : undefined

  const [courses] = useCourses({
    locations: locationIds,
    addresses: addressIds,
    types: typeIds,
  })
  const locations = uniq(courses.map(({ location }) => location))
  const [showMap, setShowMap] = useState(false)
  const [openSlug, setOpenSlug] = useState<string | undefined>()
  const [interested, setInterested] = useState<boolean | undefined>(undefined)
  const [limit, setLimit] = useState(5)
  const [showFullyBooked, setShowFullyBooked] = useState(false)
  const mapContentId = `map-${useId()}`
  const [registrationForm, setRegistrationForm] = useState(
    initialRegistrationFormState
  )
  const { handleSubmit, register, control } = useLaravelForm({
    defaultValues: {
      ...registrationForm,
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const {
    filter: addressFilter,
    setFilter: setAddressFilter,
    options: addresses,
    matches: matchesAddressFilter,
  } = useFilter<CourseType>({
    options:
      courses?.reduce((acc, course) => {
        const address = course.location.address
        const existing = acc.find((a) => a.name === address)
        if (existing) {
          existing.count!++
        } else {
          acc.push({ name: address, count: 1 })
        }
        return acc
      }, [] as SelectOption[]) ?? [],
    filterFn: (course, filter) =>
      filter.some((filter) => filter.name === course.location.address),
  })

  const {
    filter: weekdayFilter,
    setFilter: setWeekdayFilter,
    options: weekdays,
    matches: matchesWeekdayFilter,
  } = useFilter<CourseType>({
    options:
      courses
        ?.reduce<SelectOption[]>((days, course) => {
          const weekdays = course.timeslots.reduce((weekdayString, slot) => {
            const weekday = formatGerman(new Date(slot.isoDay), 'cccc')
            if (!weekdayString.includes(weekday)) {
              weekdayString +=
                weekdayString.length === 0 ? weekday : ` & ${weekday}`
            }
            return weekdayString
          }, '')

          const existing = days.find((day) => day.name === weekdays)
          if (existing) {
            existing.count!++
          } else {
            days.push({ name: weekdays, count: 1 })
          }
          return days
        }, [])
        .sort(
          (a, b) =>
            weekdaySorting.findIndex((ws) => a.name.startsWith(ws)) -
            weekdaySorting.findIndex((ws) => b.name.startsWith(ws))
        ) ?? [],
    filterFn: (course, filter) => {
      const weekdays = course.timeslots.reduce((weekdayString, slot) => {
        const weekday = formatGerman(new Date(slot.isoDay), 'cccc')
        if (!weekdayString.includes(weekday)) {
          weekdayString +=
            weekdayString.length === 0 ? weekday : ` & ${weekday}`
        }
        return weekdayString
      }, '')
      return filter.some((d) => d.name === weekdays)
    },
  })

  useEffect(() => {
    setOpenSlug(activeCourseSlug)
    const activeCourseIndex = activeCourseSlug
      ? courses.findIndex((course) => course.slug === activeCourseSlug) + 1
      : -1
    setLimit(activeCourseIndex > limit ? activeCourseIndex : limit)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCourseSlug])

  useEffect(() => {
    if (interested === true) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'thumb_up',
          params: {
            type: 'missing_course_location',
            location: locationName ? locationName : 'unknown',
          },
        },
      })
    } else if (interested === false) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'thumb_down',
          params: {
            type: 'missing_course_location',
            location: locationName ? locationName : 'unknown',
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interested])

  const filteredCourses = courses.filter((course) => {
    return (
      !course.isFullyBooked &&
      matchesAddressFilter(course) &&
      matchesWeekdayFilter(course)
    )
  })

  const fullyBookedCourses = courses.filter((course) => {
    return (
      course.isFullyBooked &&
      matchesAddressFilter(course) &&
      matchesWeekdayFilter(course)
    )
  })

  const shownFullyBookedCourses =
    showFullyBooked || fullyBookedCourses.length === 0
      ? fullyBookedCourses
      : fullyBookedCourses.slice(
          fullyBookedCourses.length - 1,
          fullyBookedCourses.length
        )

  return (
    <Container>
      {googleRating && (
        <JsonLd
          data={{
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Blink AG',
            description: `BLINK Fahrschule - ${googleRating.place}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: googleRating.street,
              addressLocality: googleRating.place,
              postalCode: googleRating.zip,
            },
            aggregateRating:
              googleRating.rating && googleRating.rating > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: googleRating.rating,
                    ratingCount: googleRating.rating_count,
                    bestRating: 5,
                    worstRating: 1,
                  }
                : undefined,
            geo: locations[0]
              ? {
                  '@type': 'GeoCoordinates',
                  latitude: locations[0].latitude,
                  longitude: locations[0].longitude,
                }
              : googleRating && googleRating.latitude && googleRating.longitude
                ? {
                    '@type': 'GeoCoordinates',
                    latitude: googleRating.latitude,
                    longitude: googleRating.longitude,
                  }
                : undefined,
            priceRange: courses[0] ? `${courses[0].price} CHF` : undefined,
            // hasMap: `https://g.page/${gmbShortname}`,
            openingHours: ['Mo,Tu,We,Th,Fr 09:00-17:00', 'Sa 09:00-12:00'],
            telephone: googleRating.phone,
            logo: pageInfo.settings.logo?.permalink,
            url: pageInfo.fullUrl,
            image: pageInfo.socialImage
              ? [pageInfo.socialImage?.permalink]
              : undefined,
          }}
        />
      )}
      {courses.map((course, index) => (
        <Fragment key={index}>
          {course.schema && <JsonLd data={course.schema} />}
        </Fragment>
      ))}
      <div className="relative items-start lg:grid lg:grid-cols-3 lg:gap-24">
        <div className="md:pb-5 lg:col-span-2">
          <div className="flex justify-between">
            <h2 className="mr-6 text-2xl font-extrabold [text-wrap:balance] md:text-10xl">
              {(courseType === 'digital-firstaid' ||
                courseType === 'firstaid') && (
                <>Jetzt für Nothelferkurs anmelden</>
              )}
              {(courseType === 'bike-part-1-2' ||
                courseType === 'bike-full' ||
                courseType === 'bike-part-3' ||
                courseType === 'bike-exam-preparation') && (
                <>Jetzt für Grundkurs anmelden</>
              )}
              {courseType === 'vku' && <>Jetzt für VKU anmelden</>}
              {courseType === 'wab' && <>Jetzt für WAB anmelden</>}
            </h2>
            <div className="flex shrink-0 items-center space-x-2">
              {courses.length !== 0 && (
                <IconButton
                  className="lg:hidden"
                  status={showMap ? 'active' : 'default'}
                  onClick={() => {
                    setShowMap(!showMap)
                  }}
                  aria-expanded={showMap}
                  aria-controls={showMap ? `#${mapContentId}` : undefined}
                >
                  <MapWithPin className="size-4" />
                  <span className="sr-only">Karte umschalten</span>
                </IconButton>
              )}
            </div>
          </div>

          {showMap && (
            <div
              className="aspect-h-1 aspect-w-1 relative mt-8 w-full overflow-hidden rounded-2xl lg:hidden"
              id={mapContentId}
            >
              <div>
                <div className="absolute inset-0 flex">
                  {locations.length > 0 && (
                    <Map
                      className="size-full"
                      markers={locations.map(({ longitude, latitude }) => [
                        longitude,
                        latitude,
                      ])}
                    >
                      {locations.map((location, index) => (
                        <Marker
                          key={index}
                          position={{
                            lat: location.latitude,
                            lng: location.longitude,
                          }}
                        >
                          {`<h4 class="font-bold text-sm">BLINK AG</h4>${location.address}`}
                        </Marker>
                      ))}
                    </Map>
                  )}
                  {locations.length === 0 &&
                    googleRating &&
                    googleRating.longitude &&
                    googleRating.latitude && (
                      <Map
                        className="size-full"
                        markers={[
                          [googleRating.longitude, googleRating.latitude],
                        ]}
                      >
                        <Marker
                          position={{
                            lat: googleRating.latitude,
                            lng: googleRating.longitude,
                          }}
                        >
                          {`<h4 class="font-bold text-sm">BLINK AG</h4>${googleRating.street}, ${googleRating.zip} ${googleRating.place}`}
                        </Marker>
                      </Map>
                    )}
                </div>
                <div className="absolute right-5 top-5 size-7">
                  <IconButton
                    type="button"
                    style="transparent"
                    className="flex items-center justify-center"
                    onClick={() => setShowMap(false)}
                  >
                    <X
                      className="size-7"
                      color="currentColor"
                      weight="bold"
                      alt="Schliessen"
                    />
                  </IconButton>
                </div>
              </div>
            </div>
          )}

          {(addresses.length > 1 ||
            (weekdays.length > 1 && weekdays.length <= 5)) && (
            <div className="@container">
              <div className="mt-8 grid grid-cols-1 gap-4 @xl:grid-cols-2">
                {addresses.length > 1 && (
                  <MultiSelect
                    label="Kursstandort"
                    selected={addressFilter}
                    setSelected={setAddressFilter}
                    options={addresses}
                  />
                )}
                {weekdays.length > 1 && weekdays.length <= 5 && (
                  <MultiSelect
                    label="Wochentag"
                    selected={weekdayFilter}
                    setSelected={setWeekdayFilter}
                    options={weekdays}
                  />
                )}
              </div>
            </div>
          )}

          {showTimepressure && (
            <TimePressure
              courseState={
                filteredCourses.length > 0
                  ? 'available'
                  : courses.length > 0
                    ? 'fullyBooked'
                    : 'none'
              }
              location_id={locationIds[0]}
            />
          )}

          {courses.length === 0 && !showTimepressure && (
            <div className="mt-8 rounded-[15px] bg-gradient-100 p-8 md:mt-10 md:p-10">
              <p className="font-bold md:text-lg">
                Bad news: 😢 Wir sind ganz neu{' '}
                {locationName ? `in ${locationName}` : 'hier'} und haben noch
                keine Kurse online...
              </p>
              <p className="mt-5 text-lg font-extrabold md:text-4xl">
                <GradientText>
                  {`[Wärst du an einem Kurs ${
                    locationName ? `in ${locationName}` : 'an diesem Standort'
                  } interessiert?]`}
                </GradientText>
              </p>
              <div className="mt-10 flex justify-center space-x-10">
                <IconButton
                  size="huge"
                  disabled={interested !== undefined}
                  onClick={() => setInterested(true)}
                  status={
                    interested === undefined
                      ? 'default'
                      : interested === true
                        ? 'success'
                        : 'active'
                  }
                >
                  <ThumbsUpIcon className="m-2 h-[39px] w-[42px] text-white md:h-[46px] md:w-[52px]" />
                </IconButton>
                <IconButton
                  size="huge"
                  disabled={interested !== undefined}
                  onClick={() => setInterested(false)}
                  status={
                    interested === undefined
                      ? 'default'
                      : interested === false
                        ? 'error'
                        : 'active'
                  }
                >
                  <ThumbsDownIcon className="m-2 h-[39px] w-[42px] text-white md:h-[46px] md:w-[52px]" />
                </IconButton>
              </div>
              {interested !== undefined && (
                <p className="mt-5 text-center">
                  Danke für dein Feedback! {interested ? '🤩' : '😔'}
                </p>
              )}
            </div>
          )}

          {courses.length !== 0 && (
            <>
              <div className="space-y-5">
                <ul className="mt-8 space-y-5">
                  {shownFullyBookedCourses.map((course, index) => (
                    <li key={index}>
                      <Course course={course} open={false} />
                    </li>
                  ))}
                </ul>
                {!showFullyBooked && fullyBookedCourses.length > 1 && (
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowFullyBooked(true)}
                      className="flex w-full items-center justify-between rounded-md bg-gradient-100 p-5 font-bold text-purple-600 hover:text-purple-800"
                    >
                      Alle ausgebuchten Kurse anzeigen
                      <PlusIcon className="ml-2 size-5 shrink-0" />
                    </button>
                  </div>
                )}
                <ul className="space-y-5">
                  {filteredCourses.slice(0, limit).map((course, index) => (
                    <li key={index}>
                      <Course
                        course={course}
                        open={openSlug === course.slug}
                        register={register}
                        control={control}
                        onInput={(name, value) => {
                          setRegistrationForm({
                            ...registrationForm,
                            [name]: value,
                          })
                        }}
                        onSubmit={handleSubmit}
                        reset={() => {
                          setRegistrationForm(initialRegistrationFormState)
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              {filteredCourses.length > limit && (
                <div className="mt-5 text-center">
                  <Button
                    style="transparent"
                    size="none"
                    className="group inline-flex items-center transition lg:text-base"
                    onClick={() => setLimit(limit + 10)}
                  >
                    Weitere Kurse anzeigen
                    <ArrowIcon className="ml-3 size-5 rotate-90 transition-transform duration-300 group-hover:translate-y-1" />
                  </Button>
                </div>
              )}
            </>
          )}

          {googleRating && (
            <div className="mt-12 lg:hidden">
              <GoogleRating {...googleRating} mapOnly={mapOnly} />
            </div>
          )}
        </div>

        <Sticky stickyTopSpace={80}>
          <div className="hidden lg:block">
            {(locations.length > 0 || googleRating) && (
              <Title style={4} as="h3">
                {sidebarTitle ? sidebarTitle : '[Unsere Kursstandorte]'}
              </Title>
            )}
            <div className="mt-4">
              {locations.length > 0 && (
                <SquareMap
                  markers={locations.map(({ longitude, latitude }) => [
                    longitude,
                    latitude,
                  ])}
                >
                  {locations.map((location, index) => (
                    <Marker
                      key={index}
                      position={{
                        lat: location.latitude,
                        lng: location.longitude,
                      }}
                    >
                      {`<h4 class="font-bold text-sm">BLINK AG</h4>${location.address}`}
                    </Marker>
                  ))}
                </SquareMap>
              )}
              {locations.length === 0 &&
                googleRating &&
                !!googleRating.longitude &&
                !!googleRating.latitude && (
                  <SquareMap
                    markers={[[googleRating.longitude, googleRating.latitude]]}
                  >
                    <Marker
                      position={{
                        lat: googleRating.latitude,
                        lng: googleRating.longitude,
                      }}
                    >
                      {`<h4 class="font-bold text-sm">BLINK AG</h4>${googleRating.street}, ${googleRating.zip} ${googleRating.place}`}
                    </Marker>
                  </SquareMap>
                )}
            </div>
            {googleRating && (
              <div className="mt-4">
                <GoogleRating {...googleRating} mapOnly={mapOnly} />
              </div>
            )}
          </div>
        </Sticky>
      </div>
    </Container>
  )
}
