import Link, { LinkProps } from 'next/link'
import { Children, cloneElement, HTMLProps, isValidElement } from 'react'
import { useIsClient } from 'usehooks-ts'

export default function CustomLink({
  as: _as,
  children,
  href,
  replace,
  scroll,
  shallow,
  passHref,
  newTab,
  ...props
}: LinkProps & HTMLProps<HTMLAnchorElement> & { newTab?: boolean }) {
  const isClient = useIsClient()
  if (!href) {
    return null
  }

  // need to prevent ssr since it would lead to a hydration mismatch on ab testing sites
  if (href.startsWith('#') && !isClient) {
    return null
  }
  const externalHref = !href.startsWith('/') && !href.startsWith('#')

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        target:
          (externalHref && newTab !== false) || newTab ? '_blank' : undefined,
        rel: externalHref ? 'noopener noreferrer' : undefined,
        ...props,
      })
    }
    return child
  })?.[0]

  return (
    <Link
      href={href}
      passHref={passHref}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      legacyBehavior
    >
      {childrenWithProps}
    </Link>
  )
}
