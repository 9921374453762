import { SyntheticEvent, useState } from 'react'
import TagManager from 'react-gtm-module'
import { VoucherFormBlock } from '@/src/@types/statamic'
import Bard from '@/src/components/bard/Bard'
import { Input } from '@/src/components/core/Input'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import Honeypot from '@/src/components/form/Honeypot'
import RadioButtons from '@/src/components/form/RadioButtons'
import Toggle from '@/src/components/form/Toggle'
import LoadingSpinner from '@/src/components/icons/LoadingSpinner'
import Container from '@/src/components/layout/Container'
import useLaravelForm from '@/src/hooks/useLaravelForm'
import useUtmCache from '@/src/hooks/useUTMCache'

const requestUrl = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api/website/v2/voucher-orders`

export default function VoucherForm({ title, description }: VoucherFormBlock) {
  const { utmData } = useUtmCache()

  const [sameAddress, setSameAddress] = useState(false)
  const [bundle, setBundle] = useState<string | number>(2000)
  const [isLoading, setIsloading] = useState(false)
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { isSubmitSuccessful },
  } = useLaravelForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      address: '',
      zip: '',
      place: '',
      value: '',
      receiverName: '',
      senderName: '',
      invoiceFirstName: '',
      invoiceLastName: '',
      invoiceAddress: '',
      invoiceZip: '',
      invoicePlace: '',
      postalCode: '', // honeypot
      utm_term: utmData.utmTerm,
      utm_source: utmData.utmSource,
      utm_medium: utmData.utmMedium,
      ref: utmData.ref,
    },
  })

  const submitForm = (event: SyntheticEvent<HTMLFormElement>) => {
    setIsloading(true)
    if (sameAddress) {
      setValue('invoiceFirstName', getValues('firstName'))
      setValue('invoiceLastName', getValues('lastName'))
      setValue('invoiceAddress', getValues('address'))
      setValue('invoiceZip', getValues('zip'))
      setValue('invoicePlace', getValues('place'))
    }

    if (bundle !== 'custom') {
      setValue('value', String(bundle))
    }

    handleSubmit(
      () => {
        setIsloading(false)
        TagManager.dataLayer({
          dataLayer: {
            event: 'voucher_form_sent',
            params: {
              origin: 'Website',
              site: window.location.href,
            },
          },
        })
      },
      undefined,
      () => setIsloading(false)
    )(event)
  }

  return (
    <Container>
      <div className="relative">
        <div>
          <div className="md:w-1/2">
            <Title style={2}>{title}</Title>
          </div>
          {description && (
            <div className="mt-6">
              <Bard
                content={[{ type: 'text', text: description }]}
                className="max-w-prose"
                fullWidth
              />
            </div>
          )}
          {!isSubmitSuccessful && (
            <form
              onSubmit={submitForm}
              method="POST"
              action={requestUrl}
              className="lg:w-3/4"
            >
              <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2">
                <div className="grid grid-cols-1 gap-5">
                  <RadioButtons
                    label="Paket"
                    value={bundle}
                    onChange={(value) => setBundle(value)}
                    options={[
                      {
                        value: 2000,
                        label: 'CHF 2000',
                      },
                      {
                        value: 200,
                        label: 'CHF 200',
                      },
                      {
                        value: 59,
                        label: 'CHF 59',
                      },
                      {
                        value: 'custom',
                        label: 'Freier Betrag',
                      },
                    ]}
                  />
                  {bundle === 'custom' && (
                    <div>
                      <Input
                        label="Betrag"
                        labelType="block"
                        type="number"
                        {...register('value')}
                      />
                      <p className="mt-1 text-xs text-gray-600">
                        Betrag in CHF
                      </p>
                    </div>
                  )}
                  <div>
                    <Input
                      label="Gutschein für"
                      labelType="block"
                      {...register('receiverName')}
                    />
                    <p className="mt-1 text-xs text-gray-600">
                      Wird auf Gutschein gedruckt
                    </p>
                  </div>
                  <div>
                    <Input
                      label="Gutschein von"
                      labelType="block"
                      {...register('senderName')}
                    />
                    <p className="mt-1 text-xs text-gray-600">
                      Wird auf Gutschein gedruckt
                    </p>
                  </div>
                  <Input
                    label="Telefonnummer für Rückfragen"
                    labelType="block"
                    kind="tel"
                    {...register('phone')}
                  />
                  <Input
                    label="E-Mail für Rückfragen"
                    labelType="block"
                    kind="email"
                    {...register('email')}
                  />
                </div>
              </div>
              <div className="mt-5 grid gap-x-8 md:mt-14 md:grid-cols-2">
                <div className="grid content-start gap-5">
                  <div>
                    <h3 className="text-2xl font-bold text-gray-900">
                      Lieferadresse
                    </h3>
                  </div>
                  <div className="grid grid-cols-1 gap-5 md:mt-11 md:grid-cols-2">
                    <Input
                      label="Vorname"
                      labelType="block"
                      kind="firstName"
                      {...register('firstName')}
                    />
                    <Input
                      label="Nachname"
                      labelType="block"
                      kind="lastName"
                      {...register('lastName')}
                    />
                  </div>
                  <Input
                    label="Strasse und Hausnummer"
                    labelType="block"
                    kind="street"
                    {...register('address')}
                  />
                  <div className="grid grid-cols-4 gap-5">
                    <Input
                      label="PLZ"
                      labelType="block"
                      kind="zip"
                      {...register('zip')}
                    />
                    <div className="col-span-3">
                      <Input
                        label="Ort"
                        labelType="block"
                        kind="place"
                        {...register('place')}
                      />
                    </div>
                  </div>
                  <Honeypot {...register('postalCode')} />
                </div>
                <div className="grid content-start  gap-5">
                  <div>
                    <h3 className="text-2xl font-bold text-gray-900">
                      Rechnungsadresse
                    </h3>
                  </div>
                  <div>
                    <Toggle enabled={sameAddress} setEnabled={setSameAddress}>
                      Gleich wie Lieferadresse
                    </Toggle>
                  </div>
                  {!sameAddress && (
                    <>
                      <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                        <Input
                          label="Vorname"
                          labelType="block"
                          kind="firstName"
                          {...register('invoiceFirstName')}
                        />
                        <Input
                          label="Nachname"
                          labelType="block"
                          kind="lastName"
                          {...register('invoiceLastName')}
                        />
                      </div>
                      <Input
                        label="Strasse und Hausnummer"
                        labelType="block"
                        kind="street"
                        {...register('invoiceAddress')}
                      />
                      <div className="grid grid-cols-4 gap-5">
                        <Input
                          label="PLZ"
                          labelType="block"
                          kind="zip"
                          {...register('invoiceZip')}
                        />
                        <div className="col-span-3">
                          <Input
                            label="Ort"
                            labelType="block"
                            kind="place"
                            {...register('invoicePlace')}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex justify-center pt-8 md:mt-0">
                <Button
                  type="submit"
                  status={isLoading ? 'active' : 'default'}
                  disabled={isLoading}
                  className="inline-flex items-center"
                >
                  {isLoading && <LoadingSpinner className="mr-1 size-4" />}
                  Gutschein bestellen
                </Button>
              </div>
            </form>
          )}
          {isSubmitSuccessful && (
            <div className="mt-10 rounded-2xl border border-blue-100 bg-gradient-100 px-5 py-8 text-lg font-extrabold md:w-2/3 md:p-9 md:text-2xl xl:w-auto xl:max-w-[690px]">
              <p>Vielen Dank für deine Bestellung! Wir legen gleich los! 🙂</p>
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
