import * as FirstAidPage from '../components/generic/FirstAidPage'
import * as SchoolPage from '../components/generic/SchoolPage'
import * as VkuPage from '../components/generic/VkuPage'
import { GenericPageBlueprint } from '@/src/@types/infrastructur'

const genericPages: Record<string, GenericPageBlueprint<any>> = {
  vku: VkuPage,
  firstaid: FirstAidPage,
  school: SchoolPage,
}

export default function find<Name extends string>(genericPageName: Name) {
  const genericPage = genericPages[genericPageName]

  if (!genericPage) {
    return undefined
  }

  return { ...genericPage, name: genericPageName }
}
