import { GetStaticPropsContext } from 'next'
import Link from 'next/link'
import { useState } from 'react'
import { ImageType, MagazineHeroBlock } from '../../@types/statamic'
import MagazineQuery from '../../graphql/MagazineQuery.graphql'
import { PageParams, PreviewData } from '@/src/@types/infrastructur'
import {
  RadioGroupItem,
  RadioGroupRoot,
} from '@/src/components/core/RadioGroup'
import Content from '@/src/components/elements/Content'
import Picture from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import Container from '@/src/components/layout/Container'
import { request } from '@/src/StatamicGraphQLClient'

export function getTitle({ title }: MagazineHeroBlock) {
  return title
}

export function getDescription({ text }: MagazineHeroBlock) {
  if (text) {
    const pTag = text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export async function getStaticProps(
  { category, ...props }: MagazineHeroBlock,
  page: GetStaticPropsContext<PageParams, PreviewData>
) {
  const {
    entries: { data: entries },
  } = await request<{
    entries: {
      data: {
        uri: string
        title: string
        image?: ImageType
        preview_text?: string
        date: string
        isoDate: string
        tags: string[]
      }[]
    }
  }>(
    MagazineQuery,
    {
      site: process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE,
      category: category.value || 'magazine',
    },
    page.previewData?.statamicToken
  )

  return {
    ...props,
    entries,
  }
}

const SORTED_TAGS = [
  'Auto',
  'Motorrad',
  'Nothelferkurs',
  'VKU',
  'Tipps & Tricks',
  'Prüfungsvorbereitung',
  'BLINK Vorteile',
  'News',
  'App',
]

export default function MagazineHero({
  title,
  text,
  entries,
}: Awaited<ReturnType<typeof getStaticProps>>) {
  const tags = [
    ...new Set(
      entries
        .flatMap((entry) => entry.tags)
        .filter(Boolean)
        .sort((a, b) => SORTED_TAGS.indexOf(a) - SORTED_TAGS.indexOf(b))
    ),
  ]

  const [selectedTag, setSelectedTag] = useState('ALL')

  const filteredEntries = entries.filter(
    (entry) =>
      selectedTag === 'ALL' || entry.tags?.some((tag) => tag === selectedTag)
  )

  return (
    <div className="mt-4">
      <Container>
        <div>
          <Title style="bigH1">{title}</Title>
          <div className="mt-4 md:mt-8">
            <Content content={text} />
          </div>
          <RadioGroupRoot
            value={selectedTag}
            onValueChange={setSelectedTag}
            className="-ml-2 mt-4 flex flex-wrap"
          >
            <RadioGroupItem
              value={'ALL'}
              size="filterTag"
              color="blue-outlined"
              showCheckbox={false}
              className="ml-2 mt-2"
            >
              Alle
            </RadioGroupItem>
            {tags.map((tag) => (
              <RadioGroupItem
                key={tag}
                value={tag}
                size="filterTag"
                color="blue-outlined"
                showCheckbox={false}
                className="ml-2 mt-2"
              >
                {tag}
              </RadioGroupItem>
            ))}
          </RadioGroupRoot>
          <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-10 md:mt-16 md:grid-cols-2 lg:grid-cols-3">
            {filteredEntries.map((entry) => (
              <article key={entry.title}>
                <Link
                  href={entry.uri}
                  className="group flex h-full flex-col rounded-xl transition-all hover:bg-gradient-100"
                >
                  <div className="aspect-h-9 aspect-w-16 relative w-auto md:m-2">
                    {entry.image && (
                      <Picture
                        {...entry.image}
                        fill
                        sizes="(min-width: 768px) 33vw, 100vw"
                        className="rounded-lg object-cover"
                      />
                    )}
                  </div>
                  <div className="flex grow flex-col justify-between p-5">
                    <div>
                      <Title
                        style={4}
                        as="h3"
                        className="group-hover:underline"
                      >
                        {entry.title}
                      </Title>
                      <p className="mt-2">{entry.preview_text}</p>
                    </div>
                    {entry.tags && (
                      <div className="-ml-1 mt-3 flex flex-wrap">
                        {entry.tags
                          .sort(
                            (a, b) =>
                              SORTED_TAGS.indexOf(a) - SORTED_TAGS.indexOf(b)
                          )
                          .map((tag) => (
                            <div
                              key={tag}
                              className="ml-1 mt-1 rounded-full border border-blue-125 px-2.5 py-0.5 text-sm text-gray-600"
                            >
                              {tag}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </Link>
              </article>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
