import classNames from 'classnames'
import { A11y, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/a11y'
import { TestimonalsBlock } from '@/src/@types/statamic'
import Content from '@/src/components/elements/Content'
import Picture from '@/src/components/elements/Picture'
import Caret from '@/src/components/icons/Caret'
import { useBetterUseId } from '@/src/hooks/betterUseId'
import Heart from '../../../public/img/heart.png'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import styles from '/src/css/Masks.module.css'

export function getTitle({ title }: TestimonalsBlock) {
  return title
}

export default function Component({
  title,
  testimonials,
  mode,
}: TestimonalsBlock) {
  const hasMissingImages = testimonials.reduce(
    (x, a) => (!a.image || !a.image.permalink ? true : x),
    false
  )
  const paginationId = `pagination-${useBetterUseId()}`

  return (
    <Container>
      <div className="relative">
        {((mode.value === 'text_only' || hasMissingImages) && (
          <>
            <div>
              <Title className="md:ml-[50%]" style={2}>
                {title}
              </Title>
              <div className="absolute -z-10 mt-5 w-4/5 md:!-mt-10 lg:!-mt-24 xl:!-mt-28">
                <div className="aspect-h-1 aspect-w-1">
                  <svg
                    viewBox="0 0 379 394"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-[-40%] top-[-20%] w-[70%] md:left-[-10%] md:top-[-40%] md:w-2/5 xl:top-[-35%]"
                  >
                    <path
                      d="M273.567 381.033C273.567 381.033 374.983 351.627 378.176 200.245C381.37 48.8627 282.663 23.7045 248.357 7.66644C214.052 -8.37155 110.857 -3.49496 43.0274 63.8294C-24.8021 131.154 -22.4998 358.68 123.452 386.024C228.298 405.667 273.567 381.033 273.567 381.033Z"
                      fill="url(#paint0_linear_2192_20642)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2192_20642"
                        x1="361.231"
                        y1="420.523"
                        x2="20.2458"
                        y2="-20.7512"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FBFBFF" />
                        <stop offset="1" stopColor="#DAE0FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    viewBox="0 0 193 184"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-[70%] top-[20%] w-1/2 md:left-[30%] md:top-0 md:w-[30%]"
                  >
                    <path
                      opacity="0.5"
                      d="M176.748 137.962C211.712 70.9581 185.721 10.8103 111.772 1.14919C37.823 -8.51191 -19.9609 43.7179 6.73933 121.062C33.4395 198.407 141.785 204.966 176.748 137.962Z"
                      fill="url(#paint0_linear_2192_20641)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2192_20641"
                        x1="192.111"
                        y1="0"
                        x2="15.2476"
                        y2="208.782"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F3F5FF" />
                        <stop offset="1" stopColor="#E7EBFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    viewBox="0 0 340 327"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute md:top-[-15%] md:w-[55%] lg:top-[-20%] lg:w-1/2"
                  >
                    <path
                      d="M320.07 87.2835C274.091 5.05999 137.782 -29.2693 61.6763 31.5573C-14.4297 92.3838 -9.21553 190.622 19.9625 245.27C49.1405 299.918 94.7438 345.337 212.889 318.231C331.034 291.126 366.049 169.507 320.07 87.2835Z"
                      fill="url(#paint0_linear_2192_20647)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2192_20647"
                        x1="55.4216"
                        y1="25.4637"
                        x2="313.317"
                        y2="338.125"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#511DEC" />
                        <stop offset="0.0001" stopColor="#511DEC" />
                        <stop offset="1" stopColor="#8D41ED" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div
                    className={classNames([
                      'absolute top-[2%] md:top-[8.7%] md:w-[55%] lg:top-[6%] lg:w-[50.1%]',
                      styles['mask-13'],
                      styles['mask'],
                    ])}
                  >
                    <Image
                      src={Heart}
                      alt="Herz Illustration"
                      layout="responsive"
                      unoptimized
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[77vw] md:ml-[50%] md:mt-8 lg:mt-10">
                <Swiper
                  pagination={{
                    el: `#${paginationId}`,
                    bulletClass:
                      'h-4 w-4 inline-block rounded-full bg-blue-100 mx-1',
                    clickable: true,
                  }}
                  modules={[Pagination, A11y]}
                  a11y={{
                    prevSlideMessage: 'Vorheriges Bild',
                    nextSlideMessage: 'Nächstes Bild',
                    paginationBulletMessage: 'Gehe zu Bild {{index}}',
                    lastSlideMessage: 'Dies ist das letzte Bild',
                    firstSlideMessage: 'Dies ist das erste Bild',
                  }}
                  slidesPerView={1}
                  spaceBetween={20}
                  loop
                >
                  {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index} className="!h-auto min-h-full">
                      <div className="flex h-full flex-col justify-between rounded-lg bg-gradient-100 p-8 md:min-h-[300px]">
                        <Content content={testimonial.text} />
                        <p className="mt-4 font-bold">{testimonial.author}</p>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div
              id={paginationId}
              className="mt-10 flex justify-center md:mt-28"
            />
          </>
        )) || (
          <>
            <div>
              <Title
                style={2}
                className="z-40 md:!-mb-10 md:ml-[50%] lg:!-mb-24 xl:!-mb-28"
              >
                {title}
              </Title>
              <div className="absolute -z-10 mt-5 w-4/5">
                <div className="aspect-h-1 aspect-w-1">
                  <svg
                    viewBox="0 0 379 394"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-[-40%] top-[-20%] w-[70%] md:left-[-10%] md:top-[-40%] md:w-2/5 xl:top-[-35%]"
                  >
                    <path
                      d="M273.567 381.033C273.567 381.033 374.983 351.627 378.176 200.245C381.37 48.8627 282.663 23.7045 248.357 7.66644C214.052 -8.37155 110.857 -3.49496 43.0274 63.8294C-24.8021 131.154 -22.4998 358.68 123.452 386.024C228.298 405.667 273.567 381.033 273.567 381.033Z"
                      fill="url(#paint0_linear_2192_20642)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2192_20642"
                        x1="361.231"
                        y1="420.523"
                        x2="20.2458"
                        y2="-20.7512"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FBFBFF" />
                        <stop offset="1" stopColor="#DAE0FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <svg
                    viewBox="0 0 193 184"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-[70%] top-[20%] w-1/2 md:left-[30%] md:top-0 md:w-[30%]"
                  >
                    <path
                      opacity="0.5"
                      d="M176.748 137.962C211.712 70.9581 185.721 10.8103 111.772 1.14919C37.823 -8.51191 -19.9609 43.7179 6.73933 121.062C33.4395 198.407 141.785 204.966 176.748 137.962Z"
                      fill="url(#paint0_linear_2192_20641)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2192_20641"
                        x1="192.111"
                        y1="0"
                        x2="15.2476"
                        y2="208.782"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#F3F5FF" />
                        <stop offset="1" stopColor="#E7EBFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="mt-0 md:mt-0">
                <Swiper
                  pagination={{
                    el: `#${paginationId}`,
                    bulletClass:
                      'h-4 w-4 inline-block rounded-full bg-blue-100 mx-1',
                    clickable: true,
                  }}
                  modules={[Pagination, A11y]}
                  a11y={{
                    prevSlideMessage: 'Vorheriges Bild',
                    nextSlideMessage: 'Nächstes Bild',
                    paginationBulletMessage: 'Gehe zu Bild {{index}}',
                    lastSlideMessage: 'Dies ist das letzte Bild',
                    firstSlideMessage: 'Dies ist das erste Bild',
                  }}
                  slidesPerView={1}
                  spaceBetween={20}
                  loop
                >
                  {testimonials.map((testimonial, index) => (
                    <SwiperSlide
                      key={index}
                      className="flex !h-auto min-h-full flex-col"
                    >
                      <div
                        className={`relative mb-8 mt-5 w-4/5 md:absolute md:top-[10%] md:my-0 md:w-[45%] lg:left-[5%] lg:top-[5%] lg:w-2/5 ${styles['mask-11']} ${styles['mask']}`}
                      >
                        <div className="aspect-h-1 aspect-w-1">
                          <div className="absolute inset-0">
                            <Picture
                              {...testimonial.image}
                              sizes="(min-width: 1024px) 40vw, (min-width: 768px) 45vw, 80vw"
                              fill
                              className="object-cover"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 md:flex">
                        <div className="relative flex h-full flex-col justify-between rounded-lg bg-gradient-100 p-8 md:my-20 md:ml-[50%] md:h-auto md:min-h-[300px] lg:my-32">
                          <Caret className="absolute inset-0 left-[33%] top-[-20px] w-9 text-blue-100 md:left-[-28px] md:my-auto md:-rotate-90" />
                          <Content content={testimonial.text} />
                          <p className="mt-4 font-bold">{testimonial.author}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div id={paginationId} className="mt-10 flex justify-center" />
          </>
        )}
      </div>
    </Container>
  )
}
