import { ForwardedRef, forwardRef } from 'react'
import Input, { InputProps } from './Input'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INPUT_TAG = 'input'

function PhoneInput(
  props: Omit<
    InputProps<typeof INPUT_TAG>,
    'autoComplete' | 'inputMode' | 'type'
  >,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <Input
      {...props}
      ref={ref}
      autoComplete="tel"
      inputMode="tel"
      type="text"
    />
  )
}

export default forwardRef(PhoneInput)
