import classNames from 'classnames'
import { GuideBlock } from '@/src/@types/statamic'
import Container from '../../components/layout/Container'
import Bard from '@/src/components/bard/Bard'
import Picture, {
  illustrationBackgrounds,
} from '@/src/components/elements/Picture'
import styles from '/src/css/Masks.module.css'

export function getTitle({ heading }: GuideBlock) {
  return heading
}

export default function Component({
  heading,
  guide_parts,
  numbered,
}: GuideBlock) {
  return (
    <Container>
      <p className="text-2xl font-extrabold">{heading}</p>
      <div
        className={`${
          guide_parts.length < 4 ? 'md:grid-cols-3' : 'md:grid-cols-4'
        } mt-8 grid snap-x grid-cols-1 overflow-x-auto md:flex lg:grid lg:min-w-0 lg:overflow-x-hidden`}
      >
        {guide_parts.map((guide_part, index) => (
          <div
            key={index}
            className={classNames([
              'border-t border-blue-100 first:border-none md:min-w-[400px] md:border-l md:border-t-0 md:px-5 md:py-0 lg:min-w-0',
              {
                'py-8': index !== 0 && index !== guide_parts.length - 1,
                'pb-8': index === 0,
                'pt-8': index === guide_parts.length - 1,
              },
            ])}
          >
            <div className="relative mx-auto size-52">
              <svg
                width="102"
                height="101"
                viewBox="0 0 102 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-[10%] left-[-20%]"
              >
                <path
                  opacity="0.5"
                  d="M89.7472 18.9626C66.621 -4.75209 41.2718 -4.80699 18.5084 10.9573C-3.78328 27.0911 -5.08067 58.7429 10.0163 82.0199C25.1133 105.297 58.7277 105.174 79.2501 92.3652C99.7726 79.5567 112.874 42.6773 89.7472 18.9626Z"
                  fill="url(#paint0_linear_3406_42)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3406_42"
                    x1="-6.97"
                    y1="4.545"
                    x2="109.921"
                    y2="92.2015"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FBFBFF" />
                    <stop offset="1" stopColor="#DAE0FF" />
                  </linearGradient>
                </defs>
              </svg>

              <svg
                width="142"
                height="140"
                viewBox="0 0 142 140"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-[-10%] right-[-10%]"
              >
                <path
                  opacity="0.5"
                  d="M124.942 26.2848C92.7468 -6.58706 57.4568 -6.66316 25.7665 15.1884C-5.26691 37.552 -7.07309 81.4258 13.9443 113.691C34.9616 145.956 81.7581 145.785 110.329 128.031C138.899 110.277 157.138 59.1567 124.942 26.2848Z"
                  fill="url(#paint0_linear_3406_41)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3406_41"
                    x1="-9.70333"
                    y1="6.3"
                    x2="152.519"
                    y2="128.479"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#FBFBFF" />
                    <stop offset="1" stopColor="#DAE0FF" />
                  </linearGradient>
                </defs>
              </svg>

              {numbered && (
                <>
                  <svg
                    width="65"
                    height="63"
                    viewBox="0 0 65 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute bottom-[-5%] left-[-20%]"
                  >
                    <path
                      d="M57.1919 11.8282C42.4545 -2.96418 26.3006 -2.99842 11.7945 6.83476C-2.41091 16.8984 -3.23768 36.6416 6.38294 51.1609C16.0036 65.6802 37.4245 65.6034 50.5025 57.6139C63.5806 49.6245 71.9292 26.6205 57.1919 11.8282Z"
                      fill="url(#paint0_linear_3422_1838)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3422_1838"
                        x1="77.5125"
                        y1="63"
                        x2="-14.3108"
                        y2="-5.02536"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#501DEC" />
                        <stop offset="0.0001" stopColor="#511DEC" />
                        <stop offset="1" stopColor="#8D41ED" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <p className="absolute bottom-[-2%] left-[-10%] text-7xl font-extrabold text-white">
                    {index + 1}
                  </p>
                </>
              )}

              <div
                className={`absolute inset-0 mx-auto size-52 ${
                  guide_part.image.illustration_background
                    ? illustrationBackgrounds[
                        guide_part.image.illustration_background.value
                      ]
                    : 'bg-gradient-600'
                } ${styles['mask-11']} ${styles['mask']}`}
              >
                <div className="absolute inset-0">
                  <Picture
                    priority
                    {...guide_part.image}
                    fill
                    className="object-contain"
                  />
                </div>
              </div>
            </div>
            <p className="mt-10 text-4xl font-extrabold">{guide_part.title}</p>
            <Bard
              className="mt-4"
              content={guide_part.text}
              fullWidth
              textSize="allBase"
            />
          </div>
        ))}
      </div>
    </Container>
  )
}
