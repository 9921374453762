import Icon from './Icon'

export default function ArrowIcon({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.6875 10.625V10.5625H10.625H3.125C2.97582 10.5625 2.83274 10.5032 2.72725 10.3977C2.62176 10.2922 2.5625 10.1492 2.5625 9.99998C2.5625 9.8508 2.62176 9.70772 2.72725 9.60223C2.83274 9.49674 2.97582 9.43748 3.125 9.43748H10.625H10.6875V9.37498V4.37498C10.6875 4.26374 10.7205 4.15499 10.7823 4.06249C10.8441 3.97 10.932 3.8979 11.0347 3.85533C11.1375 3.81275 11.2506 3.80161 11.3597 3.8233C11.4688 3.845 11.5691 3.89856 11.6477 3.97721L17.2727 9.6022C17.325 9.65444 17.3664 9.71645 17.3947 9.7847C17.423 9.85295 17.4375 9.92611 17.4375 9.99998C17.4375 10.0739 17.423 10.147 17.3947 10.2153C17.3664 10.2835 17.325 10.3455 17.2727 10.3978L11.6477 16.0228C11.6477 16.0228 11.6477 16.0228 11.6477 16.0228C11.5691 16.1014 11.4688 16.155 11.3597 16.1767C11.2506 16.1984 11.1375 16.1872 11.0347 16.1446C10.932 16.1021 10.8441 16.03 10.7823 15.9375C10.7205 15.845 10.6875 15.7362 10.6875 15.625V10.625Z"
            fill="currentColor"
            stroke="#currentColor"
            strokeWidth="0.125"
          />
        </svg>
      }
    />
  )
}
