'use client'

import { WarningCircle } from '@phosphor-icons/react'
import * as Label from '@radix-ui/react-label'
import { cva, VariantProps } from 'class-variance-authority'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, ReactNode, useId } from 'react'
import { cn } from '@/core/lib/utils'

const inputStyle = cva(
  'rounded border pl-3 transition-colors duration-200 hover:duration-150 disabled:cursor-not-allowed',
  {
    variants: {
      color: {
        blue: [
          'border-blue-125 bg-blue-25',
          'focus:border-purple-600 focus:ring-1 focus:ring-purple-600',
          'disabled:border-transparent disabled:bg-blue-50 disabled:text-gray-600',
        ],
        white: [
          'border-blue-125 bg-white',
          'focus:border-purple-600 focus:ring-1 focus:ring-purple-600',
          'disabled:border-transparent disabled:text-gray-600',
        ],
      },
    },
    defaultVariants: {
      color: 'blue',
    },
  }
)

const inputLabelStyle = cva(undefined, {
  variants: {
    type: {
      float: [
        'absolute left-3 origin-left cursor-text text-sm text-gray-600 transition-all peer-disabled:text-gray-500',
        'pointer-events-none',
        'top-[6px] scale-75',
        'peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100',
        'peer-focus:top-[6px] peer-focus:translate-y-0 peer-focus:scale-75',
      ],
      block: '-order-1 mb-1 block text-sm',
    },
  },
  defaultVariants: {
    type: 'float',
  },
})

export type InputProps = Omit<ComponentPropsWithoutRef<'input'>, 'children'> &
  VariantProps<typeof inputStyle> & {
    label: string | ReactNode
    kind?:
      | 'default'
      | 'firstName'
      | 'lastName'
      | 'tel'
      | 'street'
      | 'email'
      | 'zip'
      | 'place'
    labelType?: 'float' | 'block' | 'placeholder'
    error?: string
    hideErrorMessage?: boolean
    errorMessageColor?: 'red' | 'white'
  }

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      color = 'blue',
      kind = 'default',
      label,
      labelType = 'float',
      error,
      hideErrorMessage,
      errorMessageColor = 'red',
      ...props
    },
    ref
  ) => {
    const inputId = useId()

    let type = 'text'
    let autoComplete = undefined
    let inputMode: ComponentPropsWithoutRef<'input'>['inputMode'] = undefined

    switch (kind) {
      case 'firstName':
        autoComplete = 'given-name'
        break
      case 'lastName':
        autoComplete = 'family-name'
        break
      case 'tel':
        autoComplete = 'tel'
        inputMode = 'tel'
        break
      case 'street':
        autoComplete = 'street-address'
        break
      case 'email':
        type = 'email'
        autoComplete = 'email'
        break
      case 'zip':
        autoComplete = 'postal-code'
        break
      case 'place':
        autoComplete = 'address-level2'
        break
    }

    if (typeof label !== 'string' && labelType === 'placeholder') {
      throw new Error('label must be a string when labelType is not block')
    }

    return (
      <div>
        {labelType === 'block' && (
          <Label.Root
            htmlFor={inputId}
            className={cn(inputLabelStyle({ type: labelType }))}
          >
            {label}
          </Label.Root>
        )}
        <div className="relative flex w-full flex-col">
          <input
            ref={ref}
            id={inputId}
            placeholder={labelType === 'placeholder' ? (label as string) : ' '}
            className={cn(
              inputStyle({ color }),
              {
                'pb-[7.5px] pt-[22.5px]': labelType === 'float',
                'py-[15px]': labelType === 'block',
                'border-red-600 pr-12 focus:ring-1 focus:ring-red-600 focus:border-red-600':
                  !!error,
              },
              'peer',
              className
            )}
            type={type}
            autoComplete={autoComplete}
            inputMode={inputMode}
            {...props}
          ></input>
          {labelType === 'float' && (
            <Label.Root
              htmlFor={inputId}
              className={cn(inputLabelStyle({ type: labelType }))}
            >
              {label}
            </Label.Root>
          )}
          {error && (
            <WarningCircle
              weight="fill"
              size={20}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-red-600"
            />
          )}
        </div>
        {error && !hideErrorMessage && (
          <p
            className={clsx('ml-0.5 mt-1 text-xs', {
              'text-red-600': errorMessageColor === 'red',
              'text-white': errorMessageColor === 'white',
            })}
          >
            {error}
          </p>
        )}
      </div>
    )
  }
)
// @ts-ignore
Input.displayName = 'core-input'

export { Input }
