import Icon from './Icon'

export default function ExamMarker({ className }: { className: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.69185 5.60807C8.69134 2.66214 12.7581 1.00494 17 1C21.2419 1.00494 25.3087 2.66214 28.3082 5.60807C31.3076 8.554 32.995 12.5481 33 16.7143C33 30.1602 18.4545 40.3164 17.8352 40.7419C17.5903 40.9099 17.2987 41 17 41C16.7013 41 16.4097 40.9099 16.1648 40.7419C15.5455 40.3164 1 30.1602 1 16.7143C1.00503 12.5481 2.69236 8.554 5.69185 5.60807Z"
            fill="#F9A8D4"
            stroke="white"
          />
          <path
            d="M25.2812 13.2594C25.1053 13.0599 24.8888 12.9002 24.6464 12.7909C24.4039 12.6815 24.141 12.6249 23.875 12.625H19.5V11.375C19.5 10.5462 19.1708 9.75134 18.5847 9.16529C17.9987 8.57924 17.2038 8.25 16.375 8.25C16.2589 8.24992 16.145 8.28218 16.0462 8.34317C15.9474 8.40417 15.8676 8.49148 15.8156 8.59531L12.8641 14.5H9.5C9.16848 14.5 8.85054 14.6317 8.61612 14.8661C8.3817 15.1005 8.25 15.4185 8.25 15.75V22.625C8.25 22.9565 8.3817 23.2745 8.61612 23.5089C8.85054 23.7433 9.16848 23.875 9.5 23.875H22.9375C23.3943 23.8752 23.8354 23.7085 24.1781 23.4065C24.5208 23.1044 24.7413 22.6876 24.7984 22.2344L25.7359 14.7344C25.7692 14.4703 25.7458 14.2022 25.6674 13.9479C25.589 13.6936 25.4574 13.4589 25.2812 13.2594ZM9.5 15.75H12.625V22.625H9.5V15.75Z"
            fill="#831843"
          />
        </svg>
      }
    />
  )
}
