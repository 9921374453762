import camelCase from 'lodash-es/camelCase'
import upperFirst from 'lodash-es/upperFirst'

export function fileName(path: string, includeDirectories = 0): string {
  const parts = path.split('/')

  const newPath = parts
    .slice(parts.length - (includeDirectories + 1))
    ?.join('/')
  return newPath?.split('.')[0] || ''
}

export function pascalCase(snakeCaseString: string): string {
  return upperFirst(camelCase(snakeCaseString))
}
