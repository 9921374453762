import Bard from '../../components/bard/Bard'
import { PageInfo } from '@/src/@types/models'
import { ContentBlock } from '@/src/@types/statamic'
import Container from '@/src/components/layout/Container'

export default function Content({
  sidebar,
  content,
  pageInfo,
}: ContentBlock & { pageInfo: PageInfo }) {
  return (
    <Container>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-32">
        <div
          className={`${
            sidebar.length === 0 ? 'lg:col-span-3' : 'lg:col-span-2'
          } w-full`}
        >
          <Bard
            content={content}
            fullWidth={sidebar.length > 0}
            pageInfo={pageInfo}
          />
        </div>
        {sidebar.length > 0 && (
          <div className="mt-20 lg:mt-0">
            <Bard
              content={sidebar}
              fullWidth
              textSize="base"
              sidebar
              pageInfo={pageInfo}
            />
          </div>
        )}
      </div>
    </Container>
  )
}
