import classNames from 'classnames'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'

export default function Sticky({
  children,
  stickyTopSpace = 0,
  className = '',
  parentClassName = '',
}: {
  children: ReactNode
  stickyTopSpace?: number
  className?: string
  parentClassName?: string
}): ReactElement {
  // Absolute positions for fixed element
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(0)

  // Whether the element is currently fixed or not
  const [fixed, setFixed] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // handle resize event as this changes the absolute positioning of the element
    const handleResize = () => {
      if (containerRef.current) {
        const currentLeft = containerRef.current.getBoundingClientRect().left
        setLeft(currentLeft)
        setRight(
          window.document.body.clientWidth -
            containerRef.current.getBoundingClientRect().width -
            currentLeft
        )
      }
    }

    // Handle scroll events
    const handleScroll = () => {
      if (containerRef.current && innerRef.current) {
        if (
          containerRef.current.getBoundingClientRect().top < stickyTopSpace &&
          containerRef.current.getBoundingClientRect().top >
            0 - containerRef.current.clientHeight &&
          containerRef.current.clientHeight !== innerRef.current.clientHeight
        ) {
          setFixed(true)
          // check if element would overflow at the bottom and subtract from top property accordingly
          if (
            containerRef.current.clientHeight +
              containerRef.current.getBoundingClientRect().top <
            innerRef.current.clientHeight + stickyTopSpace
          ) {
            setTop(
              containerRef.current.clientHeight +
                containerRef.current.getBoundingClientRect().top -
                innerRef.current.clientHeight
            )
          } else {
            setTop(stickyTopSpace)
          }
        } else {
          setFixed(false)
        }
      }
    }

    handleResize()
    handleScroll()

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [containerRef, stickyTopSpace])

  return (
    <div
      ref={containerRef}
      className={classNames('h-full w-full', parentClassName)}
    >
      <div
        ref={innerRef}
        className={classNames([
          {
            fixed: fixed,
          },
          className,
        ])}
        style={{
          top: `${top}px`,
          left: `${left}px`,
          right: `${right}px`,
        }}
      >
        {children}
      </div>
    </div>
  )
}
