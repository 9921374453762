import classNames from 'classnames'
import {
  ComponentPropsWithoutRef,
  createElement,
  ElementType,
  Fragment,
} from 'react'
import ArrowIcon from '../icons/ArrowIcon'
import CustomLink from './CustomLink'

type ArrowActionProps<TAG> = TAG extends typeof DEFAULT_TAG_ACTION
  ? { href: string }
  : { href: never }

const sizes = {
  base: 'text-base',
  small: 'text-sm',
}

const styles = {
  purple: 'text-purple-600 hover:text-purple-800',
  white: 'text-white',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_TAG_ACTION = 'a'

export default function ArrowAction<
  TAG extends ElementType = typeof DEFAULT_TAG_ACTION,
>({
  as,
  href,
  children,
  size = 'base',
  style = 'purple',
  back = false,
  ...props
}: Omit<ComponentPropsWithoutRef<TAG>, 'className' | 'href' | 'styles'> & {
  as?: TAG
  size?: keyof typeof sizes
  style?: keyof typeof styles
  back?: boolean
  children: string
} & ArrowActionProps<TAG>) {
  const tag = as || 'a'

  const className = classNames(
    sizes[size],
    styles[style],
    'inline-flex items-center font-bold group'
  )

  if (tag !== 'a') {
    return createElement(
      tag,
      {
        ...props,
        className,
      },
      back ? (
        <Fragment>
          <ArrowIcon className="mr-2 size-5 rotate-180 transition group-hover:-translate-x-2" />
          <span>{children}</span>{' '}
        </Fragment>
      ) : (
        <Fragment>
          <span>{children}</span>{' '}
          <ArrowIcon className="ml-2 size-5 transition group-hover:translate-x-2" />
        </Fragment>
      )
    )
  }

  return (
    <CustomLink href={href} target={props.target}>
      <a className={className} {...props}>
        {(back && (
          <>
            <ArrowIcon className="mr-2 size-5 rotate-180 transition group-hover:-translate-x-2" />
            <span>{children}</span>{' '}
          </>
        )) || (
          <>
            <span>{children}</span>{' '}
            <ArrowIcon className="ml-2 size-5 transition group-hover:translate-x-2" />
          </>
        )}
      </a>
    </CustomLink>
  )
}
