import dayjs, { Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useState } from 'react'
import Content from '../elements/Content'
import { BardDateOfBirthChecker as BardDateOfBirthCheckerType } from '@/src/@types/statamic'
import DateInput from '@/src/components/form/DateInput'
import FloatingLabel from '@/src/components/form/FloatingLabel'

export default function BardDateOfBirthChecker({
  optional_title,
  type: _type,
  ...texts
}: BardDateOfBirthCheckerType) {
  dayjs.extend(customParseFormat)
  const [dateOfBirth, setDateOfBirth] = useState('')
  const isCompleteDate = dateOfBirth.length === 10
  const date = isCompleteDate ? dayjs(dateOfBirth, 'DD.MM.YYYY') : dayjs()
  const age = dayjs().diff(date, 'year')
  const isValidDate = age > 0 && age < 100
  const earliestTheoryExam = date.add(17, 'year').subtract(1, 'month')
  const earliestTheoryExamRequest = earliestTheoryExam.subtract(1, 'month')
  const earliestPracticalExam = date.add(18, 'year')

  const text = isValidDate && isCompleteDate ? determineText(date, texts) : ''

  const replacePlaceholders = (text?: string) =>
    (text || '')
      .replace('{{theory}}', earliestTheoryExam.format('DD.MM.YYYY'))
      .replace('{{practical}}', earliestPracticalExam.format('DD.MM.YYYY'))
      .replace(
        '{{theoryRequest}}',
        earliestTheoryExamRequest.format('DD.MM.YYYY')
      )

  return (
    <div className="max-w-prose">
      <div className="rounded-lg bg-gradient-100 p-12">
        <div className="grid grid-cols-2 gap-6">
          <label htmlFor="dateOfBirth" className="block text-xl font-bold">
            {optional_title}
          </label>
          <FloatingLabel label="Geburtsdatum" id="dateOfBirth">
            {(props) => (
              <DateInput
                {...props}
                onChange={(event) => setDateOfBirth(event.currentTarget.value)}
              />
            )}
          </FloatingLabel>
        </div>
        {text && (
          <div className="mt-12">
            <Content content={replacePlaceholders(text)} />
          </div>
        )}
      </div>
    </div>
  )
}

function determineText(
  dateOfBirth: Dayjs,
  {
    almost_out_of_range,
    out_of_range,
    in_range,
    not_in_range,
  }: Omit<BardDateOfBirthCheckerType, 'title' | 'type'>
) {
  const age = dayjs().diff(dateOfBirth, 'year')
  const monthUntilOutOfRange = dateOfBirth.diff(
    dayjs().subtract(20, 'year'),
    'month'
  )
  if (age < 17) {
    return not_in_range
  }

  if (age < 20 && monthUntilOutOfRange > 2) {
    return in_range
  }

  if (monthUntilOutOfRange <= 2 && age < 20) {
    return almost_out_of_range
  }

  return out_of_range
}
