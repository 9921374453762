import CustomLink from './CustomLink'
import StarRating from '@/src/components/elements/StarRating'
import { normalizeInternational, swissFormat } from '@/src/lib/phone'

export type GoogleRatingType = {
  rating?: number
  rating_count?: number
  rating_url: string
  name: string
  phone?: string
  email?: string
  street: string
  place: string
  zip: string
  longitude?: number
  latitude?: number
}

export default function GoogleRating({
  phone,
  email,
  street,
  place,
  zip,
  rating,
  rating_count,
  rating_url,
  mapOnly = false,
}: GoogleRatingType & { mapOnly?: boolean }) {
  return (
    <div>
      <address className="not-italic">
        {place && (
          <span className="text-xl font-bold">BLINK Fahrschule {place}</span>
        )}
        {(street && place && zip && (
          <p className="my-2">
            {street}
            <br />
            {zip} {place}
          </p>
        )) || <br />}
        {email && (
          <a
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-purple-600 no-underline hover:text-purple-800"
          >
            {email}
          </a>
        )}
        {phone && (
          <>
            {email && <br />}
            <a
              href={`tel:${normalizeInternational(phone)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold text-purple-600 no-underline hover:text-purple-800"
            >
              {swissFormat(phone)}
            </a>
          </>
        )}
      </address>
      {!mapOnly && rating && rating > 0 ? (
        <CustomLink href={rating_url}>
          <a>
            <div className="mt-5 space-y-2">
              <StarRating rating={rating} />
              <p>{rating_count} Bewertungen auf Google</p>
            </div>
          </a>
        </CustomLink>
      ) : null}
    </div>
  )
}
