import classNames from 'classnames'
import Content, { textSizes } from '../elements/Content'
import { BardText as BardTextType } from '@/src/@types/statamic'

export default function BardText({
  text,
  fullWidth,
  textSize,
  sidebar,
}: BardTextType & {
  fullWidth?: boolean
  textSize?: keyof typeof textSizes
  sidebar?: boolean
}) {
  return (
    <div>
      <Content
        content={text}
        className={classNames([
          {
            'md:max-w-[33.333%]': sidebar,
            'max-w-full lg:max-w-full': fullWidth,
            'max-w-full lg:max-w-[66.667%]': !fullWidth,
          },
        ])}
        textSize={textSize}
      />
    </div>
  )
}
