import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import { getSpecificTeamMembers } from '../../AdminApiClient'
import { TeamSelectedBlock } from '../../@types/statamic'
import Content from '@/src/components/elements/Content'
import classNames from 'classnames'
import Picture from '@/src/components/elements/Picture'
import styles from '/src/css/Masks.module.css'

type TeamSelectedBlockProps = Awaited<ReturnType<typeof getStaticProps>>

export function getTitle({ title }: TeamSelectedBlock) {
  return title
}

export async function getStaticProps({
  title,
  text,
  team_members,
}: TeamSelectedBlock) {
  const team = await getSpecificTeamMembers(
    team_members.map((team_member) => team_member.value)
  )

  return { team, text, title }
}

export default function Component({
  title,
  text,
  team,
}: TeamSelectedBlockProps) {
  return (
    <div className="md:pb-12 lg:pb-16">
      <Container>
        <div className="max-w-prose md:text-lg">
          <Title style={2}>{title}</Title>
        </div>
        {text && <Content className="mt-8 md:mt-10" content={text} />}
        <div
          className={classNames([
            'mt-10 grid grid-cols-1 gap-x-7 gap-y-10',
            {
              'md:grid-cols-3 lg:grid-cols-4': team.length >= 4,
              'md:grid-cols-3': team.length < 4,
            },
          ])}
        >
          {team.map((teamMember, index) => (
            <div key={index}>
              <div
                className={`max-w-[450px] md:max-w-none ${styles['mask-11']} ${styles['mask']}`}
              >
                <div className="aspect-h-1 aspect-w-1">
                  <div className="absolute inset-0">
                    <Picture
                      permalink={teamMember.image}
                      alt={teamMember.name}
                      sizes="(min-width: 768px) 33vw, 100vw"
                      fill
                      className="object-cover"
                    />
                  </div>
                </div>
              </div>
              <p className="mt-1">{teamMember.shortDescription}</p>
              <p className="mt-2.5 text-4xl font-extrabold">
                {teamMember.name}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
