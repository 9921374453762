import Icon from '@/src/components/icons/Icon'

export default function TiktokLogo({ className = '' }: { className?: string }) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
            fill="#DADADA"
          />
          <path
            d="M35.2598 21.5191C35.0472 21.5398 34.8337 21.5506 34.6202 21.5516C32.2773 21.5519 30.0922 20.3717 28.8089 18.4129V29.1007C28.8089 33.4633 25.2699 37 20.9044 37C16.5389 37 13 33.4634 13 29.1007C13 24.738 16.5389 21.2014 20.9044 21.2014C21.0694 21.2014 21.2307 21.2162 21.393 21.2264V25.1191C21.2307 25.0996 21.0713 25.07 20.9044 25.07C18.6764 25.07 16.8702 26.875 16.8702 29.1016C16.8702 31.3282 18.6764 33.1333 20.9044 33.1333C23.1329 33.1333 25.1009 31.3787 25.1009 29.1517L25.1398 11H28.8663C29.2177 14.3396 31.9123 16.9481 35.2635 17.1929V21.5191"
            fill="white"
          />
        </svg>
      }
    />
  )
}
