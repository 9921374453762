export default function Caret({ className }: { className: string }) {
  return (
    <svg
      className={className}
      viewBox="0 0 38 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 -1.66103e-06L-7.56909e-07 22L38 22L19 -1.66103e-06Z"
        fill="currentColor"
      />
    </svg>
  )
}
