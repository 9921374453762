import classNames from 'classnames'
import { useId } from 'react'
import Content from '@/src/components/elements/Content'
import Picture from '@/src/components/elements/Picture'
import Title from '@/src/components/elements/Title'
import Sticky from '@/src/components/layout/Sticky'
import { ContentFactsBlock, ImageType } from '../../@types/statamic'
import Container from '../../components/layout/Container'
import styles from '/src/css/Masks.module.css'

export function getTitle({ title }: ContentFactsBlock) {
  return title
}

export function getDescription({ text }: ContentFactsBlock) {
  if (text) {
    const pTag = text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export function getImage({ required_images }: ContentFactsBlock) {
  return required_images[0]
}

export default function Component({
  title,
  text,
  facts,
  required_images: images,
  switch_sides,
}: ContentFactsBlock) {
  return (
    <Container>
      <div className="flex flex-col md:grid md:grid-cols-2">
        <div>
          <Title style={2}>{title}</Title>
          {text && (
            <Content content={text} className="mt-6 max-w-full lg:max-w-full" />
          )}
          <div
            className={classNames([
              'ml-8 flex flex-col space-y-6',
              {
                'my-10': text,
                'mb-10 mt-8': !text,
                'lg:ml-0 lg:grid lg:grid-cols-2 lg:gap-x-6 lg:space-y-0':
                  facts.length === 2,
              },
            ])}
          >
            {facts.map((fact, index) => (
              <div key={index} className="relative">
                {fact.emoji && (
                  <p
                    className={classNames([
                      'absolute -left-8 top-1',
                      {
                        'lg:hidden': facts.length === 2,
                      },
                    ])}
                  >
                    {fact.emoji}
                  </p>
                )}
                <p className="text-xl font-extrabold">
                  {fact.emoji && (
                    <span
                      className={classNames([
                        'mr-2 hidden',
                        {
                          'lg:inline': facts.length === 2,
                        },
                      ])}
                    >
                      {fact.emoji}
                    </span>
                  )}
                  {fact.title}
                </p>
                <Content textSize="sm" className="mt-1" content={fact.text} />
              </div>
            ))}
          </div>
        </div>
        <Sticky
          stickyTopSpace={80}
          className={classNames([
            'hidden items-start justify-center pb-[150px] pt-[60px] md:flex',
            {
              '-translate-x-12 lg:-translate-x-20': switch_sides,
            },
          ])}
          parentClassName={classNames('-order-1', {
            'order-2': !switch_sides,
          })}
        >
          <ImageBubbles images={images} factLength={facts.length} />
        </Sticky>
        <div className="-order-1 flex items-start justify-center pb-[50px] md:hidden">
          <ImageBubbles images={images} factLength={facts.length} />
        </div>
      </div>
    </Container>
  )
}

const ImageBubbles = ({
  images,
  factLength,
}: {
  images: ImageType[]
  factLength: number
}) => {
  const gradientIdOne = `svg-gradient-${useId()}`
  const gradientIdTwo = `svg-gradient-${useId()}`

  return (
    <div className="-z-10 w-[70%]">
      <div className="aspect-h-1 aspect-w-1 relative">
        <svg
          width="289"
          height="281"
          viewBox="0 0 289 281"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-[60%] size-1/2"
        >
          <path
            d="M271.768 75.2772C232.714 4.68101 116.936 -24.7937 52.2923 27.4312C-12.351 79.6562 -7.92209 164.002 16.8613 210.923C41.6446 257.843 80.3793 296.838 180.73 273.566C281.08 250.294 310.822 145.873 271.768 75.2772Z"
            fill={`url(#${gradientIdOne})`}
          />
          <defs>
            <linearGradient
              id={gradientIdOne}
              x1="288.354"
              y1="0.879883"
              x2="19.0796"
              y2="315.026"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3F5FF" />
              <stop offset="1" stopColor="#E7EBFF" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          width="77"
          height="74"
          viewBox="0 0 77 74"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute left-full top-[80%] size-1/5"
        >
          <path
            d="M72.1577 19.485C61.7884 0.995533 31.0478 -6.72404 13.8842 6.95393C-3.27933 20.6319 -2.10341 42.7226 4.47687 55.0112C11.0572 67.2998 21.3417 77.5129 47.986 71.4178C74.6303 65.3227 82.5271 37.9745 72.1577 19.485Z"
            fill={`url(#${gradientIdTwo})`}
          />
          <defs>
            <linearGradient
              id={gradientIdTwo}
              x1="76.5616"
              y1="0"
              x2="6.19106"
              y2="83.2277"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3F5FF" />
              <stop offset="1" stopColor="#E7EBFF" />
            </linearGradient>
          </defs>
        </svg>
        <div
          className={classNames([
            'bg-gradient-700',
            styles['mask-10'],
            styles['mask'],
          ])}
        >
          <Picture
            priority
            sizes="65vw"
            {...images[0]}
            fill
            className="object-cover"
          />
        </div>
        {factLength >= 4 && images[1] && (
          <div
            className={classNames([
              'absolute left-[65%] top-[96%] hidden h-[45%] w-[45%] bg-gradient-700 md:block',
              styles['mask-10'],
              styles['mask'],
            ])}
          >
            <Picture
              priority
              sizes="65vw"
              {...images[1]}
              fill
              className="object-cover"
            />
          </div>
        )}
      </div>
    </div>
  )
}
