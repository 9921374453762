import { BardText, SliderHeroBlock } from '../../@types/statamic'
import Container from '../../components/layout/Container'
import { PageInfo } from '@/src/@types/models'
import Bard from '@/src/components/bard/Bard'
import CompactSliderHeader from '@/src/components/elements/CompactSliderHeader'
import CustomLink from '@/src/components/elements/CustomLink'
import JsonLd from '@/src/components/elements/JsonLd'
import SliderHeader from '@/src/components/elements/SliderHeader'
import Title from '@/src/components/elements/Title'
import Button from '@/src/components/form/Button'
import TrackingCrossIcon from '@/src/components/icons/TrackingCrossIcon'
import locateMeService from '@/src/lib/locateMeService'

export function getTitle({ title }: SliderHeroBlock) {
  return title
}

export function getDescription({ hero_content }: SliderHeroBlock) {
  const bardText = hero_content.find((bard) => bard.type === 'text') as BardText
  if (bardText) {
    const pTag = bardText.text.match(/.*?<p>(.*?)<\/p>/)
    if (pTag) {
      return pTag[1]
    }
  }
  return undefined
}

export function getImage({ images }: SliderHeroBlock) {
  return images && images[0]
}

export default function Component({
  title,
  heading,
  hero_content,
  images,
  compact,
  link_link,
  link_text,
  locate_me_button,
  google_rating,
  pageInfo,
}: SliderHeroBlock & { pageInfo: PageInfo }) {
  const locateMe = () => {
    const interactiveMap = document.getElementById('interactive_map-1')
    if (interactiveMap) {
      interactiveMap.scrollIntoView({ behavior: 'smooth' })
      locateMeService.locateMe()
    }
  }

  return (
    <div className="relative mt-[-30px] xl:-mt-12 xl:min-h-[500px]">
      {pageInfo && google_rating && (
        <JsonLd
          data={{
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Blink AG',
            description: `BLINK Fahrschule - ${google_rating.place}`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: google_rating.street,
              addressLocality: google_rating.place,
              postalCode: google_rating.zip,
            },
            aggregateRating:
              google_rating.rating && google_rating.rating > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: google_rating.rating,
                    ratingCount: google_rating.rating_count,
                    bestRating: 5,
                    worstRating: 1,
                  }
                : undefined,
            geo:
              google_rating.latitude && google_rating.longitude
                ? {
                    '@type': 'GeoCoordinates',
                    latitude: google_rating.latitude,
                    longitude: google_rating.longitude,
                  }
                : undefined,
            // priceRange: courses[0] ? `${courses[0].price} CHF` : undefined,
            // hasMap: `https://g.page/${gmbShortname}`,
            openingHours: ['Mo,Tu,We,Th,Fr 09:00-17:00', 'Sa 09:00-12:00'],
            telephone: google_rating.phone,
            logo: pageInfo.settings.logo?.permalink,
            url: pageInfo.fullUrl,
            image: pageInfo.socialImage
              ? [pageInfo.socialImage?.permalink]
              : undefined,
          }}
        />
      )}
      <div>
        <Container fullPageOnMobile>
          <div className="flex flex-col md:flex-row">
            <div
              className={`relative z-10 order-2 mt-4 w-full px-7 pt-6 md:order-1 md:mt-0 md:max-w-sm md:px-0 md:pt-12 lg:max-w-xl`}
            >
              <Title style={1}>{title}</Title>
              <Title
                style={compact ? 'headerCompact' : 'header'}
                className="mt-4 lg:mt-8"
              >
                {heading}
              </Title>
              <Bard
                content={hero_content}
                className="mt-4 md:mt-5 lg:mt-7"
                fullWidth
              />
              {link_link && link_text && !locate_me_button && (
                <CustomLink href={link_link} passHref>
                  <Button as="a" className="mt-7 md:mt-7 lg:mt-10">
                    {link_text}
                  </Button>
                </CustomLink>
              )}
              {locate_me_button && link_text && (
                <Button
                  className="mt-7 inline-flex items-center md:mt-7 lg:mt-10"
                  onClick={() => locateMe()}
                >
                  <TrackingCrossIcon className="size-6" />
                  <span className="ml-2">{link_text}</span>
                </Button>
              )}
            </div>
            {images && images.length !== 0 && (
              <div className="relative order-1 w-full flex-1 md:order-2 md:w-auto">
                {(compact && (
                  <CompactSliderHeader
                    images={images}
                    google_rating={google_rating}
                  />
                )) || <SliderHeader images={images} />}
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}
