import { ValidateResult } from 'react-hook-form'

export function colorStartingWithB(color: any): ValidateResult {
  if (!color)
    return 'Die Eingabe muss einer Farbe entsprechen und mit "B" beginnen.'

  if (typeof color !== 'string')
    return 'Die Eingabe muss einer Farbe entsprechen und mit "B" beginnen.'

  return ['blau', 'blaugrün', 'blaugrau', 'braun', 'bronze', 'beige'].includes(
    color.toLocaleLowerCase()
  )
    ? true
    : 'Die Eingabe muss einer Farbe entsprechen und mit "B" beginnen.'
}
