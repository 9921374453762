import Booking from '@/src/components/booking/Booking'
import BookingContextProvider from '@/src/components/booking/BookingContext'
import Container from '@/src/components/layout/Container'

export default function Component() {
  return (
    <Container>
      <BookingContextProvider>
        <Booking />
      </BookingContextProvider>
    </Container>
  )
}
