'use client'

import { MagnifyingGlass, X } from '@phosphor-icons/react'
import * as Label from '@radix-ui/react-label'
import { VariantProps, cva } from 'class-variance-authority'
import { Command as CommandPrimitive } from 'cmdk'
import {
  ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  useContext,
  useRef,
  useState,
} from 'react'
import { cn } from '@/core/lib/utils'

const ComboboxContext = createContext<{
  value: string
  setValue: (value: string) => void
  isFocused: boolean
  setIsFocused: (isFocused: boolean) => void
} | null>(null)

const useComboboxContext = () => {
  const context = useContext(ComboboxContext)

  if (!context) {
    throw new Error(
      'useComboboxContext must be used within a ComboboxContextProvider'
    )
  }

  return context
}

const Combobox = forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive> & {
    value: string
    setValue: (value: string) => void
  }
>(({ className, value, setValue, ...props }, ref) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <ComboboxContext.Provider
      value={{
        value,
        setValue,
        isFocused,
        setIsFocused,
      }}
    >
      <CommandPrimitive
        ref={ref}
        className={cn('relative', className)}
        {...props}
      />
    </ComboboxContext.Provider>
  )
})
// @ts-ignore
Combobox.displayName = CommandPrimitive.displayName

const inputStyle = cva(
  [
    'rounded-t border-x border-b-0 border-t px-11 focus:ring-0 disabled:cursor-not-allowed',
    'placeholder-shown:rounded placeholder-shown:border',
    'focus:rounded-b-none focus:border-b-0',
  ],
  {
    variants: {
      color: {
        blue: [
          'bg-blue-25',
          'border-blue-125',
          'focus:border-blue-125',
          'disabled:border-transparent disabled:bg-blue-50 disabled:text-gray-600',
        ],
        white: [
          'border-blue-125 bg-white',
          'border-blue-125',
          'focus:border-blue-125',
          'disabled:border-transparent disabled:text-gray-600',
        ],
      },
    },
    defaultVariants: {
      color: 'blue',
    },
  }
)

const ComboboxInput = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Input> &
    VariantProps<typeof inputStyle> & { label: string }
>(({ className, color = 'blue', label, ...props }, _ref) => {
  // TODO: Merge refs

  const inputRef = useRef<HTMLInputElement>(null)
  const { value, setValue, setIsFocused } = useComboboxContext()

  return (
    <div className={cn('relative', className)} cmdk-input-wrapper="">
      <MagnifyingGlass
        size={20}
        weight="bold"
        className="pointer-events-none absolute left-3 top-[18px] my-auto text-purple-500"
      />
      <CommandPrimitive.Input
        ref={inputRef}
        value={value}
        onValueChange={setValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={cn(
          inputStyle({ color }),
          'pb-[7.5px] pt-[22.5px]',
          'w-full',
          'peer'
        )}
        placeholder={props.placeholder ?? ''}
        {...props}
      />
      {value && (
        <button
          className="absolute inset-y-0 right-3 my-auto text-purple-500"
          onClick={() => {
            setValue('')
            inputRef.current?.focus()
          }}
        >
          <X size={20} weight="bold" />
        </button>
      )}
      <Label.Root
        className={cn([
          'absolute left-11 origin-left cursor-text text-sm text-gray-600 transition-all peer-disabled:text-gray-500',
          'top-[13.5px] -translate-y-1/2 scale-75',
          'peer-placeholder-shown:top-1/2 peer-placeholder-shown:scale-100',
          'peer-focus:top-[13.5px] peer-focus:scale-75',
          'pointer-events-none',
        ])}
      >
        {label}
      </Label.Root>
    </div>
  )
})
// @ts-ignore
ComboboxInput.displayName = CommandPrimitive.Input.displayName

const listStyle = cva('rounded-b border-x border-b', {
  variants: {
    color: {
      blue: 'border-blue-125 bg-blue-25',
      white: 'border-blue-125 bg-white',
    },
  },
  defaultVariants: {
    color: 'blue',
  },
})

const ComboboxList = forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List> &
    VariantProps<typeof listStyle>
>(({ className, color = 'blue', ...props }, ref) => {
  const { value, isFocused } = useComboboxContext()

  if (!value && !isFocused) return null

  return (
    <CommandPrimitive.List
      ref={ref}
      className={cn(
        listStyle({ color }),
        'absolute inset-x-0 z-50 max-h-[185px] overflow-y-auto overflow-x-hidden',
        className
      )}
      {...props}
    />
  )
})
// @ts-ignore
ComboboxList.displayName = CommandPrimitive.List.displayName

const ComboboxEmpty = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cn('py-2 text-center text-sm', className)}
    {...props}
  />
))
// @ts-ignore
ComboboxEmpty.displayName = CommandPrimitive.Empty.displayName

const ComboboxItem = forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'cursor-default select-none px-3 py-2 text-sm outline-none aria-selected:bg-blue-125 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50',
      className
    )}
    {...props}
  />
))
// @ts-ignore
ComboboxItem.displayName = CommandPrimitive.Item.displayName

export { Combobox, ComboboxInput, ComboboxList, ComboboxEmpty, ComboboxItem }
