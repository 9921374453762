import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import { ImageType, NavigationLink } from '../../@types/statamic'
import Button from '../form/Button'
import BurgerIcon from '../icons/BurgerIcon'
import Container from '../layout/Container'
import CustomLink from './CustomLink'
import PhoneButton from './PhoneButton'
import WhatsappButton from './WhatsappButton'

export default function TopMobileNavigation({
  logo,
  scrolled = false,
  mainNavigation,
  secondaryNavigation,
}: {
  logo?: ImageType
  scrolled?: boolean
  mainNavigation: NavigationLink[]
  secondaryNavigation: NavigationLink[]
}) {
  const [showNavigation, setShowNavigation] = useState(false)
  const [activeEntry, setActiveEntry] = useState<NavigationLink>()
  const [showSubNavigation, setShowSubNavigation] = useState(false)
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', close)

    return () => router.events.off('routeChangeComplete', close)
  }, [router.events])

  const chooseEntry = (entry: NavigationLink) => {
    setActiveEntry(entry)
    setShowSubNavigation(true)
  }

  const backToMain = () => {
    setShowSubNavigation(false)
  }

  const close = () => {
    setActiveEntry(undefined)
    setShowNavigation(false)
    setShowSubNavigation(false)
  }

  const toggleNavigation = () => {
    if (showNavigation) {
      close()
    } else {
      setShowNavigation(true)
    }
  }

  return (
    <div
      className={`${scrolled || showNavigation ? 'bg-white shadow-lg' : ''} ${
        showNavigation ? 'inset-0' : 'inset-x-0 top-0 z-10'
      } fixed flex flex-col transition-colors`}
    >
      <div className="w-full">
        <Container>
          <div className="flex h-16 items-center justify-between xl:h-24">
            {logo && (
              <Link
                href="/"
                className={classNames([
                  'block',
                  {
                    'w-24 xl:w-32':
                      process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE ===
                      'default',
                    'w-[92px]':
                      process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'nohe',
                  },
                ])}
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={logo.permalink} alt={logo.alt} className="w-full" />
                <span className="sr-only">Startseite</span>
              </Link>
            )}

            <ul className="flex items-center space-x-3">
              {process.env.NEXT_PUBLIC_STATAMIC_SITE_HANDLE === 'default' && (
                <>
                  <li>
                    <PhoneButton />
                  </li>
                  <li>
                    <WhatsappButton />
                  </li>
                </>
              )}
              <li>
                <button
                  type="button"
                  className="flex items-center"
                  onClick={toggleNavigation}
                  aria-expanded={showNavigation}
                  aria-controls={
                    showNavigation ? 'mobile-navigation' : undefined
                  }
                >
                  <BurgerIcon className="size-6" />
                  {showNavigation && (
                    <span className="sr-only">Navigation verstecken</span>
                  )}
                  {!showNavigation && (
                    <span className="sr-only">Navigation anzeigen</span>
                  )}
                </button>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div
        aria-hidden={!showNavigation}
        id="mobile-navigation"
        className={`${
          showNavigation ? '' : 'hidden'
        } relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden border-t border-gray-50`}
      >
        <Transition
          show={!showSubNavigation}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="opacity-0 -translate-x-full"
          enterTo="opacity-100 translate-x-0"
          leave="transition ease-in-out duration-300 transform absolute top-0 left-0"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-full"
        >
          <ul className="space-y-4 px-6 pt-12">
            {mainNavigation.map((entry, index) => (
              <Fragment key={index}>
                {(!entry.children[0] && entry.page.url && (
                  <li>
                    <CustomLink href={entry.page.url}>
                      <a className="text-4xl font-extrabold">
                        {entry.page.title}
                      </a>
                    </CustomLink>
                  </li>
                )) || (
                  <li>
                    <button
                      type="button"
                      className="flex items-center text-4xl font-extrabold"
                      onClick={() => chooseEntry(entry)}
                    >
                      <span>{entry.page.title}</span>
                      <svg
                        className="ml-4 text-purple-700"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 7L1 7M15 7L9 13M15 7L9 1"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>

          <ul className="mt-12 space-y-4 px-6 pb-12">
            {secondaryNavigation.map((entry, index) => (
              <li key={index}>
                {entry.children.length === 0 ? (
                  <CustomLink href={entry.page.url || '#'}>
                    <a className="text-xl leading-[1.625rem]">
                      {entry.page.title}
                    </a>
                  </CustomLink>
                ) : (
                  <button
                    type="button"
                    className="flex items-center text-xl font-medium leading-[1.625rem]"
                    onClick={() => chooseEntry(entry)}
                  >
                    <span>{entry.page.title}</span>
                    <svg
                      className="ml-4 text-purple-700"
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7L1 7M15 7L9 13M15 7L9 1"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                )}
              </li>
            ))}
          </ul>

          <CustomLink
            href="https://myblink.blinkdrive.ch"
            passHref
            newTab={false}
          >
            <Button as="a" className="mx-4">
              myBLINK
            </Button>
          </CustomLink>
        </Transition>
        {activeEntry && (
          <Transition
            className="flex flex-1 flex-col"
            show={showSubNavigation}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition ease-in-out duration-300 transform absolute top-0 left-0"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 transform-x-full"
          >
            <div className="px-6 pt-12">
              <button
                type="button"
                className="flex items-center text-sm font-bold text-purple-700"
                onClick={backToMain}
              >
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-4"
                >
                  <line
                    x1="1"
                    y1="-1"
                    x2="12.5"
                    y2="-1"
                    transform="matrix(-1 0 0 1 15.0627 8)"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M6.0625 1L1 7L6.0625 13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span>zurück</span>
              </button>
            </div>
            {activeEntry.children[0] &&
            activeEntry.children[0].children.length > 0 ? (
              <>
                <div className="mt-12 px-6">
                  <h3 className="text-sm font-bold text-blue-900/50">
                    {activeEntry.page.url ? (
                      <CustomLink href={activeEntry.page.url}>
                        <a>{activeEntry.children[0].page.title}</a>
                      </CustomLink>
                    ) : (
                      activeEntry.children[0].page.title
                    )}
                  </h3>
                  <ul className="mt-[22px]">
                    {activeEntry.children[0].children.map((link, index) => (
                      <SubNavigationLink key={index} link={link} />
                    ))}
                  </ul>
                </div>

                {activeEntry.children[1] &&
                  activeEntry.children[1].children.length && (
                    <div className="mt-12 flex-1 bg-gradient-700 px-6 py-12 text-xl text-white">
                      <h3 className="text-sm font-bold text-white/70">
                        {activeEntry.children[1].page.title}
                      </h3>
                      <ul className="mt-[22px] font-bold">
                        {activeEntry.children[1].children.map((link, index) => (
                          <SubNavigationLink key={index} link={link} />
                        ))}
                      </ul>
                    </div>
                  )}
              </>
            ) : (
              <div className="mt-12 px-6">
                <ul>
                  {activeEntry.children.map((link, index) => (
                    <SubNavigationLink key={index} link={link} />
                  ))}
                </ul>
              </div>
            )}
          </Transition>
        )}
      </div>
    </div>
  )
}

function SubNavigationLink({ link }: { link: NavigationLink }) {
  if ((link.children && link.children.length !== 0) || !link.page.url) {
    return (
      <li className="mt-[22px] first:mt-0">
        {(link.page.url && (
          <CustomLink href={link.page.url}>
            <a className="text-lg font-bold leading-[2.75rem] text-blue-900">
              {link.page.title}
            </a>
          </CustomLink>
        )) || (
          <h4 className="text-lg font-bold leading-[2.75rem] text-blue-900">
            {link.page.title}
          </h4>
        )}
        <ul className="text-lg font-medium leading-[2.75rem]">
          {link.children.map((link, index) => {
            return <SubNavigationLink key={index} link={link} />
          })}
        </ul>
      </li>
    )
  }

  return (
    <li className="text-lg leading-[2.75rem] first:mt-0">
      <CustomLink href={link.page.url}>
        <a>{link.page.title}</a>
      </CustomLink>
    </li>
  )
}
