import Icon from './Icon'

export default function LinkedInIcon({
  className = 'text-base-900',
}: {
  className?: string
}) {
  return (
    <Icon
      className={className}
      label="LinkedIn Logo"
      icon={
        <svg
          viewBox="0 0 25 25"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5.399 8.4H0.399001V24.4H5.399V8.4ZM5.849 2.9C5.86 1.309 4.579 0.011 2.989 0H2.899C1.297 0.001 -0.000999423 1.3 5.77424e-07 2.901C0.00100058 4.502 1.298 5.799 2.899 5.8C4.489 5.839 5.81 4.582 5.849 2.992C5.849 2.991 5.849 2.991 5.849 2.99V2.9ZM24.399 14.68C24.399 9.87 21.339 8 18.299 8C16.273 7.899 14.347 8.881 13.239 10.58H13.099V8.4H8.399V24.4H13.399V15.89C13.249 14.076 14.587 12.479 16.399 12.31H16.589C18.179 12.31 19.359 13.31 19.359 15.83V24.4H24.359L24.399 14.68Z"></path>
        </svg>
      }
    />
  )
}
