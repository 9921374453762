import axios from 'axios'
import trim from 'lodash-es/trim'
import {
  AddressesResponse,
  CourseType,
  LocationNameResponse,
  WabCourseType,
} from './@types/admin'
import { Mandatory } from './@types/util'

const API_ADMIN_URL = `${process.env.NEXT_PUBLIC_BLINK_ADMIN_URL}/api`

export const websiteUrl = (path: string) => `/website/v2/${path}`
export const fullWebsiteUrl = (path: string) =>
  `${API_ADMIN_URL}/website/v2/${path}`

type ApiCourseType = Omit<CourseType, 'location'> & {
  location: CourseType['location'] & {
    latitude: string
    longitude: string
  }
}

export async function get<Response = unknown>(
  url: string,
  params?: Record<string, string | string[] | number[] | number>
): Promise<Response> {
  url = trim(url, '/')

  if (params) {
    const queryParameters = new URLSearchParams()
    Object.entries(params).forEach(([parameterName, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) =>
          queryParameters.append(`${parameterName}[]`, String(v))
        )
      } else {
        queryParameters.append(parameterName, String(value))
      }
    })
    url += '?' + queryParameters.toString()
  }

  const res = await fetch(`${API_ADMIN_URL}/${url}`, {
    headers: { Accept: 'application/json' },
  })

  if (res.status >= 200 && res.status < 300) {
    return await res.json()
  }
  return undefined as any
}

export async function getCourses(
  params: {
    locations?: string[]
    addresses?: string[]
    types?: string[]
  } = { locations: [] }
): Promise<CourseType[]> {
  const courses = await get<ApiCourseType[]>('/website/v2/courses', params)

  return courses.map((course) => ({
    ...course,
    location: {
      ...course.location,
      latitude: Number(course.location.latitude),
      longitude: Number(course.location.longitude),
    },
  }))
}

export async function getWabCourses({
  locations,
}: {
  locations: string[]
}): Promise<WabCourseType[]> {
  return await get<WabCourseType[]>('/website/v2/wab-courses', { locations })
}

export async function getAddresses(
  ids?: string[]
): Promise<Mandatory<AddressesResponse, 'latitude' | 'longitude'>[]> {
  const params: Record<string, string | string[] | number[] | number> = {
    'fields[addresses]': 'latitude,longitude,street',
  }
  if (ids) {
    params['filter[id]'] = ids
  }
  const addresses = await get<AddressesResponse[]>('/cms/v1/addresses', params)

  return addresses.filter(
    (
      location
    ): location is Mandatory<AddressesResponse, 'latitude' | 'longitude'> =>
      !!location.latitude && !!location.longitude
  )
}

export async function getLocationName(id: string): Promise<string | undefined> {
  const location = await get<LocationNameResponse>(
    '/website/v2/locations/' + id
  )

  return location.name ? location.name : undefined
}

export const adminGetFile = async (fileId: string) =>
  axios.get(fullWebsiteUrl(`media/${fileId}`))

export const adminPostFile = async (formData: FormData) =>
  axios.post(fullWebsiteUrl('media'), formData)

export const adminDeleteFile = async (fileId: string) =>
  axios.delete(fullWebsiteUrl(`media/${fileId}`))
