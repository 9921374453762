import { Switch } from '@headlessui/react'
import { ReactNode } from 'react'

export default function Toggle({
  enabled,
  setEnabled,
  children,
}: {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
  children: ReactNode
}) {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? 'bg-gradient-700' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span
            className={`${
              enabled ? 'translate-x-6' : 'translate-x-1'
            } inline-block size-4 rounded-full bg-white transition`}
          />
        </Switch>
        <Switch.Label className="ml-2 cursor-pointer text-sm text-blue-900">
          {children}
        </Switch.Label>
      </div>
    </Switch.Group>
  )
}
