import Link from 'next/link'
import Title from '../../components/elements/Title'
import Container from '../../components/layout/Container'
import Packet from '@/blocks/Packets/Packet'
import { LocationPrices } from '@/src/@types/blinkadmin'
import { PacketsBlock } from '@/src/@types/statamic'
import { get } from '@/src/BlinkAdminApiClient'

export async function getStaticProps({ single_location }: PacketsBlock) {
  const location = await get<{ name: string }>(
    `/website/v2/locations/${single_location.value}`
  )

  const locationPrices = await get<LocationPrices>(
    `/website/v2/locations/${single_location.value}/prices`
  )

  if (
    !locationPrices ||
    locationPrices.lessonB45 === 0 ||
    locationPrices.tenPackage === 0 ||
    locationPrices.fiverPackage === 0
  ) {
    return {
      validLocation: false,
    }
  }

  const vku_course = locationPrices.courses?.find(
    (course) => course.type === 'VKU'
  )

  return {
    validLocation: true,
    locationName: location.name,
    prices: {
      lesson_price: locationPrices.lessonB45 / 100,
      packet_10_price: locationPrices.tenPackage / 100,
      packet_5_price: locationPrices.fiverPackage / 100,
      packet_vku_price: locationPrices.tenPackageWithVKU / 100,
      vku_price: vku_course ? vku_course.min / 100 : 0,
    },
  }
}

export default function Component({
  validLocation,
  locationName,
  prices,
}:
  | {
      validLocation: false
      locationName: never
      prices: never
    }
  | {
      validLocation: true
      locationName: string
      prices: {
        lesson_price: number
        packet_10_price: number
        packet_5_price: number
        packet_vku_price: number
        vku_price: number
      }
    }) {
  if (!validLocation) {
    return null
  }

  return (
    <>
      <div className="md:pb-12 lg:pb-16">
        <Container>
          <Title style={2}>So fährst du günstiger!</Title>
          <ul className="my-5 grid grid-flow-row grid-cols-1 gap-7 md:mt-20 md:grid-cols-3">
            <Packet
              type="single"
              price={prices.lesson_price}
              locationName={locationName}
              index={0}
              pricePerLesson={prices.lesson_price}
              facts={[
                'Für alle, die weniger als 5 Lektionen oder nur Einzellektionen beziehen möchten.',
              ]}
              bookable={false}
            />
            <Packet
              type="10"
              price={prices.packet_10_price}
              locationName={locationName}
              vkuPriceOriginal={
                prices.vku_price !== 0 ? prices.vku_price : undefined
              }
              vkuPricePacket={
                prices.packet_vku_price !== 0
                  ? prices.packet_vku_price
                  : undefined
              }
              highlighted
              index={1}
              pricePerLesson={prices.packet_10_price / 10}
              facts={[
                '10 Fahrlektionen à 45 Minuten',
                `Gesamtpreis: ${prices.packet_10_price} CHF`,
              ]}
            />
            <Packet
              type="5"
              price={prices.packet_5_price}
              locationName={locationName}
              index={2}
              pricePerLesson={prices.packet_5_price / 5}
              facts={[
                '5 Fahrlektionen à 45 Minuten',
                `Gesamtpreis: ${prices.packet_5_price} CHF`,
              ]}
            />
          </ul>
          <p className="md:mt-6">
            Eine Administrations-/Versicherungspauschale von 90 CHF kommt
            einmalig dazu. Mehr Informationen sowie unsere Einzelpreise findest
            du{' '}
            <Link
              href="#prices-1"
              className="font-bold text-purple-600 hover:text-purple-800"
            >
              hier
            </Link>
            .
          </p>
        </Container>
      </div>
    </>
  )
}
