import classNames from 'classnames'
import Map from '@/src/components/elements/Map'

export default function MeetupMap(props: Parameters<typeof Map>[0]) {
  const className = classNames(props.className, 'h-full w-full')
  return (
    <div className="aspect-h-1 aspect-w-1 flex overflow-hidden  rounded-2xl md:aspect-w-2">
      <div>
        <Map {...props} className={className} />
      </div>
    </div>
  )
}
