import { ArrowRight, CaretDown, MapPin } from '@phosphor-icons/react'
import { CaretLeft } from '@phosphor-icons/react/dist/ssr'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { DialogClose } from '@radix-ui/react-dialog'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import axios from 'axios'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { MyBLINK, SwissPlaceSearchResult } from '@/src/@types/blinkadmin'
import { fullWebsiteUrl } from '@/src/BlinkAdminApiClient'
import { useBookingContext } from '@/src/components/booking/BookingContext'
import PlaceSearch from '@/src/components/booking/PlaceSearch'
import Button from '@/src/components/core/Button'
import { DialogBody, DialogHeader } from '@/src/components/core/Dialog'
import Map from '@/src/components/elements/Map'
import Marker from '@/src/components/elements/Marker'

export default function Location() {
  const { location, address, confirmAddress } = useBookingContext()

  const [selectedPlace, setSelectedPlace] =
    useState<SwissPlaceSearchResult | null>(null)
  const [selectedLocation, setSelectedLocation] =
    useState<MyBLINK.BookableLocation | null>(location)
  const [selectedAddress, setSelectedAddress] =
    useState<MyBLINK.BookableAddress | null>(address)
  const [accordionSelected, setAccordionSelected] = useState<
    string | undefined
  >(address?.id.toString())

  const { data: locations, isLoading: locationsLoading } = useSWR<
    MyBLINK.BookableLocation[]
  >(
    selectedPlace && `booking/places/${selectedPlace.id}/locations`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(`booking/places/${selectedPlace!.id}/locations`)
        )
      ).data
  )

  const { data: addresses, isLoading: addressesLoading } = useSWR<
    MyBLINK.BookableAddress[]
  >(
    selectedLocation &&
      `booking/locations/${selectedLocation.id}/addresses?type=pickup`,
    async () =>
      (
        await axios.get(
          fullWebsiteUrl(
            `booking/locations/${selectedLocation!.id}/addresses?type=pickup`
          )
        )
      ).data
  )

  useEffect(() => {
    if (!selectedAddress && addresses && addresses.length === 1) {
      setSelectedAddress(addresses[0])
      setAccordionSelected(addresses[0].id.toString())
    }
  }, [addresses, selectedAddress])

  const confirmSelection = () => {
    if (selectedLocation && selectedAddress) {
      confirmAddress(selectedLocation, selectedAddress)
    }
  }

  return (
    <>
      <DialogHeader title="Wo sollen wir uns treffen?" />
      <DialogBody>
        {selectedLocation === null && (
          <>
            <PlaceSearch
              selectedPlace={selectedPlace}
              setSelectedPlace={setSelectedPlace}
            />
            {(selectedPlace && (
              <div className="mt-8">
                <p className="mb-4">Standorte in der Umgebung</p>
                {locationsLoading && !locations?.length && (
                  <p className="flex items-center justify-center space-x-2 py-2">
                    <svg
                      className="size-4 animate-spin text-purple-700"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span>Suche...</span>
                  </p>
                )}
                <div className="space-y-3">
                  {locations?.map((location) => (
                    <button
                      key={location.id}
                      onClick={() => {
                        setSelectedLocation(location)
                        setSelectedAddress(null)
                      }}
                      className="flex w-full items-center justify-between rounded border border-blue-125 bg-blue-25 px-3 py-4 transition-colors duration-150 hover:bg-blue-100"
                    >
                      <div className="flex items-center">
                        <MapPin
                          size={20}
                          weight="fill"
                          className="shrink-0 opacity-80"
                        />
                        <span className="ml-1 font-bold">{location.name}</span>
                        <span className="ml-2">{location.distance} km</span>
                      </div>
                      <ArrowRight
                        size={20}
                        weight="bold"
                        className="shrink-0 text-purple-500"
                      />
                    </button>
                  ))}
                </div>
              </div>
            )) || <div className="h-56" />}
          </>
        )}
        {selectedLocation && (
          <>
            <button
              onClick={() => {
                setSelectedLocation(null)
                setSelectedAddress(null)
                setAccordionSelected(undefined)
              }}
              className="flex items-center space-x-2 text-purple-600"
            >
              <CaretLeft size={20} weight="bold" />
              <span className="text-sm font-bold">
                Zurück zur Standortsuche
              </span>
            </button>
            <p className="mt-8">
              Unsere Treffpunkte in {selectedLocation.name}
            </p>
            {addressesLoading && !addresses?.length && (
              <p className="flex items-center justify-center space-x-2 py-2">
                <svg
                  className="size-4 animate-spin text-purple-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Suche...</span>
              </p>
            )}
            <RadioGroupPrimitive.Root
              value={selectedAddress?.id.toString()}
              onValueChange={(addressId) => {
                setSelectedAddress(
                  addresses?.find((a) => a.id.toString() === addressId) ?? null
                )
                setAccordionSelected(addressId)
              }}
            >
              <AccordionPrimitive.Root type="single" value={accordionSelected}>
                {addresses?.map((address) => (
                  <AccordionPrimitive.Item
                    key={address.id}
                    value={address.id.toString()}
                    className="group border-b border-blue-100 py-4"
                  >
                    <RadioGroupPrimitive.Item
                      value={address.id.toString()}
                      className="group flex w-full items-center justify-between"
                    >
                      <div className="flex items-center text-left">
                        <div className="box-border size-5 shrink-0 rounded-full border-2 border-purple-600 group-data-[state=checked]:border-[6px]" />
                        <span className="ml-3 grow font-bold">
                          {address.name}
                        </span>
                      </div>
                      <CaretDown
                        size={20}
                        weight="bold"
                        className="ml-4 shrink-0 text-purple-600 transition-transform duration-150 group-data-[state=open]:rotate-180"
                      />
                    </RadioGroupPrimitive.Item>
                    <AccordionPrimitive.Content>
                      {address.longitude && address.latitude && (
                        <div className="aspect-h-2 aspect-w-3 relative mt-2 w-full overflow-hidden rounded-[5px] lg:aspect-h-2 lg:aspect-w-5">
                          <div>
                            <Map
                              markers={[[address.longitude, address.latitude]]}
                              interactive={false}
                              className="size-full"
                            >
                              <Marker
                                position={{
                                  lat: address.latitude,
                                  lng: address.longitude,
                                }}
                                icon="pickup"
                                size="large"
                              >
                                {`<h4 class="font-extrabold text-sm">${address.name}</h4><a class="mt-1 text-sm text-purple-700 underline" href="${address.googleMapLink}" target="_blank" rel="noopener noreferrer">${address.street}<br/>${address.zip} ${address.place}</a>`}
                              </Marker>
                            </Map>
                          </div>
                        </div>
                      )}
                    </AccordionPrimitive.Content>
                  </AccordionPrimitive.Item>
                ))}
              </AccordionPrimitive.Root>
            </RadioGroupPrimitive.Root>
            <div className="mt-8 flex justify-end space-x-4">
              <DialogClose asChild>
                <Button version="purple-ghost">Abbrechen</Button>
              </DialogClose>
              <Button onClick={confirmSelection}>Standort auswählen</Button>
            </div>
          </>
        )}
      </DialogBody>
    </>
  )
}
