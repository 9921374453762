import CustomLink from '../elements/CustomLink'
import AppStoreIcon from '../icons/AppStoreIcon'
import GooglePlayIcon from '../icons/GooglePlayIcon'
import { BardAppLinks as BardAppLinksType } from '@/src/@types/statamic'

export default function BardAppLinks({
  app_store_link,
  google_play_link,
}: BardAppLinksType) {
  return (
    <div className="flex">
      {app_store_link && (
        <CustomLink href={app_store_link}>
          <a>
            <AppStoreIcon className="mr-5 h-10 w-[120px]" />
          </a>
        </CustomLink>
      )}
      {google_play_link && (
        <CustomLink href={google_play_link}>
          <a>
            <GooglePlayIcon className="h-10 w-[136px]" />
          </a>
        </CustomLink>
      )}
    </div>
  )
}
