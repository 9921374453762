import useSWR, { unstable_serialize } from 'swr'
import { CourseType } from '@/src/@types/admin'
import { getCourses } from '@/src/BlinkAdminApiClient'

export default function useCourses(params: Parameters<typeof getCourses>[0]) {
  const key = unstable_serialize(['courses', params?.types, params?.locations])
  const { data } = useSWR(key, () => getCourses(params))

  return [data as CourseType[], key] as const
}
