import { useId, useState } from 'react'

export default function useCollapsable(initial = false) {
  const [open, setOpen] = useState(initial)
  const id = `collapsable-content-${useId()}`

  const toggle = {
    onClick: () => setOpen(!open),
    'aria-expanded': open,
    'aria-controls': open ? id : undefined,
  }

  return {
    open,
    setOpen,
    toggle,
    id,
  } as const
}
