import classNames from 'classnames'

export default function TooltipInfoIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('inline', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.125C13.4873 17.125 17.125 13.4873 17.125 9C17.125 4.51269 13.4873 0.875 9 0.875C4.51269 0.875 0.875 4.51269 0.875 9C0.875 13.4873 4.51269 17.125 9 17.125ZM10.3162 8.25597H7.79368V14H10.3162V8.25597ZM7.84828 5.42218C7.78276 5.57506 7.75 5.73886 7.75 5.91359C7.75 6.09559 7.78276 6.26667 7.84828 6.42683C7.92108 6.57972 8.01573 6.7144 8.13221 6.83088C8.25597 6.94008 8.39793 7.02745 8.5581 7.09297C8.71826 7.15849 8.88934 7.19125 9.07135 7.19125C9.24607 7.19125 9.40987 7.15849 9.56275 7.09297C9.71564 7.02745 9.85032 6.94008 9.9668 6.83088C10.0906 6.7144 10.1852 6.57972 10.2507 6.42683C10.3235 6.26667 10.3599 6.09559 10.3599 5.91359C10.3599 5.73886 10.3235 5.57506 10.2507 5.42218C10.1852 5.26201 10.0906 5.12369 9.9668 5.00721C9.85032 4.89073 9.71564 4.79972 9.56275 4.7342C9.40987 4.6614 9.24607 4.625 9.07135 4.625C8.88934 4.625 8.71826 4.6614 8.5581 4.7342C8.39793 4.79972 8.25597 4.89073 8.13221 5.00721C8.01573 5.12369 7.92108 5.26201 7.84828 5.42218Z"
        fill="currentColor"
      />
    </svg>
  )
}
