import Icon from '@/src/components/icons/Icon'

export default function InstagramLogo({
  className = '',
}: {
  className?: string
}) {
  return (
    <Icon
      className={className}
      icon={
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            opacity="0.2"
            d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
            fill="#DADADA"
          />
          <path
            d="M24 13.35C27.45 13.35 27.9 13.35 29.25 13.45C32.75 13.6 34.4 15.3 34.6 18.8C34.65 20.15 34.65 20.6 34.65 24.05C34.65 27.5 34.65 27.95 34.6 29.3C34.45 32.8 32.8 34.45 29.25 34.65C27.9 34.7 27.45 34.75 24 34.75C20.55 34.75 20.1 34.75 18.75 34.65C15.2 34.5 13.6 32.8 13.4 29.3C13.35 27.95 13.3 27.5 13.3 24.05C13.3 20.6 13.3 20.15 13.4 18.8C13.55 15.3 15.2 13.65 18.75 13.45C20.1 13.35 20.55 13.35 24 13.35ZM24 11C20.45 11 20.05 11 18.65 11.1C13.95 11.3 11.3 13.95 11.1 18.65C11 20.05 11 20.45 11 24C11 27.55 11 27.95 11.1 29.35C11.3 34.05 13.95 36.7 18.65 36.9C20.05 37 20.45 37 24 37C27.55 37 27.95 37 29.35 36.9C34.05 36.7 36.7 34.05 36.9 29.35C37 27.95 37 27.55 37 24C37 20.45 37 20.05 36.9 18.65C36.7 13.95 34.05 11.3 29.35 11.1C27.95 11 27.55 11 24 11ZM24 17.3C20.3 17.35 17.35 20.35 17.4 24.05C17.45 27.7 20.35 30.6 24 30.65C27.7 30.6 30.65 27.6 30.6 23.9C30.55 20.3 27.65 17.35 24 17.3ZM24 28.35C21.6 28.35 19.65 26.4 19.65 24C19.65 21.6 21.6 19.65 24 19.65C26.4 19.7 28.3 21.65 28.25 24.05C28.2 26.4 26.35 28.3 24 28.35ZM30.95 15.5C30.1 15.5 29.4 16.2 29.4 17.05C29.4 17.9 30.1 18.6 30.95 18.6C31.8 18.6 32.5 17.9 32.5 17.05C32.5 16.2 31.8 15.5 30.95 15.5Z"
            fill="white"
          />
        </svg>
      }
    />
  )
}
