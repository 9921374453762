import {
  BookingState,
  useBookingContext,
} from '@/src/components/booking/BookingContext'
import Overview from '@/src/components/booking/Overview'
import Slots from '@/src/components/booking/Slots'
import Summary from '@/src/components/booking/Summary'

export default function Booking() {
  const { config } = useBookingContext()

  if (config.state === BookingState.Summary) {
    return (
      <div className="rounded-lg bg-white py-4 @container lg:col-span-7 lg:p-10">
        <Summary />
      </div>
    )
  }

  return (
    <div className="grid gap-7 @container lg:grid-cols-10">
      <div className="rounded-lg bg-white py-4 lg:col-span-7 lg:p-10">
        <h1 className="text-2xl font-extrabold lg:text-4xl">
          Deine Probelektion für{' '}
          {config.voucher ? config.voucher.new_price : 59} CHF
        </h1>
        <div className="@6xl:hidden">
          <Slots step="halfWeek" />
        </div>
        <div className="hidden @6xl:block">
          <Slots step="week" />
        </div>
      </div>
      <div className="lg:sticky lg:top-4 lg:col-span-3 lg:self-start">
        <Overview />
      </div>
    </div>
  )
}
