import Link from 'next/link'
import IconButton from '../form/IconButton'
import WhatsappIcon from '../form/WhatsappIcon'

export default function WhatsappButton({
  message = 'Liebes BLINK Team, ',
  number = '41315391065',
}: {
  message?: string
  number?: string
}) {
  const link = `https://wa.me/${number}?text=${encodeURIComponent(message)}`

  return (
    <Link href={link} passHref legacyBehavior>
      <IconButton
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        style="whatsapp"
      >
        <WhatsappIcon className="size-4" />
        <span className="sr-only">Kontakt Whatsapp</span>
      </IconButton>
    </Link>
  )
}
